import React from "react";
import { RxCross2 } from "react-icons/rx";

const GuestPasswordModal = ({ setGuestPasswordModal, randomPassword }) => {
  return (
    <>
      <div className="w-full font-Trueno bg-[#AEE0FF]">
        <div className="fixed inset-0 overflow-y-auto scrollbar-hide  bg-black bg-opacity-25 backdrop-blur-sm px-3 md:px-9 py-40">
          <div className="md:w-[50%] h-auto mx-auto bg-white px-3 md:px-6 flex justify-center border rounded-[15px] relative">
            <button
              className=" font-bold uppercase  py-2 text-sm outline-none focus:outline-none mb-1 absolute right-5 top-7"
              type="button"
            >
              <RxCross2
                onClick={() => {
                  setGuestPasswordModal(false);
                  sessionStorage.removeItem("guest");
                }}
                className="w-[18px] md:w-[22px] h-[18px] md:h-[22px]"
              />
            </button>
            <div className="flex flex-col gap-5 py-20 w-full mx-auto text-center">
              <h1 className="font-extrabold text-xl md:text-[24px] leading-[29.26px]">Guest User Created Successfully</h1>
              <p className="text-sm text-black font-normal leading-6">Your guest user has been created against</p>
              <p className="text-sm text-black font-normal leading-6">
                Mobile Number:
                <span className="text-[#0973BA] text-base font-bold"> {sessionStorage.getItem("mobile_number")}</span>
              </p>

              <p className="text-sm text-black font-normal leading-6">
                Your Default Password is:
                <span className="text-[#0973BA] font-bold text-base"> 123456</span>
              </p>

              <p className="text-sm text-black font-normal leading-6">
                Please save it and change your password from your account setting
              </p>

              <button
                type="button"
                onClick={() => {
                  setGuestPasswordModal(false);
                  sessionStorage.removeItem("guest");
                }}
                className="font-semibold text-[14px] leading-[12px] text-white h-[46px] px-5 w-fit mx-auto border bg-[#0973BA] rounded-lg"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestPasswordModal;
