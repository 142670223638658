import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const useAuth = () => {
  const token = localStorage.getItem("token");
  const user = { loggedIn: !!token };
  return user && user.loggedIn;
};

const PortectedRoutes = () => {
  const navigate = useNavigate();
  const isAuth = useAuth();

  function handleLoginSuccess() {
    navigate("/home");
  }

  return isAuth ? <Outlet /> : <Navigate to="/login" state={{ from: navigate.location }} onLoginSuccess={handleLoginSuccess} />;
};

export default PortectedRoutes;
