import React from "react";

const CustomerChooseIcons = (props) => {
  return (
    <>
      <div className="flex w-full">
        <div className="flex flex-col items-center w-[90%] font-Trueno">
          <div className="rounded-full bg-white  h-[60px] w-[60px] p-[20px]">
            <img src={props.picture} alt="pictures" className="" />
          </div>
          <div className="">
            <h4 className="text-center text-[15px] md:text-[16px] leading-[18.6px] font-normal mt-3">{props.heading}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerChooseIcons;
