import React from "react";

const AdminUserLogin = () => {
  return (
    <>
      <div>
        <p>This is me Usama</p>
      </div>
    </>
  );
};

export default AdminUserLogin;
