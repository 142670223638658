import * as yup from "yup";
import { useFormik } from "formik";
import OTPPasscode from "./OTPPasscode";
import { useEffect, useState } from "react";
import { FaFacebookF } from "react-icons/fa";
import SocialOTPModal from "./SocialOTPModal";
import RightSideBar from "common/RightSideBar";
import { AiOutlineGoogle } from "react-icons/ai";
import { useForgotPassword } from "hooks/useUser";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import BookingNavbar from "views/partials/navbar/BookingNavbar";
import { auth, provider } from "views/firebaseAuthentication/config";
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import PhoneNumberModal from "views/firebaseAuthentication/PhoneNumberModal";
import { useLogin, useSendOtp, useSync_login_social_account } from "hooks/useAuth";

const LogIn = () => {
  const navigate = useNavigate();
  const { mutate, isLoading, data } = useLogin();
  const { mutate: sendOtp_mutate } = useSendOtp();
  const { mutate: forgot_pass_mutate } = useForgotPassword();

  const {
    mutate: sync_social_account_mutate,
    data: sync_social_account_data,
    isLoading: sync_social_account_isLoading,
  } = useSync_login_social_account();

  const [showPassword, setShowPassword] = useState(true);
  const [step, setStep] = useState(1);
  const [user, setUser] = useState("");
  const [socialPhoneModal, setSocialPhoneModal] = useState(false);
  const [social_data, setSocial_data] = useState("");
  const [mobile_number, setMobile_number] = useState();
  const [auth_user, setAuth_user] = useState("");

  const handle_sync_payload = () => {
    const payload = {
      mobile: mobile_number,
      device_type: "web",
      device_token: "qweqweqweqweqweqweqwe",
      login_by: user,
      google_unique_id: user === "google" ? social_data?.user?.providerData?.[0]?.uid : "",
      facebook_unique_id: user === "facebook" ? social_data?.user?.providerData?.[0]?.uid : "",
    };
    sync_social_account_mutate(payload);
  };

  const handleFacebookLogin = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((data) => {
        setSocial_data(data);
        const payload = {
          mobile: mobile_number,
          device_type: "web",
          device_token: "qweqweqweqweqweqweqwe",
          login_by: "facebook",
          facebook_unique_id: data?.user?.providerData?.[0]?.uid,
        };
        sync_social_account_mutate(payload);
      })
      .catch((error) => {
        console.error("facebook sign-in error:", error);
      });
  };

  const handleGoogleLogin = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        setSocial_data(data);
        const payload = {
          mobile: mobile_number,
          device_type: "web",
          device_token: "qweqweqweqweqweqweqwe",
          login_by: "google",
          google_unique_id: data?.user?.providerData?.[0]?.uid,
        };
        sync_social_account_mutate(payload);
      })
      .catch((error) => {
        console.error("Google sign-in error:", error);
      });
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    phone: "",
    password: "",
    device_type: "web",
    login_by: "manual",
  };

  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      password: yup.string().min(6).required("Enter Your Password"),
      phone: yup
        .string()
        .matches(/^[0-9]+$/, "Invalid phone number")
        .max(11)
        .min(11)
        .required("Enter Phone Number"),
    }),
    validateOnChange: true,
    validateOnBlur: false,

    onSubmit: async (values) => {
      const { phone, password } = values;

      const newObj = {
        mobile: phone,
        password,
        login_by: "manual",
        device_type: "web",
        id: "",
      };

      mutate(newObj);
      sessionStorage.setItem("mobile_number", newObj?.mobile);
    },
  });
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } = Formik;

  const handleNext = () => {
    setStep(step + 1);
  };
  const handleSecondNext = () => {
    setStep(step + 2);
  };

  useEffect(() => {
    if (data?.data?.otpverified === 0) {
      const id_token = {
        token: data?.data?.token,
        id: data?.data?.user_id,
      };
      setAuth_user(id_token);

      localStorage.setItem("token", data?.data?.token);
      localStorage.setItem("id", data?.data?.user_id);

      sendOtp_mutate();
      setUser("manual");

      handleNext();
    } else if (data?.success === true) {
      const id_token = {
        token: data?.data?.token,
        id: data?.data?.user_id,
      };
      setAuth_user(id_token);

      localStorage.setItem("token", data?.data?.token);
      localStorage.setItem("id", data?.data?.user_id);

      // api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      navigate("/home");
    }
  }, [data]);

  useEffect(() => {
    if (sync_social_account_data?.success === true) {
      navigate("/");
    } else if (sync_social_account_data?.success === false) {
      setSocialPhoneModal(true);
    }
  }, [sync_social_account_data]);

  return (
    <>
      {step === 1 && (
        <div className="flex flex-col w-full  font-Trueno bg-[#F0F7FC]">
          <BookingNavbar color="#F0F7FC" />

          <RightSideBar />
          <div className="w-[95%] md:w-[80%] mx-auto my-20 px-[5px] lg:px-[100px]">
            <div className=" flex flex-col-reverse md:grid md:grid-cols-2 gap-9 justify-between items-center border rounded-[20px] shadow-2xl py-6 px-5 md:pl-12 md:pr-6  bg-white">
              {/* --------------------- Form Area --------------------- */}
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-5 md:gap-6">
                  <h1 className="font-extrabold text-[22px] md:text-[36px] leading-[26.82px] md:leading-[43px]">
                    Log in to <span className="text-[#0973BA]">Karsaaz</span>
                  </h1>
                  <label className="font-normal text-[14px] leading-[16.8px]">
                    If you are a Professional then you should use Karsaaz App to Login
                  </label>
                  <div className="flex flex-col gap-3 w-full">
                    {/* ------------------ Phone Number Field ------------------ */}

                    <div className="flex flex-col gap-3">
                      <label htmlFor="phone Number" className="font-semibold text-xs md:text-sm">
                        Mobile Number
                      </label>
                      <input
                        type="Phone"
                        autoComplete="off"
                        name="phone"
                        id="Phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Mobile Number"
                        className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 text-black placeholder:font-normal  placeholder-black-500  placeholder:text-xs  md:placeholder:text-sm"
                      />
                      {errors.phone && touched.phone ? (
                        <p className="font-semibold text-sm text-red-600">{errors.phone}</p>
                      ) : null}
                    </div>

                    {/* ------------------ Password Field ------------------ */}

                    <div className="flex flex-col gap-3 relative">
                      <label htmlFor="phone Number" className="font-semibold text-xs md:text-sm">
                        Password
                      </label>
                      <input
                        type={showPassword ? "password" : "text"}
                        autoComplete="off"
                        name="password"
                        id="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Password"
                        className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs  md:placeholder:text-sm"
                      />
                      {errors.password && touched.password ? (
                        <p className="font-semibold text-sm text-red-600">{errors.password}</p>
                      ) : null}

                      <div onClick={togglePassword} className="cursor-pointer absolute inset-y-[48px] right-3 ">
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </div>
                    </div>
                  </div>
                  <button className="font-extrabold text-sm text-[#0973BA] text-right">
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </button>

                  <button
                    type="submit"
                    className="font-semibold text-[14px] leading-[12px] text-white h-[46px] w-full border bg-[#0973BA] rounded-lg relative"
                  >
                    {isLoading ? (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div role="status" className="absolute inset-y-3.8">
                          <div role="status" className="">
                            <svg
                              aria-hidden="true"
                              className="w-6 h-6 mr-2 text-slate-200 animate-spin  fill-[#0973BA]"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      "Log In"
                    )}
                  </button>

                  <div className="flex gap-2 justify-between items-center ">
                    <hr className="w-full border-gray-300 border-0.5 hidden md:block" />
                    <h1 className="w-full whitespace-nowrap text-center font-semibold text-[12px] leading-[14.63px]">
                      Don't have a Karsaaz account?
                    </h1>
                    <hr className="w-full border-gray-300 border-0.5 hidden md:block" />
                  </div>
                  <Link to="/register">
                    <button
                      type="button"
                      className="font-semibold text-sm text-[#0973BA] py-[14px] w-full border border-[#0973BA] rounded-lg "
                    >
                      Create Account
                    </button>
                  </Link>

                  <div className="flex gap-2 justify-between items-center ">
                    <hr className="w-full border-gray-300 border-0.5 hidden md:block" />
                    <h1 className="w-full whitespace-nowrap text-center font-semibold text-[12px] leading-[14.63px]">
                      Login using your account on
                    </h1>
                    <hr className="w-full border-gray-300 border-0.5 hidden md:block" />
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      setUser("facebook");
                      handleFacebookLogin();
                    }}
                    className="flex justify-center items-center font-semibold text-xs md:text-sm text-white text-center bg-[#1773EA] py-[14px] px-4 w-full rounded-lg"
                  >
                    {sync_social_account_isLoading && user === "facebook" ? (
                      <p className="font-semibold text-xs md:text-sm text-white text-center">Please Wait...</p>
                    ) : (
                      <div className="w-full flex justify-center items-center">
                        <FaFacebookF className="flex-none grow-0 w-[24px] h-[24px]" />
                        <p className="grow">Facebook</p>
                      </div>
                    )}
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      setUser("google");
                      handleGoogleLogin();
                    }}
                    className="flex justify-center items-center font-semibold text-xs md:text-sm text-white text-center bg-[#EB4235] py-[14px] px-4 w-full rounded-lg"
                  >
                    {sync_social_account_isLoading && user === "google" ? (
                      <p className="font-semibold text-xs md:text-sm text-white text-center">Please Wait...</p>
                    ) : (
                      <div className="w-full flex justify-center items-center">
                        <AiOutlineGoogle className="flex-none grow-0 w-[24px] h-[24px]" />
                        <p className="grow">Google</p>
                      </div>
                    )}
                  </button>
                </div>
              </form>

              {/* --------------------- Image Area --------------------- */}

              <div
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/images/createAccountImages/RoundRingIcon.png)`,
                }}
                className="bg-[#0973BA] rounded-[20px] bg-no-repeat bg-right flex md:flex-col justify-between w-full h-full"
              >
                <div className="m-5 md:flex justify-center items-center hidden h-[100%] w-[100%] p-4">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/createAccountImages/VerticalLogInImage.png`}
                    alt=""
                    className="object-cover h-auto xl:max-h-auto max-w-full"
                  />
                </div>
                <div className="m-5 flex justify-center items-center sm:hidden h-[100%] w-[100%] p-4">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/createAccountImages/HorizontalLogInImage.png`}
                    alt=""
                    className="  object-cover h-auto max-w-full"
                  />
                </div>
              </div>

              {/* --------------------- Social Phone Number Modal --------------------- */}

              {socialPhoneModal && (
                <PhoneNumberModal
                  setSocialPhoneModal={setSocialPhoneModal}
                  user={user}
                  sync_social_account_isLoading={sync_social_account_isLoading}
                  mobile_number={mobile_number}
                  setMobile_number={setMobile_number}
                  forgot_pass_mutate={forgot_pass_mutate}
                  handleSecondNext={handleSecondNext}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {/* This OTP component is for manual user if the user is not verified. */}

      {step === 2 && (
        <div>
          <OTPPasscode user={user} auth_user={auth_user} handle_sync_payload={handle_sync_payload} />
        </div>
      )}

      {/* This OTP component is for social user authentication if the user is not verified. */}

      {step === 3 && (
        <div>
          <SocialOTPModal
            mobile={mobile_number}
            user={user}
            handle_sync_payload={handle_sync_payload}
            setStep={setStep}
            sync_social_account_data={sync_social_account_data}
          />
        </div>
      )}
    </>
  );
};

export default LogIn;
