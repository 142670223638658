import RightSideBar from "common/RightSideBar";
import BookingNavbar from "views/partials/navbar/BookingNavbar";
import AppStoreButton from "theme/buttons/AppStoreButton";
import PlayStoreButton from "theme/buttons/PlayStoreButton";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { useEnv_verification_values } from "hooks/useUser";

const JoinAsServiceProvider = () => {
  const { data } = useEnv_verification_values();

  return (
    <>
      <div className="flex flex-col w-full  font-Trueno bg-[#F0F7FC]">
        <BookingNavbar color="#F0F7FC" />

        <RightSideBar />
        <div className="w-[90%] mx-auto my-20  md:px-[40px] xl:px-[140px]">
          <div className=" flex flex-col-reverse md:grid md:grid-cols-2 gap-9 justify-between items-center border rounded-[20px] shadow-2xl px-3 py-6 md:pl-10 md:pr-6 bg-white">
            <div className="flex flex-col ">
              <div className="flex flex-col gap-3 items-center md:items-start overflow-x-clip">
                <h1 className="font-bold text-[22px] md:text-[36px] leading-[26.4px] md:leading-[43.2px] text-center md:text-left">
                  Join <label className="text-[#0973BA]">Karsaaz App </label>
                  as a Service Provider For Free
                </h1>
                <label className="font-normal text-[14px] leading-[16.8px]">Download Karsaaz App</label>
                <div className="grid lg:flex gap-1">
                  <AppStoreButton />
                  <PlayStoreButton />
                </div>
              </div>
              <div className="flex flex-col gap-4 mt-20 items-center md:items-start overflow-x-hidden">
                <h1 className="font-bold text-[22px] leading-[23px]">Alternatively</h1>
                <p className="font-normal text-base  text-center md:text-left">
                  Contact our customer support team by clicking on call or WhatsApp buttons{" "}
                </p>

                {/* -------------------------- Buttons -------------------------- */}
                <div className="grid md:flex md:flex-row gap-4 md:gap-2 w-full md:w-[460px]">
                  <a href={`tel:${data?.data?.karsaaz_mobile}`}>
                    <button className="bg-[#0973BA] flex gap-3 justify-center items-center w-full md:w-[120px] font-semibold text-[14px] leading-[23px] text-white rounded-xl py-3">
                      <FaPhoneAlt color="white" className="flex-none ml-[15px] w-[18px] h-[18px]" />
                      <p className="grow mr-[33px] md:mr-[18px]">Call Us</p>
                    </button>
                  </a>
                  <a href={`https://wa.me/92${data?.data?.karsaaz_whatsapp.slice(1)}`} target="_blank" rel="noreferrer">
                    <button className="bg-[#25D366] flex gap-3 justify-center items-center w-full md:w-[120px] font-semibold text-[14px] leading-[23px] text-white rounded-xl py-3">
                      <FaWhatsapp color="white" className="flex-none ml-[15px] w-[20px] h-[20px]" />
                      <p className="grow mr-[33px] md:mr-[15px]">Whatsapp</p>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            {/* --------------------- Image Area --------------------- */}

            <div
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/createAccountImages/RoundRingIcon.png)`,
              }}
              className="bg-[#0973BA] rounded-[20px]  bg-no-repeat bg-right flex md:flex-col justify-between w-full h-full"
            >
              <div className="md-3 md:m-10 ">
                <img
                  src={`${process.env.PUBLIC_URL}/images/createAccountImages/KarsaazSingleLogo.svg`}
                  alt=""
                  className=" h-[80px] w-[89.43px]"
                />
              </div>
              <div className="">
                <img
                  src={`${process.env.PUBLIC_URL}/images/createAccountImages/ServiceProviderIcon.svg`}
                  alt=""
                  className="h-full  md:h-[435px] object-cover"
                />
              </div>
              {/* <img
                src={ServiceProviderIcon}
                alt=""
                className="block sm:hidden object-cover   border border-black"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinAsServiceProvider;
