import { useFormik } from "formik";
import * as yup from "yup";
import { useAbandonedLogIn } from "hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "store/features/toastSlice";
import { useDispatch } from "react-redux";
import { facebook_conversions } from "views/conversionAPI/FacebookConversions";

const AbandonedPhoneNumber = ({ setCurrentStep, sub_category_is_bidded }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate, data } = useAbandonedLogIn();

  const initialValues = {
    phone: "",
  };
  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      phone: yup
        .string()
        .matches(/^[0-9]+$/, "Invalid phone number")
        .max(11)
        .min(11)
        .required("Enter Phone Number"),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const { phone } = values;

      const newObj = {
        mobile: phone,
        password: "#2@&%!_",
        login_by: "manual",
        device_type: "web",
      };
      mutate(newObj);
      sessionStorage.setItem("mobile_number", newObj?.mobile);

      if (data?.data?.isbuyer === "no") {
        dispatch(showErrorToast("Provider not allowed, try again with buyer account !"));
      }

      setCurrentStep(sub_category_is_bidded === "no" || sub_category_is_bidded === "only" ? 2 : 1);
    },
  });

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } = Formik;

  return (
    <>
      <div className="px-3 md:px-0 w-full font-Trueno">
        <div className="my-[31px] md:my-0 pb-9 ">
          <h4 className="text-sm text-black font-normal leading-6 mb-5">You are almost there!</h4>
          <h1 className="font-extrabold text-xl md:text-[24px] leading-[29.26px]">
            Enter Your Mobile Number to <span className="text-[#0973BA]">Continue</span>
          </h1>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4 mt-5">
            <div className="flex flex-col gap-3 w-full">
              {/* ------------------ Phone Number Field ------------------ */}

              <div className="flex flex-col gap-3 my-11">
                <label htmlFor="phone Number" className="font-semibold text-xs md:text-sm">
                  Mobile Number
                </label>
                <input
                  type="Phone"
                  autoComplete="off"
                  name="phone"
                  id="Phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="03xxx"
                  className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-4 md:pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs md:placeholder:text-sm"
                />
                {errors.phone && touched.phone ? <p className="font-semibold text-sm text-red-600">{errors.phone}</p> : null}
              </div>

              <div className="flex gap-4 items-center w-fit mx-auto mt-11">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button"
                  className="cursor-pointer rounded-xl  bg-[#DFDFDF] py-[10px] px-8 font-semibold text-[#707070] transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    handleSubmit();
                    facebook_conversions("Next");
                  }}
                  type="submit"
                  className="cursor-pointer rounded-lg bg-[#0973BA] py-[10px] px-8 font-semibold uppercase text-white transition duration-200 ease-in-out"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AbandonedPhoneNumber;
