import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAbandoned_request } from "hooks/useUser";
import { facebook_conversions } from "views/conversionAPI/FacebookConversions";

export default function StepperControl({
  handleClick,
  currentStep,
  steps,
  images,
  formik,
  selectedMarketingOption,
  comment,
  description,
  selectedDate,
  selectedTime,
  address,
  latitude,
  longitude,
  setCurrentStep,
  handle_request_submit,
  loading,
}) {
  const navigate = useNavigate();

  const { mutate: abandoned_request_mutate } = useAbandoned_request({ images });

  const bookingValue = parseInt(localStorage.getItem("selectedBooking"));
  const card_json_data = localStorage.getItem("cardDetails");
  const CardDetails = JSON.parse(card_json_data);

  const title = CardDetails.title;
  const category_id = CardDetails.category_id;
  const sub_category_id = CardDetails.sub_category_id;
  const sub_category_is_bidded = CardDetails.sub_category_is_bidded;

  const processAbandonedRequest = async () => {
    if (bookingValue === 0) {
      const abandoned_request_payload = {
        s_latitude: latitude,
        s_longitude: longitude,
        category_id: category_id,
        s_address: address,
        sub_category_id: sub_category_id,
        distance: "",
        name: title,
        city_id: localStorage.getItem("selectedOption"),
        price_type: 1,
        // price_type: 1,
        is_quick_book: 1,
        is_urgent: 1,
        job_type: 2,
        request_type: 1,
        request_date: selectedDate,
        request_start_time: selectedTime,
        description: description,
        id: parseInt(localStorage.getItem("id")),
        token: localStorage.getItem("token"),
        marketing_platform: selectedMarketingOption,
        platform_comments: comment,
        device_type: "web",
        mobile: sessionStorage.getItem("mobile_number"),
        abandoned_status: "active",
      };
      window.onbeforeunload = (event) => {
        event.preventDefault();
        abandoned_request_mutate(abandoned_request_payload);
      };
      window.onpopstate = () => {
        abandoned_request_mutate(abandoned_request_payload);
        // alert("Changes that you made may not be saved.");
      };
    } else if (bookingValue === 1) {
      const abandoned_request_payload = {
        s_latitude: latitude,
        s_longitude: longitude,
        s_address: address,
        category_id: category_id,
        sub_category_id: sub_category_id,
        distance: 7,
        name: title,
        price_type: 1,
        is_urgent: 0,
        is_quick_book: 0,
        request_type: 1,
        job_type: 2,
        request_date: selectedDate,
        request_start_time: selectedTime,
        description: description,
        id: parseInt(localStorage.getItem("id")),
        token: localStorage.getItem("token"),
        marketing_platform: selectedMarketingOption,
        city_id: localStorage.getItem("selectedOption"),
        platform_comments: comment,
        device_type: "web",
        mobile: sessionStorage.getItem("mobile_number"),
        abandoned_status: "active",
      };
      window.onbeforeunload = (event) => {
        event.preventDefault();
        abandoned_request_mutate(abandoned_request_payload);
      };
      window.onpopstate = () => {
        abandoned_request_mutate(abandoned_request_payload);
        // alert("Changes that you made may not be saved.");
      };
    } else if (bookingValue === 2) {
      const abandoned_request_payload = {
        s_latitude: latitude,
        s_longitude: longitude,
        s_address: address,
        category_id: category_id,
        sub_category_id: sub_category_id,
        distance: 7,
        name: title,
        price_type: 1,
        is_quick_book: 1,
        is_urgent: 0,
        job_type: 2,
        request_type: 1,
        request_date: selectedDate,
        request_start_time: selectedTime,
        description: description,
        id: parseInt(localStorage.getItem("id")),
        token: localStorage.getItem("token"),
        marketing_platform: selectedMarketingOption,
        platform_comments: comment,
        city_id: localStorage.getItem("selectedOption"),
        device_type: "web",
        mobile: sessionStorage.getItem("mobile_number"),
        abandoned_status: "active",
      };
      window.onbeforeunload = (event) => {
        event.preventDefault();
        abandoned_request_mutate(abandoned_request_payload);
      };
      window.onpopstate = () => {
        abandoned_request_mutate(abandoned_request_payload);
        // alert("Changes that you made may not be saved.");
      };
    }
  };

  useEffect(() => {
    processAbandonedRequest();
  }, [
    images,
    selectedMarketingOption,
    comment,
    description,
    selectedDate,
    selectedTime,
    address,
    latitude,
    longitude,
    sessionStorage.getItem("mobile_number"),
  ]);

  return (
    <div className="container mt-4 mb-8 gap-4 flex justify-center">
      {/* Back button */}

      {[0, 3].includes(currentStep) ? null : (
        <>
          {[4, 5, 6].includes(currentStep) && sub_category_is_bidded === "no" ? (
            <button
              onClick={() => {
                processAbandonedRequest();
                setCurrentStep(2);
              }}
              // onClick={() => handleClick("back")}
              type="button"
              className="cursor-pointer rounded-xl  bg-[#DFDFDF] py-[10px] px-8 font-semibold text-sm md:text-[16px] leading-[19.2px] text-[#707070] transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
            >
              Back
            </button>
          ) : (
            <>
              {sub_category_is_bidded === "no" || sub_category_is_bidded === "only" ? (
                <button
                  onClick={() => {
                    processAbandonedRequest();
                    navigate(-1);
                  }}
                  type="button"
                  className="cursor-pointer rounded-xl  bg-[#DFDFDF] py-[10px] px-8 font-semibold text-sm md:text-[16px] leading-[19.2px] text-[#707070] transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
                >
                  Back
                </button>
              ) : (
                <button
                  onClick={() => {
                    processAbandonedRequest();
                    setCurrentStep(1);
                  }}
                  type="button"
                  className="cursor-pointer rounded-xl  bg-[#DFDFDF] py-[10px] px-8 font-semibold text-sm md:text-[16px] leading-[19.2px] text-[#707070] transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
                >
                  Back
                </button>
              )}
            </>
          )}
        </>
      )}

      {currentStep === steps.length - 1 ? (
        <>
          {loading ? (
            <div className="cursor-pointer rounded-lg bg-[#0973BA] py-[10px] px-6 md:px-8 text-white">
              <div
                className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          ) : (
            <button
              onClick={async (e) => {
                const errs = await formik.validateForm();
                if (Object.keys(errs).length > 0) {
                  for (let err in errs) {
                    formik.setFieldTouched(err, true);
                  }
                  return;
                } else if (!localStorage.getItem("token")) {
                  // return setCurrentStep(4);

                  setCurrentStep(4);
                  facebook_conversions("Continue");
                } else {
                  handle_request_submit();
                  facebook_conversions("Submit Request");
                }
              }}
              type="button"
              className="cursor-pointer rounded-lg bg-[#0973BA] py-[10px] px-6 md:px-8 font-semibold text-sm md:text-[16px] leading-[19.2px]  text-white transition duration-200 ease-in-out"
            >
              <p>{!localStorage.getItem("token") ? "Continue" : "Submit Request"}</p>
            </button>
          )}
        </>
      ) : (
        <div>
          {[0, 4, 5, 6, 3].includes(currentStep) ? null : (
            <button
              onClick={(e) => {
                e.preventDefault();
                handleClick("next");
              }}
              type="button"
              className="cursor-pointer rounded-lg bg-[#0973BA] py-[10px] px-8 font-semibold text-sm md:text-[16px] leading-[19.2px] text-white transition duration-200 ease-in-out"
            >
              Next
            </button>
          )}
        </div>
      )}
    </div>
  );
}
