import AppStoreButton from "theme/buttons/AppStoreButton";
import PlayStoreButton from "theme/buttons/PlayStoreButton";
import { RiSendPlaneFill } from "react-icons/ri";
import React from "react";
import { useSubscriber_email } from "hooks/useUser";
import { useFormik } from "formik";
import * as yup from "yup";

const SubscriptionArea = () => {
  const { mutate } = useSubscriber_email();

  const initialValues = {
    email: "",
  };

  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      email: yup.string().required("Enter the Email to Subscribe"),
    }),
    validateOnChange: true,
    validateOnBlur: false,

    onSubmit: async (values) => {

      const newObj = {
        email: values.email,
      };

      mutate(newObj);
      Formik.resetForm();
    },
  });
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } = Formik;
  return (
    <>
      <div className="w-full font-Roboto">
        <div className="w-full text-white">
          <h3 className=" font-bold text-[18.4592px] leading-6 text-white">Subscribe to Karsaaz Newsletter</h3>
          <form onSubmit={handleSubmit}>
            <div className="mt-5 relative w-full max-w-[317px] ">
              <input
                type="email"
                autoComplete="off"
                name="email"
                id="Phone"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Email"
                className="border rounded-[15px] w-full  outline-none  h-[52px] py-4 pl-4 text-black placeholder:font-bold  placeholder-black-500 placeholder:text-base	"
              />
              <div className="">
                {errors.email && touched.email ? <p className="capitalize font-medium  text-white pt-3">{errors.email}</p> : null}
              </div>
              <button type="submit">
                <RiSendPlaneFill
                  color="black"
                  className="w-[30px] h-[30px]  absolute cursor-pointer inset-y-[13px] right-3 hover:scale-105 hover duration-200"
                />
              </button>
            </div>
          </form>
        </div>
        <div className="flex flex-col gap-6 mt-6">
          <h3 className=" font-bold text-[18.4592px] leading-6 text-white">Download Karsaaz App Now</h3>
          <AppStoreButton />
          <PlayStoreButton />
        </div>
      </div>
    </>
  );
};

export default SubscriptionArea;
