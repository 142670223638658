import React from "react";

const ShimmerSkeleton = () => {
  return (
    <>
      <div className="flex flex-col gap-3 justify-between w-[315px] bg-white drop-shadow-md rounded-2xl p-3">
        <div className="animate-pulse rounded-xl  h-[225.22px] bg-slate-200"></div>
        <div className="p-3 space-y-4">
          <div className="animate-pulse w-2/3 h-6 bg-slate-200"></div>
          <div className="flex space-x-4">
            <div className="animate-pulse w-1/3 h-3 bg-sky-200"></div>
            <div className="animate-pulse w-1/3 h-3 bg-sky-200"></div>
          </div>
          <div className="">
            <div className="animate-pulse w-3/4 h-3 bg-slate-200"></div>
          </div>
          <div className="">
            <div className="animate-pulse rounded-xl w-full h-[56px] bg-slate-200"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShimmerSkeleton;
