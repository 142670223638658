import {
  usePopular_services_by_category,
  useSearch_all,
  useSeo_page_view,
} from "hooks/useUser";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BookingNavbar from "views/partials/navbar/BookingNavbar";
import BookingMain from "views/stepperBookingForm/BookingMain";
import { useServices, useSubServices } from "hooks/useService";
import ShimmerSkeleton from "views/loader/ShimmerSkeleton";
import FooterMain from "views/partials/footer/FooterMain";
import CustomerChoosesUs from "common/CustomerChoosesUs";
import CustomerLoveUs from "theme/cards/CustomerLoveUs";
import NavCustom from "views/partials/navbar/NavCustom";
import SubCategoriesSEO from "./SubCategoriesSEO";
import UpdateMetaTags from "utils/UpdateMetaTags";
import RightSideBar from "common/RightSideBar";
import CityFilter from "common/CityFilter";
import { useEffect } from "react";
import SearchBar from "common/SearchBar";
import { Card } from "theme/cards/Card";
import Loader from "views/loader/Loader";
import "./../../index.css";
import "index.css";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const CategorySEO = () => {
  let { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { sub_category_slug, id } = location.state || {};
  const { data: slug_data } = useSeo_page_view({ slug });
  const { data: services, mutate, isLoading: isLoading1 } = useServices();
  const {
    data: sub_services,
    mutate: mutate2,
    isLoading: isLoading2,
  } = useSubServices();
  const {
    data: search_all_data,
    mutate: search_all_mutate,
    isLoading: isLoading_search_all,
  } = useSearch_all();

  const {
    data: popular_services_by_category_data,
    mutate: popular_services_by_category_mutate,
    isLoading: popular_services_by_category_isLoading,
  } = usePopular_services_by_category();

  const handleClick = (item) => {
    if (item?.category_web_seo_url !== "") {
      navigate(`${item?.category_web_seo_url.match(/\/([^/]+)$/)[0]}`, {
        state: {
          id: item?.category_id,
        },
      });
    } else {
      navigate(`/sub-categories`, {
        state: {
          web_banner_image: item?.web_banner_image,
          web_banner_text: item?.web_banner_text,
          category_name: item?.category_name,
          category_id: item?.category_id,
          category_description: item?.category_description,
        },
      });
    }
  };

  useEffect(() => {
    mutate(slug_data?.data?.super_category_id);
    const popular_services_by_category = {
      id: slug_data?.data?.super_category_id,
      popular_type: "categories",
    };
    popular_services_by_category_mutate(popular_services_by_category);
  }, [slug_data]);

  useEffect(() => {
    const sub_services = {
      category_id: slug_data?.data?.category_id,
      city_id: localStorage.getItem("selectedOption"),
    };
    mutate2(sub_services);

    const payload = {
      key: slug_data?.data?.sub_category_name,
    };
    search_all_mutate(payload);
    mutate(slug_data?.data?.super_category_id);
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [slug_data]);

  const handleCityChange = (cityValue) => {
    const payload = {
      category_id: slug_data?.data?.category_id,
      city_id: cityValue,
    };
    mutate2(payload);
  };

  const handle_popular_cat = (item) => {
    navigate(`/sub-categories/${item?.slug}`, {
      state: {
        web_banner_image: item?.web_banner_image,
        web_banner_text: item?.web_banner_text,
        category_name: item?.name,
        category_id: item?.id,
      },
    });
  };

  return (
    <>
      {sub_category_slug ? (
        <BookingMain />
      ) : (
        <div>
          <UpdateMetaTags data={slug_data?.data ?? {}} />

          {slug_data?.data?.web_image ? (
            <div
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/FooterBackIcon.png)`,
              }}
              className={`bg-[#0973BA] relative bg-no-repeat bg-[length:_500px] bg-left-bottom`}
            >
              <div className="flex flex-col w-full h-574px">
                <NavCustom
                  styling={{ color: "white", backgroundColor: "#0973BA" }}
                />

                <RightSideBar />
                {isLoading1 ? (
                  <div>
                    <div className="w-[90%] mx-auto flex flex-col-reverse md:grid md:grid-cols-2 p-3 py-12">
                      <div className="w-full flex flex-col gap-3 pt-5 md:py-12">
                        <div className="animate-pulse w-full h-7 bg-sky-200"></div>
                        <div className="animate-pulse w-full h-7 bg-sky-200"></div>
                        <div className="animate-pulse w-1/5 h-7 bg-sky-200"></div>

                        <div className="animate-pulse w-1/3 h-3 bg-sky-200 mt-3"></div>
                        <div className="animate-pulse w-full h-12 bg-sky-200 mt-3 rounded-2xl"></div>
                      </div>
                      <div className="flex items-center justify-center gap-5 w-full">
                        <div className=" animate-pulse w-[200px] h-[300px]  rounded-t-full rounded-b-full bg-slate-200"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="font-Roboto flex flex-col-reverse md:flex-row justify-between items-center w-[90%] mx-auto ">
                    <div className="md:w-[610px]">
                      <div
                        className="heading-style text-center md:text-left md:mb-[0px] mb-[51.8px]"
                        dangerouslySetInnerHTML={{
                          __html: slug_data?.data?.title,
                        }}
                      ></div>
                      {/* <div
                    className="font-normal text-base md:text-lg text-white my-6"
                    dangerouslySetInnerHTML={{ __html: super_category_description }}
                  ></div> */}

                      <div
                        className="font-normal text-base md:text-lg text-white my-6"
                        dangerouslySetInnerHTML={{
                          __html: slug_data?.data?.description,
                        }}
                      ></div>
                      <div className="my-3">
                        <SearchBar />
                      </div>
                    </div>
                    {/* <------------- Hero----Section----Image-------- >  */}

                    <div className="w-fit max-w-[363.76px] justify-center md:justify-start">
                      <img
                        src={slug_data?.data?.web_image}
                        alt=""
                        className="h-[331.36px] my-16 mb-[0px] md:mb-[81.8px] md:mr-[134px] "
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="w-full ">
              <BookingNavbar
                styling={{ color: "white", backgroundColor: "#0973BA" }}
              />
              <RightSideBar />

              <img src={slug_data?.data?.image} alt="" className="" />
            </div>
          )}

          {/* ----------------------- Category Popular Services ----------------------- */}

          {popular_services_by_category_data?.success === true &&
            sub_services?.data?.en?.length === 0 ? (
            <div className="w-[90%] mx-auto my-10">
              <div className="font-Inter w-full text-center font-extrabold text-2xl md:text-4xl">
                Weekly Offers
              </div>

              <Swiper
                grabCursor={true}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                }}
                navigation={{ nextEl: "#arrow-right1", prevEl: "#arrow-left1" }}
                modules={[Autoplay, Navigation]}
                className="w-full mx-auto"
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1250: {
                    slidesPerView: 5,
                    spaceBetween: 30,
                  },
                }}
              >
                <div className="flex gap-3"></div>
                {popular_services_by_category_isLoading
                  ? Array(
                    popular_services_by_category_data?.data?.length > 4
                      ? 4
                      : popular_services_by_category_data?.data?.length
                  )
                    .fill()
                    .map((_, index) => (
                      <div key={index} className="w-full my-7">
                        <div className="flex flex-col gap-4 md:gap-8 mx-auto justify-between w-[231px] bg-white drop-shadow-md rounded-2xl p-3">
                          <div className="animate-pulse rounded-xl w-full h-[210.22px] bg-slate-200"></div>
                          <div className="p-3 space-y-4">
                            <div className="">
                              <div className="animate-pulse rounded-xl w-full h-[22px] bg-slate-200"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : popular_services_by_category_data?.data?.map(
                    (item, index) => (
                      <SwiperSlide key={index} className="my-7">
                        <button onClick={() => handle_popular_cat(item)}>
                          <div className="h-[274px] w-[231px] border rounded-2xl border-[#DFDFDF] shadow-lg	 p-[7px] flex flex-col gap-2 justify-between items-center  cursor-pointer">
                            <div className="h-[210px] w-[210.6px]">
                              <img
                                src={item?.icon}
                                alt="pictures"
                                className="rounded-xl h-full w-full object-fit"
                              />
                            </div>

                            <h1 className="font-semibold text-center text-[14px] lg:text-[18px] leading-[22px] text-black">
                              {item?.name?.length > 32
                                ? item?.name.substring(0, 32) + "...."
                                : item?.name}
                            </h1>
                          </div>
                        </button>
                      </SwiperSlide>
                    )
                  )}
              </Swiper>
            </div>
          ) : null}

          {/* -----------------------------------This is for Services ----------------------------------- */}

          <div className="w-full font-Trueno">
            {slug_data?.data?.services_grid === "enable" ? (
              <div className="flex flex-col gap-8 w-[90%] mx-auto">
                <>
                  <div className="flex gap-3 mx-auto">
                    {isLoading1 ? (
                      <Loader />
                    ) : sub_services?.data?.en?.length === 0 ? (
                      <div className="w-full">
                        <div className="flex flex-col gap-3 justify-between items-center text-center my-[60px] mx-auto">
                          <div className="font-Inter font-extrabold text-2xl md:text-4xl">
                            {slug_data?.data?.super_category_name}
                          </div>
                        </div>

                        <div className="grid gap-4 md:gap-8 lg:grid-cols-3 xl:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 justify-center">
                          {isLoading1 ? (
                            <div className="grid gap-4 md:gap-8 lg:grid-cols-3 xl:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 justify-center">
                              {Array(5)
                                .fill()
                                .map((_, index) => (
                                  <div
                                    key={index}
                                    className="flex flex-col gap-4 md:gap-8 mx-auto justify-between w-[231px] bg-white drop-shadow-md rounded-2xl p-3 mt-[80px]"
                                  >
                                    <div className="animate-pulse rounded-xl w-full h-[210.22px] bg-slate-200"></div>
                                    <div className="p-1">
                                      <div className="">
                                        <div className="animate-pulse rounded-xl w-full h-[22px] bg-slate-200"></div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          ) : (
                            services?.data?.en?.map((item, index) => (
                              <div key={index} className="w-full">
                                <button onClick={() => handleClick(item)}
                                  key={item?.category_id}
                                  className="h-[274px] w-[231px] border rounded-2xl border-[#DFDFDF]	shadow-lg p-[7px] flex flex-col gap-2 justify-between items-center  cursor-pointer">
                                  <div className="h-[210px] w-[210.6px]">
                                    <img
                                      src={item?.category_icon}
                                      alt="pictures"
                                      className="rounded-xl h-full w-full object-fit"
                                    />
                                  </div>

                                  <span className="font-Roboto font-semibold text-center text-[14px] lg:text-[18px] leading-[22px] ">
                                    {item?.category_name?.length > 32
                                      ? item?.category_name.substring(0, 32) +
                                      "...."
                                      : item?.category_name}
                                  </span>
                                </button>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {/* -----------------------------------This is for Search All ----------------------------------- */}

                  {search_all_data?.success === true ? (
                    <>
                      {isLoading_search_all ? (
                        Array(1)
                          .fill()
                          .map((_, index) => (
                            <div key={index} className="w-full my-[100px]">
                              <div className="flex flex-col gap-3 justify-center mx-auto w-[315px] mb-[70px]">
                                <div className="p-3 space-y-4">
                                  <div className="">
                                    <div className="animate-pulse rounded-xl w-full h-[26px] bg-slate-200"></div>
                                  </div>
                                </div>
                              </div>
                              <ShimmerSkeleton />
                            </div>
                          ))
                      ) : search_all_data?.success ? (
                        <div>
                          <div className="flex flex-col">
                            <h2 className="grid md:flex gap-2 items-center justify-center font-extrabold text-2xl md:text-4xl text-center capitalize my-[60px]">
                              {slug_data?.data?.sub_category_name}
                            </h2>
                          </div>

                          <div className="flex justify-center md:justify-start w-full mb-[60px]">
                            <CityFilter onCityChange={handleCityChange} />
                          </div>

                          <ul>
                            <li className="">
                              <Card
                                // category_id={item?.category_id}
                                sub_category_id={
                                  search_all_data?.data?.result?.sub_category_id
                                }
                                name={
                                  search_all_data?.data?.result
                                    ?.sub_category_name
                                }
                                picture={
                                  search_all_data?.data?.result
                                    ?.sub_category_picture
                                }
                                price={
                                  search_all_data?.data?.result
                                    ?.sub_category_price
                                }
                                price_type={
                                  search_all_data?.data?.result
                                    ?.sub_cat_price_type
                                }
                                category_description={
                                  search_all_data?.data?.result
                                    ?.sub_cat_price_description
                                }
                                sub_category_is_bidded={
                                  search_all_data?.data?.result?.sub_cat_bid
                                }
                                sub_category_discount_price={
                                  search_all_data?.data?.result
                                    ?.sub_category_discount_price
                                }
                                backgroundColor="#0973BA"
                              />
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  {/* -----------------------------------This is for Sub Services ----------------------------------- */}

                  <>
                    {!isLoading1 &&
                      sub_services?.data?.en?.length !== 0 &&
                      !search_all_data?.success && (
                        <div className="w-full mx-auto flex flex-col gap-8">
                          <SubCategoriesSEO
                            slug_data={slug_data}
                            sub_services={sub_services}
                            isLoading2={isLoading2}
                            handleCityChange={handleCityChange}
                            isLoading1={isLoading1}
                            id={id}
                          />
                        </div>
                      )}
                  </>
                </>

                <div className="my-12">
                  <div
                    className="inner-html-seo-pages"
                    dangerouslySetInnerHTML={{
                      __html: slug_data?.data?.contents,
                    }}
                  ></div>
                </div>
              </div>
            ) : null}
          </div>

          {/* ----------------------------------- This is for No Page Found ----------------------------------- */}
          {isLoading1 || isLoading2 ? (
            <Loader />
          ) : (
            <>
              {isLoading1 || isLoading2 === false ? (
                <>
                  {slug_data?.success === false ? (
                    <div className="flex flex-col items-center gap-4 pb-36">
                      <div className="w-[220px] h-[220px]">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/PageError404.png`}
                          alt=""
                        />
                      </div>
                      <h2 className="font-extrabold text-2xl md:text-[36px] md:leading-[52px] text-center md:text-left text-[#07609C]">
                        Oops! That page can’t be found
                      </h2>
                      <button
                        onClick={() => {
                          navigate("/");
                        }}
                        className="py-4 w-[204.56px] rounded-lg font-bold  text-[#07609C] border border-[#07609C] text-[16px] leading-[19.2px] bg-white hover:scale-105 hover duration-200"
                      >
                        Go to Home Page
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          )}

          <CustomerChoosesUs color="#ECF7FE" TextColor="black" />
          <CustomerLoveUs
            color="#FAFDFF"
            QuoteIconColor="#07609C"
            FaArrowCircleColor="#07609C"
            TextColor="#0973BA"
          />
          {/* <GetMoreDoneWithKarsaaz color="#ECF7FE" TextColor="#0973BA" /> */}
          <FooterMain />
        </div>
      )}
    </>
  );
};

export default CategorySEO;
