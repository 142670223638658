import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import "./drop-file-input.css";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/features/toastSlice";

const DropFileInput = (props) => {
  const wrapperRef = useRef(null);
  const fileList = props.fileList;
  const setFileList = props.setFileList;

  // const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const dispatch = useDispatch();

  const onFileDrop = (e) => {
    const files = e.target.files;
    const updatedList = [...fileList];

    for (let i = 0; i < files.length && updatedList.length < 4; i++) {
      const file = files[i];
      const fileType = file.type;
      const validFileTypes = ["image/png", "image/jpeg", "image/svg+xml"];

      if (validFileTypes.includes(fileType)) {
        updatedList.push(file);
      } else {
        dispatch(showErrorToast("Only image files are allowed"));
      }
    }

    setFileList(updatedList);
    props.onFileChange(updatedList); // Send the fileList array to the parent component
  };

  const fileRemove = (index) => {
    const updatedList = [...fileList];
    updatedList.splice(index, 1);
    setFileList(updatedList);
    props.onFileChange(updatedList);
  };

  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input font-Trueno"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label flex flex-col w-full justify-center items-center border-2 border-gray-300 border-dashed rounded-md">
          <img src={`${process.env.PUBLIC_URL}/images/DragDropIcon.png`} alt="" />
          <span className="font-normal text-sm md:text-base text-gray-600">Upload or Drag and drop images</span>
          <span className="font-normal text-sm md:text-base text-gray-600">{fileList?.length}/4</span>

          <input type="file" value="" onChange={onFileDrop} multiple />
        </div>
      </div>
      {fileList?.length > 0 ? (
        <div className="drop-file-preview grid grid-cols-2 md:flex md:flex-row py-7">
          {fileList?.map((file, index) => (
            <div key={index} className="drop-file-preview__item">
              <img src={URL.createObjectURL(file)} alt="" className="drop-file-preview__item__img object-cover" />
              {/* <div className="drop-file-preview__item__info">
                <p>{file.name}</p>
                <p>{file.size}B</p>
              </div> */}
              <span className="drop-file-preview__item__del font-semibold text-lg rounded-xl" onClick={() => fileRemove(index)}>
                x
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
