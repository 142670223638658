import { useState, useEffect } from "react";
import Stepper from "views/stepperBookingForm/Stepper";
import StepperControl from "views/stepperBookingForm/StepperControl";
import { UseContextProvider } from "views/stepperBookingForm/StepperContext";
import ServiceType from "./steps/ServiceType";
import Details from "./steps/BookingDetails";
import Final from "./steps/Final";
import { useFormik } from "formik";
import * as Yup from "yup";
import LogInToContinue from "views/bookingSteps/LogInToContinue";
import OtpModal from "views/auth/OtpModal";
import RegisterFormModal from "views/auth/RegisterFormModal";
import AbandonedPhoneNumber from "./steps/AbandonedPhoneNumber";
import CheckoutAsGuest from "./steps/CheckoutAsGuest";
import { RequestImages, useProfile } from "hooks/useUser";
import { useBookBiddingMutation, useBookDirectUrgentMutation, useBookQuicklyBookMutation } from "middleware/bookingApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "store/features/toastSlice";

function StepperMain(props) {
  const [selectedMarketingOption, setSelectedMarketingOption] = useState();
  const [description, setText_description] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [cardDetails, setCardDetails] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [longitude, setLongitude] = useState("");
  const [booking, setBooking] = useState(null);
  const [latitude, setLatitude] = useState("");
  const [fileList, setFileList] = useState([]);
  const [comment, setComment] = useState("");
  const [address, setAddress] = useState("");
  const [images, setImages] = useState([]);
  const [autoComplete2, setAutoComplete2] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [randomPassword, setRandomPassword] = useState("");
  const [guestPasswordModal, setGuestPasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp_modal_data, setOtp_modal_data] = useState();
  const [user, setUser] = useState("");
  const [admin_approvedRequest, setAdmin_approvedRequest] = useState(false);
  const [admin_userMobile, setAdmin_userMobile] = useState("");

  // stepper control data

  let dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const handleSubmit = () => {
    handleClick("next");
  };

  const { data: profile_data, refetch: profile_data_refetch } = useProfile();
  const { mutate, data: request_image_data } = RequestImages();
  const [bookBidding] = useBookBiddingMutation();
  const [bookQuicklyBook] = useBookQuicklyBookMutation();
  const [bookDirectUrgent] = useBookDirectUrgentMutation();

  const card_json_data = localStorage.getItem("cardDetails");
  const CardDetails = JSON.parse(card_json_data);
  const bookingValue = parseInt(localStorage.getItem("selectedBooking"));

  const title = CardDetails?.title;
  const provider_id = CardDetails?.provider_id;
  const category_id = CardDetails?.category_id;
  const sub_category_id = CardDetails?.sub_category_id;
  const sub_category_is_bidded = CardDetails?.sub_category_is_bidded;

  const currentStep = props.currentStep; // Converting props to state which getting from Booking Main Component
  const setCurrentStep = props.setCurrentStep; // Converting props to state which getting from Booking Main Component

  const handle_request_submit = async () => {
    let res;
    setLoading(true);

    if (bookingValue === 0) {
      await bookQuicklyBook({
        s_latitude: latitude,
        s_longitude: longitude,
        category_id: category_id,
        s_address: address,
        sub_category_id: sub_category_id,
        distance: "",
        title: title,
        city_id: localStorage.getItem("selectedOption"),
        price_type: 1,
        // price_type: 1,
        is_quick_book: 1,
        is_urgent: 1,
        job_type: 2,
        request_type: 1,
        request_date: selectedDate,
        request_start_time: selectedTime,
        description: description,
        id: parseInt(localStorage.getItem("id")),
        token: localStorage.getItem("token"),
        platform: selectedMarketingOption,
        platform_comments: comment,
        device_type: "web",
        payment_mode: "default",
        is_approved: admin_approvedRequest === true ? "no" : undefined,
        is_admin: profile_data?.data?.is_admin,
        user_mobile: admin_userMobile,
      }).then((resp) => {
        res = resp;
      });

      if (res?.data?.success === true) {
        for (let i = 0; i < images.length; i++) {
          const formData = new FormData();
          formData.append("id", localStorage.getItem("id"));
          formData.append("request_id", res.data?.data?.request_id);
          formData.append("token", localStorage.getItem("token"));
          formData.append("image", images[i]);

          // const userRequestImagesResult = await mutate(formData);
          await mutate(formData);
        }
        if (images.length === 0) {
          window.onbeforeunload = null;
          window.onpopstate = null;
          window.location.replace("/user/job/success");
        }

        handleSubmit();

        dispatch(showSuccessToast(res?.data?.message));
        const request_id = res?.data?.data?.request_id;
        sessionStorage.setItem("request_id", request_id);
      } else if (res?.data?.error_code === 104 || res?.data?.error_code === 103) {
        dispatch(showErrorToast("Another user is Logged in on different device"));

        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("id");

          navigate("/login");
        }, 2000);
      } else {
        dispatch(showErrorToast(res.data.error));
        setCurrentStep(2);
      }
    } else if (bookingValue === 1) {
      await bookBidding({
        s_latitude: latitude,
        s_longitude: longitude,
        s_address: address,
        category_id: category_id,
        sub_category_id: sub_category_id,
        distance: 7,
        title: title,
        price_type: 1,
        is_urgent: 0,
        is_quick_book: 0,
        request_type: 1,
        job_type: 2,
        request_date: selectedDate,
        request_start_time: selectedTime,
        description: description,
        id: parseInt(localStorage.getItem("id")),
        token: localStorage.getItem("token"),
        platform: selectedMarketingOption,
        city_id: localStorage.getItem("selectedOption"),
        platform_comments: comment,
        device_type: "web",
        payment_mode: "default",
        is_approved: admin_approvedRequest === true ? "no" : undefined,
        is_admin: profile_data?.data?.is_admin,
        user_mobile: admin_userMobile,
      }).then((resp) => {
        res = resp;
      });

      if (res?.data?.success === true) {
        for (let i = 0; i < images.length; i++) {
          const formData = new FormData();
          formData.append("id", localStorage.getItem("id"));
          formData.append("request_id", res.data?.data?.request_id);
          formData.append("token", localStorage.getItem("token"));
          formData.append("image", images[i]);

          // const userRequestImagesResult = await mutate(formData);
          await mutate(formData);
        }
        if (images.length === 0) {
          window.onbeforeunload = null;
          window.onpopstate = null;
          window.location.replace("/user/job/success");
        }

        handleSubmit();

        dispatch(showSuccessToast(res.data.message));
        const request_id = res.data?.data?.request_id;
        sessionStorage.setItem("request_id", request_id);
      } else if (res?.data?.error_code === 104 || res?.data?.error_code === 103) {
        dispatch(showErrorToast("Another user is Logged in on different device"));

        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("id");

          navigate("/login");
        }, 2000);
      } else {
        dispatch(showErrorToast(res.data.error));
        setCurrentStep(2);
      }
    } else if (bookingValue === 2) {
      await bookDirectUrgent({
        s_latitude: latitude,
        s_longitude: longitude,
        s_address: address,
        category_id: category_id,
        sub_category_id: sub_category_id,
        distance: 7,
        title: title,
        price_type: 1,
        is_quick_book: 1,
        is_urgent: 0,
        job_type: 2,
        request_type: 1,
        request_date: selectedDate,
        request_start_time: selectedTime,
        description: description,
        id: parseInt(localStorage.getItem("id")),
        token: localStorage.getItem("token"),
        platform: selectedMarketingOption,
        platform_comments: comment,
        city_id: localStorage.getItem("selectedOption"),
        device_type: "web",
        payment_mode: "default",
        is_approved: admin_approvedRequest === true ? "no" : undefined,
        is_admin: profile_data?.data?.is_admin,
        user_mobile: admin_userMobile,
      }).then((resp) => {
        res = resp;
      });

      if (res?.data?.success === true) {
        for (let i = 0; i < images.length; i++) {
          const formData = new FormData();
          formData.append("id", localStorage.getItem("id"));
          formData.append("request_id", res.data?.data?.request_id);
          formData.append("token", localStorage.getItem("token"));
          formData.append("image", images[i]);

          // const userRequestImagesResult = await mutate(formData);
          await mutate(formData);
        }
        if (images.length === 0) {
          window.onbeforeunload = null;
          window.onpopstate = null;
          window.location.replace("/user/job/success");
        }

        handleSubmit();

        dispatch(showSuccessToast(res.data.message));
        const request_id = res.data?.data?.request_id;
        sessionStorage.setItem("request_id", request_id);
      } else if (res?.data?.error_code === 104 || res?.data?.error_code === 103) {
        dispatch(showErrorToast("Another user is Logged in on different device"));

        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("id");

          navigate("/login");
        }, 2000);
      } else {
        dispatch(showErrorToast(res.data.error));
        setCurrentStep(2);
      }
    } else if (bookingValue === 3) {
      await bookBidding({
        s_latitude: latitude,
        s_longitude: longitude,
        s_address: address,
        category_id: category_id,
        sub_category_id: sub_category_id,
        distance: 7,
        title: title,
        price_type: 1,
        is_urgent: 0,
        is_quick_book: 0,
        request_type: 1,
        job_type: 2,
        provider_id: provider_id,
        request_date: selectedDate,
        request_start_time: selectedTime,
        description: description,
        id: parseInt(localStorage.getItem("id")),
        token: localStorage.getItem("token"),
        platform: selectedMarketingOption,
        city_id: localStorage.getItem("selectedOption"),
        platform_comments: comment,
        device_type: "web",
        payment_mode: "default",
        is_approved: admin_approvedRequest === true ? "no" : undefined,
        is_admin: profile_data?.data?.is_admin,
        user_mobile: admin_userMobile,
      }).then((resp) => {
        res = resp;
      });

      if (res?.data?.success === true) {
        for (let i = 0; i < images.length; i++) {
          const formData = new FormData();
          formData.append("id", localStorage.getItem("id"));
          formData.append("request_id", res.data?.data?.request_id);
          formData.append("token", localStorage.getItem("token"));
          formData.append("image", images[i]);

          // const userRequestImagesResult = await mutate(formData);
          await mutate(formData);
        }
        if (images.length === 0) {
          window.onbeforeunload = null;
          window.onpopstate = null;
          window.location.replace("/user/job/success");
        }

        handleSubmit();

        dispatch(showSuccessToast(res.data.message));
        const request_id = res.data?.data?.request_id;
        sessionStorage.setItem("request_id", request_id);
      } else if (res?.data?.error_code === 104 || res?.data?.error_code === 103) {
        dispatch(showErrorToast("Another user is Logged in on different device"));

        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("id");

          navigate("/login");
        }, 2000);
      } else {
        dispatch(showErrorToast(res.data.error));
        setCurrentStep(2);
      }
    }

    setLoading(false);
  };

  // stepper end

  useEffect(() => {
    const storedCardDetails = localStorage.getItem("cardDetails");
    if (storedCardDetails) {
      setCardDetails(JSON.parse(storedCardDetails));
    }

    if (sessionStorage.getItem("existing_user") || localStorage.getItem("token")) {
      setCurrentStep(
        sub_category_is_bidded === "no" || sub_category_is_bidded === "only" || localStorage.getItem("selectedBooking") === "3"
          ? 2
          : 1
      );
    }
  }, [CardDetails?.sub_category_is_bidded]);

  useEffect(() => {
    if (profile_data?.data?.is_admin === "yes") {
      formik.setFieldValue("is_admin", true);
    }
  }, [profile_data]);

  const formik = useFormik({
    initialValues: {
      autocomplete: null,
      selectedTime: "",
      selectedDate: "",
      description: "",
      mobile: "",
      is_admin: false,
    },
    validationSchema: Yup.object({
      autocomplete: Yup.object().required("Address Field is required").nullable(),
      selectedDate: Yup.string().required("Please Select the Date"),
      selectedTime: Yup.string().required("Please Select the Time"),
      description: Yup.string().max(100).required("Please add Descriptions"),
      // comment: Yup.string().required("Please add your Comments"),
      mobile: Yup.string()
        .matches(/^[0-9]+$/, "Invalid phone number")
        .max(11)
        .min(11)
        .when("is_admin", (is_admin, schema) => (is_admin ? schema.required("Please Enter Mobile Number") : schema)),
    }),
  });
  const { touched, errors, setFieldValue, values } = formik;

  // It is for current steps

  const steps = ["Service type", "Booking Details", "Complete"];

  const displayStep = (step) => {
    switch (step) {
      case 0:
        return (
          <AbandonedPhoneNumber
            handleClick={handleClick}
            setCurrentStep={setCurrentStep}
            sub_category_is_bidded={sub_category_is_bidded}
          />
        );
      case 1:
        return <ServiceType cardDetails={cardDetails} setBooking={setBooking} />;

      case 2:
        return (
          <Details
            setCurrentStep={setCurrentStep}
            // handleSubmit={handleSubmit}
            setImages={setImages}
            images={images}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            values={values}
            handleBlur={formik.handleBlur}
            selectedMarketingOption={selectedMarketingOption}
            setSelectedMarketingOption={setSelectedMarketingOption}
            comment={comment}
            setComment={setComment}
            description={description}
            setText_description={setText_description}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            latitude={latitude}
            longitude={longitude}
            address={address}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setAddress={setAddress}
            fileList={fileList}
            setFileList={setFileList}
            autoComplete2={autoComplete2}
            setAutoComplete2={setAutoComplete2}
            selectedPlace={selectedPlace}
            setSelectedPlace={setSelectedPlace}
            currentLocation={currentLocation}
            setCurrentLocation={setCurrentLocation}
            setGuestPasswordModal={setGuestPasswordModal}
            guestPasswordModal={guestPasswordModal}
            randomPassword={randomPassword}
            admin_approvedRequest={admin_approvedRequest}
            setAdmin_approvedRequest={setAdmin_approvedRequest}
            admin_userMobile={admin_userMobile}
            setAdmin_userMobile={setAdmin_userMobile}
            profile_data={profile_data}
          />
        );
      case 3:
        return (
          <Final
            randomPassword={randomPassword}
            setGuestPasswordModal={setGuestPasswordModal}
            guestPasswordModal={guestPasswordModal}
            request_image_data={request_image_data}
            //booking details data

            setImages={setImages}
            images={images}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            values={values}
            handleBlur={formik.handleBlur}
            selectedMarketingOption={selectedMarketingOption}
            setSelectedMarketingOption={setSelectedMarketingOption}
            comment={comment}
            setComment={setComment}
            description={description}
            setText_description={setText_description}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            latitude={latitude}
            longitude={longitude}
            address={address}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setAddress={setAddress}
            fileList={fileList}
            setFileList={setFileList}
            autoComplete2={autoComplete2}
            setAutoComplete2={setAutoComplete2}
            selectedPlace={selectedPlace}
            setSelectedPlace={setSelectedPlace}
            currentLocation={currentLocation}
            setCurrentLocation={setCurrentLocation}
          />
        );
      case 4:
        return (
          <LogInToContinue
            setCurrentStep={setCurrentStep}
            setRandomPassword={setRandomPassword}
            handle_request_submit={handle_request_submit}
            setOtp_modal_data={setOtp_modal_data}
            otp_modal_data={otp_modal_data}
            user={user}
            setUser={setUser}
            profile_data_refetch={profile_data_refetch}
          />
        );
      case 5:
        return (
          <OtpModal
            setCurrentStep={setCurrentStep}
            setGuestPasswordModal={setGuestPasswordModal}
            handle_request_submit={handle_request_submit}
            otp_modal_data={otp_modal_data}
            user={user}
          />
        );
      case 6:
        return (
          <RegisterFormModal
            setCurrentStep={setCurrentStep}
            setOtp_modal_data={setOtp_modal_data}
            otp_modal_data={otp_modal_data}
            handle_request_submit={handle_request_submit}
            user={user}
            setUser={setUser}
          />
        );
      case 7:
        return <CheckoutAsGuest setCurrentStep={setCurrentStep} />;

      default:
    }
  };

  return (
    // <form className="w-full mx-auto rounded-2xl bg-white pb-2 shadow-xl" onSubmit={handleSubmit}>
    <div className="w-full mx-auto rounded-2xl bg-white pb-2 shadow-xl">
      <div className="mt-5">
        <div className="px-2 md:px-24">
          <Stepper className="hidden md:block" steps={steps} currentStep={currentStep} />
        </div>

        <div className="my-20 p-1 md:p-10 border-t border-[#DFDFDF]">
          <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
        </div>
      </div>

      {/* navigation button */}

      {currentStep !== steps.length && (
        <StepperControl
          handle_submit={handleSubmit}
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
          booking={booking}
          setBooking={setBooking}
          job_type={props.job_type}
          request_id={props.request_id}
          description={description}
          setText_description={setText_description}
          images={images}
          formik={formik}
          selectedMarketingOption={selectedMarketingOption}
          comment={comment}
          setComment={setComment}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          latitude={latitude}
          longitude={longitude}
          address={address}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setAddress={setAddress}
          setCurrentStep={setCurrentStep}
          handle_request_submit={handle_request_submit}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </div>
    // </form>
  );
}

export default StepperMain;
