import axios from "axios";
import { api, paymob_api } from "hooks/index";

const baseURL = process.env.REACT_APP_API_URL;

export const getAllServices = async (superCategoryID) =>
  await axios.post(
    baseURL + "/v1/common/services",
    {},
    { params: { super_category_id: superCategoryID } }
  );

// ------------------- APIs using React Query -------------------

export const request_later = (body) =>
  api.post("/user/request_later", body).then((e) => {
    return e.data;
  });

export const updateUser = (body) =>
  api.post("/v1/user/update_profile", body).then((e) => {
    return e.data;
  });

export const profile = (body) =>
  api
    .post("/user/profile", {
      ...body,
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    })
    .then((e) => {
      return e.data;
    });

export const changePassword = (body) =>
  api
    .post("/user/change_password", {
      ...body,
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    })
    .then((e) => {
      return e.data;
    });

export const updatePassword = (body) =>
  api.post("/common/update_password", body).then((e) => {
    return e.data;
  });

export const forgotPassword = (body) =>
  api.post("common/forgot_password", body).then((e) => {
    return e.data;
  });

export const verify_forgototp = async (body) => {
  return api.post("common/verify_forgototp", body).then((e) => e.data);
};

export const updateLocation = (body) =>
  api.post("/user/location/update", body).then((e) => {
    return e.data;
  });

export const showLocation = (body) =>
  api
    .post("/user/location", {
      ...body,
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    })
    .then((e) => {
      return e.data;
    });

export const reviews = (body) =>
  api.get("/common/reviews", body).then((e) => {
    return e.data;
  });

export const homepage_videos = (body) =>
  api.get("/common/homepage_videos", body).then((e) => {
    return e.data;
  });

// const fetchHistory = (body) =>
//   api.post("/user/history", body).then((e) => {
//     return e.data;
//   });

export const fetchHistory = (body) =>
  api
    .post("/user/history", {
      ...body,
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    })
    .then((e) => {
      return e.data;
    });

export const fetchMyProjects = (body) =>
  api
    .post("/user/myprojects", {
      ...body,
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    })
    .then((e) => {
      return e.data;
    });

export const single_request = ({ request_id }) =>
  api
    .post("/user/single_request", {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      request_id: request_id,
    })
    .then((e) => {
      return e.data;
    });

export const cancel_request = ({ request_id }) =>
  api
    .post("/user/cancel_request", {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      request_id: request_id,
      job_type: 2,
    })
    .then((e) => {
      return e.data;
    });

// export const single_request = async (superCategoryID) =>
//   await axios.post(baseURL + "/common/single_request", {}, { params: { super_category_id: superCategoryID } });

// export const single_request = (body) =>
//   api.post("/user/single_request", body).then((e) => {
//     return e.data;
//   });

export const request_description_update = ({ request_id, description }) =>
  api
    .post("/user/request_description_update", {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      request_id: request_id,
      description: description,
    })
    .then((e) => {
      return e.data;
    });

export const remove_user_request_images = (body) =>
  api
    .post("/user/remove_user_request_images", {
      ...body,
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    })
    .then((e) => {
      return e.data;
    });

export const requests_count = (body) =>
  api
    .post("/user/requests_count", {
      ...body,
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    })
    .then((e) => {
      return e.data;
    });

export const bids = ({ request_id }) =>
  api
    .post("/user/bids", {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      request_id: request_id,
    })
    .then((e) => {
      return e.data;
    });

export const assign_request = ({
  request_id,
  request_meta_id,
  price_per_hour,
}) =>
  api
    .post("/user/assign_request", {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      request_id: request_id,
      request_meta_id: request_meta_id,
      price_per_hour: price_per_hour,
    })
    .then((e) => {
      return e.data;
    });

export const collaborators = (body) =>
  api.get("/common/collaborators", body).then((e) => {
    return e.data;
  });

export const provider_detail = (body) =>
  api.post("/common/provider_detail", body).then((e) => {
    return e.data;
  });

export const add_fav_provider = (body) =>
  api.post("/user/fav_providers/add", body).then((e) => {
    return e.data;
  });

export const fav_providers_list = (body) =>
  api
    .post("/user/fav_providers", {
      ...body,
      user_id: localStorage.getItem("id"),
    })
    .then((e) => {
      return e.data;
    });

export const remove_fav_provider = (body) =>
  api.post("/user/fav_providers/remove", body).then((e) => {
    return e.data;
  });

export const env_verification_values = (body) =>
  api.get("/common/env_verification_values", body).then((e) => {
    return e.data;
  });

export const contact_us_send_email = (body) =>
  api.post("/common/contact_us/send/email", body).then((e) => {
    return e.data;
  });

export const subscriber_email = (body) =>
  api.post("/common/subscriber/send/email", body).then((e) => {
    return e.data;
  });

export const faq_categories = (body) =>
  api.get("/common/faq_categories", body).then((e) => {
    return e.data;
  });

export const faq_questions_answers = (body) =>
  api.post("/common/faq_questions_answers", body).then((e) => {
    return e.data;
  });

//--------------------------- Services APIs ---------------------------

export const fetchSuperServices = () =>
  api.post("/common/super_services").then((e) => e.data);

export const fetchServices = (id) =>
  api
    .post("/v1/common/services", { super_category_id: id })
    .then((e) => e.data);

export const fetchSubServices = (body) =>
  api.post("/v1/common/sub_services", body).then((e) => {
    return e.data;
  });

export const fetchbanner = () => api.get("/common/banners").then((e) => e.data);

// ---------------------- Auth Apis ----------------------

// apis

export const login = (body) =>
  api.post("/user/login", body).then((e) => {
    return e.data;
  });

export const LogInToContinue = (body) =>
  api.post("/user/login", body).then((e) => {
    return e.data;
  });
export const register = (body) =>
  api.post("/user/register", body).then((e) => {
    return e.data;
  });

// export const register = async () => api.post("/user/register", {});

export const sendOtp = async () =>
  api.post("/user/sendotp", {
    id: localStorage.getItem("id"),
    token: localStorage.getItem("token"),
  });

// export const verifyOtp = async (verification_code) =>
//   api
//     .post("/user/verifyotp", {
//       id: localStorage.getItem("id"),
//       token: localStorage.getItem("token"),
//       verification_code,
//     })
//     .then((e) => e.data);

export const verifyOtp = (body) =>
  api.post("/user/verifyotp", body).then((e) => {
    return e.data;
  });

export const logout = async () =>
  api
    .post("/user/logout", {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    })
    .then((e) => e.data);

export const search = (body) =>
  api.post("/common/search", body).then((e) => {
    return e.data;
  });

export const search_all = (body) =>
  api.post("/common/search_all", body).then((e) => {
    return e.data;
  });

export const popular_sub_categories = (body) =>
  api.post("/common/popular_sub_categories", body).then((e) => {
    return e.data;
  });

// export const get_country_city = (body) =>
//   api.post("/common/get_country_city", body).then((e) => {
//     return e.data;
//   });

export const get_country_city = async () =>
  api
    .post("/common/get_country_city", {
      country_id: 1,
    })
    .then((e) => e.data);

export const seo_page_view = (body) =>
  api.post("/common/seo_page_view", body).then((e) => {
    return e.data;
  });

export const user_request_images = (body) =>
  api.post("/user/user_request_images", body).then((e) => {
    return e.data;
  });

export const marketing_platform = (body) =>
  api.get("/common/marketing_platform", body).then((e) => {
    return e.data;
  });

export const request_rating = (body) =>
  api.post("/user/request_rating", body).then((e) => {
    return e.data;
  });

export const provider_profile = (body) =>
  api.post("/user/provider/profile", body).then((e) => {
    return e.data;
  });

export const karsaaz_settings = (body) =>
  api.get("/common/karsaaz_settings", body).then((e) => {
    return e.data;
  });

export const service_by_slug = (body) =>
  api.post("/common/service_by_slug", body).then((e) => {
    return e.data;
  });

export const sitemap = (body) =>
  api.get("/common/sitemap", body).then((e) => {
    return e.data;
  });

export const blog_categories = (body) =>
  api.get("/common/blog_categories", body).then((e) => {
    return e.data;
  });
export const all_blogs = (body) =>
  api.get("/common/all_blogs", body).then((e) => {
    return e.data;
  });

export const blogs_by_category = (body) =>
  api.post("/common/blogs_by_category", body).then((e) => {
    return e.data;
  });

export const blogs_search = (body) =>
  api.post("/common/blogs/search", body).then((e) => {
    return e.data;
  });

export const blog_by_slug = (body) =>
  api.post("/common/blog_by_slug", body).then((e) => {
    return e.data;
  });

export const abandoned_request = (body) =>
  api.post("/common/abandoned_request", body).then((e) => {
    return e.data;
  });

export const abandoned_request_images = (body) =>
  api.post("/common/abandoned_request_images", body).then((e) => {
    return e.data;
  });

export const sync_login_social_account = (body) =>
  api.post("/user/sync_login_social_account", body).then((e) => {
    return e.data;
  });

export const popular_services_by_category = (body) =>
  api.post("/common/popular_services_by_category", body).then((e) => {
    return e.data;
  });

export const weekly_offer_sub_categories = (body) =>
  api.post("/common/weekly_offer_sub_categories", body).then((e) => {
    return e.data;
  });

export const weekly_offer_by_category = (body) =>
  api.post("/common/weekly_offer_by_category", body).then((e) => {
    return e.data;
  });

export const delink_social_account = (body) =>
  api.post("/user/delink_social_account", body).then((e) => {
    return e.data;
  });

export const delete_account_request = (body) =>
  api.post("/user/delete_account_request", body).then((e) => {
    return e.data;
  });

export const post_paymob_response = (body) =>
  api.post("/user/post_paymob_response", body).then((e) => {
    return e.data;
  });

export const transactions_history = (body) =>
  api.post("/user/transactions_history", body).then((e) => {
    return e.data;
  });

export const cod_payment = (body) =>
  api.post("/user/cod_payment", body).then((e) => {
    return e.data;
  });

export const rewards = (body) =>
  api
    .post("/user/rewards", {
      ...body,
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    })
    .then((e) => {
      return e.data;
    });

export const redeem_reward = (body) =>
  api.post("/user/redeem_reward", body).then((e) => {
    return e.data;
  });

export const apply_cashback_discount = (body) =>
  api.post("/user/apply_cashback_discount", body).then((e) => {
    return e.data;
  });

export const remove_cashback_discount = (body) =>
  api.post("/user/remove_cashback_discount", body).then((e) => {
    return e.data;
  });

export const apply_coupon_discount = (body) =>
  api.post("/user/coupon/apply", body).then((e) => {
    return e.data;
  });

export const get_coupons_list = (body) =>
  api.get("/common/get_coupons_list", body).then((e) => {
    return e.data;
  });
export const remove_coupan = (body) =>
  api.post("/user/coupon/remove", body).then((e) => {
    return e.data;
  });
// ----------------------------- Paymob API Start -----------------------------

export const paymob_auth_token = (body) =>
  paymob_api.post("/auth/tokens", body).then((e) => {
    return e.data;
  });

export const paymob_order_registration = (body) =>
  paymob_api.post("/ecommerce/orders", body).then((e) => {
    return e.data;
  });

export const paymob_card_payment_key = (body) =>
  paymob_api.post("/acceptance/payment_keys", body).then((e) => {
    return e.data;
  });

export const acceptance_iframe = (body) =>
  paymob_api.post("/acceptance/iframes", body).then((e) => {
    return e.data;
  });

// ----------------------------- Paymob API Ends here -----------------------------
