import { RxCross2 } from "react-icons/rx";
import { MdVerifiedUser } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import { IoMdPricetag } from "react-icons/io";

const OfferDetails = ({
  offer,
  onClose,
  onAssigned,
  assign_request_isLoading,
}) => {
  const {
    provider_picture,
    provider_name,
    bid_amount,
    created,
    provider_description,
    request_meta_images,
    rating,
  } = offer;

  return (
    <>
      <div className="w-full font-Trueno bg-[#AEE0FF]">
        <div className="fixed inset-0 overflow-y- scroll-none bg-black bg-opacity-25 backdrop-blur-sm px-2 md:px-9">
          <div className="md:w-[85%] h-auto mx-auto bg-white px-4 md:px-6 my-9 border rounded-[15px] relative">
            <button
              className=" font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 absolute right-1 top-7"
              type="button"
              onClick={onClose}
            >
              <RxCross2 className="w-[14px] md:w-[22px] h-[14px] md:h-[22px]" />
            </button>
            <div className="py-8">
              <h1 className="font-semibold text-[16px] md:text-[24px] leading-[19.5px] md:leading-[26.82px]">
                Offer Detail
              </h1>
            </div>
            <div className="flex gap-4  justify-between">
              <div className="grid md:flex gap-4 items-center">
                <img
                  // src={`${process.env.PUBLIC_URL}/images/FavoriteProviderIcon.png`}
                  src={provider_picture}
                  className="w-[128px] md:w-[132px] h-[128px] md:h-[132px] rounded-xl"
                  alt=""
                />
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between mt-3 md:mt-0">
                    <h1 className="grid-flex-col-reverse md:flex gap-1 md:gap-4 items-center font-semibold text-[18px] leading-[21.94px]">
                      {provider_name}
                      <div className="flex gap-1 md:gap-2 items-center">
                        <AiFillStar
                          color="#F9B523"
                          height="16.28px"
                          width="17.12px"
                        />
                        <p className="font-semibold text-[14px] leading-[16.8px]">
                          {rating && parseFloat(rating).toFixed(1)}
                        </p>
                        {/* <span className="font-normal text-[14px] leading-[16.8px] text-[#979797]">(5)</span> */}
                      </div>
                    </h1>
                  </div>
                  <div className="flex gap-1 md:gap-2 items-center">
                    <span className="flex gap-1 md:gap-[6.67px] justify-start md:justify-between items-center text-[#09BA70] font-normal text-[12px] leading-[14.4px]">
                      <MdVerifiedUser height="16.5px" width="13.5px" />
                      Verified
                    </span>
                    {/* <div className="flex gap-2">
                      <label className="font-semibold text-[12px] leading-[14.4px]">|</label>
                      <p className="flex font-normal text-[14px] leading-[16.8px]">0.5 km</p>
                    </div> */}
                  </div>
                  <div>
                    <p className="flex font-normal text-[14px] leading-[16.8px]">
                      Price
                    </p>
                  </div>
                  <div className="min-w-[165px] grid lg:flex gap-1 items-center ">
                    <span className="flex gap-1 items-center">
                      <h1 className="font-bold text-[14px] md:text-[22px] leading-[26.4px] text-[#0973BA]">
                        Rs. {bid_amount}
                      </h1>
                      <label className="flex gap-1 items-center font-bold text-[14px] leading-[#09BA70] text-[#09BA70]">
                        <IoMdPricetag
                          className="w-[20.13px] h-[20.13px]"
                          color="#09BA70"
                        />
                        Estimated
                      </label>
                    </span>
                    {/* <label className="font-normal text-[14px] leading-[16.8px] text-[#CC7D05]">{provider_description}</label> */}
                  </div>
                </div>
              </div>
              <div className="w-fit">
                <p className="font-normal text-[14px] leading-[16.8px]">
                  {" "}
                  {created}ago
                </p>
              </div>
            </div>

            <div>
              {/* --------------------------- Description --------------------------- */}
              <div className="grid gap-4 my-6">
                <h1 className="font-semibold text-[16px] leading-[19.5px]">
                  Description
                </h1>
                <p className="border border-[#DFDFDF] rounded-xl p-5 md:p-8 font-semibold text-[16px] leading-[19.5px] text-black">
                  {provider_description}
                </p>
              </div>

              {/* --------------------------- Images --------------------------- */}
              {request_meta_images?.map((item, index) => (
                <div key={index} className="grid gap-4">
                  <h1 className="font-normal text-[18px] leading-[21.6px]">
                    Images
                  </h1>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/FavoriteProviderIcon.png`}
                    alt=""
                    className="w-[115.27px] h-[115.27px]"
                  />
                </div>
              ))}

              {/* --------------------------- Accept Cancel Button --------------------------- */}

              <div className="w-full flex gap-3 items-center justify-center md:justify-end my-9">
                <button
                  onClick={onClose}
                  className="w-[117px] h-[40px] font-medium md:font-semibold text-[12px] leading-[19.5px] text-[#979797] border border-[#979797] rounded-xl py-[10px] px-9"
                >
                  Back
                </button>
                <button
                  onClick={onAssigned}
                  className="w-[117px] h-[40px] font-medium md:font-semibold text-[12px] md:text-[16px] leading-[19.5px] text-white bg-[#0973BA] rounded-xl"
                >
                  {assign_request_isLoading ? (
                    <div
                      className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                  ) : (
                    "Accept"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferDetails;
