import { useBlogs_search } from "hooks/useUser";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BlogsSkeleton from "views/loader/BlogsSkeleton";
import FooterMain from "views/partials/footer/FooterMain";
import TrendingPosts from "./TrendingPosts";
import BlogsCarousel from "./BlogsCarousel";
import BlogsNavbar from "./BlogsNavbar";
import BlogsCategories from "./BlogsCategories";

const BlogsSearchResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { BlogsSearches } = location.state || {};
  const { data: search_data, mutate: search_mutate, isLoading } = useBlogs_search();

  useEffect(() => {
    const payload = {
      key: BlogsSearches,
    };
    search_mutate(payload);
  }, [BlogsSearches]);

  const handleBlogsDetailPage = (item) => {
    navigate(`/blogs/${item?.slug}`, {
      state: {
        blogs_detail_data: item?.slug,
      },
    });
  };

  return (
    <>
      <BlogsNavbar />
      <BlogsCarousel />
      <div className="w-[90%] mx-auto flex flex-col-reverse md:flex md:flex-row gap-7 justify-between">
        <div className="w-full flex flex-col gap-3">
          <h2 className="font-extrabold text-2xl md:text-4xl mb-4 md:mb-7">
            Search Results for:
            <span className="text-[#0973BA]"> {BlogsSearches}</span>
          </h2>

          <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-8">
            {isLoading
              ? Array(3)
                  .fill()
                  .map((_, index) => <BlogsSkeleton key={index} />)
              : search_data?.data?.map((item, index) => (
                  <div key={index} className="border border-[#DFDFDF] p-1 rounded-2xl w-full md:w-[413px]">
                    <div className=" relative">
                      <img src={item?.banner_image} alt="" className="w-full md:w-[402.15px] h-full md:h-[307px] rounded-2xl" />
                      <span className="px-3 md:px-4 py-1 bg-[#0973BA] font-semibold text-sm md:text-base text-white w-fit rounded-2xl absolute top-3 left-3">
                        {item?.blog_category}
                      </span>
                    </div>
                    <div className="flex flex-col gap-2 w-full p-4">
                      <h4 className="font-extrabold text-[22px] leading-[26.82px]">
                        {item?.title?.length > 50 ? item?.title.substring(0, 50) + "...." : item?.title}
                      </h4>
                      <p className="font-normal text-base">{item?.description}</p>
                      <div className="w-full flex justify-between items-center">
                        <button
                          onClick={() => {
                            handleBlogsDetailPage(item);
                          }}
                          className="px-3 md:px-4 py-1 bg-[#0973BA] font-semibold text-sm md:text-base text-white w-fit rounded-[50px]"
                        >
                          Read More
                        </button>
                        <p className="font-normal text-xs">{item?.posted_on}</p>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          {search_data?.success === false ? <p className="font-normal text-base text-black">{search_data?.message}</p> : null}
        </div>
        <BlogsCategories />
      </div>
      <div className="w-[90%] mx-auto block md:hidden mt-4">
        <TrendingPosts />
      </div>
      <FooterMain />
    </>
  );
};

export default BlogsSearchResults;
