import React from "react";
import AppStoreButton from "theme/buttons/AppStoreButton";
import PlayStoreButton from "theme/buttons/PlayStoreButton";

const GetMoreDoneWithKarsaaz = (props) => {
  return (
    <>
      <div className="w-full mt-[112px] bg-white ">
        <div
          style={{ backgroundColor: props.color }}
          className="w-[90%]  px-5 mx-auto grid-cols-1  md:flex justify-between items-center border rounded-[20px]  "
        >
          <div className=" font-Trueno  text-center md:text-left md:w-[35%] mt-9 md:mx-14 relative  ">
            <h1 className="font-extrabold text-2xl md:text-[36px] md:leading-[52px]  ">
              Get More Done with
              <span style={{ color: props.TextColor }}> Karsaaz </span>
              Mobile App
            </h1>
            <p className="font-normal text-base md:text-[18px] leading-[31px] mt-[23px]">
              Download Karsaaz mobile app today for your Android or iPhone and get All your work done Quickly, Safely, with high
              Quality and Cheapest Price anywhere in Lahore.
            </p>
            <img
              src={`${process.env.PUBLIC_URL}/images/CurveArrowIcon.png`}
              alt=""
              className="w-[70.77px] h-[90.77px] absolute top-[68%]  left-[98%] hidden md:block"
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/CurveArrowIcon.png`}
              alt=""
              className=" -rotate-45 w-[70.77px] h-[90.77px] absolute top-[88%]  left-[68%] block md:hidden"
            />

            <div className="grid gap-2 md:flex my-3">
              <PlayStoreButton />
              <AppStoreButton />
            </div>
          </div>
          <div className="w-fit mt-6">
            <img src={`${process.env.PUBLIC_URL}/images/MobileGetMoreDoneIcon.svg`} alt="" className="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default GetMoreDoneWithKarsaaz;
