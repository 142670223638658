import { useKarsaaz_settings, useProfile } from "hooks/useUser";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { MdContentCopy } from "react-icons/md";
import { showErrorToast, showSuccessToast } from "store/features/toastSlice";

const JobCompleteThankYouPage = ({
  single_request,
  setShowJobCompleteThankyouPage,
}) => {
  const dispatch = useDispatch();

  const { data: profile_data, isLoading: profile_isLoading } = useProfile();
  const { data: settings_data } = useKarsaaz_settings();

  const handleCopy = () => {
    navigator.clipboard
      .writeText(profile_data?.data?.referral_code)
      .then(() => {
        dispatch(showSuccessToast("Text copied to clipboard:"));
      })
      .catch((err) => {
        dispatch(showErrorToast(err));
      });
  };

  return (
    <>
      <div className="w-full font-Trueno bg-[#AEE0FF]">
        <div className="fixed inset-0 overflow-y-auto scrollbar-hide bg-black bg-opacity-25 backdrop-blur-sm px-2 md:px-9">
          <div className="md:w-[85%] h-auto mx-auto my-9 rounded-[15px] relative">
            <button
              className=" font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 absolute right-1 top-7"
              type="button"
              onClick={() => {
                setShowJobCompleteThankyouPage(false);
              }}
            >
              <RxCross2
                color="white"
                className="w-[14px] md:w-[22px] h-[14px] md:h-[22px]"
              />
            </button>
            <div
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/ServiceCompleteScreenBg.svg)`,
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
              className="w-full mx-auto text-center flex flex-col  items-center gap-6 pt-14 pb-8 bg-[#0973BA]"
            >
              <h2 className="font-black text-3xl text-white">
                Congratulations!{" "}
              </h2>
              <p className="font-bold text-sm  text-white flex space-x-2">
                You Job id
                <span className="px-1 font-semibold">
                  # {single_request?.data?.request_id}
                </span>
                is completed
              </p>
              <p className="font-normal text-sm  text-white">You earned</p>
              <img
                src={`${process.env.PUBLIC_URL}/images/KarsaazCoin.svg`}
                alt=""
                className="w-[80px] h-[80px] object-cover
                transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 hover:rotate-[360deg] duration-300  
                "
              />
              <h2 className="font-bold text-6xl text-[#F9B523]">
                {single_request?.data?.price_per_hour}
              </h2>
              <p className="font-normal text-base text-[#F9B523]">
                Estimated Price Rs.
                {single_request?.data?.price_per_hour *
                  settings_data?.data?.reward_settings?.rewards_conversion_rate}
              </p>
            </div>
            <div className="flex flex-col gap-5 items-center text-center rounded-b-[15px] bg-white py-8 px-3">
              <h3 className="font-bold text-base">
                Invite your friends to earn Exciting Rewards
              </h3>
              <p className="font-normal text-xs">Your Referral Code</p>
              <span className="font-semibold text-4xl text-[#0973BA] bg-white px-4 py-2 border border-[#0973BA] rounded-2xl">
                {profile_isLoading ? (
                  <div className="flex flex-col justify-between w-[141px] bg-[#EDF6FC] drop-shadow-md rounded-xl p-2">
                    <div className="animate-pulse w-full h-6 bg-[#0973BA] rounded-md"></div>
                  </div>
                ) : (
                  profile_data?.data?.referral_code
                )}
              </span>

              <button
                type="button"
                onClick={() => {
                  handleCopy();
                }}
                className="w-full max-w-[400px] py-2 bg-[#09BA70] rounded-[15px] relative"
              >
                <p className="text-white text-base font-bold text-center">
                  Copy Referral code
                </p>

                <div className="right-4 top-3  absolute">
                  <MdContentCopy color="white" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobCompleteThankYouPage;
