import { useEffect, useState } from "react";
import SideBarMenu from "./SideBarMenu";
import { BsTrash } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import SettingFooter from "./SettingFooter";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineGoogle } from "react-icons/ai";
import { showErrorToast } from "store/features/toastSlice";
import BookingNavBar from "views/partials/navbar/BookingNavbar";
import { useDelink_social_account, useProfile } from "hooks/useUser";

const SocialAccount = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const { data, refetch, isLoading: profile_isLoading } = useProfile();
  const [delink_modal, setDelink_modal] = useState(false);

  const { data: delink_data, mutate: delink_mutate, isLoading: delink_isLoading } = useDelink_social_account();

  const handle_delink = () => {
    if (data?.data?.signup_by === "google" && user === "google") {
      dispatch(showErrorToast("You cannot delink your social account"));
    } else if (data?.data?.signup_by === "facebook" && user === "facebook") {
      dispatch(showErrorToast("You cannot delink your social account"));
    } else {
      const delink_payload = {
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
        device_type: "web",
        login_by: user === "google" ? "google" : "facebook",
        google_unique_id: user === "google" ? data?.data?.google_unique_id : "",
        facebook_unique_id: user === "facebook" ? data?.data?.facebook_unique_id : "",
      };
      delink_mutate(delink_payload);
    }
  };

  useEffect(() => {
    if (delink_data?.success === true) {
      refetch();
    }
  }, [delink_data]);

  return (
    <>
      <div className="bg-[#F0F7FC]">
        <BookingNavBar color="#F0F7FC" />

        <div className="w-full h-screen font-Trueno my-20">
          <div className="md:w-[85%] mx-auto flex-col md:flex md:flex-row md:gap-3 justify-between md:px-0 px-2">
            <div>
              <SideBarMenu />
            </div>
            <div className="border rounded-[20px] shadow-2xl bg-white w-full md:w-[988px] lg:w-full mt-4 md:mt-0">
              <h1 className="font-normal text-[18px] leading-[21.6px] border-b border-[#DFDFDF] px-4 md:px-[38px] pt-8 pb-4">
                Delink Social Account
              </h1>
              {profile_isLoading ? (
                <div className="my-6 p-8 flex flex-col gap-3">
                  <div className=" w-full mx-4 ">
                    <div className="animate-pulse w-96 bg-sky-400 h-12 rounded-lg"></div>
                  </div>
                  <div className="  w-full mx-4 ">
                    <div className="animate-pulse w-96 bg-sky-400 h-12 rounded-lg"></div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 gap-1 lg:gap-9 justify-between px-[18px] md:px-[38px] w-full ">
                  <div className="my-14 w-full flex flex-col gap-9">
                    <button
                      type="button"
                      onClick={() => {
                        setUser("facebook");
                        setDelink_modal(true);
                      }}
                      className={`flex justify-center items-center font-semibold text-xs md:text-sm text-white bg-[#1773EA] py-[14px] px-4 w-full rounded-lg ${
                        data?.data?.facebook_unique_id === "" ? "hidden" : "block"
                      }`}
                    >
                      {user === "facebook" && delink_isLoading ? (
                        <p className="w-full mx-auto font-semibold text-xs md:text-sm text-white text-center">Please Wait...</p>
                      ) : (
                        <div className="w-full flex justify-between items-center">
                          <FaFacebookF className="flex-none grow-0 w-[24px] h-[24px]" />
                          <p>Delink Facebook Account</p>
                          <BsTrash color="white" className="w-[24px] h-[24px] hover:scale-105 duration-200" />
                        </div>
                      )}
                    </button>

                    <button
                      type="button"
                      onClick={() => {
                        setUser("google");
                        setDelink_modal(true);
                      }}
                      className={`flex items-center font-semibold text-xs md:text-sm text-white bg-[#EB4235] py-[14px] px-4 w-full rounded-lg ${
                        data?.data?.google_unique_id === "" ? "hidden" : "block"
                      }`}
                    >
                      {user === "google" && delink_isLoading ? (
                        <p className="w-full mx-auto font-semibold text-xs md:text-sm text-white text-center">Please Wait...</p>
                      ) : (
                        <div className="w-full flex justify-between gap-2 items-center">
                          <AiOutlineGoogle className="w-[24px] h-[24px]" />
                          <p>Delink Google Account</p>
                          <BsTrash color="white" className="w-[24px] h-[24px] hover:scale-105 duration-200" />
                        </div>
                      )}
                    </button>
                    {data?.data?.google_unique_id === "" && data?.data?.facebook_unique_id === "" && (
                      <p className="font-normal text-[18px] leading-[21.6px] py-4">No Social Account is Linked</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {delink_modal && (
            <>
              <div className="w-full font-Trueno bg-[#AEE0FF]">
                <div className="fixed inset-0 overflow-y-auto scrollbar-hide  bg-black bg-opacity-25 backdrop-blur-sm px-3 md:px-9 py-40">
                  <div className="md:w-[50%] h-auto mx-auto bg-white px-3 md:px-6 flex justify-center border rounded-[15px] relative">
                    <button
                      className=" font-bold uppercase  py-2 text-sm outline-none focus:outline-none mb-1 absolute right-5 top-7"
                      type="button"
                    >
                      <RxCross2
                        onClick={() => {
                          setDelink_modal(false);
                        }}
                        className="w-[18px] md:w-[22px] h-[18px] md:h-[22px]"
                      />
                    </button>
                    <div className="flex flex-col gap-5 py-20 w-full mx-auto text-center">
                      <h1 className="font-extrabold text-xl md:text-[24px] leading-[29.26px]">Delink Social Login</h1>
                      <p className="text-sm text-black font-normal leading-6">Do you really want to delink your social Login?</p>

                      <p className="text-sm text-black font-normal leading-6">
                        You can link back your social account by continuing through
                      </p>

                      <p className="text-sm text-[#0973BA] font-semibold leading-6">
                        Google
                        <span className="text-black"> or </span>
                        Facebook
                      </p>

                      <button
                        type="button"
                        onClick={() => {
                          setDelink_modal(false);
                          handle_delink();
                        }}
                        className="font-semibold text-[14px] leading-[12px] text-white h-[46px] px-5 w-fit mx-auto border bg-[#0973BA] rounded-lg"
                      >
                        {delink_isLoading ? (
                          <p className="w-full mx-auto font-semibold text-xs md:text-sm text-white text-center">Please Wait...</p>
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <SettingFooter />
      </div>
    </>
  );
};

export default SocialAccount;
