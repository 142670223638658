import { FaQuoteRight, FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { useReviews } from "hooks/useUser";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function CustomerLoveUs(props) {
  const { data, isLoading } = useReviews();

  return (
    <>
      <div style={{ backgroundColor: props.color }} className="w-full mt-0 md:mt-28">
        <div className="w-[90%] h-fit mx-auto py-0 md:py-8 border rounded-3xl p-5 bg-[#ECF7FE]">
          <div className="  relative">
            <div className=" top-[-22%] right-24 absolute hidden lg:block ">
              <FaQuoteRight style={{ color: props.QuoteIconColor }} className="object-fill  w-[157.33px] h-[138.8px]" />
            </div>
            <div className="font-Roboto">
              <div className="md:flex justify-between">
                <div className="font-Inter font-bold text-3xl md:text-[36px] leading-[43.2px] text-center">Customers love Karsaaz</div>
                <div className="flex gap-2 md:gap-[21.33px] justify-end mt-6 md:mt-2">
                  <button
                    id="arrow-left1"
                    type="button"
                  >
                    <FaArrowCircleLeft style={{ color: props.FaArrowCircleColor }} className="w-[34px] h-[34px]" />
                  </button>

                  <button id="arrow-right1"
                    type="button"
                  >
                    <FaArrowCircleRight style={{ color: props.FaArrowCircleColor }} className="w-[34px] h-[34px] " />
                  </button>
                </div>
              </div>

              {/* ------------------------ slider ------------------------ */}

              <div className="mt-[50px] ">
                <Swiper
                  slidesPerView={1}
                  rewind={true}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={{ nextEl: "#arrow-right1", prevEl: "#arrow-left1" }}
                  modules={[Autoplay, Navigation]}
                  className="w-full mx-auto"
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                >
                  <div className="w-full flex gap-3">
                    {isLoading
                      ? Array(3)
                        .fill()
                        .map((_, index) => (
                          <div key={index} className="w-full flex flex-col justify-between bg-[#EDF6FC] drop-shadow-md rounded-2xl p-3">
                            <div className="grid md:flex gap-3 items-center md:justify-start justify-center">
                              <div className="animate-pulse w-[60px] h-[60px] md:w-[80px] md:h-[80px] rounded-full bg-slate-200"></div>

                              <div className="animate-pulse w-1/4 h-3 bg-sky-200"></div>
                            </div>
                            <div className="p-3 space-y-4">
                              <div className="flex flex-col space-y-4">
                                <div className="animate-pulse w-full h-2 bg-sky-200"></div>
                                <div className="animate-pulse w-full h-2 bg-sky-200"></div>
                                <div className="animate-pulse w-full h-2 bg-sky-200"></div>
                                <div className="animate-pulse w-full h-2 bg-sky-200"></div>
                              </div>
                            </div>
                          </div>
                        ))
                      : data?.data?.map((item, index) => (
                        <SwiperSlide key={index}
                          className="overflow-hidden  flex flex-col gap-6  rounded-[10px] shadow-lg bg-white p-7 shadow-[0_4px_-15px_rgba(149, 173, 188, 0.26)]"
                        >
                          <div className="grid md:flex gap-[15px] justify-center  sm:justify-center md:justify-start items-center ">
                            <div>
                              {item?.gender === "male" ? (
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/MaleAvatar.png`}
                                  alt="Avatar"
                                  className="row-span-3 w-[60px] h-[60px] md:w-[80px] md:h-[80px]"
                                />
                              ) : (
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/FemaleAvatar.png`}
                                  alt="Avatar"
                                  className="row-span-3 w-[60px] h-[60px] md:w-[80px] md:h-[80px] "
                                />
                              )}
                            </div>
                            <div>
                              <span
                                style={{ color: props.TextColor }}
                                className="font-Inter text-center font-extrabold text-[14px] md:text-[20px] leading-[24.83px]">
                                {item.name}
                              </span>
                            </div>
                          </div>

                          <p
                            className="font-normal text-[16px] leading-[26px] text-black mt-3 text-center md:text-start`"
                            dangerouslySetInnerHTML={{
                              __html: item?.comment,
                            }}
                          ></p>
                        </SwiperSlide>
                      ))}
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
