// import Places from "views/GoogleMaps/Places";
import DropFileInput from "common/dropFile/DropFileInput";
import React, { useEffect, useState } from "react";
import Places from "views/googleMaps/Places";
import DatePicker from "../DatePicker";
import { useMarketing_platform } from "hooks/useUser";
import GuestPasswordModal from "./GuestPasswordModal";

export default function BookingDetails({
  setImages,
  images,
  file,
  touched,
  errors,
  setFieldValue,
  values,
  handleBlur,
  setSelectedMarketingOption,
  selectedMarketingOption,
  comment,
  setComment,
  description,
  setText_description,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  address,
  setAddress,
  fileList,
  setFileList,
  autoComplete2,
  setAutoComplete2,
  selectedPlace,
  setSelectedPlace,
  currentLocation,
  setCurrentLocation,
  setCurrentStep,
  setGuestPasswordModal,
  randomPassword,
  guestPasswordModal,
  admin_userMobile,
  setAdmin_userMobile,
  admin_approvedRequest,
  setAdmin_approvedRequest,
  profile_data,
}) {
  const [cardDetails, setCardDetails] = useState("");

  const handleCommentChange = (event) => {
    setFieldValue("comment", event.target.value);
    setComment(event.target.value);
  };

  const { data } = useMarketing_platform();

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedMarketingOption(value);
  };

  useEffect(() => {
    const storedCardDetails = localStorage.getItem("cardDetails");
    if (storedCardDetails) {
      setCardDetails(JSON.parse(storedCardDetails));
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleTextChange = (event) => {
    setFieldValue("description", event.target.value);
    // setCardDetails("description", event.target.value);
    setText_description(event.target.value);
    // localStorage.setItem("description", event.target.value);
  };

  // const handleSubmit = ({ values, handleFileChange }) => {
  //   localStorage.setItem("bookingDetails", JSON.stringify(values));
  //   // resetForm();
  // };

  // const bookingValue = parseInt(localStorage.getItem("selectedBooking"));

  // if (bookingValue === 0) {
  //   setBooking(0);
  // } else if (bookingValue === 1) {
  //   setBooking(1);
  // } else if (bookingValue === 2) {
  //   setBooking(2);
  // }

  const handleFileChange = (files) => {
    setImages(files);
  };

  return (
    <div className="w-full px-4 mx-auto font-Trueno">
      {cardDetails && (
        // <form handleSubmit={(handleFileChange, images, file)} className="w-full font-Trueno">
        <div className="">
          <div className="flex flex-col gap-4">
            <div className="py-10">
              <Places
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                latitude={latitude}
                longitude={longitude}
                address={address}
                setLatitude={setLatitude}
                setLongitude={setLongitude}
                setAddress={setAddress}
                autoComplete2={autoComplete2}
                setAutoComplete2={setAutoComplete2}
                selectedPlace={selectedPlace}
                setSelectedPlace={setSelectedPlace}
                currentLocation={currentLocation}
                setCurrentLocation={setCurrentLocation}
              />
            </div>
            <div className="flex gap-4 relative">
              <div className="absolute top-9 left-0 flex items-center pl-3">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <DatePicker
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
              />
            </div>
            <label htmlFor="Email Address*" className="font-normal text-[14px] leading-[16.8px]">
              Job Title *
            </label>
            <option className="font-semibold text-sm rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#DFDFDF] h-[46px] w-full pl-4 md:pl-7 py-3">
              {cardDetails.title}
            </option>
            {/* <label htmlFor="Email Address*" className="font-normal text-[14px] leading-[16.8px]">
                Total Price *
              </label>
              <option className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#DFDFDF] h-[46px] w-full pl-7 py-3">
                Rs. {cardDetails.price} | {cardDetails.type}
              </option> */}
            <label htmlFor="Job Description" className="font-normal text-[14px] leading-[16.8px]">
              Job Description
            </label>
            <textarea
              type="text"
              name="description"
              id="description"
              onBlur={handleBlur}
              placeholder={cardDetails.category_description}
              className="rounded-lg outline-none bg-[#DFDFDF] w-full h-[142px] py-4 pl-4 md:pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
              value={description}
              onChange={handleTextChange}
            />
            {errors.description && touched.description && <div className="text-red-500 text-sm">{errors.description}</div>}
            <p className="font-normal text-[#DFDFDF] text-[14px] leading-[14.4px] text-right">100 Words Maximum</p>
            <div className="w-full font-Trueno flex flex-col gap-3">
              <label htmlFor="Job Description" className="font-normal text-[14px] leading-[16.8px]">
                Where did you get to know about Karsaaz ? (optional)
              </label>
              <div className="relative inline-block ">
                <select
                  value={selectedMarketingOption}
                  onChange={handleOptionChange}
                  defaultValue=""
                  className="block appearance-none w-full bg-white border border-[#DFDFDF] text-[14px] text-gray-700 h-[46px] px-4 pr-8 rounded leading-tight outline-none"
                >
                  <option className="font-normal text-[14px] leading-[16.8px]" value="" disabled>
                    Select Platform
                  </option>
                  {data?.data?.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                  <option value="others">Others</option>
                </select>
              </div>
            </div>
            <div>
              {selectedMarketingOption === "others" ? (
                <div className="flex flex-col gap-3">
                  <label htmlFor="Email Address*" className="font-normal text-[14px] leading-[16.8px]">
                    Comments *
                  </label>
                  <textarea
                    type="text"
                    name="comment"
                    id="comment"
                    onBlur={handleBlur}
                    placeholder="Write your Comments here"
                    onChange={handleCommentChange}
                    className="rounded-lg outline-none bg-[#DFDFDF] w-full h-[142px] py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                  />
                  {/* {errors.comment && touched.comment && <div className="text-red-500 text-sm">{errors.comment}</div>} */}
                </div>
              ) : null}
            </div>

            {/* --------------------- Admin User Mobile and checkbox Area --------------------- */}
            {profile_data?.data?.is_admin === "yes" ? (
              <div className="flex flex-col gap-3">
                <label htmlFor="Job Description" className="font-normal text-[14px] leading-[16.8px]">
                  User Mobile *
                </label>
                <input
                  type="mobile"
                  name="mobile"
                  id="mobile"
                  value={admin_userMobile}
                  onChange={(e) => {
                    setFieldValue("mobile", e.target.value);
                    setAdmin_userMobile(e.target.value);
                  }}
                  onBlur={handleBlur}
                  placeholder="Mobile Number"
                  className="font-normal text-sm rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#DFDFDF] h-[46px] w-full pl-4 md:pl-7 py-3"
                />

                {errors.mobile && touched.mobile && <div className="text-red-500 text-sm">{errors.mobile}</div>}

                <div className="flex items-center gap-3">
                  <input
                    type="checkbox"
                    name="unapprovedRequest"
                    id="unapprovedRequest"
                    value={admin_approvedRequest}
                    onChange={(e) => {
                      setFieldValue("unapprovedRequest", e.target.value);
                      setAdmin_approvedRequest(!admin_approvedRequest);
                    }}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="Job Description" className="font-normal text-[14px] leading-[16.8px]">
                    Want to submit unapproved request ?
                  </label>
                </div>
              </div>
            ) : null}

            {/* --------------------- Drag and Drop Area --------------------- */}
            <div>
              <label htmlFor="Upload Images*" className="font-normal text-[14px] leading-[16.8px]">
                Upload Images
              </label>

              <DropFileInput onFileChange={handleFileChange} fileList={fileList} setFileList={setFileList} />
            </div>
          </div>
        </div>
        // </form>
      )}

      {!cardDetails && <p>No card details are found. Please select again </p>}

      {guestPasswordModal && (
        <GuestPasswordModal
          onClose={() => setGuestPasswordModal(false)}
          setGuestPasswordModal={setGuestPasswordModal}
          randomPassword={randomPassword}
        />
      )}
    </div>
  );
}
