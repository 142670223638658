import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints

const bookApi = createApi({
  reducerPath: "booking",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),

  endpoints: (builder) => {
    return {
      bookQuicklyBook: builder.mutation({
        query: (body) => {
          return {
            url: "/user/request_later",
            method: "POST",
            body,
          };
        },
      }),
      bookBidding: builder.mutation({
        query: (body) => {
          return {
            url: "/user/request_later",
            method: "POST",
            body,
          };
        },
      }),
      bookDirectUrgent: builder.mutation({
        query: (body) => {
          return {
            url: "/user/request_later",
            method: "POST",
            body,
          };
        },
      }),
    };
  },
});

export const { useBookQuicklyBookMutation, useBookBiddingMutation, useBookDirectUrgentMutation } = bookApi;

export { bookApi };
