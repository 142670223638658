import React from "react";
import FooterMain from "views/partials/footer/FooterMain";
import GetMoreDoneWithKarsaaz from "common/GetMoreDoneWithKarsaaz";
import Banner from "views/partials/sliders/Banner";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { useFormik } from "formik";
import * as yup from "yup";
import { useContact_us_send_email } from "hooks/useUser";
import { FaFacebook, FaYoutube, FaLinkedin } from "react-icons/fa";

const baseURL = "https://karsaaz.app/api/common/env_verification_values";

const ContactUs = (props) => {
  const [data, setData] = useState([]);
  const { isLoading, mutate } = useContact_us_send_email();

  const getApi = () => {
    axios
      .get(baseURL)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => { console.log(err) });
  };

  useEffect(() => {
    getApi();
  }, []);

  const initialValues = {
    full_name: "",
    phone_number: "",
    email: "",
    subject: "",
    message: "",
  };

  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      full_name: yup.string().required("Enter Your Full Name"),
      phone_number: yup.string().max(11).required("Enter Phone Number"),
      email: yup.string().required("Enter Your Email Address"),
      subject: yup.string().required("Enter Your Subject"),
      message: yup.string().required("Enter Your Message"),
    }),
    validateOnChange: true,
    validateOnBlur: false,

    onSubmit: async (values) => {
      const newObj = {
        full_name: values.full_name,
        phone_number: values.phone_number,
        email: values.email,
        subject: values.subject,
        message: values.message,
      };

      mutate(newObj);
    },
  });
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    Formik;

  const TwitterXIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50">
      <path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z" fill="white"></path>
    </svg>
  );

  const ICONS = [FaFacebook, FaYoutube, TwitterXIcon, FaLinkedin];
  const links = [
    "https://www.facebook.com/officialkarsaaz",
    "https://www.youtube.com/@karsaaz",
    "https://twitter.com/Karsaaz_App",
    "https://www.linkedin.com/company/karsaaz-app",
  ];

  return (
    <>
      <div>
        <div>
          <div className="font-Trueno relative">
            <Banner
              BackgroundImageStyling="bg-left-bottom "
              banner_height="574px"
              Heading="Contact Us"
              Descriptions="Contact Karsaaz Customer Support Team"
              BackgroundSecondImageStyling="my-16 mb-[0px] md:mb-[81.8px] md:mr-[154px]"
              Icon={`${process.env.PUBLIC_URL}/images/ContactUsBannerIcon.png`}
              backgroundImage={`${process.env.PUBLIC_URL}/images/FooterBackIcon.png`}
            />
            {/* ---------------------- Contacts Bar ---------------------- */}

            <div className="grid md:flex gap-12 md:gap-8 lg:gap-12 w-[90%] mx-auto  absolute inset-0 transform top-[82%] md:top-[85%] mt-[63px] md:mt-0">
              <a
                rel="noreferrer"
                href={`tel:${data?.data?.karsaaz_mobile}`}
                className="w-full font-semibold text-[22px] leading-[26.82px]"
              >
                <div className="flex md:grid lg:flex items-center gap-5 md:gap-1 lg:gap-[10px] w-full bg-white border rounded-xl drop-shadow-2xl h-[125.45px] px-4">
                  <div className="rounded-full bg-[#0973BA] p-3 h-fit w-fit">
                    <FaPhoneAlt color="white" className="w-[26px] h-[26px]" />
                  </div>
                  <div className="flex flex-col">
                    <label className="font-normal text-base">
                      Phone Number
                    </label>
                    {data?.data?.karsaaz_mobile}
                  </div>
                </div>
              </a>

              {/* -------------------- WhatsApp Card -------------------- */}

              <a
                target="_blank"
                rel="noreferrer"
                href={`https://wa.me/${data?.data?.karsaaz_whatsapp}`}
                className="w-full font-semibold text-[22px] leading-[26.82px]"
              >
                <div className="flex md:grid lg:flex items-center gap-5 md:gap-1 lg:gap-[10px] w-full bg-white border rounded-xl drop-shadow-2xl h-[125.45px] px-4">
                  <div className="rounded-full bg-[#0973BA] p-3 h-fit w-fit">
                    <FaWhatsapp color="white" className="w-[26px] h-[26px]" />
                  </div>
                  <div className="flex flex-col">
                    <label className="font-normal text-base">WhatsApp</label>
                    {data?.data?.karsaaz_whatsapp}
                  </div>
                </div>
              </a>
              {/* -------------------- Email Card -------------------- */}
              <a
                rel="noreferrer"
                href="mailto:info@karsaaz.com"
                className="w-full font-semibold text-[22px] leading-[26.82px]"
              >
                <div className="flex md:grid lg:flex items-center gap-5 md:gap-1 lg:gap-[10px] w-full bg-white border rounded-xl drop-shadow-2xl h-[125.45px] px-4">
                  <div className="rounded-full bg-[#0973BA] p-3 h-fit w-fit">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/EmailIcon.png`}
                      alt=""
                      className="w-[26px] h-[26px] object-fill"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="font-normal text-base">Email</label>
                    info@karsaaz.com
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* -------------------------- Get In Touch -------------------------- */}
        <div className="w-full mt-[484px] md:mt-24">
          <div className="w-[90%] mx-auto">
            <div className="my-10">
              <h1 className="font-extrabold text-[28px] leading-8 md:text-[36px] md:leading-[48px]">
                Get in Touch
              </h1>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex gap-20 justify-between">
                <div className="flex flex-col gap-7 w-full">
                  <div className="flex flex-col gap-2 w-full ">
                    <label className="font-semibold text-sm">
                      Full Name
                      <span className="text-[#0973BA]">*</span>
                    </label>
                    <div className="w-full">
                      <input
                        name="full_name"
                        id="full_name"
                        value={values.full_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder_text="Enter Full Name"
                        className="w-full rounded-lg outline-none  border border-[#DFDFDF] bg-[#FBFBFB] h-[46px] py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                      />
                      {errors.full_name && touched.full_name ? (
                        <p className="font-semibold text-sm text-red-600">
                          {errors.full_name}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="w-full flex flex-col md:flex-row gap-4">
                    <div className="w-full">
                      <label className="font-semibold text-sm">
                        Phone Number
                        <span className="text-[#0973BA]">*</span>
                      </label>
                      <div className="w-full">
                        <input
                          name="phone_number"
                          id="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="w-full rounded-lg outline-none border   border-[#DFDFDF] bg-[#FBFBFB] h-[46px] py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                        />
                        {errors.phone_number && touched.phone_number ? (
                          <p className="font-semibold text-sm text-red-600">
                            {errors.phone_number}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="w-full">
                      <label className="font-semibold text-sm">
                        Email
                        <span className="text-[#0973BA]">*</span>
                      </label>
                      <div className="w-full">
                        <input
                          name="email"
                          id="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder_text="Enter Email Address"
                          className="w-full rounded-lg outline-none border  border-[#DFDFDF] bg-[#FBFBFB] h-[46px] py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                        />
                        {errors.email && touched.email ? (
                          <p className="font-semibold text-sm text-red-600">
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 w-full ">
                    <label className="font-semibold text-sm">
                      Subject
                      <span className="text-[#0973BA]">*</span>
                    </label>
                    <div className="w-full">
                      <input
                        name="subject"
                        id="subject"
                        value={values.subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder_text="Enter Subject"
                        className="w-full rounded-lg outline-none border  border-[#DFDFDF] bg-[#FBFBFB] h-[46px] py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                      />
                      {errors.subject && touched.subject ? (
                        <p className="font-semibold text-sm text-red-600">
                          {errors.subject}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  {/* ----------------- Text Area Field ----------------- */}
                  <div className="flex flex-col gap-2">
                    <label className="font-semibold text-sm">
                      Message <span className="text-[#0973BA]">*</span>
                    </label>
                    <div className="w-full">
                      <textarea
                        type="text"
                        name="message"
                        id="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Write here"
                        className="rounded-lg outline-none border  border-[#DFDFDF] bg-[#FBFBFB] w-full h-[270px] min-h-[50px] py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                      />
                      {errors.message && touched.message ? (
                        <p className="font-semibold text-sm text-red-600">
                          {errors.message}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="h-[46px] w-full font-extrabold text-sm text-white bg-[#0973BA] rounded-lg"
                  >
                    {isLoading ? (
                      <div
                        className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>

                {/* -------------------------- Right Side Image -------------------------- */}
                <div className="w-[350.07px] hidden md:block ">
                  {/* <FollowUsOn
                    styling="font-extrabold	text-black text-[22px] leading-[26px] text-center"
                    color="#0973BA"
                    contentStyling="flex justify-between"
                  /> */}
                  <div className="flex flex-col w-full font-Trueno text-white mt-[38px]">
                    <div>
                      <h1
                        className={`not-italic font-extrabold	text-black text-[22px] leading-[26px] text-center`}
                      >
                        Follow us
                      </h1>
                      <div className="mt-4">
                        <ul className={`flex rounded-full justify-between`}>
                          {ICONS.map((Icon, index) => (
                            <li
                              className="rounded-full p-3 bg-[#0973BA]"
                              key={index}
                            >
                              <a
                                href={links[index]}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon
                                  color="white"
                                  className="w-[25px] h-[25px]"
                                />
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <img
                    src={`${process.env.PUBLIC_URL}/images/bro.png`}
                    alt=""
                    className="mt-16"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <GetMoreDoneWithKarsaaz color="#E0F3FF" />
        <FooterMain />
      </div>
    </>
  );
};

export default ContactUs;
