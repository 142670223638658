import { useSuperServices } from "hooks/useService";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AvailableIn from "./AvailableIn";

const Services = () => {
  const { data: super_services, isLoading } = useSuperServices();
  const navigate = useNavigate();

  const handleClick = (item) => {
    if (item?.super_category_web_seo_url !== "") {
      setTimeout(() => {
        window.location.reload();
      }, 300);
      navigate(item?.super_category_web_seo_url.match(/\/([^/]+)$/)[0], {
        state: {
          web_banner_image: item?.web_banner_image,
          web_banner_text: item?.web_banner_text,
          super_category_id: item?.super_category_id,
          super_category_name: item?.super_category_name,
          category_type: "category",
          super_category_description: item?.super_category_description,
        },
      });
    } else {
      navigate(`/services`, {
        state: {
          web_banner_image: item?.web_banner_image,
          web_banner_text: item?.web_banner_text,
          category_id: item?.super_category_id,
          super_category_name: item?.super_category_name,
          category_type: "category",
          super_category_description: item?.super_category_description,
        },
      });
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <div className="flex flex-col gap-[38px]  w-full ">
        <div className="w-[316px] text-white font-Roboto">
          <h3 className="font-bold not-italic text-[18.4592px] leading-[22px]	text-white">Services</h3>
          <div>
            <ul className="flex flex-col gap-2 font-normal text-sm leading-[34px] mt-5">
              {isLoading
                ? Array(4)
                  .fill()
                  .map((_, index) => <div key={index} className="animate-pulse h-[12.22px] w-[180.22px] bg-sky-200 mb-2"></div>)
                :
                super_services?.data?.en?.map((item, index) => (
                  <button key={index} onClick={() => handleClick(item)}>
                    <li className="w-fit cursor-pointer capitalize font-medium hover:scale-105 hover:duration-200" key={index}>
                      {item?.super_category_name}
                    </li>
                  </button>
                ))}
            </ul>
          </div>
        </div>
        <AvailableIn />
      </div>
    </>
  );
};

export default Services;
