import { NavBarData } from "common/dataLists/NavBarData";
import React from "react";
import { Link } from "react-router-dom";
import Policies from "./Policies";
// import Policies from "./Policies";

const Menu = () => {
  return (
    <>
      <div className="flex w-full">
        <div className="w-[316px] text-white font-Roboto">
          <h3 className="font-bold not-italic text-[18.4592px] leading-[22px]	text-white">Menu</h3>
          <div>
            <ul className="flex flex-col gap-2 font-normal text-sm leading-[34px] mt-5 mb-2">
              {NavBarData.map((item, index) => (
                <li key={index} className="w-fit cursor-pointer capitalize font-medium hover:scale-105 hover:duration-200 ">
                  <Link to={item.path}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <Policies />
        </div>
      </div>
    </>
  );
};

export default Menu;
