import React from "react";
import Menu from "./Menu";
import Services from "./Services";
import SubscriptionArea from "./SubscriptionArea";
import CopyRightSection from "./CopyRightSection";
import { useLocation } from "react-router-dom";
import FollowUsOn from "./FollowUsOn";

const FooterMain = () => {
  const location = useLocation();

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/FooterBackIcon.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className={` 2xl:mx-auto w-[100%] mt-[71px] rounded-t-3xl
        ${location?.pathname === "/home"
            ? "bg-[#07609C]"
            : location?.pathname === "/PersonalCareMain" ||
              location?.pathname === "/DiscountDealsSub_cat" ||
              location?.pathname === "/HairCareSub_cat" ||
              location?.pathname === "/MakeUpServiceAtHomeSub_cat" ||
              location?.pathname === "/SkinCareServicesAtHomeSub_cat"
              ? "bg-[#F3A267]"
              : location?.pathname === "/BridalMakeupMain"
                ? "bg-[#DE3740]"
                : "bg-[#07609C]" // default color code
          }
        `}
      >
        <div className=" w-[90%] mx-auto pt-[80px] font-Roboto">
          <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  justify-between">
            <Services />
            <Menu />
            <FollowUsOn />

            <SubscriptionArea />
          </div>

          <CopyRightSection />
        </div>
      </div>
    </>
  );
};

export default FooterMain;
