import React from "react";
import GetMoreDoneWithKarsaaz from "./GetMoreDoneWithKarsaaz";
import FooterMain from "views/partials/footer/FooterMain";
import { Card } from "theme/cards/Card";
import { usePopular_sub_categories } from "hooks/useUser";
import { useLocation, useNavigate } from "react-router-dom";
import RightSideBar from "common/RightSideBar";
import NavCustom from "views/partials/navbar/NavCustom";
import SearchBar from "./SearchBar";
import ShimmerSkeleton from "views/loader/ShimmerSkeleton";
import CityFilter from "./CityFilter";

const PopularServices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: popular_categories,
    isLoading,
    mutate,
  } = usePopular_sub_categories();
  const {
    picture,
    category_id,
    category_name,
    web_banner_image,
    web_banner_text,
  } = location.state || {};

  const handleCityChange = (cityValue) => {
    const payload = {
      city_id: localStorage.getItem("selectedOption"),
    };
    mutate(payload);
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/FooterBackIcon.png)`,
          }}
          className={`bg-[#0973BA] relative bg-no-repeat bg-[length:_500px] bg-left-bottom`}
        >
          {/* <div style={props.style} className="bg-[#0973BA] relative bg-no-repeat"> */}
          <div className="flex flex-col w-full h-574px">
            <NavCustom
              styling={{ color: "white", backgroundColor: "#0973BA" }}
            />

            <RightSideBar />

            {isLoading ? (
              <div>
                <div className="w-[90%] mx-auto flex flex-col-reverse md:grid md:grid-cols-2 p-3 py-12">
                  <div className="w-full flex flex-col gap-3 pt-5 md:py-16">
                    <div className="animate-pulse w-full h-7 bg-sky-200"></div>

                    <div className="animate-pulse w-1/3 h-3 bg-sky-200 mt-3"></div>
                    <div className="animate-pulse w-full h-12 bg-sky-200 mt-3 rounded-2xl"></div>
                  </div>
                  <div className="flex items-center justify-center gap-5 w-full">
                    <div className="flex items-center justify-center gap-5 w-full">
                      <div className=" animate-pulse w-[100px] h-[200px] rounded-t-full rounded-b-full bg-slate-200"></div>
                      <div className=" animate-pulse w-[200px] h-[300px]  rounded-t-full rounded-b-full bg-slate-200"></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="font-Trueno flex flex-col-reverse md:flex-row justify-between items-center w-[90%] mx-auto ">
                <div className="w-full md:w-[650px] text-center md:text-left">
                  <div className="md:w-[650px]">
                    <h1 className=" font-extrabold text-[24px] md:text-[48px] leading[58.51px] text-white">
                      Popular Services
                    </h1>
                    <p className="font-normal text-base md:text-lg text-white my-3">
                      Karsaaz Popular Services: Making Your Dream Home A Reality
                    </p>
                  </div>
                  <div className="my-3">
                    <SearchBar />
                  </div>
                </div>
                {/* <------------- Hero----Section----Image-------- > */}
                <div className="w-fit max-w-[363.76px] justify-center md:justify-start">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/PopularIcon.webp`}
                    alt=""
                    className="h-[331.36px] my-16 mb-[0px] md:mb-[81.8px] md:mr-[134px] "
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-[90%] mx-auto ">
          <h1 className="font-extrabold text-[28px] md:text-4xl my-[64px] flex justify-center">
            Popular Services
          </h1>

          <div className="w-full flex justify-center md:justify-start">
            <div className="">
              <CityFilter onCityChange={handleCityChange} />
            </div>
          </div>

          <div className="">
            {location.state !== null ? (
              <button>
                <li
                  key={category_id}
                  className="h-[284px] w-[241px] border rounded-2xl border-[#DFDFDF]	 p-[7px] flex flex-col gap-2 justify-between items-center  cursor-pointer"
                  // onClick={() => handleSingleCategoryClick()}
                  onClick={() => {
                    navigate(`/sub-categories/${category_name}`, {
                      state: {
                        web_banner_image: web_banner_image,
                        web_banner_text: web_banner_text,
                        category_name: category_name,
                        category_id: category_id,
                      },
                    });
                  }}
                >
                  <div className="h-[210px] w-[210.6px]">
                    <img
                      src={picture}
                      alt="pictures"
                      className="rounded-xl h-full w-full object-fit"
                    />
                  </div>

                  <h1 className="font-semibold text-center text-[14px] lg:text-[18px] leading-[22px] ">
                    {category_name}
                  </h1>
                </li>
                <div className="my-3">
                  <SearchBar />
                </div>
              </button>
            ) : (
              <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 self-center mt-11 md:mt-14">
                {isLoading
                  ? Array(4)
                    .fill()
                    .map((_, index) => (
                      <div key={index}>
                        <ShimmerSkeleton />
                      </div>
                    ))
                  : popular_categories?.data?.map((item, index) => (
                    <ul key={index}>
                      <li className="">
                        <Card
                          category_id={item?.category_id}
                          sub_category_id={item?.id}
                          name={item?.name}
                          picture={item?.picture}
                          price={item?.price}
                          price_type={item?.price_type}
                          category_description={item?.description}
                          sub_category_is_bidded={item?.is_bidded}
                          sub_category_discount_price={item?.discount_price}
                          backgroundColor="#0973BA"
                          sub_category_web_seo_url={item?.web_seo_url}
                          sub_category_is_enabled={item?.is_enabled}
                          sub_category_slug={item?.slug}
                        />
                      </li>
                    </ul>
                  ))}
              </div>
            )}
          </div>
        </div>

        <GetMoreDoneWithKarsaaz color="#E0F3FF" TextColor="#0973BA" />
        <FooterMain />
      </div>
    </>
  );
};

export default PopularServices;
