import { useEffect } from "react";
import Loader from "views/loader/Loader";
import BookingDetails from "./BookingDetails";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function Final({
  request_image_data,

  setImages,
  images,
  file,
  touched,
  errors,
  setFieldValue,
  values,
  handleBlur,
  setSelectedMarketingOption,
  selectedMarketingOption,
  comment,
  setComment,
  description,
  setText_description,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  address,
  setAddress,
  fileList,
  setFileList,
  autoComplete2,
  setAutoComplete2,
  selectedPlace,
  setSelectedPlace,
  currentLocation,
  setCurrentLocation,
  setCurrentStep,
  setGuestPasswordModal,
  randomPassword,
  guestPasswordModal,
}) {
  useEffect(() => {
    // disabling window event listener
    window.onbeforeunload = null;
    window.onpopstate = null;

    if (request_image_data?.success === true) {
      window.onbeforeunload = null;
      window.onpopstate = null;
      window.location.replace("/user/job/success");
    }
  }, [request_image_data]);

  const formik = useFormik({
    initialValues: {
      autocomplete: null,
      selectedTime: "",
      selectedDate: "",
      description: "",
    },
    validationSchema: Yup.object({
      autocomplete: Yup.object().required("Address Field is required").nullable(),
      selectedDate: Yup.string().required("Please Select the Date"),
      selectedTime: Yup.string().required("Please Select the Time"),
      description: Yup.string().max(100).required("Please add Descriptions"),
      // comment: Yup.string().required("Please add your Comments"),
    }),
  });

  return (
    // <div className="my-10  w-full">
    //   <div className="w-full mx-auto flex flex-col gap-5 px-1 md:px-10 py-5 items-center">
    //     <div className="bg-[#DAF5E9] p-3 md:p-5  rounded-full">
    //       <div className="wrapper bg-[#BBECD7] py-1 px-3 md:px-5  rounded-full">
    //         <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
    //           <circle className="checkmark__circle " cx="26" cy="26" r="25" fill="none" />
    //           <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    //         </svg>
    //       </div>
    //     </div>
    //     <h1 className="font-bold text-[22px] md:text-[28px] leading-[26.4px] md:leading-[33.6px] my-2  text-green-500 text-center">
    //       Loading
    //     </h1>
    //     <p className="font-bold text-[16pz] md:text-[18px] leading-[31px] text-center text-black">Please Wait... </p>

    //     {/* <div className="flex flex-col  justify-between items-center ">
    //       <h1 className="font-bold text-[22px] md:text-[28px] leading-[26.4px] md:leading-[33.6px] my-7 uppercase text-green-500 text-center">
    //         Job Posted Successfully
    //       </h1>
    //       <p className="font-bold text-[16pz] md:text-[18px] leading-[31px] text-center text-black">
    //         Your Job has been posted successfully. Your Job ID is “<label className="text-[#0973BA]"> {request_id} </label>“ . You
    //         can check the status of your job in "My Jobs" section.
    //       </p>
    //     </div>
    //     <div className="w-full bg-[#E0F3FF]">
    //       <div className="flex flex-col justify-between items-center py-12 px-5 md:px-28">
    //         <h1 className="font-bold text-[18px] leading-[31px] text-center">
    //           You get following benefits only when you book an expert through Karsaaz App :)
    //         </h1>
    //         <ul className="flex flex-col gap-5 w-full first-letter:font-normal text-[16px] leading-5 list-disc list-outsides ">
    //           <div className="flex gap-1 justify-between items-center">
    //             <li className="font-normal text-sm md:text-base">Guaranteed Lowest Price</li>
    //             <div className="rounded-full bg-[#0973BA] p-1">
    //               <RiCheckDoubleLine color="white" height="18px" width="18px" />
    //             </div>
    //           </div>

    //           <div className="flex justify-between items-center">
    //             <li className="font-normal text-sm md:text-base">Full job tracking for your safety and security</li>
    //             <div className="rounded-full bg-[#0973BA] p-1">
    //               <RiCheckDoubleLine color="white" height="18px" width="18px" />
    //             </div>
    //           </div>

    //           <div className="flex justify-between items-center">
    //             <li className="font-normal text-sm md:text-base">15 Days Service Warranty by Karsaaz App</li>
    //             <div className="rounded-full bg-[#0973BA] p-1">
    //               <RiCheckDoubleLine color="white" height="18px" width="18px" />
    //             </div>
    //           </div>

    //           <div className="flex justify-between items-center">
    //             <li className="font-normal text-sm md:text-base">Rewards and Cashback</li>
    //             <div className="rounded-full bg-[#0973BA] p-1">
    //               <RiCheckDoubleLine color="white" height="18px" width="18px" />
    //             </div>
    //           </div>
    //         </ul>
    //       </div>
    //     </div>
    //     <TrackYourService />
    //     <div className="grid gap-6 md:grid-cols-3">
    //       <Link to="/my-jobs">
    //         <button className="py-4 w-[204.56px] rounded-lg font-bold text-white  text-[16px] leading-[19.2px] bg-[#0973BA] hover:scale-105 hover duration-200">
    //           Your Jobs
    //         </button>
    //       </Link>
    //       <Link to="/home">
    //         <button className="py-4 w-[204.56px] rounded-lg font-bold  text-white text-[16px] leading-[19.2px] bg-[#09BA70] hover:scale-105 hover duration-200">
    //           Book Another Service
    //         </button>
    //       </Link>
    //       <Link to="/home" className="w-full">
    //         <button className="py-4 w-[204.56px] rounded-lg font-bold  text-[#0973BA] border border-[#0973BA] text-[16px] leading-[19.2px] bg-white hover:scale-105 hover duration-200">
    //           Home
    //         </button>
    //       </Link>
    //     </div> */}
    //   </div>
    // </div>

    <>
      <div className="relative w-full h-auto font-Trueno bg-white">
        <div className="absolute z-50 inset-0 overflow-y-auto scrollbar-hide backdrop-blur-sm px-3 md:px-9 py-7">
          {/* <div className="md:w-[50%] h-auto mx-auto bg-white px-3 md:px-6 flex justify-center border rounded-[15px] relative">
            <div className="flex flex-col gap-5 py-20 w-full mx-auto text-center">
              <h1 className="font-extrabold text-xl md:text-[24px] leading-[29.26px]">Loading...</h1>
              <p className="text-sm text-black font-normal leading-6">Please Wait</p>
            </div>
          </div> */}
          <Loader />
        </div>
        <BookingDetails
          setCurrentStep={setCurrentStep}
          // handleSubmit={handleSubmit}
          setImages={setImages}
          images={images}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          values={values}
          handleBlur={formik.handleBlur}
          selectedMarketingOption={selectedMarketingOption}
          setSelectedMarketingOption={setSelectedMarketingOption}
          comment={comment}
          setComment={setComment}
          description={description}
          setText_description={setText_description}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          latitude={latitude}
          longitude={longitude}
          address={address}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setAddress={setAddress}
          fileList={fileList}
          setFileList={setFileList}
          autoComplete2={autoComplete2}
          setAutoComplete2={setAutoComplete2}
          selectedPlace={selectedPlace}
          setSelectedPlace={setSelectedPlace}
          currentLocation={currentLocation}
          setCurrentLocation={setCurrentLocation}
          setGuestPasswordModal={setGuestPasswordModal}
          guestPasswordModal={guestPasswordModal}
          randomPassword={randomPassword}
        />
      </div>
    </>
  );
}
