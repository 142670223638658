import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from ".";
import { useDispatch } from "react-redux";
import { showErrorToast, showSuccessToast } from "store/features/toastSlice";
import { useNavigate } from "react-router-dom";
import { checkIsUserLoggedIn } from "actions/auth";

import {
  updateUser,
  changePassword,
  updatePassword,
  forgotPassword,
  verify_forgototp,
  updateLocation,
  fetchHistory,
  fetchMyProjects,
  single_request,
  cancel_request,
  request_description_update,
  reviews,
  homepage_videos,
  profile,
  requests_count,
  bids,
  assign_request,
  collaborators,
  provider_detail,
  add_fav_provider,
  fav_providers_list,
  remove_fav_provider,
  env_verification_values,
  contact_us_send_email,
  subscriber_email,
  faq_questions_answers,
  faq_categories,
  showLocation,
  search,
  search_all,
  popular_categories,
  get_country_city,
  request_later,
  seo_page_view,
  user_request_images,
  marketing_platform,
  request_rating,
  provider_profile,
  karsaaz_settings,
  remove_user_request_images,
  service_by_slug,
  sitemap,
  popular_sub_categories,
  blog_categories,
  all_blogs,
  blogs_by_category,
  blogs_search,
  blog_by_slug,
  abandoned_request,
  abandoned_request_images,
  popular_services_by_category,
  delink_social_account,
  weekly_offer_sub_categories,
  weekly_offer_by_category,
  rewards,
  redeem_reward,
  apply_cashback_discount,
  remove_cashback_discount,
  apply_coupon_discount,
  remove_coupan,
} from "middleware/api";

// -------------------------------- Hooks of APIs --------------------------------

// hooks

const useRequest_later = (body) => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: request_later,
    onSuccess: (data) => {
      if (data.success) dispatch(showSuccessToast("JOB POSTED SUCCESSFULLY"));
      else {
        dispatch(showErrorToast(data.error));
      }
    },
  });
};

const useUpdateUser = (body) => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: updateUser,
    onSuccess: (data) => {
      dispatch(checkIsUserLoggedIn());

      if (data.success)
        dispatch(showSuccessToast("Profile Updated Successfully"));
      else {
        dispatch(showErrorToast(data.error));
      }
    },
  });
};

const useDelink_social_account = (body) => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: delink_social_account,
    onSuccess: (data) => {
      if (data.success) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.error));
      }
    },
  });
};

const useProfile = () =>
  useQuery({
    queryKey: ["profile"],
    queryFn: profile,
  });

const useShowLocation = () =>
  useQuery({
    queryKey: ["showLocation"],
    queryFn: showLocation,
  });

// hooks for change password
const useChangePassword = (body) => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: changePassword,
    onSuccess: (data) => {
      if (data.success) dispatch(showSuccessToast(data.message));
      if (!data.success) dispatch(showErrorToast(data.error));
    },
  });
};

// hooks for Update password
const useUpdatePassword = (body) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: updatePassword,
    onSuccess: (data) => {
      if (data.success) dispatch(showSuccessToast(data.message));
      navigate("/login");
      if (!data.success) dispatch(showErrorToast(data.error));
    },
  });
};

//hooks for forgot password

const useForgotPassword = (body) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  return useMutation({
    mutationFn: forgotPassword,
    onSuccess: (data) => {
      if (data?.original?.data?.isbuyer === "no") {
        dispatch(
          showErrorToast("Provider not allowed, try again with buyer account !")
        );
      } else if (
        data?.original?.data?.isbuyer === "yes" &&
        data.original?.success
      ) {
        dispatch(showSuccessToast(data.message));

        // const mobile = data.original.data.mobile;
        // const id = data.original.data.id;

        // navigate(`/forgot-password-otp`, {
        //   state: { mobile: mobile, id: id },
        // });
      }

      if (data.error) dispatch(showErrorToast(data.error));
    },
  });
};

// hooks for change Location
const useUpdateLocation = (body) => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: updateLocation,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast("Location Updated Successfully"));
      } else {
        dispatch(showErrorToast("There is an error. Please try again later"));
      }
    },
  });
};

const useVerify_forgototp = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  return useMutation({
    mutationFn: (data) => {
      return verify_forgototp({ ...data, user_type: "user" });
    },

    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.data.message));
        dispatch(checkIsUserLoggedIn());

        // const id = data.data.id;
        // const mobile = data.data.mobile;

        // navigate(`/update-password`, {
        //   state: { mobile: mobile, id: id },
        // });
      } else {
        dispatch(showErrorToast(data.error));
      }
    },
  });
};

// hooks for History

const useHistory = () =>
  useQuery({
    queryKey: ["history"],
    queryFn: fetchHistory,
    dispatch: checkIsUserLoggedIn(),
  });

// const useHistory = (body) => {
//   const dispatch = useDispatch();
//   dispatch(checkIsUserLoggedIn());

//   return useMutation({
//     mutationFn: fetchHistory,
//   });
// };

// hooks for History
const useMyProjects = () =>
  useQuery({
    queryKey: ["projects"],
    queryFn: fetchMyProjects,
    dispatch: checkIsUserLoggedIn(),
  });

const useMarketing_platform = () =>
  useQuery({
    queryKey: ["marketing_platform"],
    queryFn: marketing_platform,
    dispatch: checkIsUserLoggedIn(),
  });

const useSingle_request = ({ request_id }) => {
  return useQuery({
    queryKey: ["single_request"],
    queryFn: () => single_request({ request_id }),
    dispatch: checkIsUserLoggedIn(),
  });
};

const useCancel_request = ({ request_id }) => {
  return useQuery({
    queryKey: ["cancel_request"],
    queryFn: () => cancel_request({ request_id }),
    dispatch: checkIsUserLoggedIn(),
  });
};

const useRequest_description_update = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation({
    mutationFn: request_description_update,
    onSuccess: (data) => {
      if (data.success === true) {
        navigate("/my-jobs");
        dispatch(showSuccessToast("Booking Updated Successfully"));
      } else if (data?.error_code === 104 || data?.error_code === 103) {
        dispatch(
          showErrorToast("Another user is Logged in on different device")
        );

        localStorage.removeItem("token");
        localStorage.removeItem("id");

        navigate("/login");
      } else {
        dispatch(showErrorToast(data.error));
      }
    },
  });
};

const useRemove_user_request_images = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation({
    mutationFn: remove_user_request_images,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast("Image Deleted"));
      } else if (data?.error_code === 104 || data?.error_code === 103) {
        dispatch(
          showErrorToast("Another user is Logged in on different device")
        );

        localStorage.removeItem("token");
        localStorage.removeItem("id");

        navigate("/login");
      } else {
        dispatch(showErrorToast(data.error));
      }
    },
  });
};

const useSubscriber_email = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: subscriber_email,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.error));
      }
    },
  });
};

const useFaq_categories = () =>
  useQuery({
    queryKey: ["faq_categories"],
    queryFn: faq_categories,
  });

const useFaq_questions_answers = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: faq_questions_answers,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.error));
      }
    },
  });
};

// const useRequest_description_update = ({ request_id, description }) => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   return useMutation({
//     mutationFn: (data) => {
//       return request_description_update({ ...data, request_id, description });
//     },
//     onSuccess: (data) => {
//       if (data.success === true) {
//         dispatch(showSuccessToast(data.message));
//         dispatch(checkIsUserLoggedIn());
//         navigate("/my-jobs");
//       } else {
//         dispatch(showErrorToast(data.error));
//       }
//     },
//   });
// };

const useReviews = () =>
  useQuery({
    queryKey: ["reviews"],
    queryFn: reviews,
  });

const useHomepage_videos = () =>
  useQuery({
    queryKey: ["homepage_videos"],
    queryFn: homepage_videos,
  });

const useRequests_count = () =>
  useQuery({
    queryKey: ["requests_count"],
    queryFn: requests_count,
  });

const useBids = ({ request_id }) => {
  return useQuery({
    queryKey: ["bids"],
    queryFn: () => bids({ request_id }),
    dispatch: checkIsUserLoggedIn(),
  });
};

const useSeo_page_view = ({ slug }) =>
  useQuery({
    queryKey: ["seo_page_view", slug],
    queryFn: () => seo_page_view({ slug }),
  });

const useSee_Seo_page = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: seo_page_view,
  });
};

const Collaborators = () =>
  useQuery({
    queryKey: ["collaborators"],
    queryFn: collaborators,
  });

const useEnv_verification_values = () =>
  useQuery({
    queryKey: ["env_verification_values"],
    queryFn: env_verification_values,
  });

// const useAssign_request = ({ request_id, request_meta_id, price_per_hour }) => {
//   return useQuery({
//     queryKey: ["assign_request"],
//     queryFn: () => assign_request({ request_id, request_meta_id, price_per_hour }),
//     onSuccess: (data) => {
//       if (data.success) dispatch: showSuccessToast("Profile Updated Successfully");
//       else {
//         dispatch: showErrorToast(data.error);
//       }
//     },
//   });
// };

const useAssign_request = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: assign_request,
    onSuccess: (data) => {
      if (data.success === true) {
        const token = data.data.token;

        localStorage.setItem("token", token);
        localStorage.setItem("id", data.data.user_id);

        // api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        // Display success message
        dispatch(showSuccessToast(data.message));
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        dispatch(showErrorToast(data.error));
      }
    },
  });
};
const useAdd_fav_provider = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: add_fav_provider,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};

const useRemove_fav_provider = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: remove_fav_provider,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};

const useFav_providers_list = () =>
  useQuery({
    queryKey: ["fav_providers_list"],
    queryFn: fav_providers_list,
  });

const useProvider_detail = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: provider_detail,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.error)); // Dispatch error toast message
      }
    },
  });
};

const useContact_us_send_email = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: contact_us_send_email,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};

// const usePopular_categories = () => {
//   const dispatch = useDispatch();

//   return useMutation({
//     mutationFn: popular_categories,
//     onSuccess: (data) => {
//       if (data.success === true) {
//         dispatch(showSuccessToast(data.message));
//       } else {
//         dispatch(showErrorToast(data.message));
//       }
//     },
//   });
// };

const usePopular_sub_categories = () => {
  return useMutation({
    mutationFn: popular_sub_categories,
  });
};

const useSearch = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: search,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};

const useSearch_all = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: search_all,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};

// const requestImages = () => {
//   const dispatch = useDispatch();

//   return useMutation({
//     mutationFn: user_request_images,
//     onSuccess: (data) => {
//       if (data.success === true) {
//         dispatch(showSuccessToast(data.message));
//       } else {
//         dispatch(showErrorToast(data.message));
//       }
//     },
//   });
// };

const RequestImages = () => {
  return useMutation({
    mutationFn: user_request_images,
  });
};

const AbandonedRequestImages = () => {
  return useMutation({
    mutationFn: abandoned_request_images,
  });
};

const useAbandoned_request = ({ images }) => {
  return useMutation({
    mutationFn: abandoned_request,
    onSuccess: async (data) => {
      if (data?.success === true) {
        const formData = new FormData();
        for (let i = 0; i < images.length; i++) {
          formData.append("abandoned_request_id", data?.data?.id);
          formData.append("image", images[i]);
        }
        abandoned_request_images(formData);
      }
    },
  });
};

// const useGet_country_city = () => {
//   const dispatch = useDispatch();

//   return useMutation({
//     mutationFn: get_country_city,
//     onSuccess: (data) => {
//       if (data.success === true) {
//         dispatch(showSuccessToast(data.message));
//       } else {
//         dispatch(showErrorToast(data.message));
//       }
//     },
//   });
// };

const useGet_country_city = () =>
  useQuery({
    queryKey: ["get_country_city"],
    queryFn: get_country_city,
  });

const useRequest_rating = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: request_rating,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};

const useService_by_slug = () => {
  return useMutation({
    mutationFn: service_by_slug,
  });
};

const useWeekly_offer_sub_categories = () => {
  return useMutation({
    mutationFn: weekly_offer_sub_categories,
  });
};

const useWeekly_offer_by_category = () => {
  return useMutation({
    mutationFn: weekly_offer_by_category,
  });
};

const useProvider_profile = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: provider_profile,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};

const usePopular_services_by_category = () => {
  return useMutation({
    mutationFn: popular_services_by_category,
  });
};

const useRewards = () =>
  useQuery({
    queryKey: ["rewards"],
    queryFn: rewards,
  });

const useRedeem_reward = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: redeem_reward,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};

const useApply_cashback_discount = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: apply_cashback_discount,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};

const useRemove_cashback_discount = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: remove_cashback_discount,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};

const useApply_coupan_discount = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: apply_coupon_discount,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data?.error));
      }
    },
  });
};

const useRemove_Coupan = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: remove_coupan,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data?.error));
      }
    },
  });
};

const useBlogs_by_category = () => {
  return useMutation({
    mutationFn: blogs_by_category,
  });
};

const useBlogs_search = () => {
  return useMutation({
    mutationFn: blogs_search,
  });
};

const useBlog_by_slug = () => {
  return useMutation({
    mutationFn: blog_by_slug,
  });
};

const useKarsaaz_settings = () =>
  useQuery({
    queryKey: ["karsaaz_settings"],
    queryFn: karsaaz_settings,
  });

const useSitemap = () =>
  useQuery({
    queryKey: ["sitemap"],
    queryFn: sitemap,
  });

const useBlog_categories = () =>
  useQuery({
    queryKey: ["blog_categories"],
    queryFn: blog_categories,
  });

const useAll_blogs = () =>
  useQuery({
    queryKey: ["all_blogs"],
    queryFn: all_blogs,
  });

export {
  usePopular_services_by_category,
  useWeekly_offer_sub_categories,
  useRequest_description_update,
  useRemove_user_request_images,
  useWeekly_offer_by_category,
  useRemove_cashback_discount,
  useEnv_verification_values,
  useApply_cashback_discount,
  usePopular_sub_categories,
  useFaq_questions_answers,
  useDelink_social_account,
  useContact_us_send_email,
  useApply_coupan_discount,
  AbandonedRequestImages,
  useRemove_fav_provider,
  useFav_providers_list,
  useMarketing_platform,
  useAbandoned_request,
  useBlogs_by_category,
  useAdd_fav_provider,
  useSubscriber_email,
  useVerify_forgototp,
  useProvider_profile,
  useKarsaaz_settings,
  useChangePassword,
  useUpdateLocation,
  useForgotPassword,
  useUpdatePassword,
  useSingle_request,
  useProvider_detail,
  useHomepage_videos,
  useCancel_request,
  useFaq_categories,
  useRequests_count,
  useAssign_request,
  useRemove_Coupan,
  useShowLocation,
  useService_by_slug,
  useGet_country_city,
  useBlog_categories,
  useRequest_rating,
  useRequest_later,
  useRedeem_reward,
  useSeo_page_view,
  useSee_Seo_page,
  useBlog_by_slug,
  useBlogs_search,
  RequestImages,
  useSitemap,
  useProfile,
  useBids,
  useRewards,
  Collaborators,
  useUpdateUser,
  useHistory,
  useMyProjects,
  useReviews,
  useSearch,
  useSearch_all,
  useAll_blogs,
};
