import { api } from ".";
import { useMutation } from "@tanstack/react-query";
import { redirect, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showErrorToast, showSuccessToast } from "store/features/toastSlice";
import {
  login,
  register,
  sendOtp,
  verifyOtp,
  logout,
  sync_login_social_account,
  paymob_auth_token,
  paymob_order_registration,
  paymob_card_payment_key,
  post_paymob_response,
  transactions_history,
  cod_payment,
  delete_account_request,
} from "middleware/api";
import { checkIsUserLoggedIn } from "actions/auth";
import { signOut } from "firebase/auth";
import { auth } from "views/firebaseAuthentication/config";

// hooks
const useLogin = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: login,

    onSuccess: (data) => {
      if (data?.data?.isbuyer === "no") {
        dispatch(
          showErrorToast("Provider not allowed, try again with buyer account !")
        );
      } else if (data.success === true) {
        dispatch(showSuccessToast("Login successful"));
        const token = data?.data?.token;
        const id = data?.data?.user_id;

        localStorage.setItem("token", token);
        localStorage.setItem("id", id);
      } else {
        dispatch(showErrorToast(data.error));
      }
    },
    onError: (error) => {
      dispatch(showErrorToast("An error occurred during login."));
      console.error(error);
    },
  });
};

const useLogInToContinue = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      if (data?.data?.isbuyer === "no") {
        dispatch(
          showErrorToast("Provider not allowed, try again with buyer account !")
        );
      } else if (data.success === true) {
        const token = data.data.token;

        localStorage.setItem("token", token);
        localStorage.setItem("id", data.data.user_id);

        // api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        // Display success message
        dispatch(showSuccessToast("Login successful"));
      } else {
        dispatch(showErrorToast(data.error));
      }
    },
    onError: (error) => {
      dispatch(showErrorToast("An error occurred during login."));
      console.error(error);
    },
  });
};

const useAbandonedLogIn = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      if (data?.data?.isbuyer === "no") {
        dispatch(
          showErrorToast("Provider not allowed, try again with buyer account !")
        );
      } else if (data?.error_code === 160) {
        sessionStorage.setItem("existing_user", data?.error_code);
      } else {
        sessionStorage.setItem("existing_user", data?.error_code);
      }
    },
    onError: (error) => {
      dispatch(showErrorToast("An error occurred."));
      console.error(error);
    },
  });
};

const useSync_login_social_account = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: sync_login_social_account,

    onSuccess: (data) => {
      if (data?.data?.isbuyer === "no") {
        dispatch(
          showErrorToast("Provider not allowed, try again with buyer account !")
        );
      } else if (data.success === true) {
        dispatch(showSuccessToast("Login successful"));
        const token = data?.data?.token;
        const id = data?.data?.user_id;

        localStorage.setItem("token", token);
        localStorage.setItem("id", id);
      }
    },
    onError: (error) => {
      dispatch(showErrorToast("An error occurred during login."));
      console.error(error);
    },
  });
};

const useRegister = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: register,
    onSuccess: (data) => {
      if (data?.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        toast.error(data.error, { position: "top-center", autoClose: 5000 });
      }
    },
  });
};

const useVerifyOtp = () => {
  const dispatch = useDispatch();

  return useMutation({
    // mutationFn: (verification_code) => verifyOtp(verification_code),
    mutationFn: verifyOtp,

    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast("Otp Verified"));
      } else {
        toast.error(data.error, { position: "top-center", autoClose: 5000 });
      }
    },
  });
};

const useSendOtp = () => {
  return useMutation({
    mutationFn: sendOtp,

    onSuccess: (data) => {
      if (data.success === true) {
        toast.success(data.data.message, { position: "top-center" });
      } else {
        toast.error(data.error, { position: "top-center" });
      }

      redirect("/home");
    },
  });
};

const useResendOtp = () => {
  return useMutation({
    mutationFn: sendOtp,

    onSuccess: (data) => {
      if (data.success === true) {
        toast.success(data.data.message, { position: "top-center" });
      } else {
        toast.error(data.error, { position: "top-center" });
      }

      redirect("/home");
    },
  });
};

const useLogout = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation({
    mutationFn: logout,
    onSuccess: async (data) => {
      if (data.success === true) {
        // await auth.signOut().then(() => {});

        await signOut(auth)
          .then(() => {})
          .catch((error) => {
            console.error("An error happened.", error);
          });
        localStorage.clear();
        sessionStorage.clear();

        navigate("/login");
        dispatch(checkIsUserLoggedIn());
      }
      // this if the user is declined from admin side
      else if (data?.error_code === 502 || data?.error_code === 101) {
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        navigate("/login");
      } else if (data?.error_code === 104 || data?.error_code === 103) {
        dispatch(
          showErrorToast("Another user is Logged in on different device")
        );

        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("id");

          navigate("/login");
          dispatch(checkIsUserLoggedIn());
        }, 2000);
      } else {
        dispatch(showErrorToast(data.error));
      }
    },
  });
};

const useDelete_account_request = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: delete_account_request,
    onSuccess: (data) => {
      if (data?.success === "false") {
        dispatch(showErrorToast(data?.error));
      }
    },
  });
};
const usePaymob_auth_token = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: paymob_auth_token,
    onError: (err) => {
      if (err) {
        dispatch(showErrorToast(err));
      }
    },
  });
};

const usePaymob_order_registration = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: paymob_order_registration,
    onError: (err) => {
      if (err?.response?.status !== 200) {
        dispatch(showErrorToast(err?.response?.data?.detail));
      }
    },
  });
};

const usePaymob_paymob_card_payment_key = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: paymob_card_payment_key,
    onError: (err) => {
      if (err?.error) {
        dispatch(showErrorToast(err?.error));
      }
    },
  });
};

const usePost_paymob_response = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: post_paymob_response,
    onSuccess: (data) => {
      if (data?.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        toast.error(data.error);
      }
    },
  });
};

const useTransactions_history = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: transactions_history,
    onError: (err) => {
      dispatch(showErrorToast(err?.error));
    },
  });
};

const useCod_payment = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: cod_payment,
    onError: (err) => {
      dispatch(showErrorToast(err?.error));
    },
  });
};

export {
  useLogin,
  useLogout,
  useSendOtp,
  useRegister,
  useVerifyOtp,
  useResendOtp,
  useCod_payment,
  useAbandonedLogIn,
  useLogInToContinue,
  usePaymob_auth_token,
  useTransactions_history,
  usePost_paymob_response,
  useDelete_account_request,
  useSync_login_social_account,
  usePaymob_order_registration,
  usePaymob_paymob_card_payment_key,
};
