import React from "react";
import Banner from "views/partials/sliders/Banner";
import FooterMain from "views/partials/footer/FooterMain";
import GetMoreDoneWithKarsaaz from "common/GetMoreDoneWithKarsaaz";

const TermsAndConditions = () => {
  return (
    <>
      <div>
        <Banner
          Heading="Karsaaz Terms and Conditions"
          BackgroundImageStyling="bg-left-bottom "
          Descriptions="Last Updated: Latest"
          Icon={`${process.env.PUBLIC_URL}/images/TermsConditions.png`}
          backgroundImage={`${process.env.PUBLIC_URL}/images/FooterBackIcon.png`}
          BackgroundSecondImageStyling="py-2 md:py-16 md:mr-[154px]"
          banner_height="574px "
        />
        {/* ----------------------- Articles ------------------------ */}

        <div className="w-full mt-[64px]">
          <div className="flex flex-col gap-10 w-[90%] mx-auto font-Trueno">
            {/* ----------------------------- Article are started from below ----------------------------- */}

            {/* ----------------------------- Article N0. 1 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                Important Terms And Conditions For Karsaaz User
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                Dear User,
                <br />
                <br />
                Thank you for booking a service provider through the Karsaaz
                app. Please read and note these important terms and conditions
                for using the Karsaaz app:
              </span>
            </div>

            {/* ----------------------------- Article N0. 2 ----------------------------- */}

            <div>
              <span className="font-normal text-[18px] leading-8 mt-6">
                You can mostly navigate on the Application without identifying
                yourself, nor communicate your personal data.
                <br />
                You will be required to provide personal information :
                <br />
                <ol className="list-decimal list-inside p-2 font-normal text-[18px] leading-8 flex flex-col gap-3">
                  <li>
                    Karsaaz App Is An Online Marketplace ( Bazaar ) That
                    Connects Users With Cheap And Verified Independent Service
                    Providers. Any Service You Book Through The Karsaaz App Is
                    Provided By Independent Service Providers, Not The Karsaaz
                    App Itself. Provided By
                  </li>
                  <li>
                    When You Book Service Providers Through The Karsaaz App, You
                    Enter Into A Service Agreement With The Service Providers.
                    However, Karsaaz App Is Not A Part Of This Agreement.
                    Important Points Of This Customer And Service Provider
                    Agreement Are As Follows:
                  </li>
                  <ol className="list-decimal list-inside px-4  font-normal text-[18px] leading-8 flex flex-col gap-3">
                    <li>
                      The Service Providers Agree To Provide You (The Customer)
                      With The Required Service At The Agreed Price And With
                      Good Quality And You (The Customer) Agree To Pay The
                      Service Providers The Agreed Amount Before Or Upon
                      Completion Of The Service.
                    </li>
                    <li>
                      The Service Provider Agrees To Give You A ( 15 ) Day
                      Warranty On Its Work ( Where Applicable ).
                    </li>
                    <li>
                      If You Ask The Service Provider To Perform Additional Work
                      That Was Not Previously Agreed Upon, You Will Pay Extra
                      For The Additional Work.
                    </li>
                    <li>
                      If A Service Provider Cannot Provide A Price For The Work
                      Without A Home Visit And You Agree, Please Note That If
                      The Service Provider's Price Is Not Acceptable To You, You
                      Will Have To Pay Cancellation Charges Of
                      <strong> Rs.350 </strong>To The Service Provider.
                    </li>
                    <li>
                      If The Service Provider Arrives At Your Home For The
                      Booked Service And You Refuse Or Are Unable To Take The
                      Service, You Will Pay
                      <strong>
                        {" "}
                        Rs.350 As Cancellation Charges To The Service Provider.
                      </strong>
                    </li>
                    <li>
                      The Service Provider And The Customer Shall Treat Each
                      Other With Dignity And Respect And Shall Not Cause Any
                      Harm To Each Other.
                    </li>
                    <li>
                      Both You And The Service Provider Have The Right To Take
                      Legal Action Against The Other For Breach Of This
                      Contract.
                    </li>
                  </ol>
                  <li>
                    To Ensure Your Safety, We Only Match You With The Service
                    Provider For Which We Have Completed Police, CNIC, Photo,
                    And Mobile Number Verification. However, We Are An Online
                    Marketplace ( Bazaar ), And Such Service Providers Are Not
                    Our Employees. Therefore Karsaaz App Does Not Take Any
                    Responsibility For Any Misconduct Or Wrongdoing By Any
                    Service Provider. Where Applicable, You Reserve Your Right
                    To Report Their Conduct Directly To The Appropriate
                    Authorities.
                  </li>
                  <li>
                    If You Are Not Satisfied With The Quality Of Service Offered
                    By The Service Provider, You Can Report The Same To Our
                    Helpline Using One Of The Options Provided In The "Contact
                    Us" Section Of The App Or Our Website. I Can Complain. We
                    Will Do Our Best To Resolve The Complaint Between You And
                    The Service Provider. However, Karsaaz App Does Not Take Any
                    Responsibility If You Suffer Any Financial Or Other Loss Due
                    To The Service Booked Through Karsaaz App.
                  </li>
                  <li>
                    For Your Safety, You Should Check The Original CNIC
                    (Identity Card) Of The Service Provider To Verify The
                    Identity On Arrival. If You Are Not Satisfied With Their
                    Identity For Any Reason, You Should Refuse The Service
                    Provider To Enter Your Home And Immediately An Option From
                    The "Contact Us" Page Of The App Or Website. Should Be
                    Reported To Our Customer Support Team. If You Think You Are
                    In Danger, You Should Immediately Call The Local Emergency
                    Services For Help. You Are Responsible For Ensuring Your Own
                    Safety; The Company Does Not Take Responsibility For
                    Customer Safety.
                  </li>
                  <li>
                    If You Need To Cancel A Booked Service, You Must Notify
                    Karsaaz App Customer Service Team At Least 24 Hours Before
                    The Agreed Service Date And Time. Otherwise, The Company
                    Will Charge You Rs 350 As Cancellation Fee. Ghar Yellow Food
                    Orders Cannot Be Canceled Once The Booking Has Been
                    Confirmed And Full Advance Payment Has Been Made.
                  </li>
                  <li>
                    Suppose The Karsaaz App Collects Any Money From You Before
                    Starting The Service Or After The Service Is Completed. In
                    That Case, That Money Is Held In An Escrow Account And Paid
                    To The Service Provider When The Service Is Completed.
                  </li>
                  <li>
                    You Will Not Ask The Service Provider To Give You Its Direct
                    Contact Number, And You Will Not Book Directly With That
                    Service Provider Now Or In The Future.
                  </li>
                  <ol className="list-decimal list-inside px-4 font-normal text-[18px] leading-8 flex flex-col gap-3">
                    <li>
                      Similarly, The Service Provider Should Not Give You Their
                      Contact Number Or Ask You Directly To Book It. If A
                      Service Provider Does This, You Should Immediately Report
                      It To Our Customer Support Team Using One Of The Options
                      Available On The App Or On Our Website's "Contact Us"
                      Page, As This Is A Matter Of Communication With Us. It Is
                      Against The Agreement.
                    </li>
                  </ol>
                  <li>
                    COVID-19 Guidance: All Necessary Measures Should Be Taken
                    For The Health And Safety Of Both The Customer And The
                    Service Provider, Such As Wearing A Face Mask, Frequent Use
                    Of Hand Sanitizer, And At Least 2 Hours Away From Other
                    People During Delivery Services. Keep Meter Distance.
                  </li>
                </ol>
                After every service delivery, we talk to our service provider to
                get their feedback and ensure service quality.
                <br />
                If you fail to comply with any of the above conditions, we will
                disable your account and may take legal action against you if
                necessary.
                <br />
                Thank you for using the Karsaaz app.
              </span>
            </div>

            {/* ----------------------------- Article N0. 3 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                Terms And Conditions For Karsaaz App Service Provider
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                Dear Service Provider,
                <br />
                <br />
                Please read it carefully and note these important terms and
                conditions for providing our service to users through the
                Karsaaz app:
                <br />
                <ol className="list-decimal list-inside px-4 flex flex-col gap-3">
                  <li>
                    When You, As A Service Provider, Provide A Service To A
                    Customer Through The Karsaaz App, You Enter Into A Service
                    Contract With The Customer. However, Karsaaz App (Company)
                    Is Not A Party To This Agreement. Important Points Of This
                    Customer And Service Provider Contract Are As Follows:
                  </li>
                  <ol className="list-decimal list-inside p-2 flex flex-col gap-3">
                    <li>
                      As A Service Provider, You Agree To Provide The Customer
                      With The Required Service At An Agreed Price And With Good
                      Quality.
                    </li>
                    <li>
                      Upon Satisfactory Completion Of The Service, The Customer
                      Agrees To Pay The Service Provider The Agreed Amount
                      Before Or Upon Completion Of The Service.
                    </li>
                    <li>
                      As A Service Provider, You Agree To Provide The Customer
                      With A ( 15 ) Day Warranty On Your Work ( Where Applicable
                      ).
                    </li>
                    <li>
                      If The Customer Asks You To Do Additional Work That Was
                      Not Previously Agreed Upon, You Will Charge Extra For The
                      Additional Work, Notify The Company Immediately And Pay A
                      Commission On The Total Price Charged To The Customer.
                    </li>
                    <li>
                      If You Cannot Provide A Price For The Work Without A Home
                      Visit And The Customer Agrees, Please Note That If The
                      Price Quoted By You Is Reasonable But Not Accepted By The
                      Customer, The Customer Will Be Required To Pay Rs.350 As
                      Cancellation Charges ( If The Customer Does Not Take The
                      Service ).
                    </li>
                    <li>
                      If You Arrive At The Customer's Home For The Booked
                      Service And The Customer Refuses Or Is Unable To Take The
                      Service, The Customer Will Pay You
                      <strong> Rs 350 As Cancellation Charges.</strong>
                    </li>
                    <li>
                      The Service Provider And The Customer Shall Treat Each
                      Other With Dignity And Respect And Shall Not Cause Any
                      Harm To Each Other.
                    </li>
                    <li>
                      Both Customer And Service Provider Are Entitled To Take
                      Legal Action Against Each Other For Breach Of This
                      Contract.
                    </li>
                  </ol>

                  <li>
                    Company Reserves The Right To Cancel Your Registration
                    Immediately If You Do Not Show Up For The Service Agreed
                    With The Customer Without Prior Notification, And Reasonable
                    Cause You Will Have To Pay Cancellation Charges Of Rs.1000
                    To The Company.
                  </li>
                  <li>
                    You Must Carry Your Original CNIC (National ID Card) With
                    You When You Visit The Customer For Service Delivery. We
                    Instruct Our Customers To Check Their CNIC (Identity Card)
                    To Verify Your Identity. They Are Instructed Not To Let You
                    In If You Do Not Show Them Your Original CNIC (Identity
                    Card). In This Case, Your Booking Will Be Cancelled, And We
                    Will Transfer The Service To Another Service Provider.
                  </li>
                  <li>
                    You Will Not Give Out Your Number To The Customer, Ask The
                    Customer To Book You Directly, Or Ask For The Customer's
                    Contact Number. Even If The Customer Calls You Directly Or
                    Tries To Book You Directly Now Or In The Future, You Will
                    Decline And Immediately Notify Us On Our Helpline. This Is
                    Strictly Against Your Agreement With Us, And We Reserve The
                    Right To Immediately Cancel Your Registration As Well As
                    Display You As A Prohibited Service Provider In The App,
                    Which May Harm Anyone Using The Karsaaz App. The Person Is
                    Completely Visible.
                  </li>
                  <li>
                    Once You Have Completed Your Service And Received The
                    Payment From The Customer, You Must Immediately Inform Our
                    Customer Support Team On Our Helpline Number.
                  </li>
                  <li>
                    Karsaaz App Is An Online Marketplace ( Bazaar ) That Only
                    Facilitates The Matching Of Service Providers With
                    Customers. Therefore Karsaaz App Does Not Take Any
                    Responsibility For Rude Or Inappropriate Behavior By Any
                    Customer. We, Service Providers, Are Instructed To Ensure
                    Their Safety Routinely And Appropriately. Where Necessary,
                    The Service Provider Reserves The Right To Report Any
                    Inappropriate Behavior Directly To The Relevant Authorities.
                    You May Contact Us. Such Incidents Should Also Be Reported
                    To The Helpline So That We Can Cancel The Customer's
                    Registration Required And Provide Information To Law
                    Enforcement Agencies If Required.
                  </li>
                  <ol className="list-decimal list-inside p-2 flex flex-col gap-3">
                    <li>
                      For The Safety Of Female Service Providers, They Are
                      Strictly Instructed To Check The Original CNIC Of The
                      Customer To Ensure Identity Verification Before Entering
                      The Customer's Home.
                    </li>
                  </ol>
                  <li>
                    COVID-19 Guidelines: You Must Take All Necessary Precautions
                    For The Health And Safety Of Yourself And Your Customers,
                    Such As Wearing A Face Mask, Using Hand Sanitizer
                    Frequently, And Avoiding Contact With Other People While
                    Meeting Customers And Providing Your Services. A Distance Of
                    At Least 2 Meters Should Be Maintained.
                  </li>
                  <li>
                    Karsaaz App Reserves The Right To Make Changes To The
                    Detailed Terms And Conditions Without Any Notification To
                    Its Users.
                  </li>
                </ol>
                <span className="font-normal text-[18px] leading-8">
                  We talk to every customer after every service delivery to get
                  customer feedback about you to ensure the quality of service.
                  <br />
                  If you fail to comply with any of the above conditions, we
                  will disable your account and may take legal action against
                  you if necessary.
                </span>
              </span>
            </div>

            {/* ----------------------------- Article N0. 4 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                To Become A Service Provider On Karsaaz App
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6 ">
                In order to offer services as a service provider, you must be
                registered on the Karsaaz App. To become a service provider and
                offer your services on the Karsaaz app, you must:
                <br />
                <ul className="list-disc list-inside p-2 flex flex-col gap-3">
                  <li>Be Of Legal Adult Age;</li>
                  <li>You Can Legally Contract;</li>
                  <li>
                    Have The Proper Legal Status (Company, Sole Trader, Etc...)
                    And Have Completed All Necessary Formalities With The
                    Appropriate Organizations (Tax And Business Related, Etc.),
                    Making You Legally A Service Provider. Can Practice Your
                    Business And Issue Invoices To Customers Who Choose Your
                    Business To Provide Services.
                  </li>
                  <li>No Criminal Record</li>
                  <li>
                    Accurately Provide All Information About Yourself And Your
                    Business And Services
                  </li>
                  <li>
                    Provide All Necessary Information And Documents Such As ID
                    Card, Photo Etc. For Verification Of Your Identity And
                    Background Check.
                  </li>
                  <li>
                    Allow The Car Maker To Do Any Necessary Verification Like
                    Identity Card Verification With NADRA, Police Verification
                    Etc.
                  </li>
                </ul>
                <span className="font-normal text-[18px] leading-8 mt-6">
                  For Further Queries, Contact Karsaaz Helpline. Thank you for
                  using the Karsaaz app.
                </span>
              </span>
            </div>

            {/* ----------------------------- Article N0. 5 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                Detailed Terms And Conditions DEFINITIONS
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                <strong>Company: </strong>
                Karsaaz Limited is a company registered in the UK (England and
                Wales) that owns the website{" "}
                <strong>(https://karsaaz.app) </strong>
                and the mobile apps on Android{" "}
                <strong> (https://play.google.com) </strong> and iOS store
                <strong>(https://apps.apple.com)</strong>
                and is responsible for publishing Karsaaz's services.
                <br />
                The website:
                <a
                  href="https://karsaaz.app"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#0973BA] underline"
                >
                  https://Karsaaz.App{" "}
                </a>
                <br />
                The mobile Apps: Karsaaz mobile app on Android Play Store
                <a
                  href="https://play.google.com/store/apps/details?id=com.KarsaazPro.app"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#0973BA] underline"
                >
                  (https://play.google.com) and iOS App Store
                </a>
                <a
                  href="https://apps.apple.com/gb/app/karsaaz-%DA%A9%D8%A7%D8%B1%D8%B3%D8%A7%D8%B2/id1568292047"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[#0973BA] underline"
                >
                  (https://apps.apple.com)
                </a>
                <br />
                <strong>Karsaaz: </strong>refers to the website
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://karsaaz.app"
                  className="text-[#0973BA] underline"
                >
                  https://karsaaz.app
                </a>
                and/or the Karsaaz mobile apps on Android Play Store and iOS App
                Store
                <br />
                <strong> Request: </strong>All data (information, sounds,
                photos, design, etc.) that a Client submits online on Karsaaz
                for the purposes of requesting a service.
                <br />
                <strong> Client: </strong>Individual or legal entity registered
                on Karsaaz who submits one or more requests for services or
                searches and finds service providers using the Karsaaz. This
                refers to the service requester.
                <br />
                <strong> Service Provider: </strong>Individual or legal entity
                registered on Karsaaz's website or mobile apps who publishes
                different types of data (information, sounds, photographs,
                design, etc.) for the purposes of offering services on Karsaaz's
                website or mobile apps. This refers to a service provider.
                <br />
                <strong> User: </strong>Individual or legal entity using the
                services of Karsaaz.
                <br />
                <strong> Client Account: </strong>The account opened by a Client
                when registering on Karsaaz for the purposes of making use of
                the services offered by Karsaaz.
                <br />
                <strong> Service Provider Account: </strong>The account opened
                by a Service Provider when registering on Karsaaz for the
                purposes of making use of the services offered by Karsaaz.
                <br />
                <strong> Quotes: </strong>Pricing and information provided by
                the Service Provider on Karsaaz for the purpose of responding to
                a Client's Request. Also called Offers.
                <br />
                <strong> Profile: </strong> Information published by a Service
                Provider registered on Karsaaz, containing all the information
                about the Service Provider's business and other information
                about the Service Provider.
                <br />
                <strong> Partner: </strong>Third-party company working with
                Karsaaz under a commercial business contract for the purpose of
                providing additional services or features for Users.
                <br />
                <strong> Agreement: </strong>the most updated version of the
                agreement between a Service Provider and a Client.
                <br />
                <strong> Agreed Price: </strong>agreed price for Services
                (including any variation) agreed upon between the Client and
                Service Provider before the start of the service. It does not
                include any additional costs incurred by the Service Provider
                when completing Services which the Client agrees to reimburse.
                <br />
                <strong> Cancellation Admin Fee: </strong>the Fee payable by a
                Client for canceling a Service Contract.
                <br />
                <strong>Consequential Loss: </strong> any loss, damage, or
                expense recoverable at law:
                <ul className="flex flex-col gap-3">
                  <li>
                    (A) Other Than A Loss, Damage Or Expense That Would Be
                    Suffered Or Incurred By Any Person In A Similar Situation To
                    The Person Suffering Or Incurring The Loss, Damage Or
                    Expense; Or
                  </li>
                  <li>(B) Which Is A Loss Of:</li>
                  <ul className="flex flex-col gap-3">
                    <li>A. Opportunity Or Goodwill;</li>
                    <li>B. Profits, Anticipated Savings Or Business;</li>
                    <li>C. Data; Or</li>
                    <li>D. Value Of Any Equipment,</li>
                  </ul>
                </ul>
                and any costs or expenses incurred in connection with the
                foregoing.
                <span className="font-normal text-[18px] leading-8 mt-6">
                  Fees: all fees payable to the Company by Users, including the
                  Service Fee.
                  <strong> Identity Verification Services: </strong> the tools
                  available to help verify the identity, qualifications, or
                  skills of Users, including mobile phone verification,
                  verification of payment information, References, integration
                  with social media, etc.
                  <br />
                  <strong> Non-excludable Condition: </strong> any implied
                  condition, warranty, or guarantee in a contract, the exclusion
                  of which would contravene the law or cause any part of the
                  contract to be void.
                  <br />
                  <strong> Service Fee: </strong>the fee payable by the Service
                  Provider to the Company as consideration for the Company's
                  Services (and comprised as part of the Agreed Price) displayed
                  to a Service Provider prior to entering into each Service
                  Contract.
                  <br />
                  <strong> Services: </strong> the services to be rendered as
                  described in the request, including any variations or
                  amendments agreed upon before or subsequent to the creation of
                  a Service Contract.
                  <br />
                  <strong> Service Contract: </strong>the separate contract
                  which is formed between a Client and a Service Provider for
                  Services. In the absence of, or in addition to, any terms
                  specifically agreed, the model terms of which are included in
                  the Model Service Contract.
                  <br />
                  <strong> Service Provider Funds: </strong> the Agreed Price
                  less the Service Fee.
                </span>
              </span>
            </div>

            {/* ----------------------------- Article N0. 6 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                TERMS OF SERVICE
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                The purpose of these Terms of Service or general conditions is
                to:
              </span>
              <ul className="flex flex-col gap-3">
                <li>
                  Set Out The Terms And Conditions Of Use Under Which The
                  Company Sells Its Paid Services To Service Providers And
                  Clients, Accessible Via
                  <strong>Karsaaz. </strong>
                </li>
                <li>
                  Set Out The Rules Of Online Use Of All Services Provided By
                  Karsaaz, Property Of The Company, As Well As The Rights And
                  Obligations Of The Parties Involved.
                </li>
              </ul>
              <span>
                These terms and conditions form a binding contract between the
                Company and you, the User, and apply at all times while using{" "}
                <strong> Karsaaz </strong> and the services provided by{" "}
                <strong> Karsaaz </strong>. These Terms of Service are
                applicable, without restriction or reservation, to all products
                and services included and offered as paid services by Karsaaz.
                The Company has the legal right to modify at any time its paid
                services or offerings, to add and/or change its products and
                services, and to add new features and/or functionalities to its
                existing paying and free system without being liable to any type
                of compensation claim on the part of the Service Provider or
                Client. These terms of use are available on the Karsaaz website
                or mobile apps. All data registered by Karsaaz constitutes proof
                of all transactions. If the Service Provider or Client
                registered on <strong>Karsaaz </strong> violates these terms at
                any time while using its services and platform, Karsaaz can
                block the User from using its services and deny temporarily or
                permanently his access to <strong> Karsaaz's </strong> website
                or mobile apps.
                <br />
                The Company owns exclusive rights to these Terms of Service. Any
                distribution, transmission, use, and/or reproduction of these
                Terms and conditions as a whole, in part, or in any form is
                strictly prohibited. Should these Terms and exclusive rights be
                breached, you will be liable and subject to legal action. As a
                User registered with <strong> Karsaaz </strong>, either as
                Client or as Service Provider, your express agreement to abide
                by these Terms unconditionally and fully is, by default, implied
                and acknowledged. The User can read these Terms and conditions
                via <strong> Karsaaz's </strong> website or mobile apps. The
                Company reserves the right to modify its Terms of Service (TOS
                or Terms of Use, Terms, and Conditions) at any time and without
                prior notice by publishing the updated version directly on its
                Karsaaz website or mobile apps. Clients and Service Providers
                must regularly review these Terms of Service in order to stay
                informed of any modifications made to them.
                <br />
                The Company provides an online service designed to publish free
                requests for services or search for service Providers via its
                website and mobile apps. The Company, via{" "}
                <strong> Karsaaz </strong>, enables service requesters, also
                known as Clients, to contact service providers, also known as
                Service Providers. Service requests are comprised of requests
                published by Clients, which request help from a Service Provider
                registered on our platform to perform the service or the
                services they require. The Service Providers can then submit a
                Quote for each Request that they are interested in. The Company
                acts solely as a host and provides the following:
                <br />
                Technical tools and storage space that enables Service Providers
                on Karsaaz to register and share their Profiles, information
                about their business, and their Quotes through{" "}
                <strong> Karsaaz </strong>; and enables Clients to register and
                publish their requests for services; The technology used to
                match Service Providers and Clients via
                <strong> Karsaaz </strong>, enabling them to easily connect and
                communicate; The information provided by Service Providers and
                Clients via Karsaaz belongs to them and is their sole
                responsibility. The Company declines liability for any
                information or for the validity of the content displayed on a
                Service Provider's profile or a Client's account. The Company
                can and may advertise to its Users via its Karsaaz platform,
                mobile, and email campaigns.
                <br />
                The Company is not a recruitment agency. The Company is not a
                temporary placement agency. The Company does not have an
                Employer status for the Service Providers listed on its website
                or mobile apps. The Service Providers are in no way subordinated
                to The Company while making use of the services our company
                makes available for them via Karsaaz.
                <br />
                The Company's objective and scope are solely to enable Clients
                and Service Providers to connect for a given project and the
                service requested and offered. The Company has no obligation,
                liability, duty or control of any nature whatsoever, with
                respect to the interactions between Clients and Service
                Providers which take place via its website or mobile apps,
                namely with regards to the absence of a Quote or response from a
                Service Provider to a published Request; the number of Quotes
                received for a published Request; the quality of the service
                provided by the Service Provider who replies to a Request; the
                quality of the published Requests sent to the Service Providers;
                the time frame for providing the service offered by the Service
                Provider; the legal framework in which the service is provided
                by the Service Provider; the validity of the information
                provided by the Service Provider registered on{" "}
                <strong> Karsaaz </strong> and displayed on his public profile
                page; the validity of the Client's Request; etc.
                <br />
                To access and use the services provided by Karsaaz, users are
                solely responsible for ensuring that their system and our online
                platform at Karsaaz are compatible.
              </span>
            </div>

            {/* ----------------------------- Article N0. 7 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                TERMS OF SERVICE FOR SERVICE PROVIDERS AND CLIENTS, REGISTERED
                USERS ON KARSAAZ{" "}
              </h1>
              <h1 className="font-semibold text-[26px] leading-[31.69px] mt-4">
                1. TO BECOME A SERVICE PROVIDER ON KARSAAZ
              </h1>

              <span className="font-normal text-[18px] leading-8 mt-6">
                To offer services as a Service Provider, you must be registered
                on <strong> Karsaaz.</strong> To become a Service Provider and
                offer your services on Karsaaz, you must:
                <ul className="flex flex-col gap-3">
                  <li>Be Of Legal Adult Age (If You Are An Actual Person);</li>
                  <li>
                    Have The Legal Authority To Enter Into Contractual
                    Relationships;
                  </li>
                  <li>
                    Not Be Banned From Managing, Administrating, Or Controlling
                    A Business;
                  </li>
                  <li>
                    Have The Appropriate Legal Status (Company, Sole Trader,
                    Etc...) And Have Completed All Necessary Formalities With
                    The Appropriate Organisations (Tax And Business Related,
                    Etc.), Allowing You To Legally Practice Your Business As A
                    Service Provider And To Issue Invoices To Clients Who Choose
                    Your Business To Provide A Service.
                  </li>
                  <li>Have No Criminal Record</li>
                  <li>
                    Provide All The Information About Yourself And Your Business
                    And Services Accurately
                  </li>
                  <li>
                    Provide All Necessary Information, And Documentation For
                    Your Identity Verification And Background Check, Such As
                    CNIC, Etc.
                  </li>
                  <li>
                    Give Consent To The Company To Carry Out Any Necessary
                    Verification Such As CNIC Verification With NADRA, Police
                    Verification, Etc.
                  </li>
                  <li>
                    Have The Necessary Insurance To Cover Your Responsibility
                    For Services That You Offer To Clients Via{" "}
                    <strong>Karsaaz. </strong>
                  </li>
                  <li>
                    Accept That You Will Systematically Be In Bidding Against
                    Peers.
                  </li>
                </ul>
                <span className="mt-4">
                  <strong>Karsaaz </strong> allows all registered Service
                  Providers to benefit from certain services as part of their
                  relationship with Clients. Some of these services are free;
                  others are paid. The terms applicable in both situations are
                  detailed below.
                </span>
              </span>
            </div>

            {/* ----------------------------- Article N0. 8 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                Free and Paid services
              </h1>
              <ol className="list-decimal my-4 flex flex-col gap-3">
                <li>
                  The Company connects, automatically as well as manually and
                  free of charge, registered Service Providers with matching
                  jobs requested by Clients, based on criteria determined by the
                  Service Provider, and which may be modified at any time:
                  travel radius, availability, budget, type of service, etc.
                </li>
                <li>
                  Billing will only occur in the following circumstances: when
                  there is an initial reply from a Client to a Service Provider
                  (via a message sent through the platform); when a Client
                  obtains a Service Provider's contact information through
                  Karsaaz; when Karsaaz sends a Client's contact details to a
                  Service Provider to deliver the requested service; when Client
                  wants to connect with Service Provider through Karsaaz; when
                  Service Provider makes use of Karsaaz for any aspect of the
                  delivery of the service.
                </li>
                <li>
                  All replies by Service Providers to Clients' questions are
                  completely free. Service Providers can also send free quotes
                  to all matching Clients' requests. The price charged for
                  accepting a Client's request is always displayed and will only
                  be invoiced if the Client accepts the offer from the Service
                  Provider, and Service Provider delivers the service against
                  that request, as indicated in paragraph 1.2.
                </li>
                <li>
                  The Service Provider may access automatic messages sent by
                  Karsaaz on his behalf in response to the Client's requests (as
                  part of the free and automatic matching service described in
                  section 1.1). The messages sent by the Service Provider to
                  these requests are free. The Service Provider's access to a
                  Client's telephone number will be charged and invoiced at the
                  rate indicated on the client's request and which is the
                  equivalent of a connection. From this point forward, the
                  Service Provider can contact the client directly and shall pay
                  the applicable fee to Karsaaz upon delivery of the requested
                  Service to the Client (see 1.2).
                </li>
                <li>
                  Karsaaz is committed to connecting the Service Provider with a
                  maximum number of Clients as soon as the criteria described in
                  1.1 are set by the Service Provider in such a way as to obtain
                  a number of Clients which matches the Service Provider's
                  settings.
                </li>
                <li>
                  Karsaaz provides an invoice for each service delivery made
                  from the Service Provider's account (Payment History area). We
                  send a weekly email that summarizes the situation (expenses,
                  service deliveries).
                </li>
                <li>
                  In order to make full use of our services, all Service
                  Providers must indicate at least one valid payment method
                  (either a credit card or an authorized payment partner). Once
                  the payment method has been registered, it can be used for all
                  purchases made by the Service Provider. If the primary payment
                  method as indicated in the Service Provider's Account is
                  expired, invalid, or, for any reason, may not be debited, the
                  Service Provider agrees that Karsaaz may proceed to use the
                  second payment method where applicable as indicated in the
                  Service Provider's Account.
                </li>
                <li>
                  Cancellations and refunds. For any cancellation or refund
                  requests, please contact us via email at:
                  <a
                    href="mailto:info@karsaaz.app"
                    className="text-[#0973BA] underline"
                  >
                    info@karsaaz.app.
                  </a>
                  no later than thirty (30) days after billing. No claim will be
                  issued after the thirty (30) day period. If you deactivate
                  your account before we process your case, we will not be able
                  to process your request. No monetary refund will be issued if
                  the charge complies with the conditions presented in terms of
                  Services.
                </li>
                <li>
                  The credits awarded by our Customer Service and added to your
                  Karsaaz account are valid for 1 month, starting from the day
                  they were added to your account. After 1 month, if they have
                  not been used, they will automatically expire.
                </li>
              </ol>
            </div>

            {/* ----------------------------- Article N0. 9 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                2. BECOMING A CLIENT ON KARSAAZ
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                By registering on <strong> Karsaaz </strong>and creating a
                Request, you become a Client. As a Client, you authorize
                registered Service Providers to view and to reply to your
                requests. You authorize the Company to match your requests
                through its platform with suitable Service Providers and to
                share with them the required information from your Account. You
                also authorize the Company to share your requests with Partners,
                thus allowing them also to distribute your requests on their own
                website or mobile apps and Partners' networks with the aim of
                reaching out to a greater number of Service Providers
                potentially in a position to meet your requirements.
                <br />
                To access the Company's services, all Clients of the Company
                must create a Client Account on <strong> Karsaaz </strong>. If
                you decide you no longer want to use your Client Account, you
                can deactivate or delete it by sending an email requesting to do
                so to:
                <a
                  href="mailto:info@karsaaz.app"
                  className="text-[#0973BA] underline"
                >
                  info@karsaaz.app.
                </a>
                After deleting your data or your requests, or in the event of
                account deactivation, it is possible that copies of the data
                related to your account remain visible, as they have been shared
                with other Users of the Company.
                <br />
                Clients are solely responsible for the Requests they post online
                on <strong> Karsaaz </strong>. The Company may not be deemed to
                be a recruiter. The Company cannot be deemed to be a temping or
                recruitment agency, nor an employer or an agent on behalf of its
                Users. The Company has no legal obligation to ensure results
                regarding the responses provided to your requests. In order to
                enable your Client Account to work properly, you agree to:
                <ul className="flex flex-col gap-3">
                  <li>
                    Not Provide A False Identity Nor Any Type Of False
                    Information,
                  </li>
                  <li>Create Only One Account,</li>
                  <li>
                    Not Create A Client Account On Behalf Of A Third Party
                    Without Their Knowledge And Authorization.
                  </li>
                </ul>
                <span className="mt-4">
                  The Company provides several paid options to Clients allowing
                  them to highlight their requests to Service Providers on the
                  website or mobile apps.
                </span>
              </span>
            </div>

            {/* ----------------------------- Article N0. 10 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[22px] leading-[26.69px]">
                Each Request must originate from a real Client who requires a
                service.
                <br />
                It is strictly forbidden to use the Company's services to post
                requests or any type of content that:
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                Does not comply with the laws and regulations of the country
                where the request or content are published; breaches or does not
                comply with the country's labour law; may be perceived as
                discriminatory; contains advertisements for the Company 's
                competitors (advertisement for a competitor, link to a
                competitor's website or mobile apps, etc.); sells or advertises
                products or services; presents franchises, distribution deals,
                concessions or commercial agencies, pyramid schemes, club
                memberships or multi-level selling; targets sex, human
                trafficking or illegal human organ trafficking; promotes a
                political party, program, political position or topic, or
                benefits a specific religion; does not contain a genuine
                requirement for a service; has the sole purpose of gathering
                information about the Quotes offered by the Service Providers on
                the platform in order to share the information gathered with one
                or more competitors of same Service Providers; aims to post the
                same Request repeatedly in an attempt to receive more Quotes.
              </span>

              <h1 className="font-semibold text-[22px] leading-[26.69px]">
                The Company may, at its sole discretion, delete any requests
                and/or content published on Karsaaz which does not comply with
                the terms of service or which is harmful and damaging to the
                Company's brand or image, or to any Service Provider or Client
                using the Company's services.
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                In order to make full use of our services, you must indicate at
                least one valid payment method (either a credit card or an
                authorized payment partner). Once the payment method has been
                registered, it may be used for all purchases made.
                <br />
                If the primary payment method, as indicated in your Account, has
                expired, is invalid, or, in any event, seems impossible to
                debit, you agree that Karsaaz may proceed to use the second
                payment method where applicable as indicated in your Account.
              </span>
            </div>

            {/* ----------------------------- Article N0. 11 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                3. TERMS OF SERVICE & THE COMPANY'S CONTENT
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                Service Providers and Clients using the Company's Services must
                refrain from:
                <br />
                Transmitting on <strong> Karsaaz's </strong> platform any
                prohibited, illicit, or illegal content or any information that
                may constitute an abuse of rights (e.g. escort services, sale of
                firearms or firearm parts and related services, sale of
                prescription drugs and related services, etc.), or that may be
                contrary to public order and/or morality, or that may be used to
                exert a threat or pressure of any nature, kind or form, or that
                is contrary to or contradicts any intellectual or industrial
                property laws or copyrights, or that has been obtained or used
                by any type of infringement, crime or misdemeanor; Endangering
                the security of <strong> Karsaaz </strong> (attempts to control,
                scan or test the vulnerability of a system or network;
                violations of security or authentication measures; violations or
                attempts to violate the system's or the network's security);
                Sending unsolicited letters, emails, faxes or contacting by
                phone any Service Provider or Client using the Company's
                services with the purpose of promoting any type of products or
                services; Making commercial use of any of Karsaaz's elements or
                data; Using the Company's services for a purpose other than that
                originally intended by the website or mobile apps; Opening an
                Account or creating a profile with the sole intention of
                promoting or selling any products or services; Using{" "}
                <strong> Karsaaz </strong> to promote one's own services or
                products using SPAM or repetitive requests for services.
                <br />
                These behaviors may lead to civil and/or criminal lawsuits. The
                Company may appeal to the judicial authorities in order to
                criminally prosecute Users who have been part of such
                violations. In the event that you fail to comply with any of the
                prohibitions detailed above, the Company may temporarily revoke
                your access to Karsaaz or permanently revoke your access to{" "}
                <strong> Karsaaz </strong>.
                <br />
                All the Company's content (information, texts, graphics, videos,
                images, logos, icons, files, etc.) is the exclusive property of
                the Company. All content is protected by copyright, trademark
                law, authors' rights, and intellectual property law. No Service
                Provider nor Client using the Company's services has the right
                to use any type of content belonging to the Company in a context
                (other website or mobile apps, other location, intranet,
                extranet, etc.) other than <strong> Karsaaz </strong>. All code
                created by the Company to display or generate its content on
                Karsaaz and on <strong> Karsaaz's </strong> webpages is
                protected by intellectual property law and you should not
                attempt to copy nor modify any piece of code at the risk of
                criminal prosecution.
              </span>
            </div>

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                4. PROVIDING A SERVICE: INTERACTIONS BETWEEN SERVICE PROVIDERS
                AND CLIENTS
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                The Company offers an exclusively online service via{" "}
                <strong> Karsaaz's </strong> platform, with the sole intent of
                posting requests for services from Clients with requirements and
                providing them with Quotes from Service Providers specifically
                tailored to their requests. By using the Company's services on{" "}
                <strong> Karsaaz's </strong> platform, as a Service Provider,
                you will have access to several requests for services from
                Clients, and you will receive requests for services matching
                your skills (based on the information provided on your Profile).
                To connect with a Client, you need to send them a Quote (offer +
                price). Once you send a Quote for a service, you are committing
                yourself to carry out that service at the time, at the place,
                and under the conditions stipulated by the Client. If the Client
                accepts your Quote, you should reach out to the Client as soon
                as possible using the phone number that he will provide you with
                or using the private messaging available in{" "}
                <strong> Karsaaz </strong>, so you may both agree on all the
                specific details of the service requested. You should arrive on
                time, be fully prepared to do the work requested and be polite
                in all circumstances. The Service Provider selected to perform a
                given service is the one whose Quote (offer + price) is accepted
                by the Client. Once the Service Provider has ended the service
                agreed with the Client, only the Client is able to confirm if
                the service was, in fact, successfully completed, and is also
                the only person able to leave a review & feedback about the
                Service Provider's performance. This feedback is given without
                any interference from the Company. Consequently, the Company
                will not be held liable for the reviews given, nor may Service
                Providers or Clients demand any type of action from the Company,
                nor that they control and/or validate in any way the opinions
                expressed about the service provided by the Service Provider to
                the Client.
                <br />
                <strong>
                  The Company acts solely as an intermediary. The Company is not
                  responsible for the Service Providers' status nor for their
                  legal ability to issue invoices. The Service Providers are
                  solely responsible for their taxes, for complying with tax
                  regulations regarding the revenues obtained from the services
                  provided to the Clients, and for complying with social
                  security regulations in accordance with their country's
                  legislation.
                </strong>
                <br />
                In the context of using the services of the Company, as a
                Client, you have access to Service Providers' Profiles via{" "}
                <strong> Karsaaz </strong> . You agree to use these Profiles
                adhering to the General Terms and Conditions, current laws and
                regulations, and data protection rules. You agree to take all
                appropriate steps to protect the information which you obtain
                when viewing the Profiles, namely steps against theft, malicious
                use, unauthorized access, publication for commercial use,
                modification, or deletion. The Service Providers alone create
                their Profile. The Company in no way guarantees the accuracy or
                the veracity of these Profiles and the associated content. The
                Company reserves the right to restrict the amount of data that
                Clients can access. The Company may change these restrictions at
                any time and at its sole discretion. When you publish a Request
                for a service, the Company automatically selects the most
                suitable Service Providers for your Request. Service Providers
                who are interested in your Request will send you their
                Quotation. You may choose the Quotation which best suits you.
                Once you have selected a Quotation, there is an exchange of
                contact details between the Client and the Service Provider. At
                the end of the job, the Client can leave a review & an
                evaluation of the Service Provider.
              </span>
            </div>

            {/* ----------------------------- Article N0. 12 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                RESPONSIBILITY{" "}
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                The Company is committed to making every effort to implement all
                procedures within its power to limit risks with regard to
                malicious intrusions, hacking or the deployment of any virus on
                its server and/or on Karsaaz's platform. The Company is also
                committed to making every possible effort to ensure the security
                of the content displayed at Karsaaz. Despite the Company's best
                efforts regarding the aforementioned, the Company is unable to
                fully guarantee that such an event will not occur. Therefore the
                Company will not be liable and declines any responsibility in
                the event of any malicious intrusions, hacking, or the
                deployment of any virus on its server and/or on Karsaaz's
                platform and/or in the emails sent.
                <br />
                In spite of all precautions taken by the Company's team to
                ensure that all the information transmitted through Karsaaz's
                platform is true and correct, the Company will not be liable for
                any false information and/or any false requests for services
                sent to Service Providers, nor for any false information and/or
                Quotes sent to the Clients, neither for any direct, indirect or
                consequential damage arising from any of the aforementioned. The
                Company will not be liable, under any circumstances, for the
                outcomes of the requests for services, namely if they do not
                satisfy the Client regarding the number of replies received from
                Service Providers or the type of offers presented in the Quotes
                received, or even with regards to the quality and features of
                the products or services offered by Service Providers. The
                Company does not intervene in the negotiations between Clients
                and Service Providers, which are established following a Request
                for a service being posted on Karsaaz's platform, and the
                Company will not be liable in the event of any dispute arising
                between the two parties.
                <br />
                The Client is responsible for checking the information provided
                by the Service Provider in order to ensure the competence of the
                latter to successfully carry out the proposed services. The
                Company will not be held responsible and declines any civil
                and/or criminal liability for any direct, indirect, or
                consequential damages arising from the relation established
                between Clients and Service Providers.
                <br />
                As a User, you agree that you will not make any claims, bring
                proceedings or request compensation (including any related legal
                fees) against the Company with regards to any type of Service
                Provider or Client content that you have provided on Karsaaz's
                platform, nor for your use of the Company's content, nor for any
                infringements to third party rights' by you, nor for your breach
                of the terms stipulated on these Terms of Service.
                <br />
                The Company will not be liable, under any circumstance, in the
                event of any system malfunction which is beyond its control,
                namely any type of network interruption, telephone, or Internet
                malfunction. The Company will not be liable in the event of
                force majeure circumstances (namely a strike, an Internet
                failure, etc.).
                <br />
                Karsaaz's platform includes links to third-party websites or
                mobile apps. The Company will not be held responsible for the
                hypertext links to other websites or mobile apps. The Company
                cannot be held responsible for any hyperlinks which lead to
                Karsaaz's platform. If you access any of this third-party
                websites or mobile apps, you do so at your own risk.
                <br />
                The User is advised that The Company or other partner websites
                or mobile apps may use cookies and Tag actions (identifiers)
                with the sole intention of measuring the efficiency of its
                services. All information collected by these companies via
                cookies or Tag actions is entirely anonymous. However, the User
                has the option of refusing the cookies and the Tag actions by
                simply changing his Internet browser settings. Should the User
                decide to change these settings, the User may not be able to
                benefit from all the features and services offered on Karsaaz's
                platform.
                <br />
                Karsaaz's platform is accessible 24 hours a day, 7 days a week,
                except in the event of any possible breakdown or of any
                maintenance required to ensure the proper functioning of the
                website or mobile apps. The Company reserves the right to
                interrupt Karsaaz's services at any time and for whatever
                reason, and this will not entitle Users to damages or
                compensation.
                <br />
                THE COMPANY DOES NOT MAKE ANY CLAIMS NOR ANY PROMISES REGARDING
                THE CONDUCT OF THIRD PARTIES, SUCH AS SERVICE PROVIDERS OR
                ADVERTISERS MENTIONED ON THE WEBSITE OR MOBILE APPS OR THE USERS
                OF THE WEBSITE OR MOBILE APPS. FURTHERMORE, THE KARSAAZ ENTITY
                WILL NOT BE HELD LIABLE FOR ANY LOSSES OR DAMAGES OF ANY NATURE
                ARISING FROM THEIR ACTIONS OR OMISSIONS, INCLUDING, FOR EXAMPLE,
                IF A USER OR A SERVICE PROVIDER IS FOUND GUILTY OF ABUSIVE USE
                OF YOUR CONTENT, YOUR IDENTITY OR YOUR PERSONAL INFORMATION, OR
                IF YOU HAVE A DISAPPOINTING EXPERIENCE WITH ANY OF THE SERVICE
                PROVIDERS OR ADVERTISERS LISTED ON THE WEBSITE OR MOBILE APPS.
                YOU PURCHASE AND USE ANY PRODUCTS OR ANY SERVICES OFFERED BY A
                THIRD PARTY THROUGH THE WEBSITE OR MOBILE APPS AT YOUR OWN RISK.
                <br />
                THE COMPANY DOES NOT MAKE ANY CLAIMS NOR ANY PROMISES REGARDING
                THE CONDUCT OF THIRD PARTIES, SUCH AS ITS PARTNERS. FURTHERMORE,
                THE KARSAAZ ENTITY WILL NOT ACCEPT LIABILITY FOR ANY LOSSES OR
                DAMAGES OF ANY NATURE ARISING FROM THEIR ACTIONS OR OMISSIONS,
                INCLUDING, FOR EXAMPLE, ANY CONTENT PRODUCED BY THE COMPANY AND
                SHARED BY ITS PARTNERS, SUCH AS PROFILES, REQUESTS, OR ANY OTHER
                INFORMATION FROM THE COMPANY.
                <br />
                Should The Company be considered liable, its liability for any
                damages suffered as a result of Karsaaz's use or The Company's
                services and/or Karsaaz's content will be limited to the sum of
                one thousand (1000) Pakistani rupees, regardless of the nature
                of the liability, whether it is a contractual, a tortious,
                partly tortious or criminal liability.
              </span>
            </div>

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                TERMINATION{" "}
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                The Company reserves the right to refuse the use of its services
                immediately to any User, without any prior notification nor any
                type of compensation, and without prejudice to any possible
                compensation due to The Company, in the event of non-compliance
                by any User of any of his obligations as referenced in these
                Terms of Service, and particularly in the event of an act of
                piracy or attempted illegal use of any information present on
                Karsaaz's platform that is found to have been caused or
                originated by a User's post. The Company will inform the User of
                its decision via email, phone, or other means.
                <br />
                The only rights and resources available exclusively to you, as a
                User, in the event of general dissatisfaction with the website
                or mobile apps, the services provided, or any other complaint
                are the termination and the complete cancellation of your access
                and use of the website or mobile apps.
              </span>
            </div>
            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                INTELLECTUAL PROPERTY RIGHTS
              </h1>
              <br />
              <span className="font-normal text-[18px] leading-8 mt-6">
                The website (https://karsaaz.app) and Karsaaz mobile apps are
                the property of the Company. All the elements which make up
                Karsaaz's platform comply with legislation on copyright,
                trademark law, and general intellectual property law, be it with
                regards to the form, the components of its architecture,
                structure, forms, texts, features, and graphics, or to any other
                data which is the exclusive property of the Company. You agree
                not to use these elements and codes. You agree not to allow the
                use of these elements and codes by any person for illegal and
                unauthorized purposes. Any reproduction, modification,
                publication, the total or partial transmission of these
                Karsaaz's elements and codes in any context or by any means with
                the intent to sell, resale, retransmit or make available to a
                third party in any way is expressly forbidden (more generally,
                any non-authorized usage by the Company of Karsaaz's website or
                mobile apps and any information shared on it is expressly
                forbidden). Failure to comply with these restrictions
                constitutes a serious offense that may result in civil or
                criminal liability for the counterfeiter. By publishing or
                displaying any Service Provider or Client-related content on
                Karsaaz's platform, you authorize the Company with a
                non-exclusive, worldwide right not subject to royalties, to
                reproduce, adapt, disclose and publish both Service Provider- or
                Client-related content, with the purpose of promoting and
                developing the Company's services. The Company is not
                responsible for the content's provenance. The Company reserves,
                at its sole discretion, the right to refuse, accept, publish,
                display or transmit any and all content. By submitting content
                to a public section of Karsaaz's platform, you give all Service
                Providers and Clients the right to access, search, display, save
                and reproduce the aforementioned content for their own personal
                use.
              </span>
            </div>
            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                PERSONAL DATA & RESPECT FOR PERSONAL PRIVACY
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                In accordance with the regulations of Pakistan’s data protection
                regulations, any automatic processing of any personal data by
                Karsaaz complies with these regulations. Users are advised that
                information provided on the forms they fill in while using
                Karsaaz's platform is required to ensure the processing of their
                requests and for generating invoices, among other things. The
                data is collected in order to allow communication between
                Clients and Service Providers. Some information may, however, be
                disclosed or sold to the Company's partners. Some information
                requested on the forms is mandatory. In such cases, the User
                must provide the information requested. The User agrees not to
                provide and not to share on the website or mobile apps any kind
                of false data and also acknowledges that he is solely
                responsible or the legal owner of the information he provides.
                The User is also advised that personal data with regard to the
                use of or access to the platform (IP address, date, time,
                session duration, etc.) is liable to be recorded each time the
                website or mobile app is visited. This information is stored and
                analyzed to locate and identify fraudulent Users. By registering
                on Karsaaz's platform, the User accepts and agrees to receive
                newsletters, promotional offers, and marketing communications
                from Karsaaz or from its partners by email. If the User would
                prefer not to receive the aforementioned promotional offers and
                not to participate in our personalized marketing campaigns, he
                may indicate this by sending an email to
                <a
                  href="mailto:info@karsaaz.app"
                  className="text-[#0973BA] underline"
                >
                  info@karsaaz.app.
                </a>
                User is advised that he has the legal right to access and
                rectify his data. These rights may be exercised by sending an
                email to: Info@Karsaaz.App. The personal information and
                personal data with regards to the User, which is collected at
                the time of registration at Karsaaz, during access and use of
                Karsaaz's platform, and upon the creation of an Account, are
                used by the Company to allow the User to fully benefit from the
                Company's services. Furthermore, the Company reserves the right
                to transmit any personal information and personal data provided
                by the User if required to comply with a legal obligation, in
                the event of a claim related in any way to the User's personal
                information, or if the content provided violates any of the
                other Users' rights, or if requested to do so by any relevant
                administrative or legal authority, or if required to comply with
                any judicial, administrative, or independent administrative
                authority's decision, the Company will not be considered liable
                for any damages to Users, resulting from a third party not
                complying with the privacy policy or the security measures in
                effect on Karsaaz's platform. If any such event should occur,
                the User may promptly report it to us by sending an email to
                Info@Karsaaz.App. Although proof of identity may be requested,
                the User has the right to access, modify, rectify and delete his
                data, either by directly accessing his own Account or by sending
                an email to the following email address:
                <a
                  href="mailto:info@karsaaz.app"
                  className="text-[#0973BA] underline"
                >
                  Info@Karsaaz.App.
                </a>
                <br />
                We authorize basic information about Service Providers provided
                by third-party data providers who collect this type of
                information from public documents and other public sources. We
                also gather information about Service Providers through our
                Users, who help us to correct the data we have online, or who
                inform us about the opening of a new business. Please do not
                hesitate to contact us to advise us of any updates or
                rectifications required from us by writing an email to:
                Info@Karsaaz.App.
              </span>
            </div>

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                GOVERNING LAW & JURISDICTION
              </h1>
              <span className="font-normal text-[18px] leading-8 mt-6">
                In the event of a dispute arising from or related to these Terms
                of Service, including any question regarding the interpretation,
                execution, and/or implementation of any of its sections, and in
                the event that no settlement can be reached between the parties
                involved, the relevant governing law and jurisdiction clauses
                apply. These Terms of Service are governed by the laws of the
                country.
              </span>
            </div>

            {/* ----------------------------- Article are End Now ----------------------------- */}
          </div>
        </div>

        <GetMoreDoneWithKarsaaz color="#E0F3FF" TextColor="#0973BA" />
        <FooterMain />
      </div>
    </>
  );
};

export default TermsAndConditions;
