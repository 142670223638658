import * as yup from "yup";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { useAbandonedLogIn } from "hooks/useAuth";
import { showErrorToast } from "store/features/toastSlice";

const PhoneNumberModal = ({
  user,
  setSocialPhoneModal,
  sync_social_account_isLoading,
  mobile_number,
  setMobile_number,
  forgot_pass_mutate,
  handleSecondNext,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const random_password = Math.random().toString(36).slice(2).substr(0, 6);

  const {
    mutate: abandoned_request_mutate,
    data: abandoned_request_data,
    isLoading: abandoned_request_isLoading,
  } = useAbandonedLogIn();

  const initialValues = {
    phone: "",
  };
  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      phone: yup
        .string()
        .matches(/^[0-9]+$/, "Invalid phone number")
        .max(11)
        .min(11)
        .required("Enter Phone Number"),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const { phone } = values;

      const newObj = {
        mobile: phone,
        password: random_password,
        login_by: "manual",
        device_type: "web",
      };
      abandoned_request_mutate(newObj);

      setMobile_number(newObj?.mobile);
    },
  });

  const handle_otp_payload = () => {
    const payload = {
      mobile: mobile_number,
      device_type: "web",
      login_by: user,
    };
    forgot_pass_mutate(payload);
    handleSecondNext();
  };

  useEffect(() => {
    if (abandoned_request_data?.error_code === 160) {
      dispatch(showErrorToast("New User Must Register Account First"));
      navigate("/register");
      localStorage.removeItem("mobile_number");
    } else if (abandoned_request_data?.error_code === 1051 && user === "google") {
      handle_otp_payload();
    } else if (abandoned_request_data?.error_code === 1051 && user === "facebook") {
      handle_otp_payload();
    }
  }, [abandoned_request_data]);

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } = Formik;
  return (
    <>
      <div className="w-full font-Trueno bg-[#AEE0FF]">
        <div className="fixed inset-0 overflow-y-auto scrollbar-hide  bg-black bg-opacity-25 backdrop-blur-sm px-3 md:px-9 py-32">
          <form
            onSubmit={handleSubmit}
            className="md:w-[50%] h-auto mx-auto bg-white  flex justify-center border rounded-[15px] relative"
          >
            <button
              className=" font-bold uppercase  py-2 text-sm outline-none focus:outline-none mb-1 absolute right-5 top-5"
              type="button"
            >
              <RxCross2
                onClick={() => {
                  setSocialPhoneModal(false);
                }}
                className="w-[18px] md:w-[22px] h-[18px] md:h-[22px]"
              />
            </button>

            <div className="flex flex-col gap-5 py-20 w-full mx-auto text-center px-3 md:px-6">
              <h2 className="font-extrabold text-xl md:text-[24px] leading-[29.26px] text-black">
                Sync
                <span className="text-[#0973BA]"> Social Account</span>
              </h2>
              <p className="text-sm text-black font-normal leading-6">
                Please enter your mobile number if you already have an account
              </p>
              <p className="text-sm text-black font-normal leading-6">
                If you don't have account please signup your account to continue
              </p>

              <div className="flex flex-col gap-3 mb-11 text-left">
                <label htmlFor="phone Number" className="font-semibold text-xs md:text-sm">
                  Mobile Number
                </label>
                <input
                  type="Phone"
                  autoComplete="off"
                  name="phone"
                  id="Phone"
                  value={values?.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="03xxx"
                  className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-4 md:pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs md:placeholder:text-sm"
                />
                {errors.phone && touched.phone ? <p className="font-semibold text-sm text-red-600">{errors.phone}</p> : null}
              </div>

              <div className="w-fit mx-auto flex items-center gap-2">
                <button
                  type="button"
                  onClick={() => {
                    navigate("/register");
                  }}
                  className="relative font-semibold text-[14px] leading-[12px] text-white h-[46px] px-5 w-fit mx-auto border bg-[#0973BA] rounded-lg"
                >
                  SignUp
                </button>

                <button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="relative font-semibold text-[14px] leading-[12px] text-white h-[46px] px-5 w-fit mx-auto border bg-[#0973BA] rounded-lg"
                >
                  {abandoned_request_isLoading || sync_social_account_isLoading ? (
                    <p className="font-semibold text-xs md:text-sm text-white text-center">Please Wait...</p>
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PhoneNumberModal;
