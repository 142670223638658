import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from ".";
import { fetchSuperServices, fetchServices, fetchSubServices, fetchbanner, fetchVerification_CallData } from "middleware/api";
import { useDispatch } from "react-redux";
import { showErrorToast, showSuccessToast } from "store/features/toastSlice";

// hooks
const useSuperServices = () =>
  useQuery({
    queryKey: ["superServices"],
    queryFn: fetchSuperServices,
  });

// const useServices = (id) =>
//   useQuery({
//     queryKey: ["services", [id]],
//     queryFn: () => fetchServices(id),
//   });

const useSubServices = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: fetchSubServices,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};

// const useSubServices = (id) =>
//   useQuery({
//     queryKey: ["subServices", [id]],
//     queryFn: () => fetchSubServices(id),
//   });

const useServices = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: fetchServices,
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(showSuccessToast(data.message));
      } else {
        dispatch(showErrorToast(data.message));
      }
    },
  });
};
const useBanner = () =>
  useQuery({
    queryKey: ["banner"],
    queryFn: fetchbanner,
  });

// hooks

export { useSuperServices, useServices, useSubServices, useBanner };
