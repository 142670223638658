import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { bookApi } from "../middleware/bookingApi";
import { auth } from "./auth";
import toastReducer from "./features/toastSlice";

const store = configureStore({
  reducer: {
    auth: auth,
    toast: toastReducer,
    // [bookApi.reducerPath]: bookApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(bookApi.middleware),
});

setupListeners(store.dispatch);

export { store };
