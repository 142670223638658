import { BsFacebook, BsTwitter, BsLinkedin, BsYoutube } from "react-icons/bs";
import UpdateMetaTags from "utils/UpdateMetaTags";
import BlogsSkeleton from "views/loader/BlogsSkeleton";
import Loader from "views/loader/Loader";
import BlogsNavbar from "./BlogsNavbar";
import BlogsCategories from "./BlogsCategories";
import TrendingPosts from "./TrendingPosts";
import FooterMain from "views/partials/footer/FooterMain";
import { useBlog_by_slug, useBlogs_by_category } from "hooks/useUser";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "lib/Pagination";
import BlogsPopularCategories from "./BlogsPopularCategories";

const BlogDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { blog_category_id } = location.state || {};
  let { slug } = useParams();

  const { data: blog_by_slug_data, isLoading: blog_by_slug_isLoading, mutate: blog_by_slug_mutate } = useBlog_by_slug();
  const { data: blogs_by_category_data, mutate, isLoading: blogs_by_category_isLoading } = useBlogs_by_category();

  const [postsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const payload = {
      slug: slug,
    };
    blog_by_slug_mutate(payload);
  }, [slug]);

  useEffect(() => {
    const payload = {
      blog_category_id: blog_category_id,
    };
    mutate(payload);
  }, [blog_category_id]);

  const handleBlogsDetailPage = (item) => {
    navigate(`/blogs/${item?.slug}`, {
      state: {
        blogs_detail_data: item?.slug,
      },
    });
  };

  const ICONS = [BsFacebook, BsYoutube, BsTwitter, BsLinkedin];
  const links = [
    "https://www.facebook.com/karsaazApp/",
    "https://www.youtube.com/@karsaaz",
    "https://twitter.com/",
    "https://www.linkedin.com/company/karsaaz-app",
  ];


  // Get current page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogs_by_category_data?.data?.slice(indexOfFirstPost, indexOfLastPost);

  // Change pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleClick = (direction) => {
    let newStep = currentPage;
    direction === "next" ? newStep++ : newStep--;
    newStep > 0 && newStep <= blogs_by_category_data?.length && setCurrentPage(newStep);
  };

  return (
    <>
      <UpdateMetaTags data={blog_by_slug_data?.data?.[0] ?? {}} />

      <BlogsNavbar />
      <div className="w-[90%] mx-auto flex flex-col md:flex md:flex-row gap-7 justify-between">
        <div className="w-full mx-auto font-Trueno">
          {blog_by_slug_isLoading
            ? Array(1)
              .fill()
              .map((_, index) => (
                <div key={index} className="py-40">
                  <Loader />
                </div>
              ))
            : blog_by_slug_data?.data?.map((item, index) => (
              <div key={index} className="w-full flex flex-col gap-2">
                <h1 className="font-bold text-3xl my-3">{item?.title}</h1>
                <p className="flex justify-between items-center font-extrabold text-xs text-[#0973BA]">
                  {blog_by_slug_data?.data?.created_by}
                  <span className="flex items-center gap-1 font-normal text-xs text-black">Posted on: {item?.posted_on}</span>
                </p>
                <img src={item?.banner_image} alt="" className=" w-full max-h-[485px] rounded-xl my-3" />

                <div>
                  <ul className={`flex rounded-full gap-2 w-fit`}>
                    {ICONS.map((Icon, index) => (
                      <li className="rounded-full p-2 bg-[#0973BA]" key={index}>
                        <a href={links[index]} target="_blank" rel="noreferrer">
                          <Icon color="white" className="w-[16px] h-[16px]" />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* ------------------------------- Blogs SEO Contents ------------------------------- */}
                <div>
                  <div className="inner-html-seo-pages" dangerouslySetInnerHTML={{ __html: item?.contents }}></div>
                </div>
              </div>
            ))}

          {blogs_by_category_data?.success === true ? (
            <div className="flex flex-col gap-3">
              <h2 className="font-extrabold text-2xl md:text-4xl my-4 md:my-7">Latest Blogs</h2>

              <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
                {blogs_by_category_isLoading
                  ? Array(3)
                    .fill()
                    .map((_, index) => <BlogsSkeleton key={index} />)
                  : currentPosts?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        handleBlogsDetailPage(item);
                      }}
                      className="border border-[#DFDFDF] p-1 rounded-2xl w-full max-w-[413px] cursor-pointer"
                    >
                      <div className=" relative">
                        <img src={item?.banner_image} alt="" className="w-full md:w-[402.15px] h-[307px] rounded-2xl" />
                        <span className="px-3 md:px-4 py-1 bg-[#0973BA] font-semibold text-sm md:text-base text-white w-fit rounded-2xl absolute top-3 left-3">
                          {item?.blog_category}
                        </span>
                      </div>
                      <div className="flex flex-col gap-2 w-full p-4">
                        <h4 className="font-extrabold text-lg md:text-[22px] leading-[26.82px">
                          {item?.title?.length > 50 ? item?.title.substring(0, 50) + "...." : item?.title}
                        </h4>
                        <p className="font-normal text-sm md:text-base">{item?.description}</p>
                        <div className="w-full flex justify-between items-center">
                          <button
                            onClick={() => {
                              handleBlogsDetailPage(item);
                            }}
                            className="px-3 md:px-4 py-1 bg-[#0973BA] font-semibold text-sm md:text-base text-white w-fit rounded-[50px]"
                          >
                            Read More
                          </button>
                          <p className="font-normal text-xs">{item?.posted_on}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className={`my-10 ${blog_by_slug_data?.data?.length >= 7 ? "block" : "hidden"}`}>
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={blog_by_slug_data?.data?.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  handleClick={handleClick}
                />
              </div>
            </div>
          ) : null}

          {blogs_by_category_data?.success === false && blog_by_slug_data?.success === false ? (
            <div className="flex justify-center items-center my-20">
              <h2 className="font-extrabold text-2xl">No Details Found</h2>
            </div>
          ) : null}
        </div>

        <BlogsCategories />
      </div>

      <div className="w-[90%] mx-auto block md:hidden mt-4">
        <TrendingPosts />
      </div>

      {/* ----------------------- Blogs Popular Services ----------------------- */}

      <BlogsPopularCategories />

      <FooterMain />
    </>
  );
};

export default BlogDetailPage;
