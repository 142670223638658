import { useWeekly_offer_by_category } from "hooks/useUser";
import ShimmerSkeleton from "views/loader/ShimmerSkeleton";
import FooterMain from "views/partials/footer/FooterMain";
import CustomerChoosesUs from "common/CustomerChoosesUs";
import CustomerLoveUs from "theme/cards/CustomerLoveUs";
import NavCustom from "views/partials/navbar/NavCustom";
import { Link, useLocation } from "react-router-dom";
import { useSubServices } from "hooks/useService";
import RightSideBar from "common/RightSideBar";
import CityFilter from "common/CityFilter";
import SearchBar from "common/SearchBar";
import { Card } from "theme/cards/Card";
import { useEffect } from "react";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const SubCategories = () => {
  const location = useLocation();
  const { isLoading, data, mutate } = useSubServices();
  const { web_banner_image, web_banner_text, category_name, category_id, category_description } = location.state || {};
  const {
    data: weekly_offer_data,
    mutate: weekly_offer_mutate,
    isLoading: weekly_offer_isLoading,
  } = useWeekly_offer_by_category();

  const handleCityChange = (cityValue) => {
    const payload = {
      category_id: category_id,
      city_id: cityValue,
    };
    mutate(payload);

    const weekly_offer_payload = {
      id: category_id,
      city_id: cityValue,
    };
    weekly_offer_mutate(weekly_offer_payload);
  };

  useEffect(() => {
    const sub_services = {
      category_id: category_id,
      city_id: localStorage.getItem("selectedOption"),
    };
    mutate(sub_services);

    const weekly_offer_payload = {
      id: category_id,
      city_id: localStorage.getItem("selectedOption"),
    };
    weekly_offer_mutate(weekly_offer_payload);
  }, []);
  return (
    <>
      <div className="w-full">
        <div
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/FooterBackIcon.png)`,
          }}
          className={`bg-[#0973BA] relative bg-no-repeat bg-[length:_500px] bg-left-bottom`}
        >
          <div className="flex flex-col w-full h-574px">
            <NavCustom styling={{ color: "white", backgroundColor: "#0973BA" }} />

            <RightSideBar />
            {isLoading ? (
              <div>
                <div className="w-[90%] mx-auto flex flex-col-reverse md:grid md:grid-cols-2 p-3 py-12">
                  <div className="w-full flex flex-col gap-3 pt-5 md:py-12">
                    <div className="animate-pulse w-full h-7 bg-sky-200"></div>
                    <div className="animate-pulse w-full h-7 bg-sky-200"></div>
                    <div className="animate-pulse w-1/5 h-7 bg-sky-200"></div>

                    <div className="animate-pulse w-1/3 h-3 bg-sky-200 mt-3"></div>
                    <div className="animate-pulse w-full h-12 bg-sky-200 mt-3 rounded-2xl"></div>
                  </div>
                  <div className="flex items-center justify-center gap-5 w-full">
                    <div className=" animate-pulse w-[200px] h-[300px]  rounded-t-full rounded-b-full bg-slate-200"></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="font-Trueno flex flex-col-reverse md:flex-row justify-between items-center w-[90%] mx-auto ">
                <div className="md:w-[650px]">
                  <div
                    className="inner-html-style text-center md:text-left  md:mb-[0px] mb-[51.8px]"
                    dangerouslySetInnerHTML={{ __html: web_banner_text }}
                  ></div>
                  <div
                    className="font-normal text-base md:text-lg text-white my-6"
                    dangerouslySetInnerHTML={{ __html: category_description }}
                  ></div>
                  <div className="my-3">
                    <SearchBar />
                  </div>
                </div>
                {/* <------------- Hero----Section----Image-------- > */}
                <div className="w-fit max-w-[363.76px] justify-center md:justify-start">
                  <img src={web_banner_image} alt="" className="h-[331.36px] my-16 mb-[0px] md:mb-[81.8px] md:mr-[154px]" />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* ----------------------- Sub Category Popular Services ----------------------- */}

        {weekly_offer_data?.success === false ? null : (
          <div className="w-[90%] mx-auto mt-20 mb-10">
            <h2 className="w-full text-center font-extrabold text-[28px] md:text-4xl my-[60px]">Weekly Offers</h2>

            <div className="flex justify-center md:justify-start w-full my-11">
              <CityFilter onCityChange={handleCityChange} />
            </div>

            <Swiper
              grabCursor={true}
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              navigation={{ nextEl: "#arrow-right1", prevEl: "#arrow-left1" }}
              modules={[Autoplay, Navigation]}
              className="w-full mx-auto"
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1250: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
            >
              <div className="flex gap-3">
                {weekly_offer_isLoading
                  ? Array(2)
                    .fill()
                    .map((_, index) => (
                      <div key={index}>
                        <ShimmerSkeleton />
                      </div>
                    ))
                  : weekly_offer_data?.data?.map((item, index) => (
                    <SwiperSlide key={index} className="w-full my-3">

                      <Card
                        category_id={item?.category_id}
                        sub_category_id={item?.id}
                        name={item?.name}
                        picture={item?.picture}
                        price={item?.price}
                        price_type={item?.price_type}
                        category_description={item?.description}
                        sub_category_is_bidded={item?.is_bidded}
                        sub_category_discount_price={item?.discount_price}
                        backgroundColor="#0973BA"
                        sub_category_web_seo_url={item?.web_seo_url}
                        sub_category_is_enabled={item?.is_enabled}
                        sub_category_slug={item?.slug}
                      />

                    </SwiperSlide>
                  ))}
              </div>
            </Swiper>
          </div>
        )}

        {/* ----------------------- Sub Category Services ----------------------- */}

        <div className="w-[90%] mx-auto flex flex-col gap-8">
          <div className="flex flex-col  justify-between items-center text-center">
            <h2 className="font-extrabold text-[28px] md:text-4xl my-[60px]">{category_name}</h2>
            {/* <p className="font-normal text-base md:text-[22px] leading-[31px] text-[#0973BA]"></p>*/}

            {weekly_offer_data?.success === true ? null : (
              <div className="flex justify-center md:justify-start w-full">
                <CityFilter onCityChange={handleCityChange} />
              </div>
            )}
          </div>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 self-center">
            {isLoading
              ? Array(4)
                .fill()
                .map((_, index) => (
                  <div key={index}>
                    <ShimmerSkeleton />
                  </div>
                ))
              : data?.data?.en?.map((item, index) => (
                <ul key={index}>
                  <li className="">
                    <Card
                      category_id={category_id}
                      sub_category_id={item?.sub_category_id}
                      name={item?.sub_category_name}
                      picture={item?.sub_category_picture}
                      price={item?.sub_category_price}
                      price_type={item?.sub_category_price_type}
                      category_description={item?.sub_category_description}
                      sub_category_is_bidded={item?.sub_category_is_bidded}
                      sub_category_discount_price={item?.sub_category_discount_price}
                      backgroundColor="#0973BA"
                      sub_category_web_seo_url={item?.sub_category_web_seo_url}
                      sub_category_is_enabled={item?.sub_category_is_enabled}
                      sub_category_slug={item?.sub_category_slug}
                    />
                  </li>
                </ul>
              ))}
          </div>
        </div>

        {/* <GetMoreDoneWithKarsaaz color="#E0F3FF" TextColor="#0973BA" /> */}
        <CustomerChoosesUs color="#ECF7FE" TextColor="black" />
        <CustomerLoveUs color="#FAFDFF" QuoteIconColor="#07609C" FaArrowCircleColor="#07609C" TextColor="#0973BA" />
        <FooterMain />
      </div>
    </>
  );
};

export default SubCategories;
