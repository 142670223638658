import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaWhatsapp,
  FaEnvelope,
} from "react-icons/fa";

const ShareSocialLink = ({ url }) => {
  const shareToWhatsApp = () => {
    window.open(`https://wa.me/?text=${encodeURIComponent(url)}`, "_blank");
  };

  const shareToFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fkarsaaz.app%2F&amp;src=sdkpreparse=${encodeURIComponent(
        url
      )}`,
      "_blank"
    );
  };

  const shareToLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=https://karsaaz.app/`,
      "_blank"
    );
  };

  const shareToTwitter = () => {
    window.open(
      "https://twitter.com/intent/tweet?text=" + encodeURIComponent(url),
      "_blank"
    );
    // window.open(
    //   `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
    //   "_blank"
    // );
  };

  const shareViaEmail = () => {
    window.location.href = `mailto:?subject=Check%20out%20this%20link&body=${encodeURIComponent(
      url
    )}`;
  };
  return (
    <div className="w-full mx-auto flex gap-2 items-center justify-center">
      <button
        onClick={shareToWhatsApp}
        className="w-fit bg-green-500 p-3 text-white rounded-full"
      >
        <FaWhatsapp className="w-4 md:w-[25px] h-4 md:h-[25px]" />
      </button>
      <button
        onClick={shareToFacebook}
        className="w-fit bg-blue-600 p-3 text-white rounded-full"
      >
        <FaFacebook className="w-4 md:w-[25px] h-4 md:h-[25px]" />
      </button>
      <button
        onClick={shareToLinkedIn}
        className="w-fit bg-[#0A66C2] p-3 text-white rounded-full"
      >
        <FaLinkedin className="w-4 md:w-[25px] h-4 md:h-[25px]" />
      </button>
      <button
        onClick={shareToTwitter}
        className="w-fit bg-[#24A3F1] p-3 text-white rounded-full"
      >
        <FaTwitter className="w-4 md:w-[25px] h-4 md:h-[25px]" />
      </button>

      <button
        onClick={shareViaEmail}
        className="w-fit bg-gray-600 p-3 text-white rounded-full"
      >
        <FaEnvelope className="w-4 md:w-[25px] h-4 md:h-[25px]" />
      </button>
    </div>
  );
};

export default ShareSocialLink;
