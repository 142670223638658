import SideBarMenu from "./SideBarMenu";
import { useFormik } from "formik";
import React, { useRef, useState, useEffect } from "react";
import * as yup from "yup";
import SettingFooter from "./SettingFooter";
import BookingNavBar from "views/partials/navbar/BookingNavbar";
import { useProfile, useUpdateUser } from "hooks/useUser";
import { checkIsUserLoggedIn } from "actions/auth";
import { showErrorToast } from "store/features/toastSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";

const PersonalInformation = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useProfile();
  const { mutate, isLoading } = useUpdateUser();

  const profile = data?.data;
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [showProfilePic, setShowProfilePic] = useState(null);
  const [showProfilePicModal, setShowProfilePicModal] = useState(false);

  const validateFileType = (file) => {
    if (!file) {
      return null; // No file selected is considered valid
    }

    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"];

    if (!allowedTypes.includes(file.type)) {
      dispatch(showErrorToast("Invalid file type. Only images are allowed."));
      return "Invalid file type";
    }

    return null;
  };

  const onFileDrop = (e) => {
    const file = e.target.files[0];
    const fileTypeError = validateFileType(file);

    if (!fileTypeError) {
      setFileList([file]);
      if (props.onFileChange) {
        props.onFileChange([file]);
      }
    } else {
      // Clear the file input to prevent uploading an invalid file
      e.target.value = null;
    }
  };
  const initialValues = {
    mobile: "",
    name: "",
    email: "",
    gender: "",
    user_picture: "",
  };
  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      name: yup.string().min(2).max(25),
      mobile: yup.string().max(11),
      email: yup.string().email(),
      gender: yup.string(),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("gender", values.gender);
      formdata.append("gender", values.gender);
      formdata.append("mobile", values.mobile);
      formdata.append("email", values.email);
      formdata.append("id", localStorage.getItem("id"));
      formdata.append("token", localStorage.getItem("token"));

      if (fileList.length > 0) formdata.append("picture", fileList[0]);
      mutate(formdata);
    },
  });
  const { values, handleBlur, handleChange, errors, touched, handleSubmit } = Formik;
  useEffect(() => {
    Formik.setValues({
      name: profile?.user_name,
      mobile: profile?.mobile,
      email: profile?.email,
      gender: profile?.gender,
    });
    setShowProfilePic(profile?.user_picture);
  }, [profile]);

  if ((data?.success === false && data?.error_code === 104) || data?.error_code === 103) {
    dispatch(showErrorToast("Another user is Logged in on different device"));

    localStorage.removeItem("token");
    localStorage.removeItem("id");

    navigate("/login");
    dispatch(checkIsUserLoggedIn());
  }
  return (
    <>
      <div className="bg-[#F0F7FC]">
        <BookingNavBar color="#F0F7FC" />

        <div className="w-full font-Trueno my-20">
          <div className="md:w-[85%] mx-auto flex-col md:flex md:flex-row md:gap-3 justify-between md:px-0 px-2">
            <div>
              <SideBarMenu />
            </div>
            <div className="border rounded-[20px] shadow-2xl bg-white w-full md:w-[988px] lg:w-full mt-4 md:mt-0">
              <h1 className="font-normal text-[18px] leading-[21.6px] border-b border-[#DFDFDF] px-4 md:px-[38px] pt-8 pb-4">
                Edit Profile
              </h1>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 gap-1 lg:gap-9 justify-between px-[18px] md:px-[38px]  w-full ">
                  {/* --------------------- Form Area --------------------- */}

                  <div className="my-14 w-full flex flex-col gap-9">
                    <div className="flex flex-col gap-5 w-full">
                      {/* ------------------ Name Field ------------------ */}

                      <div className="flex flex-col gap-3">
                        <label htmlFor="FullName" className="font-semibold text-sm">
                          Full Name
                        </label>
                        <input
                          type="name"
                          autoComplete="off"
                          name="name"
                          id="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Write here"
                          className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                        />
                        {errors.name && touched.name ? <p className="font-semibold text-sm text-red-600">{errors.name}</p> : null}
                      </div>
                      {/* ------------------ Phone Number Field ------------------ */}

                      <div className="flex flex-col gap-3">
                        <label htmlFor="phone Number" className="font-semibold text-sm">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          name="mobile"
                          value={values.mobile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="phone Number"
                          className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full  py-3 pl-7 "
                          disabled={true}
                        />
                      </div>
                      {/* ------------------ EmailAddress Field ------------------ */}

                      <div className="flex flex-col gap-3">
                        <label htmlFor="Full Name" className="font-semibold text-sm">
                          Email
                        </label>
                        <input
                          type="email"
                          autoComplete="off"
                          name="email"
                          id="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Write here"
                          className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                        />
                        {errors.email && touched.email ? (
                          <p className="font-semibold text-sm text-red-600">{errors.email}</p>
                        ) : null}
                      </div>

                      {/* ------------------ Gender Field ------------------ */}

                      <label className="font-semibold text-sm">Gender</label>
                      <div className="flex gap-3 items-center">
                        <div className="flex gap-4">
                          <input
                            type="radio"
                            id="Male"
                            name="gender"
                            value="male"
                            checked={values.gender === "male"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <label htmlFor="Male" className="font-normal text-sm text-[#0973BA]">
                            Male
                          </label>
                        </div>
                        <div className="flex gap-4">
                          <input
                            type="radio"
                            id="female"
                            name="gender"
                            value="female"
                            checked={values.gender === "female"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <label htmlFor="Female" className="font-normal text-sm text-[#0973BA]">
                            Female
                          </label>
                        </div>

                        {errors.gender && touched.gender ? (
                          <p className="font-semibold text-sm text-red-600">{errors.gender}</p>
                        ) : null}
                      </div>

                      {/* ------------------ Button ------------------ */}

                      <button
                        type="submit"
                        className="font-semibold text-sm text-white py-[14px] mt-16 w-full border bg-[#0973BA] rounded-lg "
                      >
                        {isLoading ? (
                          <div
                            className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </div>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </div>

                  {/* --------------------- Upload Profile Area --------------------- */}

                  <div className="grid md:flex gap-4 md:items-center h-fit">
                    <div ref={wrapperRef} className="drop-file-input">
                      <div className="drop-file-preview flex-col md:flex md:flex-row justify-between items-center">
                        {fileList.length === 0 && (
                          <img
                            src={showProfilePic ? showProfilePic : `${process.env.PUBLIC_URL}/images/EditProfileIcon.png`}
                            alt=""
                            className="rounded-full h-[121px] w-[121px] object-cover cursor-pointer"
                          // onClick={() => setShowProfilePicModal(true)}
                          />
                        )}
                        {fileList.map((file, index) => (
                          <div key={index} className="edit-profile-preview__item">
                            <img src={URL.createObjectURL(file)} alt="" />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col  gap-4">
                      <span className="font-normal text-[14px] leading-[16.8px]">Upload Profile Image</span>

                      <input
                        type="file"
                        className="hidden"
                        id="fileInput"
                        value={values.user_picture}
                        onChange={onFileDrop}
                        multiple
                      />

                      <label
                        htmlFor="fileInput"
                        className="cursor-pointer bg-[#0973BA] text-white text-center font-semibold text-[12px] leading-[14.63px] rounded-lg w-[71px] py-2"
                      >
                        Upload
                      </label>
                    </div>
                  </div>

                  {showProfilePicModal && (

                    <div className="w-full font-Trueno bg-[#AEE0FF]">
                      <div className="fixed inset-0 overflow-y- scroll-none bg-black bg-opacity-25 backdrop-blur-sm px-2 md:px-9">
                        <div className="w-fit mx-auto my-9  flex justify-center relative">
                          <button
                            className=" font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 absolute right-1 top-7"
                            type="button"
                            onClick={() => setShowProfilePicModal(false)}
                          >
                            <RxCross2 className="w-[14px] md:w-[22px] h-[14px] md:h-[22px]" />
                          </button>

                          <img
                            src={showProfilePic ? showProfilePic : `${process.env.PUBLIC_URL}/images/EditProfileIcon.png`}
                            alt=""
                            className="rounded-2xl md:h-[621px] md:w-[621px] object-cover"
                          />

                        </div>
                      </div>
                    </div>

                  )}
                </div>
              </form>
            </div>
          </div>
        </div >
        <SettingFooter />
      </div >
    </>
  );
};

export default PersonalInformation;
