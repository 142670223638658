import React from "react";
import { Card } from "theme/cards/Card";
import CityFilter from "common/CityFilter";
import ShimmerSkeleton from "views/loader/ShimmerSkeleton";
import { useWeekly_offer_by_category } from "hooks/useUser";
import { useEffect } from "react";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const SubCategoriesSEO = ({
  slug_data,
  isLoading2,
  sub_services,

  handleCityChange,
  id,
}) => {

  const {
    data: weekly_offer_data,
    mutate: weekly_offer_mutate,
    isLoading: weekly_offer_isLoading,
  } = useWeekly_offer_by_category();

  useEffect(() => {
    const payload = {
      id: id,
      city_id: localStorage.getItem("selectedOption"),
    };
    weekly_offer_mutate(payload);
  }, [localStorage.getItem("selectedOption")]);

  return (
    <>
      <div>
        {/* ----------------------- Sub Category Popular Services ----------------------- */}

        {weekly_offer_data?.success === true ? (
          <div className="w-full mx-auto my-10">
            <div className="w-full text-center font-extrabold text-[28px] md:text-4xl my-[60px]">
              Weekly Offers
            </div>

            <div className="flex justify-center md:justify-start w-full my-11">
              <CityFilter onCityChange={handleCityChange} />
            </div>

            <Swiper
              grabCursor={true}
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              navigation={{ nextEl: "#arrow-right1", prevEl: "#arrow-left1" }}
              modules={[Autoplay, Navigation]}
              className="w-full mx-auto"
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1250: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
            >
              <div className="flex gap-3">
                {weekly_offer_isLoading
                  ? Array(2)
                    .fill()
                    .map((_, index) => (
                      <div key={index}>
                        <ShimmerSkeleton />
                      </div>
                    ))
                  : weekly_offer_data?.data?.map((item, index) => (
                    <SwiperSlide key={index} className="w-full my-3">
                      <Card
                        category_id={item?.category_id}
                        sub_category_id={item?.id}
                        name={item?.name}
                        picture={item?.picture}
                        price={item?.price}
                        price_type={item?.price_type}
                        category_description={item?.description}
                        sub_category_is_bidded={item?.is_bidded}
                        sub_category_discount_price={item?.discount_price}
                        backgroundColor="#0973BA"
                        sub_category_web_seo_url={item?.web_seo_url}
                        sub_category_is_enabled={item?.is_enabled}
                        sub_category_slug={item?.slug}
                      />
                    </SwiperSlide>
                  ))}
              </div>
            </Swiper>
          </div>
        ) : null}

        {/* ----------------------- Sub Category Services ----------------------- */}

        <div className="flex flex-col gap-12">
          <h2 className="grid md:flex gap-2 items-center justify-center font-extrabold text-[28px] md:text-4xl text-center capitalize mt-[60px]">
            {slug_data?.data?.category_name}
          </h2>
          <div
            className={`flex justify-center md:justify-start w-full ${weekly_offer_data?.success === true ? "hidden" : "block"
              }`}
          >
            <CityFilter onCityChange={handleCityChange} />
          </div>
        </div>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2  mt-14 justify-center">
          {isLoading2
            ? Array(4)
              .fill()
              .map((_, index) => (
                <div key={index}>
                  <ShimmerSkeleton />
                </div>
              ))
            : sub_services?.data?.en?.map((item, index) => (
              <ul key={index}>
                <li className="">
                  <Card
                    // category_id={item?.category_id}
                    sub_category_id={item?.sub_category_id}
                    name={item?.sub_category_name}
                    picture={item?.sub_category_picture}
                    price={item?.sub_category_price}
                    price_type={item?.sub_category_price_type}
                    category_description={item?.sub_category_description}
                    sub_category_is_bidded={item?.sub_category_is_bidded}
                    sub_category_discount_price={
                      item?.sub_category_discount_price
                    }
                    backgroundColor="#0973BA"
                    sub_category_web_seo_url={item?.sub_category_web_seo_url}
                    sub_category_is_enabled={item?.sub_category_is_enabled}
                    sub_category_slug={item?.sub_category_slug}
                  />
                </li>
              </ul>
            ))}
        </div>
      </div>
    </>
  );
};

export default SubCategoriesSEO;
