import React from "react";

const PlayStoreButton = () => {
  return (
    <>
      <div className="w-full">
        <a href="https://play.google.com/store/apps/details?id=com.KarsaazPro.app&pli=1" target="_blank" rel="noreferrer">
          <button className="w-[182px] h-[50px] flex gap-2 items-center bg-white box-border border rounded-lg text-Black font-normal text-[12px] font-Poppins leading-[18px] text-center pl-[15.06px] pr-8 py-2 hover:scale-105 hover duration-200">
            <img className="w-[30px] h-[30px]  " src={`${process.env.PUBLIC_URL}/images/PlayStoreIcon.svg`} alt="" />

            <p className="flex flex-col text-black">
              Get it on
              <span className="font-semibold text-base">Google Play</span>
            </p>
          </button>
        </a>
      </div>
    </>
  );
};

export default PlayStoreButton;
