import * as yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import RightSideBar from "common/RightSideBar";
import { Link, useLocation } from "react-router-dom";
import DropFileEditBooking from "./DropFileEditBooking";
import BookingNavbar from "views/partials/navbar/BookingNavbar";
import { RequestImages, useRemove_user_request_images, useRequest_description_update } from "hooks/useUser";

const EditBooking = (props) => {
  const location = useLocation();
  const { mutate: request_image_mutate } = RequestImages();
  const { mutate, isLoading } = useRequest_description_update();
  const { request_id, description, price_per_hour, s_address, request_date, name } = location.state;
  const { mutate: mutate_remove_user_request_images, data: data_remove_user_request_images } = useRemove_user_request_images();

  const [fileList, setFileList] = useState([]);
  const [jobDescription, setJobDescription] = useState(description);

  const initialValues = {
    description: jobDescription,
  };

  const handle = async (values) => {
    const { description } = values;
    const newObj = {
      description: jobDescription,
      request_id: request_id,
    };
    mutate(newObj);

    for (let i = 0; i < uploadedImages.length; i++) {
      const formData = new FormData();
      formData.append("id", localStorage.getItem("id"));
      formData.append("request_id", request_id);
      formData.append("token", localStorage.getItem("token"));
      formData.append("image", uploadedImages[i]);

      const userRequestImagesResult = await request_image_mutate(formData);
    }
  };

  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      description: yup.string().required("Enter the Description"),
    }),
    validateOnChange: true,
    validateOnBlur: false,

    onSubmit: async (values) => {
      handle(values);
    },
  });
  const { handleBlur, handleSubmit, errors, touched } = Formik;

  const [uploadedImages, setUploadedImages] = useState([]);

  const handleFileChange = (files) => {
    setUploadedImages(files);
  };

  return (
    <>
      <>
        <div className="flex flex-col items-center w-full  font-Trueno bg-[#EDF6FC]">
          <BookingNavbar color="#EDF6FC" />

          <div className="hidden md:block">
            <RightSideBar />
          </div>
          <div className="mt-5 md:mt-11">
            <h1 className="font-extrabold text-xl md:text-[24px] leading-[29.26px]">Edit your Service</h1>
          </div>
          <div className="">
            <div className="max-w-[880px] md:min-w-[880px] mx-auto my-10 md:my-20 h-full bg-white border rounded-2xl shadow-2xl">
              <div className="p-5 md:p-10">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-4 overflow-hidden font-normal text-xs md:text-[14px] leading-[16.8px]">
                    <label htmlFor="Email Address*" className="">
                      Your Date and Time
                    </label>

                    <option className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#DFDFDF] min-h-[46px] w-full pl-4 md:pl-7 py-4">
                      {request_date}
                    </option>
                    <label htmlFor="Email Address*" className="">
                      Current Address
                    </label>

                    <div className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#DFDFDF] min-h-[46px] w-full pl-4 md:pl-7 py-4">
                      {s_address}
                    </div>
                    <label htmlFor="Email Address*" className="">
                      Job Title *
                    </label>
                    <option className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#DFDFDF] min-h-[46px] w-full pl-4 md:pl-7 py-4">
                      {name}
                    </option>

                    <label htmlFor="Email Address*" className="">
                      Total Price *
                    </label>
                    <option className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#DFDFDF] min-h-[46px] w-full pl-4 md:pl-7 py-4">
                      Rs.
                      {price_per_hour}
                    </option>

                    <label htmlFor="Job Description" className="font-normal text-xs md:text-[14px] leading-[16.8px]">
                      Job Description
                    </label>

                    <textarea
                      type="text"
                      name="description"
                      id="description"
                      onBlur={handleBlur}
                      className="rounded-lg outline-none bg-[#DFDFDF] w-full h-[142px] py-4 pl-4 md:pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                      value={jobDescription}
                      onChange={(e) => setJobDescription(e.target.value)} // Update the selected address state variable when input changes
                    />
                    {errors.description && touched.description ? (
                      <p className="font-semibold text-sm text-red-600">{errors.description}</p>
                    ) : null}

                    <p className="font-normal text-[#DFDFDF] text-[14px] leading-[14.4px] text-right">100 Words Maximium</p>

                    {/* --------------------- Drag and Drop Area --------------------- */}

                    <div>
                      <label htmlFor="Upload Images*" className="font-normal text-[14px] leading-[16.8px]">
                        Upload Images
                      </label>

                      {/* <DropFileInput onFileChange={handleFileChange} /> */}

                      <DropFileEditBooking
                        onFileChange={handleFileChange}
                        fileList={fileList}
                        setFileList={setFileList}
                        request_id={request_id}
                        mutate_remove_user_request_images={mutate_remove_user_request_images}
                        data_remove_user_request_images={data_remove_user_request_images}
                      />
                    </div>
                    <div className="w-full flex items-center gap-4 justify-center">
                      <Link to="/my-jobs">
                        <button
                          type="button"
                          className="cursor-pointer rounded-xl border-2 border-slate-300 bg-[#DFDFDF] py-[10px] px-8 font-semibold text-black transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
                        >
                          Cancel
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="cursor-pointer rounded-lg bg-[#0973BA] py-[10px] px-8 font-semibold uppercase text-white transition duration-200 ease-in-out"
                      >
                        {isLoading ? (
                          <div
                            className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </div>
                        ) : (
                          "submit"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default EditBooking;
