import BookingNavBar from "views/partials/navbar/BookingNavbar";
import SettingFooter from "views/pages/settingsPage/SettingFooter";
import { MdContentCopy } from "react-icons/md";
import { useEffect, useState } from "react";
import { useProfile, useRedeem_reward, useRewards } from "hooks/useUser";
import { useDispatch } from "react-redux";
import { showErrorToast, showSuccessToast } from "store/features/toastSlice";
import ShareSocialLink from "./ShareSocialLink";
import SmallLoader from "views/loader/SmallLoader";
const InviteEarn = () => {
  const { data: profile_data } = useProfile();

  const {
    data: rewards_data,
    isLoading: reward_isLoading,
    refetch,
  } = useRewards();

  const {
    mutate: redeem_reward_mutate,
    isLoading: redeem_reward_isLoading,
    data: redeem_reward_data,
  } = useRedeem_reward();

  const [showAvailableRewards, setShowAvailableRewards] = useState(true);
  const [loadingItem, setLoadingItem] = useState("");

  const dispatch = useDispatch();

  const handleCopy = () => {
    navigator.clipboard
      .writeText(profile_data?.data?.referral_code)
      .then(() => {
        dispatch(showSuccessToast("Text copied to clipboard:"));
      })
      .catch((err) => {
        dispatch(showErrorToast(err));
      });
  };

  useEffect(() => {
    if (redeem_reward_data?.success) {
      refetch();
    } else {
      refetch();
    }
  }, [redeem_reward_data]);

  return (
    <>
      <div className="bg-[#F0F7FC] font-Trueno">
        <BookingNavBar color="#F0F7FC" />

        {reward_isLoading ? (
          <div className="w-[85%] mx-auto h-[500px] font-Trueno my-9 md:my-20  border rounded-[20px] shadow-2xl bg-white ">
            <SmallLoader />
          </div>
        ) : (
          <div className="gird grid-cols-1 md:grid md:grid-cols-2 w-[85%] mx-auto h-auto font-Trueno my-8 border rounded-[20px] shadow-2xl bg-white ">
            <div className="w-full mt-4 md:mt-0">
              <h1 className="font-bold text-[18px] leading-[21.6px] border-b border-[#DFDFDF] px-4 md:px-[38px] pt-8 pb-4">
                Invite & Earn
              </h1>

              <div className="w-full flex flex-col md:flex md:flex-row justify-between h-[79px] bg-[#FEF5D3] py-3 px-3 md:px-5">
                <div className="flex justify-between md:flex md:flex-col">
                  <p className="font-semibold leading-tight md:font-normal text-xs md:text-sm">
                    {showAvailableRewards
                      ? "Available Points"
                      : "Redeemed Points"}
                  </p>
                  <span className="flex items-center gap-2 text-[14px] md:text-[19px] text-[#F9B523] font-normal md:font-semibold">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/KarsaazCoin.svg`}
                      alt=""
                      className="max-w-[15px] max-h-[15px] object-cover"
                    />
                    {showAvailableRewards
                      ? rewards_data?.remaining_reward_points
                      : rewards_data?.used_reward_points}
                  </span>
                </div>
                <div className="flex justify-between md:flex md:flex-col font-normal">
                  <p className="font-semibold leading-tight md:font-normal text-xs md:text-sm">
                    {showAvailableRewards
                      ? "Estimated Cashback"
                      : "Redeemed Cashback"}
                  </p>
                  <span className="text-[#09BA70] text-[14px] md:text-[19px] font-normal md:font-semibold">
                    Rs.{" "}
                    {showAvailableRewards
                      ? rewards_data?.estimated_available_cashback
                      : rewards_data?.cashback?.[0]?.total_cashback_amount}
                  </span>
                </div>
              </div>

              {/* ------------------- coupans ------------------- */}
              <div className="w-full px-2 md:px-5">
                <div className="flex flex-col items-center gap-4">
                  <div className="flex justify-center pt-9 ">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/CashBackIcon.png`}
                      alt=""
                      className="max-w-[304px] max-h-[232.69px] object-cover"
                    />
                  </div>
                  <p className="max-w-[303px] text-center text-black text-[15px] md:text-base font-bold">
                    Invite your friends and Get Reward on each referrals
                  </p>
                  <p className="text-center text-black text-xs font-normal">
                    Your Referral link
                  </p>
                  <div className=" w-[90%] max-w-[400px] h-[46px] relative ">
                    <div className="w-full max-w-[400px] h-[46px] absolute bg-[#E4FAED] rounded-[15px] border-dashed border-2 border-[#09BA70]" />
                    <p
                      className="left-[20px] top-[11px] absolute text-[#09BA70] text-base font-bold"
                      href="karsaaz"
                    >
                      {profile_data?.data?.referral_code}
                    </p>

                    <div className="right-4 top-3  absolute">
                      <button
                        onClick={() => {
                          handleCopy();
                        }}
                        className="relative"
                      >
                        <MdContentCopy color="#09BA70" />
                      </button>
                    </div>
                  </div>
                  <div className="w-full flex flex-col items-center gap-2 pb-6">
                    <p className=" text-black text-[15px] md:text-base font-bold">
                      Share Link via
                    </p>
                    <ShareSocialLink
                      url={`Hi, I'm using Karsaaz App to book the Most Reliable and Affordable home repair & maintenance professionals, Beauticians & Tailors with home service. I highly recommend that you should also use Karsaaz App.
                    
                    Here is the link to visit website and download it from Android and iPhone. 

                    Website: https://karsaaz.app

                    Android: https://play.google.com/store/apps/details?id=com.KarsaazPro.app 

                    iOS : https://apps.apple.com/pk/app/karsaaz-at-home-services/id1568292047 
              
                    
                    Also use my reffer code to get 500 reward points: ${profile_data?.data?.referral_code}
                    `}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* ------------------- Second Screen ------------------- */}
            <div className="border-l w-full mt-4 md:mt-0">
              <h1 className="font-bold text-[18px] leading-[21.6px] border-b border-[#DFDFDF] px-4 md:px-[38px] pt-4 md:pt-8 pb-4">
                Points History
              </h1>

              <div className="px-2 md:px-5 py-4 grid md:flex gap-3 justify-between">
                <div className="flex gap-1 md:gap-3">
                  <button
                    type="button"
                    onClick={() => {
                      setShowAvailableRewards(true);
                    }}
                    className={`px-2 md:px-4 py-1 rounded-3xl text-xs md:text-[15px] font-semibold ${
                      showAvailableRewards === true
                        ? "bg-[#0973BA]  text-white "
                        : "bg-white text-black border border-black rounded-xl px-[22px]"
                    }`}
                  >
                    Available Rewards
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      setShowAvailableRewards(false);
                    }}
                    className={`px-2 md:px-4 py-1 rounded-3xl text-xs md:text-[15px] font-semibold ${
                      showAvailableRewards === false
                        ? "bg-[#0973BA]  text-white "
                        : "bg-white text-black border border-black rounded-xl px-[22px]"
                    }`}
                  >
                    Past Rewards
                  </button>
                </div>
                <div className="px-2 md:px-0 flex items-center gap-1">
                  <h3 className="text-xs md:text-[14px] font-semibold">
                    Total Available Balance:
                  </h3>
                  <span className="text-[#0973BA] text-xs md:text-[14px] font-semibold">
                    {rewards_data?.cashback?.[0]?.available_cashback_amount}
                  </span>
                </div>
              </div>

              {showAvailableRewards ? (
                <div className="max-h-[700px] overflow-y-auto">
                  {rewards_data?.rewards?.map((item, index) => (
                    <div
                      key={index}
                      className="md:flex md:flex-row flex flex-col gap-3 justify-between p-4 border-b border-[#DFDFDF] py-2"
                    >
                      <div className="w-full px-0 md:px-2 flex items-center">
                        <div className="w-full flex flex-col md:gap-4 gap-2 px-0 md:px-2">
                          <p className="flex  items-center gap-1 font-bold leading-[16.8px] text-xs md:text-[14px] w-auto ">
                            Rewards Name:{" "}
                            <span className="text-[#0973BA]">
                              {item?.request_title}
                            </span>
                          </p>
                          <div className="flex items-center gap-1">
                            <span className="text-[#F9B523] text-xs md:text-[14px] font-semibold">
                              Reward Points:
                            </span>
                            <div className="flex items-center gap-2">
                              <img
                                src={`${process.env.PUBLIC_URL}/images/KarsaazCoin.svg`}
                                alt=""
                                className="max-w-[15px] max-h-[15px] object-cover"
                              />
                              <span className="text-[#F9B523] font-bold text-sm md:text-base">
                                {item?.points}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          setLoadingItem(item?.id);
                          const payload = {
                            id: localStorage.getItem("id"),
                            token: localStorage.getItem("token"),
                            reward_id: item?.id,
                          };
                          redeem_reward_mutate(payload);
                        }}
                        className="h-fit w-fit  px-4 border-[#09BA70] border rounded-2xl text-[#09BA70] font-normal text-xs md:text-[14px]"
                      >
                        {redeem_reward_isLoading && item?.id === loadingItem ? (
                          <div
                            className="inline-block h-4 w-4 mx-4 my-1 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </div>
                        ) : (
                          "Redeem"
                        )}
                      </button>
                    </div>
                  ))}

                  {rewards_data?.rewards?.length === 0 ? (
                    <div className="text-center my-16">No details found</div>
                  ) : null}
                </div>
              ) : (
                <div className="max-h-[700px] overflow-y-auto">
                  {rewards_data?.redeemed_rewards?.map((item, index) => (
                    <div
                      key={index}
                      className="md:flex md:flex-row flex flex-col gap-3 justify-between px-2 md:px-4 py-4 border-b border-[#DFDFDF]  "
                    >
                      <div className="h-auto px-2 flex items-center justify-center">
                        <div className="w-full flex flex-col md:gap-4 gap-2">
                          <p className="flex  items-center gap-1 font-bold leading-[16.8px] text-xs md:text-[14px] w-auto ">
                            Rewards Name:{" "}
                            <span className="text-[#0973BA]">
                              {item?.request_title}
                            </span>
                          </p>
                          <div className="flex items-center gap-1">
                            <span className="text-[#F9B523] text-xs md:text-[14px] font-semibold">
                              Reward Points:
                            </span>
                            <div className="flex items-center gap-2 justify-between md:justify-start">
                              <img
                                src={`${process.env.PUBLIC_URL}/images/KarsaazCoin.svg`}
                                alt=""
                                className="max-w-[15px] max-h-[15px] object-cover"
                              />
                              <span className="text-[#F9B523] font-bold text-sm md:text-base">
                                {item?.points}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        type="button"
                        className="h-fit w-fit px-4 border-[#09BA70] border rounded-2xl text-[#09BA70] font-normal text-xs md:text-[14px]"
                      >
                        Redeemed
                      </button>
                    </div>
                  ))}

                  {rewards_data?.redeemed_rewards?.length === 0 ? (
                    <div className="text-center my-16">No details found</div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        )}
        <SettingFooter />
      </div>
    </>
  );
};

export default InviteEarn;
