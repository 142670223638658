import {
  useCod_payment,
  usePaymob_auth_token,
  usePaymob_order_registration,
  usePaymob_paymob_card_payment_key,
  usePost_paymob_response,
} from "hooks/useAuth";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";

import Cashback from "views/cashback/Cashback";

const PaymentConfirmation = ({ single_request, refetch }) => {
  const [step, setStep] = useState(1);
  const [payment_method, setPayment_method] = useState("");

  const integration_id =
    payment_method === "card"
      ? process.env.REACT_APP_PAYMOB_CARD_ID
      : payment_method === "jazz-cash"
      ? process.env.REACT_APP_PAYMOB_JAZZCASH_ID
      : payment_method === "easy-paisa"
      ? process.env.REACT_APP_PAYMOB_EASYPAISA_ID
      : process.env.REACT_APP_PAYMOB_CARD_ID;

  const handleNext = () => {
    setStep(step + 1);
  };

  const {
    data: paymob_auth_token_data,
    mutateAsync: paymob_auth_token_mutate,
    isLoading: paymob_auth_token_isLoading,
  } = usePaymob_auth_token();
  const {
    data: order_registration_data,
    mutateAsync: order_registration_mutate,
    isLoading: order_registration_isLoading,
  } = usePaymob_order_registration();
  const {
    mutateAsync: card_payment_key_mutate,
    isLoading: card_payment_key_isLoading,
  } = usePaymob_paymob_card_payment_key();

  const {
    mutate: post_paymob_response_mutate,
    isLoading: post_paymob_response_isLoading,
    data: post_paymob_response_data,
  } = usePost_paymob_response();

  const {
    mutate: cod_mutate,
    isLoading: cod_isLoading,
    data: cod_data,
  } = useCod_payment();

  const handle_auth_token = () => {
    const payload = {
      api_key: process.env.REACT_APP_PAYMOB_API_KEY,
    };
    paymob_auth_token_mutate(payload);
  };

  useEffect(() => {
    if (paymob_auth_token_data?.token) {
      const payload = {
        auth_token: paymob_auth_token_data?.token,
        delivery_needed: "false",
        amount_cents: single_request?.data?.discounted_total
          ? single_request?.data?.discounted_total * 100
          : single_request?.data?.price_per_hour * 100,
        currency: "PKR",
        // merchant_order_id: "",
        items: [
          {
            name: single_request?.data?.name,
            amount_cents: single_request?.data?.discounted_total
              ? single_request?.data?.discounted_total * 100
              : single_request?.data?.price_per_hour * 100,
            description: `request_id ${single_request?.data?.request_id}`,
            quantity: "1",
          },
        ],
      };
      order_registration_mutate(payload);
      handleNext();
    }
  }, [paymob_auth_token_data]);

  const initialValues = {
    payment: "",
  };
  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      payment: yup.string().required("Please Select the Payment Term"),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (order_registration_data?.token) {
        const payload = {
          auth_token: paymob_auth_token_data?.token,
          amount_cents: order_registration_data?.amount_cents,
          expiration: 3600,
          order_id: paymob_auth_token_data?.id,
          billing_data: {
            apartment: 195,
            email: "finance@helpareus.com",
            floor: 0,
            first_name: single_request?.data?.user_name,
            street: "Street 4",
            building: "195 G3",
            phone_number: paymob_auth_token_data?.profile?.primary_phone_number,
            shipping_method: "PKG",
            postal_code: 51000,
            city: "Lahore",
            country: "PK",
            last_name: single_request?.data?.user_name,
            state: "Punjab",
          },
          currency: "PKR",
          integration_id: integration_id,
        };
        card_payment_key_mutate(payload).then((res) => {
          if (res.token) {
            if (payment_method === "card") {
              window.location.href = `https://pakistan.paymob.com/api/acceptance/iframes/131339?payment_token==${res.token}`;
            } else if (payment_method === "cash") {
              const payload = {
                id: localStorage.getItem("id"),
                token: localStorage.getItem("token"),
                request_id: localStorage.getItem("request_id"),
              };
              cod_mutate(payload);
            } else {
              window.location.href = `https://pakistan.paymob.com/iframe/${res.token}`;
            }
          }
        });
      }
    },
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const payload = {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      request_id: localStorage.getItem("request_id"),
      user_type: "user",
      transaction_id: params.get("id"),
      pending: params.get("pending") === "true" ? 1 : 0,
      amount: params.get("amount_cents") / 100,
      success: params.get("success") === "true" ? 1 : 0,
      integration_id: params.get("integration_id"),
      profile_id: params.get("profile_id"),
      order: params.get("order"),
      currency: params.get("currency"),
      merchant_commission: params.get("merchant_commission"),
      discount_details: encodeURI(params.getAll("discount_details")),
      error_occurred: params.get("error_occured"),
      is_settled: params.get("is_settled") === "true" ? 1 : 0,
      owner: params.get("owner"),
      data_message: params.get("data.message"),
      source_data_type: params.get("source_data.type"),
      source_data_pan: params.get("source_data.pan"),
      source_data_card_num: params.get("source_data.card_num")
        ? params.get("source_data.card_num")
        : params.get("source_data.pan"),
      source_data_sub_type: params.get("source_data.sub_type"),
      txn_response_code: params.get("txn_response_code"),
      hmac: params.get("hmac"),
    };
    if (params.get("success") === "true" || params.get("success") === "false") {
      post_paymob_response_mutate(payload);
    } else {
      post_paymob_response_mutate(payload);
    }
  }, []);
  const { values, handleBlur, handleSubmit, errors, touched, setFieldValue } =
    Formik;

  return (
    <>
      {step === 1 && (
        <div className="w-full font-Trueno">
          <h2 className="font-normal text-[18px] leading-[21.6px] my-6">
            Payment Confirmation
          </h2>
          <div className="max-w-[700px] flex flex-col gap-3 border border-[#DFDFDF] rounded-xl py-6 md:py-8 px-4 md:px-6">
            <div className="grid md:flex gap-2 justify-between">
              <div className="grid md:flex items-center gap-2">
                <h2 className="font-semibold text-[14px] leading-[19.5px] cursor-pointer">
                  {" "}
                  Total Amount needs to pay:{" "}
                </h2>

                <p className="font-semibold text-[16px] leading-[14.4px] text-red-600">
                  Rs.{" "}
                  {single_request?.data?.discounted_total
                    ? single_request?.data?.discounted_total
                    : single_request?.data?.price_per_hour}
                </p>
              </div>
              <button
                type="button"
                onClick={() => {
                  handle_auth_token();
                }}
                className="font-normal text-[12px] leading-[14.4px] text-white bg-[#FF4E4E] rounded-lg p-3 w-fit px-6 md:px-8 justify-self-end"
              >
                {paymob_auth_token_isLoading ? (
                  <p>please wait...</p>
                ) : (
                  "Pay Now"
                )}
              </button>
            </div>
            <Cashback single_request={single_request} refetch={refetch} />
          </div>
        </div>
      )}
      {step === 2 && (
        <form onSubmit={handleSubmit}>
          <div className="w-full max-w-[700px]">
            <h2 className="font-normal text-[18px] leading-[21.6px] my-6">
              Mode Of Payments
            </h2>
            <div className="w-full space-y-2 mt-4">
              <label
                className={`flex items-center justify-between  font-normal text-[12px] leading-[14.4px] border rounded-lg p-3 w-full h-[97.88px] ${
                  payment_method === "jazz-cash"
                    ? "bg-[#f2f2f2] "
                    : "bg-Primary bg-opacity-[0.1] text-Primary"
                }`}
              >
                <span className="w-full flex gap-3 items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/jazz-cash.png`}
                    alt=""
                    className="object-cover h-full w-full max-h-[92px] max-w-[92px]"
                  />
                  Jazz Cash
                </span>

                <input
                  className="h-5 w-5 text-gray-600"
                  type="radio"
                  id="jazz-cash"
                  name="card"
                  value="jazz-cash"
                  checked={values.payment === "jazz-cash"}
                  onChange={(e) => {
                    setFieldValue("payment", e.target.value);
                    setPayment_method("jazz-cash");
                  }}
                  onBlur={handleBlur}
                />
              </label>
              <label
                className={`flex items-center justify-between  font-normal text-[12px] leading-[14.4px] border rounded-lg p-3 w-full ${
                  payment_method === "easy-paisa"
                    ? "bg-[#f2f2f2] "
                    : "bg-Primary bg-opacity-[0.1] text-Primary"
                }`}
              >
                <span className="w-full flex gap-3 items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/easy-paisa.png`}
                    alt=""
                    className="object-cover h-auto max-h-[58px] max-w-[58px]"
                  />
                  Easy Paisa
                </span>

                <input
                  className="h-5 w-5 text-gray-600"
                  type="radio"
                  id="easy-paisa"
                  name="card"
                  value="easy-paisa"
                  checked={values.payment === "easy-paisa"}
                  onChange={(e) => {
                    setFieldValue("payment", e.target.value);
                    setPayment_method("easy-paisa");
                  }}
                  onBlur={handleBlur}
                />
              </label>
              <label
                className={`flex items-center justify-between  font-normal text-[12px] leading-[14.4px] border rounded-lg p-3 w-full ${
                  payment_method === "card"
                    ? "bg-[#f2f2f2] "
                    : "bg-Primary bg-opacity-[0.1] text-Primary"
                }`}
              >
                <span className="w-full flex gap-3 items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/credit-debit.svg`}
                    alt=""
                    className="object-cover h-auto max-h-[72px] max-w-[72px]"
                  />
                  Debit/ Credit
                </span>

                <input
                  className="h-5 w-5 text-gray-600"
                  type="radio"
                  id="card"
                  name="card"
                  value="card"
                  checked={values.payment === "card"}
                  onChange={(e) => {
                    setFieldValue("payment", e.target.value);
                    setPayment_method("card");
                  }}
                  onBlur={handleBlur}
                />
              </label>
              <label
                className={`flex items-center justify-between  font-normal text-[12px] leading-[14.4px] border rounded-lg p-3 w-full ${
                  payment_method === "cash"
                    ? "bg-[#f2f2f2] "
                    : "bg-Primary bg-opacity-[0.1] text-Primary"
                }`}
              >
                <span className="w-full flex gap-3 items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/cash-on-hand.png`}
                    alt=""
                    className="object-cover h-auto max-h-[72px] max-w-[72px]"
                  />
                  Cash Payment
                </span>

                <input
                  className="h-5 w-5 text-gray-600"
                  type="radio"
                  id="cash"
                  name="card"
                  value="cash"
                  checked={values.payment === "cash"}
                  onChange={(e) => {
                    setFieldValue("payment", e.target.value);
                    setPayment_method("cash");
                  }}
                  onBlur={handleBlur}
                />
              </label>

              {errors.payment && touched.payment ? (
                <p className="font-semibold text-sm text-red-600">
                  {errors.payment}
                </p>
              ) : null}

              {cod_data?.success === true ? (
                <p className="font-semibold text-sm text-red-600 my-3">
                  Waiting provider to confirm the payment
                </p>
              ) : null}

              <div className="w-full flex justify-end">
                <button
                  type="submit"
                  className="font-normal text-[12px] leading-[14.4px] text-white bg-[#FF4E4E] rounded-lg p-3 w-full max-w-[140px]"
                >
                  {order_registration_isLoading ||
                  card_payment_key_isLoading ||
                  cod_isLoading ||
                  post_paymob_response_isLoading ? (
                    <p>please wait...</p>
                  ) : (
                    "Pay Now"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default PaymentConfirmation;
