import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

function GlobalToast() {
  const toastData = useSelector(({ toast }) => toast);

  useEffect(() => {
    if (toastData.isShowError)
      toast.error(toastData.error, { position: "top-center" });

    if (toastData.isShowSuccess) toast.success(toastData.success);
  }, [toastData]);

  return (
    <>
      <ToastContainer />
    </>
  );
}

export default GlobalToast;
