import { FaFacebook, FaYoutube, FaLinkedin } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import { useState, useEffect } from "react";
import axios from "axios";

const baseURL = "https://karsaaz.app/api/common/env_verification_values";


const FollowUsOn = (props) => {

  const [data, setData] = useState([]);

  const getApi = () => {
    axios
      .get(baseURL)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getApi();
  }, []);


  const MobileNumber = data?.data?.karsaaz_mobile

  const Twitter = () => (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50">
      <path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z" fill="white"></path>
    </svg>
  );

  const icons = [FaFacebook, FaYoutube, Twitter, FaLinkedin, AiFillInstagram, FaTiktok, RiCustomerService2Fill];
  const names = ["Facebook", "YouTube", "X", "LinkedIn", "Instagram", "Tiktok", MobileNumber];
  const links = [
    "https://www.facebook.com/officialkarsaaz",
    "https://www.youtube.com/@karsaaz",
    "https://twitter.com/Karsaaz_App",
    "https://www.linkedin.com/company/karsaaz-app",
    "https://www.instagram.com/karsaazofficial/",
    "https://www.tiktok.com/@karsaaz.app",
    `tel:${MobileNumber}`,
  ];

  return (
    <>
      <div className="flex flex-col w-full font-Roboto text-white">
        <div>
          <h3 className={`font-bold not-italic text-[18.4592px] leading-[22px] ${props.styling} text-white`}>Follow us</h3>
          <div className="mt-4">
            <ul className={`flex flex-col rounded-full ${props.contentStyling}`}>
              {icons.map((Icon, index) => (
                <li style={{ backgroundColor: props.color }} className="flex items-center py-3" key={index}>
                  <a href={links[index]} target="_blank" rel="noreferrer" className="flex items-center gap-3">
                    <Icon color="white" className="w-[25px] h-[25px]" />
                    <span className="text-white text-sm ml-2">{names[index]}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowUsOn;
