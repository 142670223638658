import { MdPerson } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import { BiTime } from "react-icons/bi";
import { RiCalendarEventLine } from "react-icons/ri";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { MdVerifiedUser } from "react-icons/md";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsFillHeartFill } from "react-icons/bs";
import { useAdd_fav_provider } from "hooks/useUser";
import { useNavigate } from "react-router-dom";

const ProfessionalDetails = ({ details, setShowProviderDetails }) => {
  const [isOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  const { mutate, isLoading } = useAdd_fav_provider();
  const navigate = useNavigate();

  const handle_ad_fav_provider = (details) => {
    const payload = {
      provider_id: details?.provider_id,
      user_id: localStorage.getItem("id"),
    };

    mutate(payload);
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const starElements = [];

    for (let i = 0; i < fullStars; i++) {
      starElements.push(<AiFillStar key={`full-star-${i}`} color="#F9B523" height="16.28px" width="17.12px" />);
    }

    if (hasHalfStar) {
      starElements.push(<AiFillStar key="half-star" color="#F9B523" height="16.28px" width="17.12px" />);
    }

    const remainingStars = 5 - Math.ceil(rating); // Calculate the remaining empty stars
    for (let i = 0; i < remainingStars; i++) {
      starElements.push(<AiOutlineStar key={`empty-star-${i}`} color="#828282" height="16.28px" width="17.12px" />);
    }

    return starElements;
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <>
      <div className="w-full font-Trueno bg-[#AEE0FF]">
        <div className="fixed inset-0 overflow-y-auto scrollbar-hide bg-black bg-opacity-25 backdrop-blur-sm px-3 md:px-9">
          <div className="md:w-[80%] h-auto mx-auto bg-white px-3 md:px-6 my-9 border rounded-[15px] relative">
            <button
              className=" font-bold uppercase  py-2 text-sm outline-none focus:outline-none mb-1 absolute right-5 md:right-9 top-7"
              type="button"
            >
              <RxCross2
                onClick={() => {
                  setShowProviderDetails(false);
                }}
                className="w-[14px] md:w-[22px] h-[14px] md:h-[22px]"
              />
            </button>
            <div className="py-8">
              <h1 className="font-semibold text-[16px] md:text-[24px] leading-[19.5px] md:leading-[26.82px]">
                Professional Details
              </h1>
            </div>
            <div className="w-full grid md:flex gap-4 items-center justify-between">
              <div className="grid md:flex gap-4 items-center">
                {details?.provider_picture === "" || details?.provider_picture === "https://stage.karsaaz.app/placeholder.png" ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/EditProfileIcon.png`}
                    alt=""
                    className="rounded-full h-[121px] w-[121px] object-cover"
                  />
                ) : (
                  <img
                    src={details?.provider_picture}
                    // src={`${process.env.PUBLIC_URL}/images/FavoriteProviderIcon.png`}
                    className="w-[116px] h-[116px] rounded-[15px] object-cover"
                    alt=""
                  />
                )}

                <div className="flex flex-col gap-3">
                  <div className="flex justify-between mt-3 md:mt=0">
                    <h1 className="grid-flex-col-reverse md:flex gap-1 md:gap-4 items-center font-semibold text-[18px] leading-[21.94px]">
                      {details?.provider_name}
                      {details?.provider_verified === "yes" ? (
                        <span className="flex gap-1 md:gap-[6.67px] justify-start md:justify-between items-center rounded-[12px] text-white bg-[#09BA70] font-medium text-[12px] leading-[28px] px-2 h-[25px]">
                          <MdVerifiedUser height="15.08px" width="12.67px" />
                          Verified
                        </span>
                      ) : null}
                    </h1>
                  </div>
                  <div className="flex items-center gap-1">
                    {details?.avg_rating && renderStars(parseFloat(details?.avg_rating))}
                    <span className="font-normal text-[14px] leading-[16.8px]">
                      {details?.avg_rating && parseFloat(details?.avg_rating).toFixed(1)}
                    </span>
                    <span className="font-normal text-sm text-[#979797]">({details?.ratings?.length} reviews)</span>
                  </div>
                  <div className="flex gap-2">
                    <p className="font-normal text-[12px] leading-[14.4px]">
                      Completed Jobs:
                      <label className="font-semibold text-[12px] leading-[14.4px]">{details?.total_projects}</label>
                    </p>
                    {/* <label className="font-semibold text-[12px] leading-[14.4px]">|</label>
                    <p className="flex font-normal text-[12px] leading-[14.4px]">
                      Away From:
                      <label className="flex gap-1 items-center font-semibold text-[12px] leading-[14.4px]">
                        <MdLocationPin color="#0973BA" width="14px" height="14px" />
                        15 km
                      </label>
                    </p> */}
                  </div>
                </div>
              </div>

              <div className={`${details?.is_favorite === "yes" ? "hidden" : "block"}`}>
                <button
                  onClick={() => {
                    handle_ad_fav_provider(details);
                  }}
                  className="min-w-[115px] w-fit h-[38px] items-center px-2 md:px-5 py-2 border rounded-lg border-[#FF4E4E] font-bold text-[12px] leading-[23px] text-[#FF4E4E]"
                >
                  {isLoading ? (
                    <div
                      className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                  ) : (
                    <div className="flex gap-2 items-center">
                      <BsFillHeartFill width="20px" height="20px" />
                      Add Favorite
                    </div>
                  )}
                </button>
              </div>
              {/* ----------------------------- About Section ----------------------------- */}
            </div>
            <div className={`${isOpen ? "flex" : "flex"} md:w-64`}>
              <ul className={`${isOpen ? "block" : "flex"}  py-5 md:py-7 pl-5 gap-9 font-semibold text-[18px] leading-[21.94px]`}>
                <button
                  className={activeTab === 1 ? "active" : ""}
                  onClick={() => handleTabClick(1)}
                  style={{
                    color: activeTab === 1 ? "#0973BA" : "black",
                    borderColor: activeTab === 1 ? "#0973BA" : "",
                    borderBottom: activeTab === 1 ? "solid" : "",
                    lineHeight: "25px",
                    width: "",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span className="font-normal text-[16px] leading-[19.2px]">About</span>
                </button>

                <button
                  className={activeTab === 2 ? "active" : ""}
                  onClick={() => handleTabClick(2)}
                  style={{
                    color: activeTab === 2 ? "#0973BA" : "black",
                    borderColor: activeTab === 2 ? "#0973BA" : "",
                    borderBottom: activeTab === 2 ? "solid" : "",
                    lineHeight: "25px",
                    width: "",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span className="font-normal text-[16px] leading-[19.2px]">Reviews</span>
                </button>
              </ul>
            </div>

            {activeTab === 1 && (
              <div>
                {/* --------------------- category section --------------------- */}
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 py-6">
                  <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px]">
                    <div className="bg-[#60C3EA] bg-opacity-[0.12] p-4 rounded-lg">
                      <MdPerson className="w-[16px] h-[16px]" color="#60C3EA" />
                    </div>
                    <div>
                      <h1 className="font-normal text-[12px] leading-[14.4px]">Type</h1>
                      {details?.isindividual === "yes" ? (
                        <h1 className="font-semibold text-[14px] leading-[19.5px]">Individual</h1>
                      ) : (
                        <h1 className="font-semibold text-[14px] leading-[19.5px]">no</h1>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px]">
                    <div className="bg-[#FFE7C5] bg-opacity-[0.12] p-4 rounded-lg">
                      <img
                        className="w-[16px] h-[16px]"
                        color="#F9A000"
                        src={`${process.env.PUBLIC_URL}/images/RequestTypeIcon.png`}
                        alt=""
                      />
                    </div>
                    <div>
                      <h1 className="font-normal text-[12px] leading-[14.4px]">Experience</h1>
                      <h1 className="font-semibold text-[14px] leading-[19.5px]">{details?.experience} Year</h1>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px]">
                    <div className="bg-[#F1755A] bg-opacity-[0.15] p-4 rounded-lg">
                      <FaBuilding className="w-[16px] h-[16px]" color="#F1755A" />
                    </div>
                    <div>
                      <h1 className="font-normal text-[12px] leading-[14.4px]">Office or Shop</h1>
                      <h1 className="font-semibold text-[14px] leading-[19.5px]">No</h1>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px]">
                    <div className="bg-[#FF4E4E] bg-opacity-[0.12] p-4 rounded-lg">
                      <svg width="31" height="26" viewBox="0 0 31 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1348_15928)">
                          <path
                            d="M24.3204 14.2676C24.3253 14.6756 24.1778 14.9692 23.84 15.2237C22.5386 16.2057 21.0226 16.7778 19.3999 17.1024C15.3549 17.9105 11.3214 17.9061 7.3336 16.8C6.13697 16.4682 5.03156 15.9529 4.07949 15.1883C3.79125 14.9576 3.6612 14.6995 3.66411 14.3412C3.67867 12.3773 3.6709 10.4135 3.6709 8.37427C5.32853 8.93308 6.92987 9.4715 8.53121 10.0126C10.119 10.5501 11.7096 11.0823 13.2916 11.634C13.7244 11.7857 14.1252 11.7919 14.559 11.6473C17.6365 10.6255 20.7179 9.61164 23.7973 8.59513C24.1409 8.48219 24.3127 8.58538 24.3127 8.9047C24.3127 10.6929 24.303 12.4802 24.3204 14.2676Z"
                            fill="#F7F9FA"
                          />
                          <path
                            d="M13.5769 9.53002C9.20862 8.04606 4.83745 6.5683 0.467245 5.08877C0.376987 5.05772 0.283818 5.03111 0.197443 4.99297C0.112038 4.95572 0.00819383 4.92467 0.000429758 4.81823C-0.00830482 4.68341 0.117861 4.65591 0.218794 4.61599C0.337196 4.56987 0.459481 4.53173 0.580794 4.49181C4.97817 3.03801 9.37555 1.5842 13.7739 0.13039C13.9098 0.0851528 14.0466 0.0425764 14.1796 0C14.3116 0.0408024 14.4348 0.0762828 14.5552 0.116198C18.9981 1.58508 23.4411 3.05308 27.8832 4.52641C28.0394 4.57874 28.2888 4.57697 28.3005 4.77299C28.3141 5.00805 28.0423 5.00184 27.8754 5.05684C23.3567 6.55056 18.8351 8.03719 14.3174 9.53446C14.0553 9.6205 13.8331 9.61784 13.5769 9.53002Z"
                            fill="#F7F9FA"
                          />
                          <path
                            d="M30.4075 22.7483C30.4075 23.5892 30.457 24.4061 30.3891 25.2151C30.3483 25.7003 29.7767 26.0267 29.2555 25.9983C28.7402 25.9708 28.2442 25.6151 28.2209 25.1565C28.1802 24.3697 28.2093 23.5794 28.2093 22.7669C29.2089 23.4606 29.4758 23.4588 30.4075 22.7483Z"
                            fill="#F7F9FA"
                          />
                          <path
                            d="M28.1836 7.50132C28.1836 7.24142 28.2535 7.10926 28.5417 7.02765C29.1687 6.84848 29.7801 6.6285 30.4264 6.4165V15.7186C29.6889 15.0258 28.959 14.9637 28.2069 15.7203C28.1982 15.5536 28.1904 15.4702 28.1904 15.3877C28.1904 12.7586 28.1933 10.1295 28.1836 7.50132Z"
                            fill="#F7F9FA"
                          />
                          <path
                            d="M31.0019 19.2347C30.9398 19.2959 30.8796 19.358 30.8156 19.4165C30.3827 19.813 29.9402 20.2015 29.52 20.6086C29.3579 20.7656 29.2618 20.763 29.1026 20.6104C28.6795 20.2059 28.2457 19.8095 27.8031 19.4218C27.6478 19.2861 27.5983 19.1983 27.7818 19.0395C28.2389 18.6448 28.6746 18.2297 29.1211 17.8252C29.2045 17.7498 29.2783 17.6194 29.4307 17.7605C29.9169 18.212 30.4099 18.6581 30.9 19.1069C30.933 19.1371 30.9563 19.1761 31.0019 19.2347Z"
                            fill="#F7F9FA"
                          />
                          <path
                            d="M28.3005 4.77299C28.3141 5.00805 28.0423 5.00184 27.8754 5.05684C23.3567 6.55056 18.8351 8.03719 14.3174 9.53446C14.0553 9.6205 13.8331 9.61784 13.5769 9.53002C9.20862 8.04606 4.83745 6.5683 0.467245 5.08877C0.376987 5.05772 0.283818 5.03111 0.197443 4.99297C0.112038 4.95572 0.00819383 4.92467 0.000429758 4.81823C-0.00830482 4.68341 0.117861 4.65591 0.218794 4.61599C0.337196 4.56987 0.459481 4.53173 0.580794 4.49181C4.97817 3.03801 9.37555 1.5842 13.7739 0.13039C13.9098 0.0851528 14.0466 0.0425764 14.1796 0C14.3116 0.0408024 14.4348 0.0762828 14.5552 0.116198C18.9981 1.58508 23.4411 3.05308 27.8832 4.52641C28.0394 4.57874 28.2888 4.57697 28.3005 4.77299Z"
                            fill="#FF4E4E"
                          />
                          <path
                            d="M24.3204 14.2676C24.3253 14.6756 24.1778 14.9692 23.84 15.2237C22.5386 16.2057 21.0226 16.7778 19.3999 17.1024C15.3549 17.9105 11.3214 17.9061 7.3336 16.8C6.13697 16.4682 5.03156 15.9529 4.07949 15.1883C3.79125 14.9576 3.6612 14.6995 3.66411 14.3412C3.67867 12.3773 3.6709 10.4135 3.6709 8.37427C5.32853 8.93308 6.92987 9.4715 8.53121 10.0126C10.119 10.5501 11.7096 11.0823 13.2916 11.634C13.7244 11.7857 14.1252 11.7919 14.559 11.6473C17.6365 10.6255 20.7179 9.61164 23.7973 8.59513C24.1409 8.48219 24.3127 8.58538 24.3127 8.9047C24.3127 10.6929 24.303 12.4802 24.3204 14.2676Z"
                            fill="#FF4E4E"
                          />
                          <path
                            d="M30.4264 6.4165V15.7186C29.6889 15.0258 28.959 14.9637 28.2069 15.7203C28.1982 15.5536 28.1904 15.4702 28.1904 15.3877C28.1904 12.7586 28.1933 10.1295 28.1836 7.50132C28.1836 7.24142 28.2535 7.10926 28.5417 7.02765C29.1687 6.84848 29.7801 6.6285 30.4264 6.4165Z"
                            fill="#FF4E4E"
                          />
                          <path
                            d="M30.3891 25.2151C30.3483 25.7003 29.7767 26.0267 29.2555 25.9983C28.7402 25.9708 28.2442 25.6151 28.2209 25.1565C28.1802 24.3697 28.2093 23.5794 28.2093 22.7669C29.2089 23.4606 29.4758 23.4588 30.4075 22.7483C30.4075 23.5892 30.457 24.4061 30.3891 25.2151Z"
                            fill="#FF4E4E"
                          />
                          <path
                            d="M31.0019 19.2347C30.9398 19.2959 30.8796 19.358 30.8156 19.4165C30.3827 19.813 29.9402 20.2015 29.52 20.6086C29.3579 20.7656 29.2618 20.763 29.1026 20.6104C28.6795 20.2059 28.2457 19.8095 27.8031 19.4218C27.6478 19.2861 27.5983 19.1983 27.7818 19.0395C28.2389 18.6448 28.6746 18.2297 29.1211 17.8252C29.2045 17.7498 29.2783 17.6194 29.4307 17.7605C29.9169 18.212 30.4099 18.6581 30.9 19.1069C30.933 19.1371 30.9563 19.1761 31.0019 19.2347Z"
                            fill="#FF4E4E"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1348_15928">
                            <rect width="31" height="26" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div>
                      <h1 className="font-normal text-[12px] leading-[14.4px]">Qualification</h1>
                      <h1 className="font-semibold text-[14px] leading-[19.5px]">{details?.qualification}</h1>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px] md:max-w-full">
                    <div className="bg-[#00997D] bg-opacity-[0.12] p-4 rounded-lg">
                      <BiTime color="#09BA5A" height="30px" width="30px" />
                    </div>
                    <div>
                      <h1 className="font-normal text-[12px] leading-[14.4px]">Working hours</h1>
                      <h1 className="font-semibold text-[14px] leading-[19.5px]">{details?.working_from} </h1>
                      <h1 className="font-semibold text-[14px] leading-[19.5px]">{details?.working_to} </h1>
                    </div>
                  </div>

                  <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2  max-w-[345px] md:max-w-full">
                    <div className="bg-[#8D0099] bg-opacity-[0.12] p-4 rounded-lg">
                      <RiCalendarEventLine color="#8D0099" height="30px" width="30px" />
                    </div>
                    <div>
                      <h1 className="font-normal text-[12px] leading-[14.4px]">Working Days</h1>
                      <div className="flex gap-[1px] md:gap-[2px]">
                        {details?.working_days?.map((item, index) => (
                          <span key={index}>
                            {item?.day_id === 1 ? (
                              <h1 className="font-semibold text-[14px] leading-[19.5px]">Mon,</h1>
                            ) : item?.day_id === 2 ? (
                              <h1 className="font-semibold text-[14px] leading-[19.5px]">Tue,</h1>
                            ) : item?.day_id === 3 ? (
                              <h1 className="font-semibold text-[14px] leading-[19.5px]">Wed,</h1>
                            ) : item?.day_id === 4 ? (
                              <h1 className="font-semibold text-[14px] leading-[19.5px]">Thu,</h1>
                            ) : item?.day_id === 5 ? (
                              <h1 className="font-semibold text-[14px] leading-[19.5px]">Fri,</h1>
                            ) : item?.day_id === 6 ? (
                              <h1 className="font-semibold text-[14px] leading-[19.5px]">Sat,</h1>
                            ) : item?.day_id === 7 ? (
                              <h1 className="font-semibold text-[14px] leading-[19.5px]">Sun,</h1>
                            ) : null}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* {details?.provider_mobile !== "" ? (
                    <div className="flex gap-3 items-center border border-[#DFDFDF] rounded-xl p-2 max-w-[345px]">
                      <div className="bg-[#FFE7C5] bg-opacity-[0.12] p-4 rounded-lg">
                        <BsTelephone className="w-[16px] h-[16px]" color="#F9A000" alt="" />
                      </div>
                      <div>
                        <h1 className="font-normal text-[12px] leading-[14.4px]">Mobile Number</h1>
                        <h1 className="font-semibold text-[14px] leading-[19.5px]">{details?.provider_mobile} </h1>
                      </div>
                    </div>
                  ) : null} */}
                </div>

                {/* --------------------------- Description --------------------------- */}
                <div className="grid gap-4 my-6">
                  <h1 className="font-semibold text-[16px] leading-[19.5px]">Description</h1>
                  <p className="border border-[#DFDFDF] rounded-xl p-4">
                    {details?.description}
                    {details?.description === "" ? <label>No details found</label> : null}
                  </p>
                </div>

                {/* --------------------------- Google Map --------------------------- */}
                {/* {loadError ? (
                  <p>Error loading Google Maps</p>
                ) : isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={14}
                    options={{
                      fullscreenControl: false,
                      zoomControl: false,
                      mapTypeControl: false,
                      streetViewControl: false,
                    }}
                  >
                    <Marker position={center} />
                  </GoogleMap>
                ) : (
                  <p className="flex mx-auto justify-center">loading...</p>
                )} */}

                <iframe
                  title="provider_location"
                  width="100%"
                  height="200"
                  style={{ borderStyle: "none" }}
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_API_KEY}&origin=${details?.latitude},${details?.longitude}&destination=${details?.latitude},${details?.longitude}&zoom=14`}
                ></iframe>

                {/* --------------------------- Services Offered --------------------------- */}
                <div className="grid gap-4 my-6">
                  <h1 className="font-semibold text-[16px] leading-[19.5px]">Services Offered</h1>
                  <div className="grid gap-4">
                    {details?.services?.data?.en?.map((item, index) => (
                      <div
                        key={index}
                        className={`border border-[#DFDFDF] rounded-xl p-4
                      ${item?.total_services === 0 ? "hidden" : "block"} 
                      `}
                      >
                        <h2 className={`font-bold text-[12px] leading-[14.4px] mb-2`}>{item?.category_name}</h2>
                        <div className="grid md:grid-cols-3 gap-2">
                          {item?.sub_category?.map((item, index) => (
                            <button
                              key={index}
                              type="button"
                              disabled={details?.is_favorite !== "yes"}
                              onClick={() => {
                                const cardDetails = {
                                  provider_id: details?.provider_id,
                                  category_id: details?.services?.data?.en?.[0]?.category_id,
                                  sub_category_id: item?.sub_category_id,
                                  title: item?.sub_category_name,
                                  image: item?.sub_category_picture,
                                  price: item?.sub_category_price,
                                  category_description: item?.category_description,
                                  type: item?.sub_category_price_type,
                                  sub_category_is_bidded: item?.sub_category_is_bidded,
                                };
                                localStorage.setItem("cardDetails", JSON.stringify(cardDetails));
                                localStorage.setItem("selectedBooking", 3);
                                localStorage.setItem("selectedOption", 0);
                                navigate(`/booking`);
                              }}
                              className={`min-h-[40px] font-normal text-[12px] leading-[14.4px] text-[#0973BA] w-full border border-[#0973BA] rounded-lg p-1 ${
                                item?.status === 0 ? "hidden" : "block"
                              }`}
                            >
                              {item?.sub_category_name}
                            </button>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {/* --------------------------- Reviews --------------------------- */}

            {activeTab === 2 && (
              <div className="border-t ">
                <div className="grid md:flex justify-between">
                  <div className="flex flex-col items-center gap-4 my-[41px] mx-[22px] md:mx-[66px] w-fit">
                    <h1 className="font-bold text-5xl text-[#F9B523]">
                      {details?.avg_rating && parseFloat(details?.avg_rating).toFixed(1)}
                    </h1>
                    <div className="flex flex-col items-center gap-4">
                      <span className="flex gap-1 items-center font-normal text-[14px] leading-[16.8px]">
                        {details?.avg_rating && renderStars(parseFloat(details?.avg_rating))}
                      </span>
                      <span className="font-normal text-sm text-[#979797]">({details?.ratings?.length} reviews)</span>
                    </div>
                  </div>
                  <div className="min-w-[360px] md:min-w-[400px] my-4">
                    <div className="flex gap-1 items-center">
                      5
                      <AiFillStar color="#F9B523" height="16.28px" width="17.12px" />
                      <div className="w-2/4 h-1 mx-4 bg-gray-200 rounded">
                        <div
                          className="h-1 bg-[#09BA70] rounded"
                          style={{ width: `${details?.rating_details?.[5]?.percentage}%` }}
                        ></div>
                      </div>
                      <span className="text-sm font-medium text-gray-500">
                        {details?.rating_details?.[5]?.percentage &&
                          parseFloat(details?.rating_details?.[5]?.percentage).toFixed(1)}
                      </span>
                    </div>
                    <div className="flex gap-1 items-center mt-4">
                      4
                      <AiFillStar color="#F9B523" height="16.28px" width="17.12px" />
                      <div className="w-2/4 h-1 mx-4 bg-gray-200 rounded">
                        <div
                          className="h-1 bg-[#09BA70] rounded"
                          style={{ width: `${details?.rating_details?.[4]?.percentage}%` }}
                        ></div>
                      </div>
                      <span className="text-sm font-medium text-gray-500">
                        {details?.rating_details?.[4]?.percentage &&
                          parseFloat(details?.rating_details?.[4]?.percentage).toFixed(1)}
                      </span>
                    </div>
                    <div className="flex gap-1 items-center mt-4">
                      3
                      <AiFillStar color="#F9B523" height="16.28px" width="17.12px" />
                      <div className="w-2/4 h-1 mx-4 bg-gray-200 rounded">
                        <div
                          className="h-1 bg-[#09BA70] rounded"
                          style={{ width: `${details?.rating_details?.[3]?.percentage}%` }}
                        ></div>
                      </div>
                      <span className="text-sm font-medium text-gray-500">
                        {details?.rating_details?.[3]?.percentage &&
                          parseFloat(details?.rating_details?.[3]?.percentage).toFixed(1)}
                      </span>
                    </div>
                    <div className="flex gap-1 items-center mt-4">
                      2
                      <AiFillStar color="#F9B523" height="16.28px" width="17.12px" />
                      <div className="w-2/4 h-1 mx-4 bg-gray-200 rounded">
                        <div
                          className="h-1 bg-[#09BA70] rounded"
                          style={{ width: `${details?.rating_details?.[2]?.percentage}%` }}
                        ></div>
                      </div>
                      <span className="text-sm font-medium text-gray-500">
                        {details?.rating_details?.[2]?.percentage &&
                          parseFloat(details?.rating_details?.[2]?.percentage).toFixed(1)}
                      </span>
                    </div>
                    <div className="flex gap-1 items-center mt-4">
                      1
                      <AiFillStar color="#F9B523" height="16.28px" width="17.12px" />
                      <div className="w-2/4 h-1 mx-4 bg-gray-200 rounded">
                        <div
                          className="h-1 bg-[#09BA70] rounded"
                          style={{ width: `${details?.rating_details?.[1]?.percentage}%` }}
                        ></div>
                      </div>
                      <span className="text-sm font-medium text-gray-500">
                        {details?.rating_details?.[1]?.percentage &&
                          parseFloat(details?.rating_details?.[1]?.percentage).toFixed(1)}
                      </span>
                    </div>
                  </div>
                </div>
                {/* --------------------- category section --------------------- */}

                <div className="border-t border-[#DFDFDF] pt-4">
                  <h1 className="font-normal text-[16px] leading-[19.2px]"> Reviews </h1>
                </div>

                {details?.ratings?.length === 0 ? (
                  <div className="grid gap-4 my-6">
                    <p className="font-normal text-[12px] leading-[19px]">No Reviews Against this Provider</p>
                  </div>
                ) : null}

                {details?.ratings?.map((item, index) => (
                  <div key={index} className="grid md:flex gap-4 items-center border-b border-[#DFDFDF] py-6">
                    {item?.user_picture === "" || item?.user_picture === "https://stage.karsaaz.app/placeholder.png" ? (
                      <img
                        src={`${process.env.PUBLIC_URL}/images/EditProfileIcon.png`}
                        alt=""
                        className="rounded-full h-[121px] w-[121px] object-cover"
                      />
                    ) : (
                      <img
                        src={item?.user_picture}
                        // src={`${process.env.PUBLIC_URL}/images/FavoriteProviderIcon.png`}
                        className="w-[116px] h-[116px] rounded-[15px] object-cover"
                        alt=""
                      />
                    )}
                    <div className="flex flex-col gap-1 ml-4 md:ml-0">
                      <div className="flex justify-between mt-3 md:mt=0">
                        <h1 className="font-semibold text-[16px] leading-[19.5px]">{item?.user_name}</h1>
                      </div>
                      <div className="flex items-center gap-1">
                        {item?.rating && renderStars(parseFloat(item?.rating))}
                        <span className="font-normal text-[14px] leading-[16.8px]">{item?.rating}</span>
                      </div>
                      <div className="flex gap-2">
                        <p className="font-normal text-[12px] leading-[19px]">{item?.comment}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionalDetails;
