import { AiFillTag } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showErrorToast } from "store/features/toastSlice";
import { facebook_conversions } from "views/conversionAPI/FacebookConversions";

export const Card = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sub_category_slug = props.sub_category_slug;

  const handle_city_check = () => {
    if (localStorage.getItem("selectedOption") === null) {
      dispatch(showErrorToast("Select the City First"));
    } else if (sub_category_slug) {
      facebook_conversions("Book Now");
      navigate(`/${sub_category_slug}`, {
        state: {
          sub_category_slug: sub_category_slug,
        },
      });
    } else {
      navigate(`/booking`);
    }
  };

  return (
    <>
      <div className="w-full font-Trueno">
        <div className="border rounded-2xl border-[#DFDFDF] h-[410px]	max-w-[315px] p-1 flex flex-col justify-between bg-white">
          <div key={props?.sub_category_id} className="flex flex-col justify-between">
            <div className="">
              <img className="rounded-lg  object-top w-[306.99px] h-[218.22px]" src={props.picture} alt={props.name} />
            </div>
            <div className="px-3 pt-2">
              <div className="flex items-center justify-between font-semibold text-base mb-2 text-black  ">
                {/* {props.name?.length > 19 ? props.name.substring(0, 19) + "...." : props.name} */}
                {props?.name}
              </div>

              <div className="flex my-[10px]">
                {props.sub_category_discount_price > 0 ? (
                  <div className="flex items-center gap-3">
                    <span className="inline-block text-[#0973BA] text-sm font-bold line-through">
                      Rs.{props.sub_category_discount_price}
                    </span>
                    <span className="inline-block text-[#0973BA] text-sm font-bold ">Rs.{props.price} </span>
                  </div>
                ) : (
                  <span className="inline-block text-[#0973BA] text-sm font-bold ">Rs.{props.price} </span>
                )}

                <span className="inline-block text-[#DFDFDF] text-sm font-bold px-[15.88px] ">|</span>

                <span className="flex gap-[9.12px]   text-[#09BA70] text-sm font-bold object-cover items-center">
                  <AiFillTag height="12px" width="12px" alt="PriceTag" />
                  {props.price_type}
                </span>
              </div>
              <span className="inline-block text-[#CC7D05] text-sm font-semibold h-[30px]">
                {props.category_description?.length > 38
                  ? props.category_description.substring(0, 32) + "...."
                  : props.category_description}
              </span>
            </div>
          </div>
          <div>
            {props.sub_category_is_bidded === "no" && (
              <div
                onClick={() => {
                  const cardDetails = {
                    category_id: props.category_id,
                    sub_category_id: props.sub_category_id,
                    title: props.name,
                    image: props.picture,
                    price: props.price,
                    category_description: props.category_description,
                    type: props.price_type,
                    sub_category_is_bidded: props.sub_category_is_bidded,
                  };
                  localStorage.setItem("cardDetails", JSON.stringify(cardDetails));
                }}
              >
                {props.sub_category_is_enabled === 0 ? (
                  <button
                    type="button"
                    disabled
                    className="relative w-full border-t  opacity-80 inline-block outline-none py-[18px] text-center text-base md:text-sm font-semibold text-[#0973BA]"
                  >
                    Coming Soon
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      localStorage.setItem("selectedBooking", 2);
                      handle_city_check();
                    }}
                    className="relative w-full border-t  opacity-80 inline-block outline-none py-[18px] text-center text-base md:text-sm font-semibold text-[#0973BA]"
                  >
                    Book Now
                  </button>
                )}
              </div>
            )}

            {props.sub_category_is_bidded === "yes" && (
              <div
                onClick={() => {
                  const cardDetails = {
                    category_id: props.category_id,
                    sub_category_id: props.sub_category_id,
                    title: props.name,
                    image: props.picture,
                    price: props.price,
                    category_description: props.category_description,
                    type: props.price_type,
                    sub_category_is_bidded: props.sub_category_is_bidded,
                  };
                  localStorage.setItem("cardDetails", JSON.stringify(cardDetails));
                }}
              >
                {props.sub_category_is_enabled === 0 ? (
                  <button
                    type="button"
                    disabled
                    className="relative w-full border-t  opacity-80 inline-block outline-none py-[18px] text-center text-bas md:text-sm font-semibold text-[#0973BA]"
                  >
                    Coming Soon
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      handle_city_check();
                      localStorage.setItem("selectedBooking", 0);
                    }}
                    className="relative w-full border-t  opacity-80 inline-block outline-none py-[18px] text-center text-base md:text-sm font-semibold text-[#0973BA]"
                  >
                    Book Now
                  </button>
                )}
              </div>
            )}
            {props.sub_category_is_bidded === "only" && (
              <div
                onClick={() => {
                  const cardDetails = {
                    category_id: props.category_id,
                    sub_category_id: props.sub_category_id,
                    title: props.name,
                    image: props.picture,
                    price: props.price,
                    category_description: props.category_description,
                    type: props.price_type,
                    sub_category_is_bidded: props.sub_category_is_bidded,
                  };
                  localStorage.setItem("cardDetails", JSON.stringify(cardDetails));
                }}
              >
                {props.sub_category_is_enabled === 0 ? (
                  <button
                    type="button"
                    disabled
                    className="relative w-full border-t  opacity-80 inline-block outline-none py-[18px] text-center text-base md:text-sm font-semibold text-[#0973BA]"
                  >
                    Coming Soon
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      handle_city_check();
                      localStorage.setItem("selectedBooking", 1);
                    }}
                    className="relative w-full border-t  opacity-80 inline-block outline-none py-[18px] text-center text-base md:text-sm font-semibold text-[#0973BA]"
                  >
                    Book Now
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
