import FooterMain from "views/partials/footer/FooterMain";
import { Card } from "theme/cards/Card";
import { useLocation } from "react-router-dom";
import { useSubServices } from "hooks/useService";
import CustomerLoveUs from "theme/cards/CustomerLoveUs";
import CustomerChoosesUs from "common/CustomerChoosesUs";
import BookingNavbar from "views/partials/navbar/BookingNavbar";

import CityFilter from "common/CityFilter";

const SearchResults = (props) => {
  const { mutate } = useSubServices();
  const location = useLocation();
  const { search_all_data, category_id } = location.state || {};

  const handleCityChange = (cityValue) => {
    const payload = {
      category_id: category_id,
      // city_id: localStorage.getItem("selectedOption"),
      city_id: cityValue,
    };
    mutate(payload);
  };

  return (
    <>
      <div className="w-full">
        <div>
          <BookingNavbar color="#fff" />

          <h1 className="grid md:flex gap-2 items-center justify-center font-extrabold text-xl md:text-[28px] md:text-4xl text-center my-8 md:my-12">
            Search Results :{" "}
            <span className="text-[#0973BA]">
              "{search_all_data?.data?.result?.sub_category_name}"
            </span>
          </h1>
        </div>

        <div className="w-[90%] mx-auto flex flex-col gap-8 ">
          {/* {search_all_data?.data?.result?.map((item, index) => ( */}

          <div className="flex justify-center md:justify-start w-full">
            <CityFilter onCityChange={handleCityChange} />
          </div>

          <div>
            <Card
              sub_category_id={search_all_data?.data?.result?.sub_category_id}
              name={search_all_data?.data?.result?.sub_category_name}
              picture={search_all_data?.data?.result?.sub_category_picture}
              price={search_all_data?.data?.result?.sub_category_price}
              price_type={search_all_data?.data?.result?.sub_cat_price_type}
              category_description={
                search_all_data?.data?.result?.sub_cat_description
              }
              sub_category_is_bidded={
                search_all_data?.data?.result?.sub_cat_bid
              }
              backgroundColor="#0973BA"
              sub_category_slug={
                search_all_data?.data?.result?.sub_category_slug
              }
            />
          </div>
        </div>

        {/* <GetMoreDoneWithKarsaaz color="#E0F3FF" TextColor="#0973BA" /> */}
        <CustomerChoosesUs color="#ECF7FE" TextColor="black" />
        <CustomerLoveUs
          color="#FAFDFF"
          QuoteIconColor="#07609C"
          FaArrowCircleColor="#07609C"
          TextColor="#0973BA"
        />
        <FooterMain />
      </div>
    </>
  );
};

export default SearchResults;
