import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsFacebook, BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { RiMenu3Fill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { useBlog_categories } from "hooks/useUser";
import BlogsSearchBar from "./BlogsSearchBar";

const BlogsNavbar = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { data, isLoading } = useBlog_categories();

  const TwitterXIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 50 50">
      <path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z" fill="#0973BA"></path>
    </svg>
  );

  const icons = [BsFacebook, BsYoutube, TwitterXIcon, BsLinkedin, BsInstagram, FaTiktok];
  const links = [
    "https://www.facebook.com/officialkarsaaz",
    "https://www.youtube.com/@karsaaz",
    "https://twitter.com/Karsaaz_App",
    "https://www.linkedin.com/company/karsaaz-app",
    "https://www.instagram.com/karsaazofficial/",
    "https://www.tiktok.com/@karsaaz.app",
  ];

  const handleBlogsCategory = (item) => {
    navigate(`/blogs/${item?.slug}`, {
      state: { blog_category_id: item?.id },
    });
  };
  return (
    <>
      <div className="w-[90%] mx-auto flex flex-col gap-3">
        <div className="flex justify-between items-center py-4">

          <img
            className="object-cover md:shrink-0 h-[41.15px] cursor-pointer"
            src={`${process.env.PUBLIC_URL}/images/KarsaazOriginalLogo.png`}
            alt=""
            onClick={() => navigate("/")}

          />

          <div className="hidden md:flex gap-2 items-center">
            <img
              src={`${process.env.PUBLIC_URL}/images/HomeLogoIcon.svg`}
              alt="Home Logo"
              className="object-cover md:shrink-0 hidden lg:block cursor-pointer h-[41.15px] font-medium hover:scale-105 duration-200"
              onClick={() => navigate("/")}
            />
            <div className="md:w-[507px]">
              <BlogsSearchBar />
            </div>
          </div>
          <div onClick={() => setOpen(!open)} className="text-black block md:hidden">
            {open ? <RxCross2 className="w-[24px] h-[24px]" /> : <RiMenu3Fill className="w-[24px] h-[24px]" />}
          </div>
        </div>

        <div className="flex justify-between items-center  md:border-y border-[#DFDFDF]">
          <ul
            className={`grid-cols-1 md:grid md:grid-flow-col auto-cols-max gap-6 place-items-center md:pb-0 pb-12 absolute md:static  md:z-auto z-10 left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 font-semibold text-base capitalize bg-white text-black cursor-pointer ${open ? "top-28 grid gap-6 justify-items-start" : "top-[-630px]"
              }
        `}
          >
            <Link to="/blogs">
              <li
                className={`hover:scale-105 hover:duration-200 ease-in hover:text-[#0973BA] ${location.pathname === "/blogs" ? "text-[#0973BA]" : "text-black"
                  }`}
              >
                All
              </li>
            </Link>
            {isLoading
              ? Array(3)
                .fill()
                .map((_, index) => <div key={index} className="animate-pulse h-[12.22px] w-[100.22px] bg-sky-200"></div>)
              : data?.data?.slice(0, 4).map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    handleBlogsCategory(item);
                  }}
                  className={`hover:scale-105 hover:duration-200 ease-in hover:text-[#0973BA] ${location.pathname === `/blogs/${item?.slug}` ? "text-[#0973BA]" : "text-black"
                    }`}
                >
                  {item?.name}
                </li>
              ))}
          </ul>

          <div className="block md:hidden w-full mb-3">
            <BlogsSearchBar />
          </div>

          <ul className="hidden md:flex gap-6 text-[#0973BA] font-semibold text-base">
            {icons.map((Icon, index) => (
              <li className="flex items-center py-3 hover:scale-105 hover:duration-200 ease-in" key={index}>
                <a href={links[index]} target="_blank" rel="noreferrer" className="flex items-center gap-3">
                  <Icon />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default BlogsNavbar;
