import BookingNavbar from "views/partials/navbar/BookingNavbar";
import { useNavigate } from "react-router-dom";
import { MdDateRange } from "react-icons/md";
import SmallLoader from "views/loader/SmallLoader";
import SettingFooter from "views/pages/settingsPage/SettingFooter";
import { useTransactions_history } from "hooks/useAuth";
import { useEffect } from "react";

const TransactionHistory = () => {
  const navigate = useNavigate();
  const { isLoading, data, mutate } = useTransactions_history();

  if (data?.error_code === 104 || data?.error_code === 103) {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    navigate("/login");
  }

  useEffect(() => {
    const payload = {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    };
    mutate(payload);
  }, []);

  const dataArray = data?.data;

  const formattedDataArray = dataArray?.map((data) => {
    const createdAt = new Date(data.created_at);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(createdAt);

    const formattedTime = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC",
    }).format(createdAt);

    const finalFormattedDateTime = `${formattedDate} Time ${formattedTime}`;

    return { ...data, formattedCreatedAt: finalFormattedDateTime };
  });

  const handleClick = (item) => {
    localStorage.setItem("request_id", item?.request_id);
    navigate(`/job-page`, {
      state: {
        request_id: item?.request_id,
      },
    });
  };

  return (
    <>
      <div className="w-full max-h-full bg-[#EBF4F9]">
        <BookingNavbar color="#EBF4F9" />
        <div className="w-[90%] mx-auto rounded-2xl shadow-2xl bg-white px-[26px] py-3 my-8">
          <div className="flex gap-4 items-center relative ">
            <h1 className="font-semibold text-[22px] leading-[26.82px] py-[34px]">Transaction History</h1>
            <span className="rounded-lg font-semibold text-[16px] leading-[19.5px] text-[#0973BA] bg-sky-200 p-2">
              {data?.data?.length}
            </span>
          </div>

          <div className="hidden md:block relative overflow-x-auto h-[738px]">
            <table className="w-full text-left">
              <thead className="font-semibold text-[14px] leading-[17.07px] border-y">
                <tr>
                  <th scope="col" className="p-2 w-[130px] h-[17px] py-3">
                    Request ID
                  </th>
                  <th scope="col" className="w-[130px] h-[17px] py-3">
                    Transaction ID
                  </th>
                  <th scope="col" className="p-3 h-[23px] w-[140px] py-3">
                    Job Name
                  </th>
                  <th scope="col" className="h-[23px] w-[140px] py-3">
                    Request Amount
                  </th>
                  <th scope="col" className="h-[23px] w-[190px] py-3">
                    Transaction via
                  </th>

                  <th scope="col" className="w-[151px] py-3">
                    Due On
                  </th>
                  <th scope="col" className="px-7 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <SmallLoader />
              ) : (
                formattedDataArray?.map((item, index) => (
                  <tbody key={index}>
                    <tr className="bg-white border-b">
                      <th scope="row" className="px-7 py-3 font-semibold text-[14px] leading-[17.07px]">
                        {item?.request_id}
                      </th>
                      <td className="min-w-[151px] py-3 font-semibold text-[14px] leading-[17.07px]">
                        <label className="flex-col md:flex md:flex-row items-center gap-5">{item?.transaction_id}</label>
                      </td>

                      <td className="min-w-[298px] py-3 font-bold text-[14px] leading-[16.8px] text-[#0973BA]">
                        <label className="flex-col md:flex md:flex-row items-center gap-5">{item?.request_title}</label>
                      </td>
                      <td className="min-w-[151px] py-4 font-normal text-[14px] leading-[16.8px]">
                        {item?.currency} {item?.amount}
                      </td>
                      <td className="flex items-center gap-2 min-w-[190px] py-4 font-normal text-[14px] leading-[16.8px]">
                        <img
                          src={`${process.env.PUBLIC_URL}${
                            item?.source_data_sub_type === "MasterCard"
                              ? "/images/credit-debit.svg"
                              : item?.source_data_sub_type === "JAZZCASH"
                              ? "/images/jazz-cash.png"
                              : item?.source_data_sub_type === "cod"
                              ? "/images/cash-on-hand.png"
                              : "/images/easy-paisa.png"
                          }`}
                          alt=""
                          className="object-cover h-auto max-h-[35px] max-w-[35px]"
                        />
                        {item?.source_data_sub_type === "cod" ? "CashPayment" : item?.source_data_sub_type}
                      </td>
                      <td className="min-w-[298px] py-4 font-normal text-[14px] leading-[16.8px]">{item?.formattedCreatedAt}</td>

                      <td className="px-7 py-3 h-fit w-fit">
                        <button
                          onClick={() => handleClick(item)}
                          className="font-normal text-[14px] leading-[14.4px] bg-[#0973BA]	 text-white py-[4px] px-3 rounded-[50px] hover:scale-105 duration-200"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))
              )}
            </table>
            {data?.error === "No transaction history found!" ? (
              <div className="w-full mx-auto my-8 text-center border rounded-lg py-3">
                <p className="font-semibold text-[14px] leading-[17.07px] text center">{data?.error}</p>
              </div>
            ) : null}
          </div>

          {/* -------------------------- for small screen size -------------------------- */}
          <div className="min-h-[738px] max-w-[358px] block md:hidden ">
            {isLoading ? (
              <div className="absolute inset-0 flex items-center justify-center">
                <div role="status" className="absolute inset-y-3.8">
                  <div role="status" className="">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 mr-2 text-slate-200 animate-spin  fill-[#0973BA]"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              formattedDataArray?.map((item, index) => (
                <div key={index} className="block md:hidden mb-5">
                  <div className="flex flex-col gap-2 justify-between  bg-white border rounded-2xl p-2 font-Trueno">
                    <div className="flex flex-col gap-2 p-3">
                      <p className="font-normal text-[12px] leading-[14.4px]">
                        Request ID:<label className="text-[#0973BA]">{item?.request_id}</label>
                      </p>
                      <p className="font-normal text-[12px] leading-[14.4px]">
                        Transaction ID:<label>{item?.transaction_id}</label>
                      </p>
                      <h1 className="font-bold text-[16px] leading-[22px] text-[#0973BA]">{item?.request_title}</h1>
                      <div className="grid md:flex gap-2 justify-between ">
                        <div className="flex gap-3 items-center font-normal text-[12px] leading-[14.4px]">
                          <span className="inline-block text-[#0973BA] text-sm font-bold ">
                            {item?.currency} {item?.amount}
                          </span>
                          <span className="flex items-center gap-2 text-sm font-bold">
                            via:
                            <img
                              src={`${process.env.PUBLIC_URL}${
                                item?.source_data_sub_type === "MasterCard"
                                  ? "/images/credit-debit.svg"
                                  : item?.source_data_sub_type === "JAZZCASH"
                                  ? "/images/jazz-cash.png"
                                  : "/images/easy-paisa.png"
                              }`}
                              alt=""
                              className="object-cover h-auto max-h-[35px] max-w-[35px]"
                            />
                            {item?.source_data_sub_type}
                          </span>
                        </div>
                        <span className="flex gap-2 items-center text-black text-sm font-normal ">
                          <MdDateRange color="#0973BA" className="w-[20px] h-[20px]" />
                          {item?.formattedCreatedAt}
                        </span>
                      </div>
                      <button
                        onClick={() => handleClick(item)}
                        className="w-full h-[31px] font-normal text-[14px] leading-[14.4px] bg-[#0973BA] text-white py-[4px] px-3 rounded-[50px] "
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <SettingFooter />
      </div>
    </>
  );
};

export default TransactionHistory;
