import { useEffect, useState } from "react";
import { RiCheckDoubleLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import TrackYourService from "views/stepperBookingForm/TrackYourService";
import RightSideBar from "common/RightSideBar";
import BookingNavbar from "views/partials/navbar/BookingNavbar";
import GuestPasswordModal from "views/stepperBookingForm/steps/GuestPasswordModal";
import { facebook_conversions } from "views/conversionAPI/FacebookConversions";

const JobPostedSuccessfully = () => {
  const navigate = useNavigate();
  const [guestPasswordModal, setGuestPasswordModal] = useState(false);

  const request_id = sessionStorage.getItem("request_id");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (sessionStorage.getItem("guest") === "true") {
      setGuestPasswordModal(true);
    }
    facebook_conversions("purchase");

    localStorage.removeItem("cardDetails");
  }, []);

  return (
    <>
      <div className="bg-[#E6F2FA] font-Trueno ">
        <BookingNavbar color="#E6F2FA" />
        <RightSideBar />
        <div className="py-16">
          <div className="flex flex-col gap-5 w-full lg:w-[1050px] mx-auto px-3 md:px-36 lg:px-44 py-12 rounded-2xl bg-white ">
            <div className="flex flex-col  justify-between items-center ">
              <h1 className="font-bold text-[22px] md:text-[28px] leading-[26.4px] md:leading-[33.6px] my-7 uppercase text-green-500 text-center">
                Job Posted Successfully
              </h1>
              <p className="font-bold text-[16pz] md:text-[18px] leading-[31px] text-center text-black">
                Your Job has been posted successfully. Your Job ID is “<label className="text-[#0973BA]"> {request_id} </label>“ .
                You can check the status of your job in "My Jobs" section.
              </p>
            </div>
            <div className="w-full bg-[#E0F3FF] rounded-[20px]">
              <div className="flex flex-col justify-between items-center py-12 px-5 md:px-28">
                <h1 className="font-bold text-[18px] leading-[31px] text-center">
                  You get following benefits only when you book an expert through Karsaaz App :)
                </h1>
                <ul className="flex flex-col gap-5 w-full first-letter:font-normal text-[16px] leading-5 list-disc list-outsides ">
                  <div className="flex gap-1 justify-between items-center">
                    <li className="font-normal text-sm md:text-base">Guaranteed Lowest Price</li>
                    <div className="rounded-full bg-[#0973BA] p-1">
                      <RiCheckDoubleLine color="white" height="18px" width="18px" />
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <li className="font-normal text-sm md:text-base">Full job tracking for your safety and security</li>
                    <div className="rounded-full bg-[#0973BA] p-1">
                      <RiCheckDoubleLine color="white" height="18px" width="18px" />
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <li className="font-normal text-sm md:text-base">15 Days Service Warranty by Karsaaz App</li>
                    <div className="rounded-full bg-[#0973BA] p-1">
                      <RiCheckDoubleLine color="white" height="18px" width="18px" />
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <li className="font-normal text-sm md:text-base">Rewards and Cashback</li>
                    <div className="rounded-full bg-[#0973BA] p-1">
                      <RiCheckDoubleLine color="white" height="18px" width="18px" />
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div className="mt-3">
              <TrackYourService />
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mx-auto">
              <Link to="/my-jobs">
                <button className="py-4 w-[204.56px] rounded-lg font-bold text-white  text-[16px] leading-[19.2px] bg-[#0973BA] hover:scale-105 hover duration-200">
                  Your Jobs
                </button>
              </Link>

              <button
                onClick={() => {
                  navigate("/");
                }}
                className="py-4 w-[204.56px] rounded-lg font-bold  text-white text-[16px] leading-[19.2px] bg-[#09BA70] hover:scale-105 hover duration-200"
              >
                Book Another Service
              </button>

              <Link to="/home" className="w-full">
                <button className="py-4 w-[204.56px] rounded-lg font-bold  text-[#0973BA] border border-[#0973BA] text-[16px] leading-[19.2px] bg-white hover:scale-105 hover duration-200">
                  Home
                </button>
              </Link>
            </div>
          </div>
        </div>
        {guestPasswordModal && (
          <GuestPasswordModal onClose={() => setGuestPasswordModal(false)} setGuestPasswordModal={setGuestPasswordModal} />
        )}
      </div>
    </>
  );
};

export default JobPostedSuccessfully;
