import { AiFillTag } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function ServiceType({ setBooking, props }) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(-1);
  };

  const card_json_data = localStorage.getItem("cardDetails");

  const cardDetails = JSON.parse(card_json_data);

  // useEffect(() => {
  //   const storedCardDetails = localStorage.getItem("cardDetails");
  //   if (storedCardDetails) {
  //     setCardDetails(JSON.parse(storedCardDetails));
  //   }
  // }, []);

  // const bookingValue = parseInt(localStorage.getItem("selectedBooking"));

  // if (bookingValue === 0) {
  //   setBooking(0);
  // } else if (bookingValue === 1) {
  //   setBooking(1);
  // } else if (bookingValue === 2) {
  //   setBooking(2);
  // }

  return (
    <div className="w-full  mx-auto font-Trueno  flex flex-col items-center">
      {cardDetails && (
        <div key={cardDetails?.id} className="w-full mx-auto ">
          <h1 className="font-bold text-[18px] leading-[21.13px] my-7">Confirm Your Booking Option</h1>
          <div className="flex-col md:flex md:flex-row gap-3 md:gap-6 justify-between border border-[#D9D9D9] rounded-2xl p-3">
            <div className="flex-col md:flex md:flex-row  gap-3 md:gap-8 justify-between">
              <img
                src={cardDetails?.image}
                alt={cardDetails?.title}
                className="w-full max-w-[344px] md:w-[131px] h-auto md:h-[131px] object-cover rounded-lg"
              />
              <div className="flex flex-col gap-3 border-[#DFDFDF] mt-2 md:mt-0 ">
                <div className="flex md:flex-row gap-2 items-start md:items-center">
                  <label className="font-extrabold text-[18px] leading-[21.94px]">{cardDetails?.title}</label>
                </div>
                <button
                  onClick={handleButtonClick}
                  className="font-medium text-[12px] leading-[14.08px] bg-[#0973BA] rounded-[50px] px-3 py-1 text-white w-fit "
                >
                  Change
                </button>

                {/* <div className="">
                {localStorage.getItem("selectedBooking") === "0" && (
                  <div className="flex gap-1 items-center">
                    <label className="text-[#0973BA] text-sm font-bold">Urgent Booking</label>
                    <BsCartCheckFill color="#0973BA" className="w-[26px] h-[16px]" />
                  </div>
                )}

                {localStorage.getItem("selectedBooking") === "1" && (
                  <div className="flex gap-1 items-center">
                    <label className="text-[#0973BA] text-sm font-bold">Get Offer</label>
                    <BsCartCheckFill color="#0973BA" className="w-[26px] h-[16px]" />
                  </div>
                )}
              </div> */}

                <div className="flex">
                  <span className="inline-block text-[#0973BA] text-sm font-bold ">Rs.{cardDetails.price}</span>
                  <span className="inline-block text-[#DFDFDF] text-sm font-bold px-[15.88px] ">|</span>

                  <span className="flex gap-[9.12px]   text-[#09BA70] text-sm font-bold object-cover items-center">
                    <AiFillTag alt="PriceTag" className="w-[16px] h-[16px]" />
                    {cardDetails.type}
                  </span>
                </div>

                <div className="grid md:flex gap-3 md:gap-6  items-center justify-between">
                  <span className="inline-block text-[#CC7D05] text-sm font-semibold">{cardDetails.category_description}</span>

                  {/* <div className="">
                  {localStorage.getItem("selectedBooking") === "0" && (
                    <div>
                      <label className="flex gap-2 items-center text-sm font-semibold md:font-bold md:text-16 md:leading-19.2 text-[#0973BA] ">
                        <IoMdRadioButtonOn color="#0973BA" />
                        Urgent Booking
                      </label>
                    </div>
                  )}

                  {localStorage.getItem("selectedBooking") === "1" && (
                    <div className=" ">
                      <label className="flex gap-2 items-center font-bold text-16 leading-19.2 text-[#0973BA]">
                        <IoMdRadioButtonOn color="#0973BA" />
                        Get offers
                      </label>
                    </div>
                  )}
                </div> */}
                </div>
              </div>
            </div>

            <div className="min-w-[267px] flex-col md:flex md:flex-row gap-3 justify-between items-center my-2 md:mt-0 border-t md:border-none">
              <div className="flex gap-2 my-5 md:my-2">
                <input
                  type="radio"
                  name="bookingOption"
                  value="0"
                  checked={localStorage.getItem("selectedBooking") == 0}
                  onChange={(e) => {
                    localStorage.setItem("selectedBooking", 0);
                    setBooking(0);
                  }}
                />
                <label
                  className={`font-bold text-[16px] leading-[19.2px]
  ${localStorage.getItem("selectedBooking") == 0 ? "text-[#0973BA]" : "text-[#979797]"}
  `}
                >
                  Urgent Booking
                </label>
              </div>
              <div className="hidden md:block">
                <img src={`${process.env.PUBLIC_URL}/images/LineForBooking.png`} alt="line" />
              </div>
              <div className="flex gap-2 md:my-6">
                <input
                  type="radio"
                  name="bookingOption"
                  value="1"
                  checked={localStorage.getItem("selectedBooking") == 1}
                  onChange={(e) => {
                    localStorage.setItem("selectedBooking", 1);
                    setBooking(1);
                  }}
                />
                <label
                  className={`font-bold text-[16px] leading-[19.2px]
  ${localStorage.getItem("selectedBooking") == 1 ? "text-[#0973BA]" : "text-[#979797]"}
  `}
                >
                  Get Offers
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
      {!cardDetails && <p>No card details are found. Please select again </p>}
    </div>
  );
}
