import React, { useEffect, useState } from "react";
import { RiGift2Fill } from "react-icons/ri";
import {
  useApply_cashback_discount,
  useApply_coupan_discount,
  useRemove_Coupan,
  useRemove_cashback_discount,
  useRewards,
} from "hooks/useUser";
import { showErrorToast } from "store/features/toastSlice";
import { useDispatch } from "react-redux";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";

const Cashback = ({ single_request, refetch }) => {
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(
    single_request?.data?.is_coupon_applied === 2 ? true : false
  );

  const [apply_coupan, setApply_coupan] = useState("");
  const [show_copan_field, setShow_copan_field] = useState(false);
  const [show_cashback_field, setShow_cashback_field] = useState(false);

  const { data: rewards_data, refetch: rewards_refetch } = useRewards();
  const {
    data: apply_cashback_discount_data,
    isLoading: apply_cashback_discount_isLoading,
    mutate: apply_cashback_discount_mutate,
  } = useApply_cashback_discount();

  const { isLoading: apply_coupan_isLoading, mutate: apply_coupan_mutate } =
    useApply_coupan_discount();

  const { mutate: remove_coupan_mutate, isLoading: remove_coupan_isLoading } =
    useRemove_Coupan();

  const {
    mutate: remove_cashback_discount_mutate,
    data: remove_cashback_discount_data,

    isLoading: remove_cashback_discount_isLoading,
  } = useRemove_cashback_discount();

  useEffect(() => {
    if (
      apply_cashback_discount_data?.success === true ||
      remove_cashback_discount_data?.success === true
    ) {
      refetch();
      rewards_refetch();
    } else {
      refetch();
    }
  }, [apply_cashback_discount_data, remove_cashback_discount_data]);

  const toggleChecked = () => {
    if (rewards_data?.cashback?.[0]?.status === "expired") {
      setIsChecked(false);
      dispatch(showErrorToast("Your cashback has expired"));
    } else if (isChecked === true) {
      setIsChecked(!isChecked);
      const payload = {
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
        request_id: single_request?.data?.request_id,
      };
      remove_cashback_discount_mutate(payload);
    } else {
      setIsChecked(!isChecked);

      const payload = {
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
        request_id: single_request?.data?.request_id,
        cashback_amount: rewards_data?.cashback?.[0]?.available_cashback_amount,
      };
      apply_cashback_discount_mutate(payload);
    }
  };

  const handleCoupan = () => {
    if (!apply_coupan) {
      dispatch(showErrorToast("Please enter a coupon code"));
    } else {
      const payload = {
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
        request_id: single_request?.data?.request_id,
        coupon_code: apply_coupan,
      };
      apply_coupan_mutate(payload);
    }
  };

  return (
    <>
      <div className="flew flex-col gap-5 full font-Trueno">
        <div
          className={`flex flex-col gap-5  rounded-lg outline-none border-[0.5px] border-[#DFDFDF] p-3
 ${single_request?.data?.is_coupon_applied === 2 ? "hidden" : "block"}

          `}
        >
          <button
            type="button"
            onClick={() => {
              setShow_cashback_field(false);
              setShow_copan_field(!show_copan_field);
            }}
            className="w-full flex justify-between items-center font-light text-sm"
          >
            Use Coupan for Cashback
            {show_copan_field ? (
              <FaArrowAltCircleUp
                color="#0973BA"
                className="w-[25px] h-[25px] cursor-pointer"
              />
            ) : (
              <FaArrowAltCircleDown
                color="#0973BA"
                className="w-[25px] h-[25px] cursor-pointer"
              />
            )}
          </button>
          {show_copan_field ? (
            <div className="flex flex-col gap-5">
              <div className="relative">
                <input
                  type="text"
                  autoComplete="off"
                  name="coupan"
                  id="coupan"
                  onChange={(e) => {
                    setApply_coupan(e.target.value);
                  }}
                  placeholder="Enter Coupon"
                  className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 font-bold text-lg text-[#0973BA] placeholder:font-normal  placeholder-black-500  placeholder:text-xs  md:placeholder:text-sm"
                  required
                />

                <button
                  type="button"
                  onClick={handleCoupan}
                  disabled={
                    apply_coupan_isLoading ||
                    remove_coupan_isLoading ||
                    single_request?.data?.is_coupon_applied === 1
                  }
                  className={`absolute top-0 bottom-0 right-3 font-bold text-sm text-[#0973BA] ${
                    single_request?.data?.is_coupon_applied === 1
                      ? "hidden"
                      : "block"
                  }`}
                >
                  Apply
                </button>
              </div>
              {apply_coupan_isLoading || remove_coupan_isLoading ? (
                <div className="w-full mx-auto flex flex-col gap-3 p-3 border border-[#DFDFDF] rounded-2xl animate-pulse">
                  <div className="h-4 w-1/12 bg-gray-300 rounded"></div>
                  <div className="flex items-center justify-between">
                    <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                    <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                    <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                    <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                  </div>
                </div>
              ) : (
                <div
                  className={`${
                    single_request?.data?.is_coupon_applied === 1
                      ? "block"
                      : "hidden"
                  }`}
                >
                  <div className="w-full mx-auto flex items-center gap-2 justify-between border border-[#DFDFDF] bg-[#E6F1F8] rounded-md py-1 px-3 my-2">
                    <p className="flex items-center gap-2 text-center font-extrabold text-base text-black  py-1">
                      <RiGift2Fill className="w-8 h-8" color="#0973BA" />
                      Coupan Discount
                    </p>
                    <p className="flex items-center gap-2 font-extrabold text-xs text-[#09BA70]">
                      Applied
                      <BsTrashFill
                        onClick={() => {
                          const payload = {
                            id: localStorage.getItem("id"),
                            token: localStorage.getItem("token"),
                            request_id: single_request?.data?.request_id,
                          };
                          remove_coupan_mutate(payload);
                        }}
                        color="#FF4E4E"
                        className="w-6 h-6 cursor-pointer"
                      />
                    </p>
                  </div>

                  <div className="w-full mx-auto flex flex-col gap-3 p-3 border border-[#DFDFDF] rounded-2xl">
                    <h2 className="font-bold text-sm my-2">Summary</h2>

                    <p className="flex justify-between items-center font-light text-sm">
                      Coupan Code
                      <span className="font-bold text-lg text-[#0973BA]">
                        {single_request?.data?.invoice?.[0]?.coupon_code}
                      </span>
                    </p>

                    <p className="flex justify-between items-center font-light text-sm">
                      Actual price
                      <span>Rs. {single_request?.data?.price_per_hour}</span>
                    </p>
                    <p className="flex justify-between items-center font-light text-sm">
                      Discounted Price
                      <span>Rs. {single_request?.data?.discounted_total}</span>
                    </p>
                    <p className="flex justify-between items-center font-bold text-xl">
                      Total
                      <span>Rs. {single_request?.data?.discounted_total}</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>

        <p
          className={`w-full font-normal text-sm text-center my-4 
        ${single_request?.data?.is_coupon_applied !== 0 ? "hidden" : "block"}
        `}
        >
          Or want to use your Cashback Rewards ?
        </p>
        <div
          className={`flex flex-col gap-5  rounded-lg outline-none border-[0.5px] border-[#DFDFDF] p-3 ${
            single_request?.data?.is_coupon_applied === 1 ? "hidden" : "block"
          }`}
        >
          <button
            type="button"
            onClick={() => {
              setShow_cashback_field(!show_cashback_field);
              setShow_copan_field(false);
            }}
            className="w-full flex justify-between items-center font-light text-sm cursor-pointer"
          >
            Use Cashback Rewards
            {show_cashback_field ? (
              <FaArrowAltCircleUp
                color="#0973BA"
                className="w-[25px] h-[25px] cursor-pointer"
              />
            ) : (
              <FaArrowAltCircleDown
                color="#0973BA"
                className="w-[25px] h-[25px] cursor-pointer"
              />
            )}
          </button>
          {show_cashback_field ? (
            <div>
              <div
                className="flex items-center gap-2 cursor-pointer my-3"
                onClick={() => {
                  toggleChecked();
                }}
              >
                <h2 className="font-semibold text-[14px] leading-[19.5px] cursor-pointer">
                  Apply for Cashback Discount
                </h2>
                <span
                  className={`h-4 w-10 rounded-full border shadow-2xl flex items-center ${
                    isChecked ? "justify-end" : "justify-start"
                  } ${isChecked ? "bg-[#0973BA]" : "bg-gray-300"}`}
                >
                  <span
                    className={`border rounded-full bg-white w-5 h-5 transform transition-transform duration-300 ${
                      isChecked ? "translate-x-1/4" : ""
                    }`}
                  />
                </span>
              </div>

              <div className="max-w-[700px] flex flex-col gap-3">
                <div className="w-full mx-auto flex items-center gap-2 justify-center border border-[#DFDFDF] bg-[#E6F1F8] rounded-md py-1">
                  <p className="flex items-center gap-1 text-center font-extrabold text-base text-black  py-1">
                    Cashback Discount
                  </p>
                  <RiGift2Fill className="w-8 h-8" color="#0973BA" />
                </div>

                {apply_cashback_discount_isLoading ||
                remove_cashback_discount_isLoading ? (
                  <div className="w-full mx-auto flex flex-col gap-3 p-3 border border-[#DFDFDF] rounded-2xl animate-pulse">
                    <div className="h-4 w-1/12 bg-gray-300 rounded"></div>
                    <div className="flex items-center justify-between">
                      <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                      <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                      <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                      <div className="h-4 w-1/5 bg-gray-300 rounded"></div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full mx-auto flex flex-col gap-3 p-3 border border-[#DFDFDF] rounded-2xl">
                    <h2 className="font-bold text-sm my-2">Summary</h2>

                    <p className="flex justify-between items-center font-light text-sm">
                      Expiration Date
                      <span
                        className={` font-semibold ${
                          rewards_data?.cashback?.[0]?.status === "expired"
                            ? "text-[#FF4E4E]"
                            : "text-[#09BA70]"
                        } `}
                      >
                        {rewards_data?.cashback?.[0]?.expiration_date}
                      </span>
                    </p>

                    <p className="flex justify-between items-center font-light text-sm">
                      Available Cashback
                      <span>
                        Rs.{" "}
                        {rewards_data?.cashback?.[0]?.available_cashback_amount
                          ? rewards_data?.cashback?.[0]
                              ?.available_cashback_amount
                          : "0"}
                      </span>
                    </p>
                    <p className="flex justify-between items-center font-light text-sm">
                      Actual price
                      <span>Rs. {single_request?.data?.price_per_hour}</span>
                    </p>
                    {single_request?.data?.is_coupon_applied === 2 ? (
                      <>
                        <p className="flex justify-between items-center font-light text-sm">
                          Discounted Price
                          <span>
                            Rs. {single_request?.data?.discounted_total}
                          </span>
                        </p>
                        <p className="flex justify-between items-center font-bold text-xl">
                          Total
                          <span>
                            Rs. {single_request?.data?.discounted_total}
                          </span>
                        </p>
                      </>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {/* ) : null} */}
        </div>
      </div>
    </>
  );
};

export default Cashback;
