import { useBlogs_by_category } from "hooks/useUser";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode } from 'swiper/modules';

const BlogsCarousel = () => {
  const navigate = useNavigate();
  const { data, isLoading, mutate } = useBlogs_by_category();

  useEffect(() => {
    const payload = {
      blog_category_id: 1,
    };
    mutate(payload);
  }, []);

  const handleBlogsDetailPage = (item) => {
    navigate(`/blogs/${item?.slug}`, {
      state: {
        blogs_detail_data: item?.slug,
      },
    });
  };

  return (
    <>
      <div>
        <div className="w-full font-Trueno my-5 px-3">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            freeMode={true}
            modules={[FreeMode]}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1250: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <div className="flex gap-3">
              {isLoading
                ? (
                  <>
                    <div className="flex flex-col gap-3 justify-between w-full  bg-white drop-shadow-md rounded-2xl p-3">
                      <div className="animate-pulse rounded-xl h-[280px] md:h-[325.22px] bg-sky-200"></div>
                    </div>
                    <div className="hidden md:flex md:flex-col gap-3 justify-between w-full  bg-white drop-shadow-md rounded-2xl p-3">
                      <div className="animate-pulse rounded-xl  h-[325.22px] bg-sky-200"></div>
                    </div>
                    <div className="hidden md:flex md:flex-col gap-3 justify-between w-full  bg-white drop-shadow-md rounded-2xl p-3">
                      <div className="animate-pulse rounded-xl  h-[325.22px] bg-sky-200"></div>
                    </div>

                  </>
                )
                : data?.data?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div
                      onClick={() => {
                        handleBlogsDetailPage(item);
                      }}
                      style={{
                        backgroundImage: `url(${item?.banner_image})`,
                        backgroundSize: "500px",
                      }}
                      className="bg-no-repeat bg-auto bg-center rounded-[12px] px-24 py-32 bg-local hover:scale-105 hover:duration-500 ease-in relative cursor-pointer"
                    >
                      <div
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                        }}
                        className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed rounded-[12px]"
                      >
                        <div className="flex flex-col gap-4 px-4 py-14 text-white text-center items-center ">
                          <button className="px-4 py-1 bg-[#0973BA] w-fit rounded-[50px]">{item?.blog_category}</button>
                          <h2 className="font-extrabold tex-[28px] leading-[34.13px]">
                            {item?.title?.length > 80 ? item?.title.substring(0, 80) + "...." : item?.title}
                          </h2>
                          <p className="font-bold text-sm">{item?.posted_on}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default BlogsCarousel;
