import PersonalInformation from "./PersonalInformation";

const Settings = () => {
  return (
    <>
      <div className="w-full max-h-max my-auto bg-[#F0F7FC]">
        <PersonalInformation />
      </div>
    </>
  );
};

export default Settings;
