import React from "react";
import Banner from "views/partials/sliders/Banner";
import FooterMain from "views/partials/footer/FooterMain";
import GetMoreDoneWithKarsaaz from "common/GetMoreDoneWithKarsaaz";

const PrivacyPolicies = () => {
  return (
    <>
      <div>
        <Banner
          Heading="Privacy Policies"
          BackgroundImageStyling="bg-left-bottom "
          Descriptions="Last Updated: Latest"
          Icon={`${process.env.PUBLIC_URL}/images/PrivacyPolicyIcon.png`}
          backgroundImage={`${process.env.PUBLIC_URL}/images/FooterBackIcon.png`}
          BackgroundSecondImageStyling=" my-2 md:my-16 md:mr-[154px]"
          banner_height="574px "
        />
        {/* ----------------------- Articles ------------------------ */}

        <div className="w-full mt-[64px]">
          <div className="flex flex-col gap-10 w-[90%] mx-auto font-Trueno">
            <div className="">
              <p className="font-normal text-[18px] leading-8">
                This privacy policy, which applies whatever the means or the medium used to access the site karsaaz.app, its
                Android and iOS applications, its sub-domains and its services (hereinafter referred to as the "Application"),
                describes the conditions under which, when using the Application, we collect, use, and store information about
                you, including your personal data, as well as your choices regarding the collection, use and disclosure of these
                information.
                <br />
                <br />
                By accessing and / or using the Application, you acknowledge that you have read, understood and agree to be
                subject to all the terms and conditions of this document. If you do not agree with this policy, you should not use
                the Application and its related services and you should not disclose your personal information to us.
              </p>
            </div>

            {/* ----------------------------- Article are started from below ----------------------------- */}

            {/* ----------------------------- Article N0. 1 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                Article 1 - Identity of the person who collects the personal data
              </h1>
              <p className="font-normal text-[18px] leading-8 mt-6">
                The person in charge of the privacy policy is :
                <br />
                <br />
                The company Karsaaz Limited, a is private limited company in England and Wales with company registration number
                12631505, whose registered office is located at 71-75 Shelton Street, London, Greater London, United Kingdom, WC2H
                9JQ (hereinafter referred to as "KARSAAZ", "we").
              </p>
            </div>

            {/* ----------------------------- Article N0. 2 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px] mb-6">
                Article 2 - Operations in which we collect personal data{" "}
              </h1>
              <span className="font-normal text-[18px] leading-8">
                You can mostly navigate on the Application without identifying yourself, nor communicate your personal data.
                <br />
                You will be required to provide personal information :
                <br />
                <ul className="list-disc list-inside p-2">
                  <li>Create an account ;</li>
                  <li>Find the best service provider ;</li>
                  <li>Subscribe to our newsletter;</li>
                  <li>Contact Karsaaz ;</li>
                  <li>Send a review ;</li>
                </ul>
                Some personal data that can be requested to you are required. The mandatory nature of the data collected is
                indicated by an asterisk or a red triangle, the other data is optional.
              </span>
            </div>

            {/* ----------------------------- Article N0. 3 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px] mb-6">Article 3 - The personal data collected </h1>
              <span className="font-normal text-[18px] leading-8">
                KARSAAZ collects from you the following personal data :
                <br />
                <ul className="list-disc list-inside p-2">
                  <li>Name, first name, address, email , phone ;</li>
                  <li> Your username and password ;</li>
                  <li>Information concerning payment, including credit card information ;</li>
                  <li>Your reviews ;</li>
                  <li>Your Picture ;</li>
                  <li>Your IP address, connection and order history, preferences, interests, viewed products</li>
                  <li>
                    For Professionals : name of the company, description of the company's activities, the year on which the
                    company was created, number of employees, services offered, Facebook and Twitter pages, Registration Number of
                    your company.
                  </li>
                </ul>
              </span>
            </div>

            {/* ----------------------------- Article N0. 4 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px] mb-6">Article 4 – The purpose of collection</h1>
              <span className="font-normal text-[18px] leading-8">
                KARSAAZ collect personal data for the Application user for different goals :
                <br />
                Requests :
                <br />
                KARSAAZ collects the personal data of the clients to manage their requests for services, including to transmit the
                requests to the professionals, to communicate the quotes of the professionals, to deal with any complaints. In
                addition, KARSAAZ collects the personal data of the professionals to manage their accounts, send them requests for
                services, display their profiles, manage credit consumption, pay invoices and process any claims. In addition,
                KARSAAZ may analyze your data to improve and develop new services, identify trends in the use of the Application
                and determine the effectiveness of its promotional campaigns. In addition, these data will be retained in order to
                comply with various legal and regulatory requirements, in particular in order to enable us to establish proof of a
                right or a contract.
                <br />
                Newsletters :
                <br />
                KARSAAZ can use your contact details in order to send you commercial offers and promotions. As soon as you create
                your acco unt or from the "Notifications" section of your customer area, you can manage the sending of
                newsletters.
                <br />
                Sharing on social networks
                <br />
                The Application has sharing tools allowing you to "like" or share a product page on the following social networks:
                Facebook, Twitter. By sharing content on these social networks, you submit to their own personal data protection
                policy which we encourage you to consult to improve your information regarding the use of your personal data.
                <br />
                <ul className="list-disc list-inside p-2">
                  <li>Carrying out solicitations and / or surveys ;</li>
                  <li>Implementation of trade statistics ;</li>
                  <li>Organization of games contests, lotteries or any promotional operation ;</li>
                  <li>Management of requests for access, rectification and opposition ;</li>
                  <li>Debt collection and litigation management ;</li>
                  <li>Management of the reviews on services or content.</li>
                </ul>
              </span>
            </div>

            {/* ----------------------------- Article N0. 5 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px] mb-6">
                Article 5 - The recipients of the personal data collected{" "}
              </h1>
              <span className="font-normal text-[18px] leading-8">
                The personal data collected is intended for KARSAAZ and our partners and enables us to provide you high-quality
                services on our platform.
                <br />
                Thank you.
                <br />
                <ul className="">
                  <li>
                    {" "}
                    In addition, customer data useful for the execution of service requests can be communicated to professionals
                    and vice versa.
                  </li>
                  <li>
                    In addition, customer data useful for the execution of service requests can be communicated to professionals
                    and vice versa.
                  </li>
                  <li>
                    In addition, KARSAAZ can publish your requests and reviews on other third-party websites or applications.
                    These reviews and requests, which contain only your first name and the first letter of your surname, are
                    visible to the public.
                  </li>
                  <li>
                    In accordance with legal obligations, KARSAAZ may also disclose data to third parties when it is necessary to
                    investigate, prevent, take action regarding illegal activities, alleged fraud, situations that may involve
                    potential threats to the Or if we believe, in our sole discretion, that your use is incompatible with this
                    document or the general terms and conditions of use of the Application.
                  </li>
                  <li>
                    In addition, personal data may be disclosed to a third party if KARSAAZ is compelled to do so by law or
                    regulation, by court order, or if such disclosure is necessary in the context of an investigation, or a
                    procedure, on the national territory or abroad.
                  </li>
                </ul>
                To ensure a good performance of our services and, in particular, the management of our Client and Professional
                accounts, the data referred to within Article 3 of this document, can be transferred to our service providers,
                located in a foreign country (USA, Brazil, Bangladesh, India, Madagascar, Morocco, Russia, Ukraine). In order to
                ensure the perfect protection of your data, we carry out these data transfers either as part of the Privacy Shield
                | GDPR or within the framework of standard contractual clauses, concluded with our subcontractors and defined by
                the European Commission.
              </span>
            </div>

            {/* ----------------------------- Article N0. 6 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                Article 6 – Technical ressources to protect personal data{" "}
              </h1>
              <p className="font-normal text-[18px] leading-8 mt-6">
                You acknowledge that total security on the Internet does not exist. <br />
                However, KARSAAZ has implemented standard technical and organizational security measures to protect your data from
                accidental or intentional manipulation, loss, destruction, communication or any unauthorized access to information
                that we collect online.
                <br />
                The access to your customer area containing your personal data is thus only possible by means of an username and a
                password. You must not disclose or make this information available to others.
              </p>
            </div>

            {/* ----------------------------- Article N0. 7 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px] mb-6">Article 7 - Data privacy policies </h1>
              <span className="font-normal text-[18px] leading-8">
                In agreement with the new regulations regarding the use of your personal data, you have the following rights:
                <ul className="">
                  <li>You can modify anytime your personal data directly from your KARSAAZ Pro or Customer account.</li>
                  <li>
                    You can exercise your right of access by writing to the following email address: info@karsaaz.app. For such
                    cases, prior to undertaking the implementation of this right, we are able to request proof of the identity of
                    the user, in order to verify its accuracy.
                  </li>
                  <li>
                    You can request the rectification or removal of your personal data. We may retain certain information about
                    you, when required by law or when we have a legitimate reason to do so, especially if we believe or detect
                    fraud or violations of our Terms of Service.
                  </li>
                  <li>
                    You have the right to object at any time, to the use of your personal data for commercial prospection
                    purposes. You can also manage the sending of our newsletters or oppose such a sending, without charge, as soon
                    as you create your account. You can do so from the "Notifications" section of your customer area or by taking
                    the steps to opt-out, present in each newsletter we send.
                  </li>
                  <li>
                    You have the right to limit the processing performed on your data under the conditions defined by Article 18.1
                    of the General Data Protection Regulations (GDPR).
                  </li>
                  <li>
                    You have the right to file a complaint with the competent supervisory authority if you consider that we have
                    not respected your rights.
                  </li>
                  <li>
                    You have the right to data portability, that is, to receive this data in a structured, commonly used,
                    machine-readable format. Any request for portability can be made to the following address: info@karsaaz.app
                  </li>
                </ul>
              </span>
            </div>

            {/* ----------------------------- Article N0. 8 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">
                Article 8 - The lenght of the storage of your personal data{" "}
              </h1>
              <p className="font-normal text-[18px] leading-8 mt-6">
                Your personal data will be kept for the time necessary to achieve the purposes described above, in Article 4 of
                this document.
                <br />
                All data used for commercial prospecting purposes is deleted within a maximum of three years from the execution of
                the contract or last contact with the customer.
                <br />
                Your personal data will be kept for the time necessary to achieve the purposes described above, in Article 4 of
                this document.
              </p>
            </div>

            {/* ----------------------------- Article N0. 9 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px] mb-6">
                Article 9 – The processing of personal data by third party websites{" "}
              </h1>
              <span className="font-normal text-[18px] leading-8">
                This document applies only to pages on the Application through which KARSAAZ collects some of your personal data
                and not to websites held by third parties.
                <ul>
                  <li>
                    The Application may contain links to other websites or to features that are not subject to our privacy policy
                    and where practices regarding privacy policy may differ from ours. KARSAAZ does not own or operate these
                    websites.
                  </li>
                  <li>
                    Users should be aware of the privacy notices provided by the websites, as KARSAAZ is not responsible and has
                    no control over the information collected, used, disclosed and more generally handled by the sites Internet
                    third party.
                  </li>
                  <li>
                    In addition, hyperlinks to partner websites sometimes offer competitive games or promotions. By redirecting
                    you to the sites concerned, these third parties may obtain personal data that you voluntarily submit to them
                    to take advantage of this offer. KARSAAZ is not responsible for the use of your personal data by these third
                    parties.
                  </li>
                </ul>
              </span>
            </div>

            {/* ----------------------------- Article N0. 10 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px] mb-6">
                Article 10 - Application Policy on Cookies and Other devices{" "}
              </h1>
              <span className="font-normal text-[18px] leading-8">
                During your visit on the Application, cookies may be used on your terminal and in particular your computer, your
                tablet or your smartphone.
                <ul>
                  <li>
                    Our use of Cookies is subject to your express prior and specific consent, before they can be deposited on your
                    terminal. We obtain your consent through a banner that includes, on the one hand, clear and explicit
                    information on the purposes for which cookies and other devices are used and on the other hand information
                    about the mean for you to decline the use of Cookies.
                  </li>
                  <li>
                    {" "}
                    By using the Application, you agree to the use of Cookies in accordance with this document. If you do not
                    accept the use of these cookies and other devices, you can disable cookies by following the instructions below
                    under "Disabling cookies". However, certain areas of the Application are only accessible with Cookies called
                    "necessary" and you must know that by completely deactivating them you may not be able to access certain
                    contents or functionalities of the Application such as making a purchase.
                  </li>
                  <br />
                  <li>
                    Cookies are text files that can identify you as a client and save your personal preferences (for example, your
                    language choices) as well as technical information (including path data).
                  </li>
                  <li>
                    In addition, we may also use web beacons, also called "pixel web" or "Clear GIF" or similar technologies that
                    are used to verify how you use our Application and to see which pages of our Application you visit.
                  </li>

                  <br />
                  {/* ---- 10.2 version ---- */}
                  <li>10.2 - Types of Cookies used by the website</li>
                  <li>KARSAAZ uses different cookie types :</li>
                  <li>
                    Cookies called "necessary" allow you to benefit from the essential functions of the Application. Without these
                    Cookies you will not be able to use the Application normally and in particular order a product.
                  </li>
                  <br />
                  <li>The "necessary" Cookies are :</li>
                  <a target="_blank" rel="noreferrer" href="https://karsaaz.app" className="text-[#0973BA] underline">
                    https://karsaaz.app laravel
                  </a>

                  <br />
                  <br />
                  <li>
                    In addition, KARSAAZ is required to collaborate with other companies that place Cookies on the Application.
                    These companies assist us in the management of the Application and offer additional services. These Cookies
                    are not required for use of the Application. The use of these Cookies by these third parties is subject to
                    their own data processing conditions and is not covered by this document.
                  </li>

                  {/* ---- 10.3 version ---- */}
                  <li>10.3 – Disable cookies</li>

                  <br />
                  <li>10.3.1 From your web browser settings.</li>
                  <br />

                  <li>
                    Most browsers accept cookies by default. However, you can set your browser at any time to accept or reject
                    Cookies on a case-by-case basis or to refuse them systematically.
                    <br />
                    <br />
                    However, if you refuse to register the necessary Cookies or delete those that are already registered on your
                    device, you will no longer be able to benefit from a certain number of functionalities of the Application.
                    <br />
                    <br />
                    We decline any responsibility for the consequences linked to the degraded operation of the Application
                    resulting from the impossibility for KARSAAZ to register or to consult the necessary Cookies and that you
                    would have refused or deleted.
                    <br />
                    <br />
                    As a guideline, you will find below tips to disable cookies on your device. If you have any difficulties,
                    please consult the "Help" section of your browser where you can find more information on how to prevent
                    cookies and other similar technologies.
                    <br />
                    <br />
                    For Internet Explorer :
                    <br />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                      className="text-[#0973BA] underline"
                    >
                      https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
                    </a>
                    <br />
                    <br />
                    For Chrome :
                    <br />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://support.google.com/chrome/answer/95647?hl=en&hlrm=en"
                      className="text-[#0973BA] underline"
                    >
                      https://support.google.com
                    </a>
                    <br />
                    <br />
                    For Firefox :
                    <br />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                      className="text-[#0973BA] underline"
                    >
                      https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                    </a>
                    <br />
                    <br />
                    For Safari :
                    <br />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="http://www.apple.com/legal/privacy/en-ww/cookies/"
                      className="text-[#0973BA] underline"
                    >
                      http://www.apple.com/legal/privacy/en-ww/cookies/
                    </a>
                  </li>
                  <br />

                  {/* ---- 10.3.2 version ---- */}

                  <li>
                    10.3.2 Via professionals platforms
                    <br />
                    You can also visit www.youronlinechoices.com/en/controler-ses-cookies/ offered by the European Digital
                    Advertising Alliance (EDAA) to refuse or accept cookies used by registered companies to this platform.
                    <br />
                    Cookies are text files that can identify you as a client and save your personal preferences (for example, your
                    language choices) as well as technical information (including path data).
                    <br />
                    In addition, we may also use web beacons, also called "pixel web" or "Clear GIF" or similar technologies that
                    are used to verify how you use our Application and to see which pages of our Application you visit.
                  </li>
                </ul>
              </span>
            </div>

            {/* ----------------------------- Article N0. 11 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">Article 11 – Changes to the Personal Data Policy </h1>

              <p className="font-normal text-[18px] leading-8 mt-6">
                We may revise or supplement this document in order to take into account new purposes or changes in legislation.
                Such changes shall be effective as from their publication. To keep you informed of changes, please read this page
                regularly.
              </p>
            </div>

            {/* ----------------------------- Article N0. 12 ----------------------------- */}

            <div>
              <h1 className="font-semibold text-[26px] leading-[31.69px]">Article 12 – Contact KARSAAZ </h1>
              <p className="font-normal text-[18px] leading-8 mt-6">
                For any questions or remarks concerning this document or questions regarding the use of your personal data, you
                can contact us as follows:
                <br />
                To send an email to our data protection officer, please use the following email address:{" "}
                <a href="mailto:info@karsaaz.app" className="text-[#0973BA] underline">
                  info@karsaaz.app.
                </a>
              </p>
            </div>

            {/* ----------------------------- Article are End Now ----------------------------- */}
          </div>
        </div>

        <GetMoreDoneWithKarsaaz color="#E0F3FF" TextColor="#0973BA" />
        <FooterMain />
      </div>
    </>
  );
};

export default PrivacyPolicies;
