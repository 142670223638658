import { useSuperServices } from "hooks/useService";
import { useLocation, useNavigate } from "react-router-dom";
import { useSeo_page_view } from "hooks/useUser";
import { useEffect, useState } from "react";
import { showErrorToast } from "store/features/toastSlice";
import { useDispatch } from "react-redux";
import UpdateMetaTags from "utils/UpdateMetaTags";
import CustomerChoosesUs from "common/CustomerChoosesUs";
import CustomerLoveUs from "theme/cards/CustomerLoveUs";
import FooterMain from "views/partials/footer/FooterMain";
import RightSideBar from "common/RightSideBar";
import BookingNavbar from "views/partials/navbar/BookingNavbar";
import NavCustom from "views/partials/navbar/NavCustom";
import SearchBar from "common/SearchBar";
import "./../../index.css";
import CityFilter from "common/CityFilter";

const Home = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const slug = location.pathname.replace(/\//g, "");
  const { data: slug_data, isLoading: slug_data_isLoading } = useSeo_page_view({ slug });
  const { isLoading, isError, error, data: super_services } = useSuperServices();

  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    const storedOption = localStorage.getItem("selectedOption");
    if (storedOption) {
      setSelectedOption(storedOption);
    }
  }, []);

  const handleCityChange = () => {
    localStorage.getItem("selectedOption", selectedOption);
  };

  const handleClick = (item) => {
    if (localStorage.getItem("selectedOption") === null) {
      dispatch(showErrorToast("Select the City First"));
    } else if (item?.super_category_web_seo_url !== "") {
      // setTimeout(() => window.location.reload());

      navigate(item?.super_category_web_seo_url.match(/\/([^/]+)$/)[0], {
        state: {
          web_banner_image: item?.web_banner_image,
          web_banner_text: item?.web_banner_text,
          super_category_id: item?.super_category_id,
          super_category_name: item?.super_category_name,
          category_type: "category",
        },
      });
    } else {
      navigate(`/services/${item?.super_category_slug}`, {
        state: {
          web_banner_image: item?.web_banner_image,
          web_banner_text: item?.web_banner_text,
          category_id: item?.super_category_id,
          super_category_name: item?.super_category_name,
          category_type: "category",
        },
      });
    }
  };

  if (isError) return `Error - ${error}`;

  return (
    <>
      <UpdateMetaTags data={slug_data?.data ?? {}} />

      {/* -------------------- Home Section ----------------------- */}

      <div className=" w-full ">
        {slug_data_isLoading ? (
          <div>
            <div className="w-full mx-auto flex py-8  sm:hidden">
              <div className="w-full flex flex-col gap-3">
                <div className="w-full flex justify-between gap-3">
                  <div className="ml-28 flex gap-5 items-center w-[160px]">
                    <div className="animate-pulse w-full h-7 bg-sky-200"></div>
                    <div className="animate-pulse w-full h-9 bg-sky-200"></div>
                  </div>
                  <div className="mr-28 flex gap-5 items-center w-[360px]">
                    <div className="animate-pulse w-[150px] h-4 bg-sky-200"></div>
                    <div className="animate-pulse w-[150px] h-4 bg-sky-200"></div>
                    <div className="animate-pulse w-full h-8 rounded-2xl bg-sky-200"></div>
                  </div>
                </div>
                <div className="animate-pulse w-full h-full md:h-[188px] bg-sky-200 mt-3 rounded-2xl"></div>
              </div>
            </div>
          </div>
        ) : slug_data?.data?.web_image ? (
          <div
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/FooterBackIcon.png)`,
            }}
            className={`bg-[#0973BA] relative bg-no-repeat bg-[length:_500px] bg-left-bottom`}
          >
            <div className="flex flex-col w-full h-574px">
              <NavCustom styling={{ color: "white", backgroundColor: "#0973BA" }} />

              <RightSideBar />
              {isLoading ? (
                <div>
                  <div className="w-[90%] mx-auto flex flex-col-reverse md:grid md:grid-cols-2 p-3 py-12">
                    <div className="w-full flex flex-col gap-3 pt-5 md:py-12">
                      <div className="animate-pulse w-full h-7 bg-sky-200"></div>
                      <div className="animate-pulse w-full h-7 bg-sky-200"></div>
                      <div className="animate-pulse w-1/5 h-7 bg-sky-200"></div>

                      <div className="animate-pulse w-1/3 h-3 bg-sky-200 mt-3"></div>
                      <div className="animate-pulse w-full h-12 bg-sky-200 mt-3 rounded-2xl"></div>
                    </div>
                    <div className="flex items-center justify-center gap-5 w-full">
                      <div className=" animate-pulse w-[200px] h-[300px]  rounded-t-full rounded-b-full bg-slate-200"></div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="font-Trueno flex flex-col-reverse md:flex-row justify-between items-center w-[90%] mx-auto ">
                  <div className="md:w-[610px]">
                    <div
                      className="heading-style text-center md:text-left md:mb-[0px] mb-[51.8px]"
                      dangerouslySetInnerHTML={{ __html: slug_data?.data?.title }}
                    ></div>
                    {/* <div
                    className="font-normal text-base md:text-lg text-white my-6"
                    dangerouslySetInnerHTML={{ __html: super_category_description }}
                  ></div> */}

                    <div
                      className="font-normal text-base md:text-lg text-white my-6"
                      dangerouslySetInnerHTML={{ __html: slug_data?.data?.description }}
                    ></div>
                    <div className="my-3">
                      <SearchBar />
                    </div>
                  </div>
                  {/* <------------- Hero----Section----Image-------- >  */}
                  <div className="w-fit max-w-[363.76px] justify-center md:justify-start">
                    <img
                      src={slug_data?.data?.web_image}
                      alt=""
                      className="h-[331.36px] my-16 mb-[0px] md:mb-[81.8px] md:mr-[134px] "
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="w-full ">
            <BookingNavbar styling={{ color: "white", backgroundColor: "#0973BA" }} />
            <RightSideBar />

            <img src={slug_data?.data?.image} alt="" className="" />
          </div>
        )}
        <div className="w-[90%] mx-auto">
          <div></div>
          <div className="pt-[60px] pb-[30px] text-center">
            <h2 className="font-bold text-3xl md:text-[36px] leading-[43.2px]">Karsaaz Services</h2>
          </div>
          <div className="w-full flex justify-center md:justify-start pb-0 sm:pb-[50px] md:pb-[60px]">
            <CityFilter onCityChange={handleCityChange} />
          </div>

          <div className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-y-11 sm:gap-y-24 gap-x-3 lg:gap-5 mt-5 md:mt-[70px] mx-auto">
            {isLoading
              ? Array(4)
                  .fill()
                  .map((_, index) => (
                    <div
                      key={index}
                      className="w-full md:w-[90%] flex flex-col gap-4 md:gap-8 mx-auto justify-between bg-white drop-shadow-md rounded-xl p-3"
                    >
                      <div className="animate-pulse rounded-xl w-full h-[150px] md:h-[210.22px] bg-slate-200"></div>
                      <div className="p-1 md:p-3 px-0 md:px-6 space-y-2 md:space-y-4">
                        <div className="">
                          <div className="animate-pulse rounded-xl w-full h-[15px] md:h-[22px] bg-slate-200"></div>
                        </div>
                      </div>
                    </div>
                  ))
              : super_services?.data?.en?.map((item, index) => (
                  <button key={index} onClick={() => handleClick(item)}>
                    <div className="flex flex-col justify-between items-center h-full sm:h-[210px] md:h-[198px] border rounded-xl relative bg-opacity-[0.15]  bg-[#D6EFFF] p-3">
                      <div className="sm:m-3 m-0 sm:absolute top-[-33%] md:top-[-53%]">
                        <img
                          src={item?.super_category_icon}
                          alt={item?.super_category_name}
                          className="w-[191px] sm:w-[248.29px] md:w-[235.29px] mx-auto"
                        />
                      </div>

                      <div className="rounded-xl sm:absolute bottom-[2%] md:py-1 lg:py-2">
                        <h3 className="font-bold text-[14px] lg:text-[18px] leading-[16px] md:leading-[31px] text-center">
                          {item?.super_category_name}
                        </h3>
                      </div>
                    </div>
                  </button>
                ))}
          </div>
          <div className="mx-auto my-12">
            <div
              className="inner-html-seo-pages w-fit flex flex-col flex-wrap"
              dangerouslySetInnerHTML={{ __html: slug_data?.data?.contents }}
            ></div>
          </div>
        </div>
        <CustomerChoosesUs color="#ECF7FE" TextColor="black" />
        <CustomerLoveUs color="#FAFDFF" QuoteIconColor="#07609C" FaArrowCircleColor="#07609C" TextColor="#0973BA" />
        {/* <GetMoreDoneWithKarsaaz color="#ECF7FE" TextColor="#0973BA" /> */}
        <FooterMain />
      </div>
    </>
  );
};

export default Home;
