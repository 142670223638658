import CustomerChoosesUs from "common/CustomerChoosesUs";
import Banner from "views/partials/sliders/Banner";
import FooterMain from "views/partials/footer/FooterMain";
import CustomerLoveUs from "theme/cards/CustomerLoveUs";
import { StarIcon } from "@heroicons/react/24/solid";
import Collaborators from "common/Collaborators";

const AboutUs = (props) => {
  return (
    <div>
      <Banner
        bg-left-
        BackgroundImageStyling="bg-left-bottom "
        Heading="Karsaaz – Your Problem Solver for Home Services in Pakistan!"
        Icon={`${process.env.PUBLIC_URL}/images/HandyManIcon.png`}
        backgroundImage={`${process.env.PUBLIC_URL}/images/FooterBackIcon.png`}
        BackgroundSecondImageStyling="h-[305.2px] my-16 mb-[0px] md:mb-[81.8px] md:mr-[154px]"
        banner_height="574px"
      />

      <div className="w-full font-Trueno my-[96px]">
        <div className="w-[90%] mx-auto flex flex-col gap-16 ">
          <div>
            <h1 className="flex gap-2 font-extrabold text-[36px] leading-[43.88px]">
              About
              <span className="text-[#0973BA]">Us</span>
            </h1>
          </div>
          <div className="flex flex-col gap-16 justify-between items-center">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-44  px-8 border-[#0973BA] border-l-8 font-normal text-[16px] leading-8 text-left">
              <p className="">
                It would be frustrating to connect with local professionals in
                every field in your area. Also, being a homeowner, you may have
                faced the problem of upgrading your home according to modern
                trends. Other than this, it would be a mission for you to find
                the right professional for the desired task. Many people also
                get trapped with scammers when picking someone for home
                maintenance, tailoring, or salon needs. Thus, focusing on the
                IDEA to bring ease to your doorstep, Karsaaz launched its
                website and app. Through this innovation, you’ll have access to
                get your problem solved via smartphone.
              </p>
              <p className="">
                Imagine opening an app on your mobile and getting all your needs
                fulfilled without stepping a single foot from your house! Would
                it be fantastic? Well, it will bring you the ease you’ve always
                dream of.
                <br />
                You’ll no longer have to look for professionals locally. Click
                “search,” type your category, and get connected with your
                desired assistant. We’re working to help people get the best
                home repair services, and salon needs fulfillment for women
                without heading out of their homes.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-32  text-left">
              <div className="flex flex-col gap-5 border rounded-2xl drop-shadow-md p-14">
                <img
                  src={`${process.env.PUBLIC_URL}/images/VisionIcon.png`}
                  alt="VisionIcon"
                  className="w-[92px] h-[92px]"
                />
                <h1 className=" font-extrabold text-[36px] leading-[43.88px]">
                  Our Vision
                </h1>
                <p className=" font-normal text-[16px] leading-8 ">
                  If you ask about our vision of helping homeowners like you, we
                  strive day and night to bring ease to your doorstep. With a
                  mobile-friendly app, we allow our customers to hunt experts
                  for their needs. We’re empowering local experts by providing
                  them with online orders through our app. In this way, they
                  will get a chance to polish their skills.
                </p>
              </div>
              <div className="flex flex-col gap-5  p-7">
                <img
                  src={`${process.env.PUBLIC_URL}/images/MissionIcon.png`}
                  alt=""
                  className="w-[92px] h-[92px]"
                />
                <h1 className=" font-extrabold text-[36px] leading-[43.88px]">
                  Our Mission
                </h1>
                <p className=" font-normal text-[16px] leading-8 ">
                  Besides, to help you and get you covered in all home services
                  (including home maintenance to the salon), Karsaaz brings
                  everything out of the box. Being in the industry for over a
                  decade, we have made a mobile-friendly app. We also aim to
                  provide dedicated home maintenance services in different
                  categories to cater to our customer's needs. Having a website,
                  we considered it necessary to bring an innovation that can
                  connect you directly with the local professionals and, of
                  course, our team. We made bringing you all services under one
                  roof our top mission. You can trust us to find the best expert
                  for your problems, not only during working hours but 24/7.
                </p>
              </div>
            </div>

            {/* ----------------------------- EQRP Model ----------------------------- */}

            <div className="  w-full lg:w-[848px] ">
              <h1 className="font-extrabold text-2xl md:text-[36px] md:leading-[52px]  text-center">
                Our Selection Process Based on
                <span className="text-[#0973BA]"> EQRP </span>
                Model
              </h1>
              <p className="font-normal text-base md:text-[18px] leading-[31px] mt-[23px] text-center ">
                We don’t believe in connecting you with professionals without
                authentication of their expertise. So, to connect you with
                worthy individuals of the industry, we follow the EQRP model. It
                includes:
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[41.46px] my-[73px] ">
              <div className="flex flex-col gap-4 justify-between my-4 md:my-24 ">
                <div className="flex flex-col gap-3 h-full md:h-[222px] border rounded-lg drop-shadow-md p-10">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ExpertiseIcon.png`}
                    alt="VisionIcon"
                    className="w-[46px] h-[61px]"
                  />
                  <h1 className=" font-extrabold text-[18px] leading-[21.94px]">
                    Expertise
                  </h1>
                  <p className=" font-normal text-[14px] leading-4 ">
                    Prefer people with years of experience. They must be masters
                    of their skills.
                  </p>
                </div>
                <div className="flex flex-col gap-3 h-full md:h-[222px] border rounded-lg  drop-shadow-md p-10">
                  <StarIcon color="#FEC007" className="w-[57px] h-[57px]" />
                  <h1 className=" font-extrabold text-[18px] leading-[21.94px]">
                    Reputation
                  </h1>
                  <p className=" font-normal text-[14px] leading-4 ">
                    Ability to satisfy customers with 5-star ratings.
                  </p>
                </div>
              </div>
              {/* ------------------------- Middle Image ------------------------- */}

              <div className="hidden md:block">
                {/* <img src={IPhone12Icon} alt="" className="hidden md:block" /> */}
                <img
                  src={`${process.env.PUBLIC_URL}/images/IPhone12Icon.svg`}
                  alt=""
                  className="w-full h-full"
                />
              </div>

              {/* ------------------------- Third Row ------------------------- */}

              <div className="flex flex-col gap-4 justify-between my-4 md:my-24">
                <div className="flex flex-col gap-3 h-full md:h-[222px] border rounded-lg  drop-shadow-md  p-10">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/QualificationIcon.png`}
                    alt="VisionIcon"
                    className="w-[55.22px] h-[55.22px]"
                  />
                  <h1 className=" font-extrabold text-[18px] leading-[21.94px]">
                    Qualification
                  </h1>
                  <p className=" font-normal text-[14px] leading-4 ">
                    Consideration for certifications, licensing, accreditation,
                    and awards when choosing professionals.
                  </p>
                </div>
                <div className="flex flex-col gap-3 h-full md:h-[222px] border rounded-lg  drop-shadow-md  p-10">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ProfessionalismIcon.png`}
                    alt="VisionIcon"
                    className="w-[54px] h-[68px]"
                  />
                  <h1 className=" font-extrabold text-[18px] leading-[21.94px]">
                    Professionalism
                  </h1>
                  <p className=" font-normal text-[14px] leading-4 ">
                    We choose individuals with their grip to constantly work
                    without compromising on quality.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomerLoveUs
        color="#FAFDFF"
        QuoteIconColor="#07609C"
        FaArrowCircleColor="#07609C"
        TextColor="black"
      />

      <Collaborators />

      <CustomerChoosesUs color="#E0F3FF" TextColor="#07609C" />
      <FooterMain />
    </div>
  );
};

export default AboutUs;
