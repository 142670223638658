import { useState, useEffect } from "react";

const DatePicker = ({
  booking,
  setFieldValue,
  values,
  errors,
  touched,
  handleBlur,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
}) => {
  const now = new Date(); // Get current system time
  const currentHour = now.getHours(); // Get current hour in 24-hour format

  let timeOptions = [];
  const startTime = 9;
  const endTime = 22;
  const interval = 30; // Time interval to 30 minutes
  const currentMinute = now.getMinutes(); // Get current minute

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  const selectedDateObj = new Date(selectedDate);
  const isToday = now.toDateString() === selectedDateObj.toDateString();

  if (isToday) {
    const startHour = currentHour < 23 ? currentHour + 1 : currentHour;

    for (let hour = startHour; hour <= endTime; hour++) {
      for (let minute = 0; minute < 60; minute += interval) {
        // Check if the time is greater than the current time and minute
        if (hour > currentHour || (hour === currentHour && minute >= currentMinute)) {
          const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
          const ampm = hour < 12 || hour === 24 ? "am" : "pm";
          const formattedMinute = minute === 0 ? "00" : minute;
          const time = `${formattedHour}:${formattedMinute} ${ampm}`;
          timeOptions.push(time);
        }
      }
    }
  } else if (localStorage.getItem("selectedBooking") === "0") {
    for (let hour = startTime; hour <= currentHour; hour++) {
      for (let minute = 0; minute < 60; minute += interval) {
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        const ampm = hour < 12 || hour === 24 ? "am" : "pm";
        const formattedMinute = minute === 0 ? "00" : minute;
        const time = `${formattedHour}:${formattedMinute} ${ampm}`;
        timeOptions.push(time);
      }
    }
  } else {
    for (let hour = startTime; hour <= endTime; hour++) {
      for (let minute = 0; minute < 60; minute += interval) {
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        const ampm = hour < 12 || hour === 24 ? "am" : "pm";
        const formattedMinute = minute === 0 ? "00" : minute;
        const time = `${formattedHour}:${formattedMinute} ${ampm}`;
        timeOptions.push(time);
      }
    }
  }

  if (isToday || localStorage.getItem("selectedBooking") === "0") {
    timeOptions = timeOptions.slice(2);
  }
  timeOptions = timeOptions.slice(0, -1);

  const handleDateChange = (event) => {
    setFieldValue("selectedDate", event.target.value);
    setSelectedDate(event.target.value);
    // localStorage.setItem("selectedDate", event.target.value);
  };

  const handleTimeChange = (event) => {
    setFieldValue("selectedTime", event.target.value);
    setSelectedTime(event.target.value);
    // localStorage.setItem("selectedTime", event.target.value);
  };

  useEffect(() => {
    if (selectedDate === null) {
      const date = new Date().toJSON().slice(0, 10);
      setSelectedDate(date);
      setFieldValue("selectedDate", date);
      // localStorage.setItem("selectedDate", date);
    }
  }, []);

  return (
    <>
      <div className="w-full flex flex-col md:flex md:flex-row justify-between gap-4 ">
        <div>
          <label htmlFor="Select Date *" className="font-normal text-[14px] leading-[16.8px]">
            Select Date *
          </label>
          <input
            type="date"
            className="no-clear-button bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full  pl-10 p-2.5 no-clear-button"
            placeholder="Select date"
            id="selectedDate"
            name="selectedDate"
            value={selectedDate ?? ""}
            onBlur={handleBlur}
            onChange={handleDateChange}
            min={new Date().toJSON().slice(0, 10)} // Set minimum date as current date
            max={localStorage.getItem("selectedBooking") === "0" ? new Date(tomorrowDate).toJSON().slice(0, 10) : ""}
            open
          />
          {errors.selectedDate && touched.selectedDate && <div className="text-red-500 text-sm">{errors.selectedDate}</div>}
        </div>

        <div>
          <label htmlFor="time" className="mb-2 text-gray-700">
            Select a time:
          </label>

          <div className="rounded-2xl border border-gray-300 p-4 md:w-[465px]">
            <div className="flex flex-wrap md:flex md:flex-wrap gap-4 w-full ">
              {timeOptions.map((time) => (
                <label key={time} className="flex items-center  font-semibold text-sm">
                  <input
                    type="radio"
                    name="selectedTime"
                    id="selectedTime"
                    value={time ?? ""}
                    checked={selectedTime === time}
                    onChange={handleTimeChange}
                    className="sr-only"
                    onBlur={handleBlur}
                  />
                  <span
                    className={`border border-gray-200 rounded-md px-4 py-2 cursor-pointer ${
                      selectedTime === time ? "bg-blue-500 text-white" : "text-[#979797]"
                    }`}
                  >
                    {time}
                  </span>
                </label>
              ))}
            </div>
            {errors.selectedTime && touched.selectedTime && (
              <div className="text-red-500 text-sm mt-7">{errors.selectedTime}</div>
            )}

            <div className="mt-5">
              {selectedTime && (
                <p className="text-[#979797]">
                  Your selected Time is : {selectedTime} and selected Date is : {selectedDate}.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatePicker;
