import RightSideBar from "common/RightSideBar";
import { useState } from "react";
import BookingNavbar from "views/partials/navbar/BookingNavbar";
import StepperMain from "views/stepperBookingForm/StepperMain";

const BookingMain = (props) => {
  const [currentStep, setCurrentStep] = useState(0);

  // const { job_type, description, request_id } = location?.state ?? 2;

  const card_json_data = localStorage.getItem("cardDetails");

  const CardDetails = JSON.parse(card_json_data);

  const title = CardDetails?.title;

  return (
    <>
      <div className="flex flex-col items-center w-full mx-auto px-3 font-Trueno bg-[#E6F2FA]">
        {currentStep >= 0 && !localStorage.getItem("token") ? (
          <BookingNavbar color="#E6F2FA" />
        ) : (
          <BookingNavbar color="#E6F2FA" />
        )}
        <div className="hidden md:block">
          <RightSideBar />
        </div>
        <div className="mt-5 md:mt-11">
          <h1 className="text-center font-extrabold text-[18px] md:text-[24px] leading-[26px] md:leading-[29.26px]">
            Book your " {title} " Service
          </h1>
        </div>

        <div className="w-full max-w-[880px]  my-10 md:my-20 h-full bg-white border  rounded-2xl shadow-2xl">
          <StepperMain currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </div>
      </div>
    </>
  );
};

export default BookingMain;
