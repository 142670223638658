import FooterMain from "views/partials/footer/FooterMain";
import GetMoreDoneWithKarsaaz from "common/GetMoreDoneWithKarsaaz";
import { useFaq_categories, useFaq_questions_answers } from "hooks/useUser";
import { useState } from "react";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import NavCustom from "./partials/navbar/NavCustom";
import RightSideBar from "common/RightSideBar";
import SearchBar from "common/SearchBar";

const FAQ = () => {
  const [isOpenArray, setIsOpenArray] = useState([]);
  const [clickedButtonIndex, setClickedButtonIndex] = useState(null);

  const { data: faq_categories } = useFaq_categories();
  const {
    mutate,
    data: faq_questions_answers,
    isLoading,
  } = useFaq_questions_answers();

  const handle_faq_questions = (item) => {
    const payload = {
      faq_category_id: item?.id,
    };
    mutate(payload);
  };

  const toggleDropdown = (index) => {
    setIsOpenArray((prevState) => {
      const updatedIsOpenArray = [...prevState];
      updatedIsOpenArray[index] = !updatedIsOpenArray[index];
      return updatedIsOpenArray;
    });
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/FooterBackIcon.png)`,
          }}
          className={`bg-[#0973BA] relative bg-no-repeat bg-[length:_500px] bg-left-bottom`}
        >
          {/* <div style={props.style} className="bg-[#0973BA] relative bg-no-repeat"> */}
          <div className="flex flex-col w-full h-574px">
            <NavCustom
              styling={{ color: "white", backgroundColor: "#0973BA" }}
            />

            <RightSideBar />

            <div className="font-Trueno flex flex-col-reverse md:flex-row justify-between items-center w-[90%] mx-auto ">
              <div className="w-full md:w-[489px] text-center md:text-left">
                <h1 className=" font-extrabold text-[24px] md:text-[48px] leading[58.51px] text-white">
                  FAQ
                </h1>
                <p className="font-normal text-base md:text-lg text-white">
                  Have Questions? We Are Here to help
                </p>
                <div className="my-3">
                  <SearchBar />
                </div>
              </div>
              {/* <------------- Hero----Section----Image-------- > */}
              <div className=" w-fit ">
                {/* <img src={HandyManIcon} alt="" className=" h-[363.97px] " /> */}
                <img
                  src={`${process.env.PUBLIC_URL}/images/FAQIcon.png`}
                  alt=""
                  className="h-auto my-16 md:mr-[49px]"
                />
              </div>
            </div>
          </div>
        </div>

        {/* --------------------- Most Asked Questions --------------------- */}
        <div className="w-full">
          <div className="w-[90%] mx-auto flex flex-col gap-8 justify-between items-center text-center mt-20">
            <div className="md:w-[719px]">
              <h1 className="font-extrabold text-[28px] md:text-4xl">
                Find the most asked questions and their answers right here.
              </h1>
            </div>
            <div className="grid md:flex gap-8">
              {faq_categories?.data?.map((item, index) => (
                <button
                  key={index}
                  onClickCapture={() => {
                    handle_faq_questions(item);
                    setClickedButtonIndex(index);
                  }}
                  className={`font-semibold text-sm border border-black rounded-xl px-[22px] py-3 ${clickedButtonIndex === index
                      ? "bg-[#0973BA] text-white"
                      : ""
                    }`}
                >
                  {item?.name}
                </button>
              ))}
            </div>

            {isLoading
              ? Array(1)
                .fill()
                .map((_, index) => (
                  <div
                    key={index}
                    className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-[#0973BA] border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      Loading...
                    </span>
                  </div>
                ))
              : faq_questions_answers?.data?.map((item, index) => (
                <div
                  className="w-full mx-auto border border-[#DFDFDF] rounded-xl font-Trueno"
                  key={index}
                >
                  <div className="relative p-6">
                    <button
                      onClick={() => toggleDropdown(index)}
                      className="flex justify-between items-center w-full font-semibold text-[20px] leading-[26px]"
                    >
                      <h1
                        className="inner-html-faq-questions-answers"
                        dangerouslySetInnerHTML={{ __html: item?.question }}
                      ></h1>
                      {isOpenArray[index] ? (
                        <FaArrowAltCircleUp
                          color="#0973BA"
                          className="w-[25px] h-[25px]"
                        />
                      ) : (
                        <FaArrowAltCircleDown
                          color="#0973BA"
                          className="w-[25px] h-[25px]"
                        />
                      )}
                    </button>
                  </div>

                  {/* Dropdown menu, show/hide based on menu state */}
                  <div
                    className={`${isOpenArray[index] ? "" : "hidden"
                      } p-6 border-t`}
                  >
                    <div
                      className="inner-html-faq-questions-answers"
                      dangerouslySetInnerHTML={{ __html: item?.answer }}
                    ></div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* --------------------- StillHaveQuestion --------------------- */}

        <div className="w-full">
          <div className="w-[90%] mx-auto mt-[61px] bg-[#dcfce7] border rounded-xl p-14">
            <div className="grid md:flex justify-between items-center ">
              <div>
                <h2 className="font-extrabold text-[28px] md:text-4xl">
                  Still Have A Question?
                </h2>
                <p className="font-normal text-base md:text-[18px] leading-[57px]">
                  Can't find the answer you are looking for? Please contact us
                  now
                </p>
              </div>
              <div className="bg-[#09BA70] py-4 px-8 rounded-xl text-center">
                <Link to="/contact-us">
                  <button
                    type="button"
                    className="font-extrabold text-sm text-white "
                  >
                    Contact Us Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <GetMoreDoneWithKarsaaz color="#E0F3FF" />
        <FooterMain />
      </div>
    </>
  );
};

export default FAQ;
