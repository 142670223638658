import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { checkIsUserLoggedIn } from 'actions/auth';
import Home from 'views/Home';
import FAQ from 'views/FAQ';
import PrivacyPolicies from 'views/PrivacyPolicies';
import PersonalInformation from 'views/pages/settingsPage/PersonalInformation';
import ProfessionalDetails from 'views/pages/dashboard/ProfessionalDetails';
import ChangePassword from 'views/pages/settingsPage/ChangePassword';
import SubCategories from 'views/categoriesServices/SubCategories';
import BookingMain from 'views/stepperBookingForm/BookingMain';
import AboutUs from 'views/AboutUs';
import Settings from 'views/pages/settingsPage/Settings';
import Location from 'views/pages/settingsPage/Location';
import FavoriteProvider from 'views/pages/jobs/FavoriteProvider';
import ContactUs from 'views/ContactUs';
import LogIn from 'views/auth/LogIn';
import CreateAccount from 'views/auth/CreateAccount';
import JoinAsServiceProvider from 'views/auth/JoinAsServiceProvider';
import ForgotPassword from 'views/auth/ForgotPassword';
import MyJobs from 'views/pages/jobs/MyJobs';
import History from 'views/pages/jobs/History';
import JobPage from 'views/pages/dashboard/JobPage';
import Services from 'views/Services';
import OfferDetails from 'views/pages/dashboard/OfferDetails';
import EditBooking from 'views/pages/dashboard/EditBooking';
import PopularServices from 'common/PopularServices';
import CategorySEO from 'views/seoPages/CategorySEO';
import TermsAndConditions from 'views/TermsAndConditions';
import ProtectedRoutes from 'actions/PortectedRoutes';
import PublicRoutes from 'actions/PublicRoutes';
import JobPostedSuccessfully from 'views/bookingSteps/JobPostedSuccessfully';
import HomePageSEO from 'views/seoPages/HomePageSEO';
import SearchResults from 'common/SearchResults';
import Blogs from 'views/blogs/Blogs';
import BlogsSearchResults from 'views/blogs/BlogsSearchResults';
import BlogDetailPage from 'views/blogs/BlogDetailPage';
import RequestDetailPage from 'views/pages/dashboard/RequestDetailPage';
import SocialAccount from 'views/pages/settingsPage/SocialAccount';
import AdminUserLogin from 'views/auth/AdminUserLogin';
import TransactionHistory from 'views/paymob/TransactionHistory';
import DeleteAccount from 'views/pages/settingsPage/DeleteAccount';
import InviteEarn from 'views/rewards/InviteEarn';
import ConsentBanner from 'views/partials/consent/ConsentBanner';
import GonePage from './common/GonePage';
import MetaTags from './common/MetaTags';

//rendering the sub component
function App() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const auth = useSelector((state) => state.auth);

  // const navigateTo = (to) => {
  //   navigate(to);
  // };

  useEffect(() => {
    dispatch(checkIsUserLoggedIn());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (auth?.isUserLoggedIn === false) {
  //     navigateTo("/home");
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [auth?.isUserLoggedIn]);

  // console.log(useSelector((state) => state));

  return (
    <>
      <div className="App">
        <MetaTags />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="*" element={<Home />} />
          <Route path="/home" element={<Home />} />
          {/* ---------------------- Public Routes ---------------------- */}
          <Route exact path="/login" element={<PublicRoutes />}>
            <Route path="/login" element={<LogIn />} />
          </Route>
          <Route exact path="/register" element={<PublicRoutes />}>
            <Route path="/register" element={<CreateAccount />} />
          </Route>
          <Route
            exact
            path="/join-as-service-provider"
            element={<PublicRoutes />}
          >
            <Route
              path="/join-as-service-provider"
              element={<JoinAsServiceProvider />}
            />
          </Route>
          <Route exact path="/forgot-password" element={<PublicRoutes />}>
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route exact path="/booking" element={<BookingMain />} />
          <Route exact path="/faqs" element={<FAQ />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/popular-services" element={<PopularServices />} />
          <Route
            exact
            path="/karsaaz-terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route exact path="/privacy-policy" element={<PrivacyPolicies />} />
          <Route exact path="/location" element={<Location />} />
          <Route
            exact
            path="/services/:super_category_slug"
            element={<Services />}
          />
          <Route
            exact
            path="/sub-categories/:category_slug"
            element={<SubCategories />}
          />
          <Route
            exact
            path="/search/:sub_category_slug"
            element={<SearchResults />}
          />
          <Route exact path="/:slug" element={<CategorySEO />} />
          <Route exact path="/lahore" element={<HomePageSEO />} />
          <Route exact path="/Karachi" element={<HomePageSEO />} />
          <Route exact path="/Islamabad" element={<HomePageSEO />} />
          <Route exact path="/Rawalpindi" element={<HomePageSEO />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route
            exact
            path="/blogs/search/:slug"
            element={<BlogsSearchResults />}
          />
          <Route exact path="/blogs/:slug" element={<BlogDetailPage />} />
          <Route exact path="/admin/user-login" element={<AdminUserLogin />} />

          {/* ---------------------- 301 Redirections ---------------------- */}
          <Route
            path="/privacy"
            element={<Navigate to="/privacy-policy" replace />}
          />
          <Route
            path="/water-tank"
            element={
              <Navigate to="/professional-home-cleaning-services" replace />
            }
          />
          <Route
            path="/advantages-of-solar-installation"
            element={<Navigate to="/solar-panel-installation" replace />}
          />
          <Route
            path="/personal-care-and-beauty-tips"
            element={<Navigate to="/beauty-personal-care" replace />}
          />
          <Route path="/signup" element={<Navigate to="/register" replace />} />
          <Route
            path="/terms"
            element={<Navigate to="/karsaaz-terms-and-conditions" replace />}
          />

          {/* ---------------------- 410 Redirections ---------------------- */}
          <Route
            path="/massage-services-for-women-lahore"
            element={<GonePage />}
          />
          <Route path="/massage-service-islamabad" element={<GonePage />} />
          <Route path="/half-body-wax-islamabad" element={<GonePage />} />
          <Route
            path="/electric-hair-repair-islamabad"
            element={<GonePage />}
          />
          <Route path="/Half-Body-Wax-Service" element={<GonePage />} />
          <Route path="/half-body-wax-rawalpindi" element={<GonePage />} />
          <Route path="/half-body-wax-karachi" element={<GonePage />} />

          {/* ---------------------- Protected Routes ---------------------- */}
          <Route exact path="/request/details" element={<ProtectedRoutes />}>
            <Route
              exact
              path="/request/details/:request_id"
              element={<RequestDetailPage />}
            />
          </Route>
          <Route
            exact
            path="/karsaaz-app-rewards"
            element={<ProtectedRoutes />}
          >
            <Route exact path="/karsaaz-app-rewards" element={<InviteEarn />} />
          </Route>
          <Route exact path="/user/job/success" element={<ProtectedRoutes />}>
            <Route
              exact
              path="/user/job/success"
              element={<JobPostedSuccessfully />}
            />
          </Route>
          <Route exact path="/my-jobs" element={<ProtectedRoutes />}>
            <Route exact path="/my-jobs" element={<MyJobs />} />
          </Route>
          <Route exact path="/history" element={<ProtectedRoutes />}>
            <Route exact path="/history" element={<History />} />
          </Route>
          <Route
            exact
            path="/transaction-history"
            element={<ProtectedRoutes />}
          >
            <Route
              exact
              path="/transaction-history"
              element={<TransactionHistory />}
            />
          </Route>
          <Route
            exact
            path="/personal-information"
            element={<ProtectedRoutes />}
          >
            <Route
              exact
              path="/personal-information"
              element={<PersonalInformation />}
            />
          </Route>
          <Route exact path="/change-password" element={<ProtectedRoutes />}>
            <Route exact path="/change-password" element={<ChangePassword />} />
          </Route>
          <Route exact path="/social-account" element={<ProtectedRoutes />}>
            <Route exact path="/social-account" element={<SocialAccount />} />
          </Route>
          <Route exact path="/delete-account" element={<ProtectedRoutes />}>
            <Route exact path="/delete-account" element={<DeleteAccount />} />
          </Route>
          <Route exact path="/favorite-provider" element={<ProtectedRoutes />}>
            <Route
              exact
              path="/favorite-provider"
              element={<FavoriteProvider />}
            />
          </Route>
          <Route
            exact
            path="/professional-details"
            element={<ProtectedRoutes />}
          >
            <Route
              exact
              path="/professional-details"
              element={<ProfessionalDetails />}
            />
          </Route>
          <Route exact path="/offer-details" element={<ProtectedRoutes />}>
            <Route exact path="/offer-details" element={<OfferDetails />} />
          </Route>
          <Route exact path="/job-page" element={<ProtectedRoutes />}>
            <Route exact path="/job-page" element={<JobPage />} />
          </Route>
          <Route
            exact
            path="/personal-information"
            element={<ProtectedRoutes />}
          >
            <Route exact path="/personal-information" element={<Settings />} />
          </Route>
          <Route exact path="/edit-booking" element={<ProtectedRoutes />}>
            <Route exact path="/edit-booking" element={<EditBooking />} />
          </Route>
        </Routes>
        <ConsentBanner />
      </div>
    </>
  );
}

export default App;
