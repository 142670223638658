import { useBlogs_search } from "hooks/useUser";
import React, { useState, useEffect, useRef } from "react";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const BlogsSearchBar = () => {
  const [query, setQuery] = useState("");

  const navigate = useNavigate();

  const handleSearchRequest = () => {
    navigate(`/blogs/search/${query}`, {
      state: {
        BlogsSearches: query,
      },
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchRequest();
    }
  };

  return (
    <>
      <div className="relative font-Trueno h-full">
        <BsSearch
          type="submit"
          color="black"
          onClick={() => {
            handleSearchRequest();
          }}
          className="w-[15.83px] h-[15.83px] absolute inset-y-0 right-4  border-left top-[18px] cursor-pointer"
        />
        <input
          type="text"
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          onKeyPress={handleKeyPress}
          placeholder="Search the service you need! "
          className="border rounded-[15px] border-[#DFDFDF] outline-none w-full h-[52px] py-3 pl-5 md:pl-9 pr-11 placeholder:not-italic placeholder:text-4 placeholder:leading-4  placeholder:text-base text-black"
        />
      </div>
    </>
  );
};

export default BlogsSearchBar;
