import { useEffect } from "react";
import { useRegister } from "hooks/useAuth";
import { showSuccessToast } from "store/features/toastSlice";
import { useDispatch } from "react-redux";
import { BsFillPersonFill } from "react-icons/bs";
import ConfirmMobileNumberModal from "./ConfirmMobileNumberModal";

const CheckoutAsGuestButton = ({
  user,
  setUser,
  setCurrentStep,
  setRandomPassword,
  handle_request_submit,
  confirmMobileNumModalGuest,
  setConfirmMobileNumModalGuest,
  mobile_number,
  setMobile_number,
  setOtp_modal_data,
  otp_modal_data,
}) => {
  const dispatch = useDispatch();
  const { mutate, isLoading, data: register_data } = useRegister();

  const random_password = Math.random().toString(36).slice(2).substr(0, 6);

  useEffect(() => {}, [random_password]);

  const handleSubmitGuest = () => {
    const payload = {
      mobile: sessionStorage.getItem("mobile_number"),
      password: "123456",
      name: "Guest User",
      device_type: "web",
      login_by: "manual",
      isbuyer: "yes",
      gender: "male",
    };

    mutate(payload);

    setConfirmMobileNumModalGuest(true);

    sessionStorage.setItem("guest", true);

    setRandomPassword(payload?.password);
  };

  useEffect(() => {
    if (register_data?.success === true) {
      const payload = {
        token: register_data?.data?.token,
        id: register_data?.data?.user_id,
      };
      setOtp_modal_data(payload);

      localStorage.setItem("token", register_data?.data?.token);
      localStorage.setItem("id", register_data?.data?.user_id);
      setCurrentStep(5);
      dispatch(showSuccessToast(register_data?.message));
    } else if (register_data && register_data?.success) {
      handle_request_submit();

      setCurrentStep(5);
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [register_data]);

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setUser("guest");
          setConfirmMobileNumModalGuest(true);
        }}
        className="flex justify-center items-center font-semibold text-xs md:text-sm text-white text-center bg-[#0973BA] py-[14px] px-4 w-full border border-[#0973BA] rounded-lg "
      >
        {isLoading ? (
          <p className="font-semibold text-xs md:text-sm text-white text-center">
            Please Wait...
          </p>
        ) : (
          <div className="w-full flex justify-center items-center">
            <BsFillPersonFill className="flex-none grow-0" />
            <p className="grow">Checkout as Guest</p>
          </div>
        )}
      </button>
      {confirmMobileNumModalGuest && (
        <ConfirmMobileNumberModal
          setConfirmMobileNumModalGuest={setConfirmMobileNumModalGuest}
          handleSubmitGuest={handleSubmitGuest}
          isLoading={isLoading}
          onClose={() => {
            setConfirmMobileNumModalGuest(false);
          }}
          user={user}
          setMobile_number={setMobile_number}
        />
      )}
    </>
  );
};

export default CheckoutAsGuestButton;
