import { IS_USER_LOGGED_IN } from "helpers/constants";

export const auth = (auth = { isUserLoggedIn: false }, action) => {
  switch (action.type) {
    case IS_USER_LOGGED_IN:
      return { ...auth, isUserLoggedIn: action.payload };

    default:
      return auth;
  }
};

// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// // Define a service using a base URL and expected endpoints

// const bookApi = createApi({
//   reducerPath: "booking",
//   baseQuery: fetchBaseQuery({
//     baseUrl: process.env.REACT_APP_API_URL,
//   }),

//   endpoints: (builder) => {
//     return {
//       bookGetOffer: builder.mutation({
//         query: (body) => {
//           return {
//             url: "/user/request_later",
//             method: "POST",
//             body,
//           };
//         },
//       }),
//       bookRequestLater: builder.mutation({
//         query: (body) => {
//           return {
//             url: "/user/request_later",
//             method: "POST",
//             body,
//           };
//         },
//       }),
//     };
//   },
// });

// export const { useBookGetOfferMutation, useBookRequestLaterMutation } = bookApi;

// export { bookApi };
