import React, { useEffect, useState } from 'react';
import { RxCross2 } from "react-icons/rx";

const ConsentBanner = () => {

    const [showCookiesPolicy, setShowCookiesPolicy] = useState(false);
    useEffect(() => {
        if (localStorage.getItem('consentMode') === null) {
            setTimeout(() => {
                document.getElementById('btn-accept-all').addEventListener('click', function () {
                    setConsent({
                        necessary: true,
                        analytics: true,
                        preferences: true,
                        marketing: true
                    });
                    hideBanner();
                });
                document.getElementById('btn-reject-all').addEventListener('click', function () {
                    setConsent({
                        necessary: false,
                        analytics: false,
                        preferences: false,
                        marketing: false
                    });
                    hideBanner();
                });
                document.getElementById('cookie-consent-banner').style.display = 'block';
            }, 3000);
        }
    }, []);

    const hideBanner = () => {
        document.getElementById('cookie-consent-banner').style.display = 'none';
    };

    const setConsent = (consent) => {
        const consentMode = {
            'functionality_storage': consent.necessary ? 'granted' : 'denied',
            'security_storage': consent.necessary ? 'granted' : 'denied',
            'ad_storage': consent.marketing ? 'granted' : 'denied',
            'analytics_storage': consent.analytics ? 'granted' : 'denied',
            'personalization_storage': consent.preferences ? 'granted' : 'denied',
        };

        if (window.gtag) {
            window.gtag('consent', 'update', consentMode);
            localStorage.setItem('consentMode', JSON.stringify(consentMode));
        } else {
            console.error('gtag function not available');
        }
    };

    return (
        <div className=''>
            <div id="cookie-consent-banner" className="cookie-consent-banner font-Trueno">
                <div className='flex flex-col md:flex md:flex-row justify-between gap-3'>
                    <p>This website uses cookies. By using this website, you consent to the use of { }
                        <span
                            onClick={() => {
                                setShowCookiesPolicy(true)
                            }}
                            className='font-bold text-[#07609C] underline cursor-pointer'>
                            cookies
                        </span>
                        .</p>
                    <div className='flex justify-center md:justify-between gap-3'>
                        <button id="btn-accept-all" className=" bg-[#07609C] text-white px-3 py-1 rounded-md">Accept All</button>

                        <button id="btn-reject-all" className="cookie-consent-button btn-grayscale hover:underline">Reject All</button>
                    </div>
                </div>
                {showCookiesPolicy && (
                    <div className="text-left w-full font-Trueno bg-[#AEE0FF]">
                        <div className="fixed inset-0 scroll-none bg-black bg-opacity-25 backdrop-blur-sm  px-2 md:px-9">
                            <div className="md:w-[55%] h-auto mx-auto bg-white px-4 md:px-8 my-12 border rounded-[15px] relative">
                                <button
                                    className=" font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 absolute right-1 top-7"
                                    type="button"
                                    onClick={() => {
                                        setShowCookiesPolicy(false)
                                    }}
                                >
                                    <RxCross2 className="w-[14px] md:w-[22px] h-[14px] md:h-[22px]" />
                                </button>
                                <div className='flex flex-col gap-1 my-8'>
                                    <div className="font-semibold text-center text-[16px] md:text-[24px] leading-[19.5px] md:leading-[26.82px] text-[#07609C] my-3">
                                        Cookie Policy
                                    </div>
                                    <span className='font-semibold text-sm'>
                                        What are cookies?
                                    </span>
                                    <p className='text-sm'>Cookies are small text files that are stored on your computer or mobile device by a website when you visit it. They are widely used to enable websites to function or to work more efficiently, as well as to provide information to the owners of the site.</p>
                                    <span className='font-semibold text-sm'>
                                        How does this website use cookies?
                                    </span>
                                    <ul className='list-disc list-inside text-sm'>
                                        <li>This website uses cookies for a number of purposes, including:</li>
                                        <li>To improve your browsing experience by remembering your preferences (e.g., language preference)</li>
                                        <li>To analyze website usage and to compile statistics on website traffic and interaction</li>
                                        <li>To target advertising to you</li>
                                    </ul>
                                    <span className='font-semibold text-sm'>
                                        What types of cookies does this website use?
                                    </span>
                                    <ul className='list-disc list-inside text-sm'>
                                        <li>This website uses a combination of first-party and third-party cookies:</li>
                                        <li>First-party cookies are set by this website directly.</li>
                                        <li>Third-party cookies are set by a third-party service provider on our behalf.</li>
                                    </ul>
                                    <span className='font-semibold text-sm'>
                                        How can you control cookies?
                                    </span>
                                    <p className='text-sm'>You can control or restrict the use of cookies in your web browser settings. However, please note that if you do this, you may not be able to use all of the features of this website.</p>

                                    <span className='font-semibold text-sm'>
                                        Changes to this cookie policy
                                    </span>
                                    <p className='text-sm'>We may update this cookie policy from time to time. The updated cookie policy will be posted on this website.</p>

                                    <span className='font-semibold text-sm'>
                                        Contact us
                                    </span>
                                    <p className='text-sm'>If you have any questions about this cookie policy, please contact us.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConsentBanner;
