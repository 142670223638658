import { useGet_country_city } from "hooks/useUser";
import { useEffect } from "react";
import { useState } from "react";

export const CityFilter = ({ mutate, onCityChange, cityFilterBorder, setCityFilterBorder }) => {
  const { data } = useGet_country_city();
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    // mutate(value);
    onCityChange(value);
    localStorage.setItem("selectedOption", value);
    setCityFilterBorder(false);
  };

  const handleOptionBlur = () => {
    localStorage.setItem("selectedOption", selectedOption);
  };

  useEffect(() => {
    onCityChange(localStorage.getItem("selectedOption"));
  }, []);

  const storedOption = localStorage.getItem("selectedOption");
  useEffect(() => {
    if (storedOption) {
      setSelectedOption(storedOption);
    }
  }, [storedOption]);

  return (
    <div className="font-Trueno flex flex-col gap-3">
      <label className="text-black text-sm md:text-base text-start ">
        Select the city in which you need service
      </label>
      <div className="w-full relative inline-block ">
        <select
          value={selectedOption}
          onChange={handleOptionChange}
          onBlur={handleOptionBlur}
          className={`space-y-4 appearance-none  bg-white border ${cityFilterBorder ? "border-red-600 animate-pulse" : "border-gray-300"} text-sm md:text-base text-gray-700 py-2 px-4 pr-8 rounded leading-tight shadow-lg focus:outline-none focus:bg-white focus:border-gray-500 w-full  md:w-[365px]`}
        >
          <option className="font-normal md:text-[14px]" value="" disabled>
            Select City
          </option>

          {data?.data?.map((item, index) => (
            <option
              key={index}
              className="select-box font-normal md:text-[14px] border border-gray-300"
              value={item?.id}
            >
              {item?.name}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path fillRule="evenodd" d="M10 12l4-4h-8l4 4z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CityFilter;
