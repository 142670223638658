import OTPInput from "react-otp-input";
import { TiTick } from "react-icons/ti";
import SideBarMenu from "./SideBarMenu";
import Loader from "views/loader/Loader";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import SettingFooter from "./SettingFooter";
import { useNavigate } from "react-router-dom";
import { checkIsUserLoggedIn } from "actions/auth";
import { showErrorToast } from "store/features/toastSlice";
import BookingNavBar from "views/partials/navbar/BookingNavbar";
import { useDelete_account_request, useResendOtp } from "hooks/useAuth";
import { useForgotPassword, useProfile, useVerify_forgototp } from "hooks/useUser";

const DeleteAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1);
  const [isChecked, setIsChecked] = useState(true);
  const [remainingTime, setRemainingTime] = useState(180);

  const handleChange = (otp) => setOtp(otp);

  const handleCheck = () => {
    setIsChecked((current) => !current);
  };

  const resendOtpMutation = useResendOtp();

  const verifyOtpMutation = useVerify_forgototp();

  const { mutateAsync: sendOtp_mutate } = useForgotPassword();

  const { data: profile_data, isLoading: profile_isLoading } = useProfile();

  const { isLoading: verify_otp_isLoading, data: verify_otp_data } = verifyOtpMutation;

  const {
    mutate: delete_account_request_mutate,
    data: delete_account_request_data,
    isLoading: delete_account_request_isLoading,
  } = useDelete_account_request();

  const handleNext = () => {
    setStep(step + 1);
  };

  const seconds = remainingTime % 60;
  const minutes = Math.floor(remainingTime / 60);

  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [remainingTime]);

  useEffect(() => {
    if (verify_otp_data?.success === true) {
      const payload = {
        id: profile_data?.data?.user_id,
        token: profile_data?.data?.token,
      };
      delete_account_request_mutate(payload);
    }

    if ((profile_data?.success === false && profile_data?.error_code === 104) || profile_data?.error_code === 103) {
      dispatch(showErrorToast("Another user is Logged in on different device"));

      localStorage.removeItem("token");
      localStorage.removeItem("id");

      navigate("/login");
      dispatch(checkIsUserLoggedIn());
    }
  }, [verify_otp_data, profile_data]);

  useEffect(() => {
    if (verify_otp_data?.success) {
      handleNext();
    }
  }, [verify_otp_data]);
  return (
    <>
      <div className="bg-[#F0F7FC]">
        <BookingNavBar color="#F0F7FC" />
        {step === 1 && (
          <div className="w-full h-screen font-Trueno my-20">
            <div className="md:w-[85%] mx-auto flex-col md:flex md:flex-row md:gap-3 justify-between md:px-0 px-2">
              <div>
                <SideBarMenu />
              </div>
              <div className="border rounded-[20px] shadow-2xl bg-white w-full md:w-[988px] lg:w-full mt-4 md:mt-0">
                <h1 className="font-normal text-[18px] leading-[21.6px] border-b border-[#DFDFDF] px-4 md:px-[38px] pt-8 pb-4">
                  Delete Account
                </h1>
                {profile_isLoading || delete_account_request_isLoading ? (
                  <div className="my-6 p-8 flex flex-col gap-3">
                    <Loader />
                  </div>
                ) : (
                  <div className="my-14 w-full flex flex-col gap-9 px-[18px] md:px-[38px] ">
                    <div className="flex flex-col gap-5 w-full">
                      {/* ------------------ Phone Number Field ------------------ */}

                      <div className="flex flex-col gap-3">
                        <label htmlFor="phone Number" className="font-semibold text-sm">
                          Phone Number
                        </label>
                        <label className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full max-w-[500px] py-3 pl-7 ">
                          {profile_data?.data?.mobile}
                        </label>
                      </div>

                      {/* ------------------ Check Box ------------------ */}

                      <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-3">
                          <input
                            type="checkbox"
                            defaultChecked
                            onChange={handleCheck}
                            value={isChecked}
                            name="deleteAccount"
                            id="deleteAccount"
                          />
                          <label htmlFor="termsAndConditions" className="font-semibold text-xs md:text-sm">
                            By deleting your account, you acknowledge and agree to the following:
                          </label>
                        </div>
                        <ol className="list-decimal	list-inside p-4 font-normal text-[14px]">
                          <li>Your account information, including any data associated with it, will be permanently deleted.</li>
                          <li> You won't be able to recover your account or any associated data.</li>
                          <li> Any ongoing subscriptions or services tied to your account will be terminated.</li>
                        </ol>
                      </div>
                      {!isChecked ? (
                        <p className="font-semibold text-sm text-red-600 px-4">Please accept terms and condition to continue</p>
                      ) : null}
                    </div>

                    {/* ------------------ Button ------------------ */}

                    <button
                      type="submit"
                      disabled={!isChecked}
                      onClick={() => {
                        const payload = {
                          mobile: profile_data?.data?.mobile,
                        };
                        sendOtp_mutate(payload);

                        handleNext();
                      }}
                      className={`max-w-[500px] font-semibold text-sm text-white py-[14px] mt-16 w-full border bg-[#0973BA] rounded-lg ${
                        isChecked ? "bg-[#0973BA]" : "bg-blue-400"
                      }`}
                    >
                      Delete Account
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="w-[98%] md:w-[80%] mx-auto py-20 px-[3px] lg:px-[100px]">
            <div className="bg-white py-20 px-[12px] md:px-32 border rounded-[20px]">
              <div className="flex flex-col gap-6 items-center">
                <h1 className="font-bold text-[20px] md:text-[36px] leading-[24px] md:leading-[43.2px] text-center">
                  Your one time Passcode for Karsaaz App is Send on your Mobile Number{" "}
                </h1>
                <p className="font-normal text-[14px] leading-[16.8px] mt-[31px]">Enter your 6 digit Passcode</p>
                <OTPInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  containerStyle={"flex gap-2 lg:gap-10 justify-center "}
                  shouldAutoFocus={true}
                  separator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    textAlign: "center",
                    borderRadius: "0.5rem",
                    borderWidth: "1px",
                    background: "#FBFBFB",
                    width: "40px",
                    height: "40px",
                    outline: "none",
                  }}
                />

                <p className="font-normal text-[14px] leading-[16.8px] mt-[31px]">Have not got it?</p>

                <label className="font-semibold text-[17.07px] leading-[17.07px] text-[#0973BA]">
                  {remainingTime > 0 ? (
                    // `Resend code in: ${minutes}:${seconds}`
                    <p>
                      Resend code in: {minutes < 10 ? `${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <button
                      type="button"
                      disabled={remainingTime > 0}
                      onClick={() => {
                        const payload = {
                          id: localStorage.getItem("id"),
                          token: localStorage.getItem("token"),
                        };
                        setRemainingTime(180);
                        resendOtpMutation.mutate(payload);
                      }}
                      className="font-semibold text-[17.07px] leading-[17.07px] text-[#0973BA]"
                    >
                      Resend Otp Code
                    </button>
                  )}
                </label>
                <div className="w-full flex flex-col gap-3 items-center max-w-[463px]">
                  <button
                    type="button"
                    onClick={() => {
                      verifyOtpMutation.mutate({ forgototpcode: otp, mobile: profile_data?.data?.mobile });
                    }}
                    className="font-semibold text-sm text-white h-[46px] w-full border bg-[#0973BA] rounded-lg relative"
                  >
                    {verify_otp_isLoading ? (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div role="status" className="absolute inset-y-3.8">
                          <div role="status" className="">
                            <svg
                              aria-hidden="true"
                              className="w-6 h-6 mr-2 text-slate-200 animate-spin  fill-[#0973BA]"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      "Verify"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="w-full h-screen font-Trueno my-20">
            <div className="md:w-[85%] mx-auto flex-col md:flex md:flex-row md:gap-3 justify-between md:px-0 px-2">
              <div>
                <SideBarMenu />
              </div>
              <div className="border rounded-[20px] shadow-2xl bg-white w-full md:w-[988px] lg:w-full mt-4 md:mt-0">
                <h1 className="font-normal text-[18px] leading-[21.6px] border-b border-[#DFDFDF] px-4 md:px-[38px] pt-8 pb-4">
                  Delete Account
                </h1>
                {profile_isLoading || delete_account_request_isLoading ? (
                  <div className="my-6 p-8 flex flex-col gap-3">
                    <Loader />
                  </div>
                ) : (
                  <div className="flex justify-center px-[18px] md:px-[38px] pb-14 w-full ">
                    <div className="py-14 w-full flex flex-col items-center  gap-9 max-w-[507px]">
                      <div className="border border-[#09BA70] rounded-full p-2 w-fit text-[#09BA70]">
                        <TiTick className="w-[60px] md:w-[80px] h-[60px] md:h-[80px]" />
                      </div>
                      <p className="font-normal text-[14px] leading-[16.8px] text-center">
                        {delete_account_request_data?.message}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <SettingFooter />
      </div>
    </>
  );
};

export default DeleteAccount;
