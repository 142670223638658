import { IS_USER_LOGGED_IN } from "helpers/constants";

export const checkIsUserLoggedIn = () => async (dispatch) => {
  const token = localStorage.getItem("token");

  dispatch({
    type: IS_USER_LOGGED_IN,
    payload: token?.length > 0 ? true : false,
  });
};
