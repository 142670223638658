import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomerChoosesUs from 'common/CustomerChoosesUs';
import CustomerLoveUs from 'theme/cards/CustomerLoveUs';
import FooterMain from 'views/partials/footer/FooterMain';
import NavCustom from 'views/partials/navbar/BookingNavbar';
import Loader from 'views/loader/Loader';
import { useServices } from 'hooks/useService';

const GonePage = () => {
  const navigate = useNavigate();
  const { isLoading: isLoading1 } = useServices();

  return (
    <div>
      <NavCustom />
      {isLoading1 ? (
        <Loader />
      ) : (
        <div className="flex flex-col items-center gap-4 pb-36">
          <div className="w-[220px] h-[220px]">
            <img
              src={`${process.env.PUBLIC_URL}/images/PageError410.jpg`}
              alt="Gone"
            />
          </div>
          <h2 className="font-extrabold text-2xl md:text-[36px] md:leading-[52px] text-center md:text-left text-[#07609C]">
            This content is no longer available.
          </h2>
          <button
            onClick={() => {
              navigate('/');
            }}
            className="py-4 w-[204.56px] rounded-lg font-bold text-[#07609C] border border-[#07609C] text-[16px] leading-[19.2px] bg-white hover:scale-105 hover duration-200"
          >
            Go to Home Page
          </button>
        </div>
      )}
      <CustomerChoosesUs color="#ECF7FE" TextColor="black" />
      <CustomerLoveUs
        color="#FAFDFF"
        QuoteIconColor="#07609C"
        FaArrowCircleColor="#07609C"
        TextColor="#0973BA"
      />
      <FooterMain />
    </div>
  );
};

export default GonePage;
