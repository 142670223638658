import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import "./../../../common/dropFile/drop-file-input.css";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/features/toastSlice";
import { useSingle_request } from "hooks/useUser";

const DropFileEditBooking = (props) => {
  const wrapperRef = useRef(null);
  const fileList = props.fileList;
  const setFileList = props.setFileList;
  const request_id = props.request_id;
  const mutate_remove_user_request_images = props.mutate_remove_user_request_images;
  const data_remove_user_request_images = props.data_remove_user_request_images;

  const { data: single_request, refetch } = useSingle_request({ request_id });

  // const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const dispatch = useDispatch();

  const onFileDrop = (e) => {
    const files = e.target.files;
    const updatedList = [...fileList];

    for (let i = 0; i < files.length && updatedList.length < 4; i++) {
      const file = files[i];
      const fileType = file.type;
      const validFileTypes = ["image/png", "image/jpeg", "image/svg+xml"];

      if (validFileTypes.includes(fileType)) {
        updatedList.push(file);
      } else {
        dispatch(showErrorToast("Only image files are allowed"));
      }
    }

    setFileList(updatedList);
    props.onFileChange(updatedList); // Send the fileList array to the parent component
  };

  const fileRemove = (index) => {
    const updatedList = [...fileList];
    updatedList.splice(index, 1);
    setFileList(updatedList);
    props.onFileChange(updatedList);
  };

  const file_user_request_images = (index, file) => {
    const updatedList = [...single_request?.data?.user_request_images];
    updatedList.splice(index, 1);

    const payload = {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      image_id: file?.user_request_image_id,
      request_id: file?.request_id,
    };
    mutate_remove_user_request_images(payload);
  };
  useEffect(() => {
    if (data_remove_user_request_images?.success === true) {
      refetch();
    }
  });

  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input font-Trueno"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label flex flex-col w-full justify-center items-center border-2 border-gray-300 border-dashed rounded-md">
          <img src={`${process.env.PUBLIC_URL}/images/DragDropIcon.png`} alt="" />
          <span className="font-medium text-gray-600">Upload or Drag and drop images</span>
          <span className="font-medium text-gray-600">
            {fileList?.length + single_request?.data?.user_request_images?.length}/4
          </span>

          <input type="file" value="" onChange={onFileDrop} multiple />
        </div>
      </div>
      <div>
        {fileList?.length > 0 || single_request?.data?.user_request_images?.length > 0 ? (
          <div className="drop-file-preview grid grid-cols-2 md:flex md:flex-row py-7">
            {fileList?.map((file, index) => (
              <div key={index} className="drop-file-preview__item">
                <img src={URL.createObjectURL(file)} alt="" className="drop-file-preview__item__img object-cover" />
                <span className="drop-file-preview__item__del font-semibold text-lg rounded-xl" onClick={() => fileRemove(index)}>
                  x
                </span>
              </div>
            ))}
            {single_request?.data?.user_request_images?.map((file, index) => (
              <div key={index} className="drop-file-preview__item">
                <img src={file?.image} alt="" className="drop-file-preview__item__img " />
                <span
                  className="drop-file-preview__item__del font-semibold text-lg rounded-xl"
                  onClick={() => file_user_request_images(index, file)}
                >
                  x
                </span>
              </div>
            ))}
          </div>
        ) : null}

        {/* <div className="drop-file-preview grid grid-cols-2 md:flex md:flex-row py-7">
          {user_request_images?.map((file, index) => (
            <div key={index} className="drop-file-preview__item">
                            <img src={URL.createObjectURL(file?.image)} alt="" className="drop-file-preview__item__img " />


              <span
                className="drop-file-preview__item__del font-semibold text-lg rounded-xl"
                onClick={() => file_user_request_images(index, file)}
              >
                x
              </span>
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
};

DropFileEditBooking.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileEditBooking;
