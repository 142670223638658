import React from "react";
import TrendingPosts from "./TrendingPosts";
import { useBlog_categories } from "hooks/useUser";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollToTop } from "common/ScrollToTop";

const BlogsCategories = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, isLoading } = useBlog_categories();

  const handleBlogsCategory = (item) => {
    navigate(`/blogs/${item?.slug}`, {
      state: { blog_category_id: item?.id },
    });
    scrollToTop();
  };

  return (
    <>
      <div className="flex flex-col gap-4 w-full md:max-w-[369px] mx-auto h-fit font-Trueno mt-0 md:mt-20">
        <div className="flex flex-col gap-4 border border-[#DFDFDF] rounded-2xl p-3 md:p-6">
          <h3 className="font-extrabold text-2xl">Categories</h3>

          {isLoading
            ? Array(3)
              .fill()
              .map((_, index) => <div key={index} className="animate-pulse h-[12.22px] w-[122.22px] bg-sky-200"></div>)
            : data?.data?.map((item, index) => (
              <div key={index}>
                <button
                  type="button"
                  onClick={() => {
                    handleBlogsCategory(item);
                  }}
                  className={`w-full py-1 cursor-pointer font-semibold text-base hover:text-[#0973BA] text-left border-b hover:border-[#0973BA] hover:scale-105 hover:duration-200 ease-in
                    ${location.pathname === `/blogs/${item?.slug}` ? "text-[#0973BA] border-b-[#0973BA]" : "text-black"}
                    `}
                >
                  {item?.name}
                </button>
              </div>
            ))}
        </div>
        <div className="hidden md:block">
          <TrendingPosts />
        </div>
      </div>
    </>
  );
};

export default BlogsCategories;
