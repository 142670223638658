import { useProfile } from "hooks/useUser";
import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";

const SideBarMenu = () => {
  const { data } = useProfile();

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="w-full font-Trueno">
        <div className="md:max-w-[262px] rounded-[15px] shadow-2xl bg-white mx-auto font-Trueno ">
          <button className="flex justify-between  items-center md:hidden  p-4 font-semibold text-xl w-full" onClick={toggleMenu}>
            <label>Settings</label>
            <RiArrowDropDownLine width="12px" height="24px" />
          </button>
          <div className={`${isOpen ? "block" : "hidden md:flex md:flex-col"} md:w-64 md:relative`}>
            <ul
              className={`${
                isOpen ? "block" : "hidden md:grid gap-2 md:gap-6 justify-start"
              } grid py-5 md:py-8 pl-5 gap-6  font-semibold text-[18px] leading-[21.94px]`}
            >
              <NavLink
                to="/personal-information"
                className="flex justify-between items-center w-full md:w-[235px] h-[36px]"
                style={({ isActive }) =>
                  isActive
                    ? {
                        color: "#0973BA",
                        borderColor: "#0973BA",
                        borderRight: "solid",
                        borderRightWidth: "4px",
                        lineHeight: "25px",
                        width: "full",
                        display: "grid",
                      }
                    : {
                        color: "black",
                      }
                }
              >
                <li className="">Profile</li>
              </NavLink>

              <NavLink
                to="/location"
                className="flex justify-between items-center w-full md:w-[235px] h-[36px] "
                style={({ isActive }) =>
                  isActive
                    ? {
                        color: "#0973BA",
                        borderColor: "#0973BA",
                        borderRight: "solid",
                        borderRightWidth: "4px",
                        lineHeight: "25px",
                        width: "full",
                        display: "grid",
                      }
                    : {
                        color: "black",
                      }
                }
              >
                <li>Location</li>
              </NavLink>
              {/* <NavLink
              to="/"
                className={({ isActive }) =>
                  `relative flex items-center ${
                    isActive
                      ? "text-[#0973BA] "
                      : "text-black border-b-2 md:border-r-2 "
                  } text-lg font-semibold ${isActive ? "" : ""} ${
                    isOpen ? "border-b-2 md:border-r-2 pb-3" : ""
                  }`
                }
                activeClassName="text-[#0973BA] border-[#0973BA] "
              >
                <li>Billing Details</li>
              </NavLink> */}

              {data?.data?.login_by === "manual" ? (
                <NavLink
                  to="/change-password"
                  className="flex justify-between items-center w-full md:w-[235px] h-[36px]"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "#0973BA",
                          borderColor: "#0973BA",
                          borderRight: "solid",
                          borderRightWidth: "4px",
                          lineHeight: "25px",
                          width: "full",
                          display: "grid",
                        }
                      : {
                          color: "black",
                        }
                  }
                >
                  <li>Change Password</li>
                </NavLink>
              ) : null}

              <NavLink
                to="/social-account"
                className="flex justify-between items-center w-full md:w-[235px] h-[36px]"
                style={({ isActive }) =>
                  isActive
                    ? {
                        color: "#0973BA",
                        borderColor: "#0973BA",
                        borderRight: "solid",
                        borderRightWidth: "4px",
                        lineHeight: "25px",
                        width: "full",
                        display: "grid",
                      }
                    : {
                        color: "black",
                      }
                }
              >
                <li>Social Account</li>
              </NavLink>

              <NavLink
                to="/delete-account"
                className="flex justify-between items-center w-full md:w-[235px] h-[36px] "
                style={({ isActive }) =>
                  isActive
                    ? {
                        color: "#0973BA",
                        borderColor: "#0973BA",
                        borderRight: "solid",
                        borderRightWidth: "4px",
                        lineHeight: "25px",
                        width: "full",
                        display: "grid",
                      }
                    : {
                        color: "black",
                      }
                }
              >
                <li>Delete Account</li>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBarMenu;

// import React, { useState } from "react";
// import { RiArrowDropDownLine } from "react-icons/ri";
// import { Link, NavLink } from "react-router-dom";

// const SideBarMenu = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <>
//       <div className="w-full font-Trueno">
//         <div className="md:w-[262px] rounded-[15px] shadow-2xl bg-white mx-auto font-Trueno ">
//           <button
//             className="flex justify-between  items-center md:hidden  p-4 font-semibold text-[22px] leading-[26.82px] w-full"
//             onClick={toggleMenu}
//           >
//             <label>Settings</label>
//             <RiArrowDropDownLine width="12px" height="24px" />
//           </button>
//           <div className={`${isOpen ? "block" : "hidden md:flex md:flex-col"} md:w-64 md:relative`}>
//             <ul
//               className={`${
//                 isOpen ? "block" : "hidden md:grid md:gap-9"
//               } grid py-5 md:py-7 pl-5 gap-9 font-semibold text-[18px] leading-[21.94px]`}
//             >
//               <NavLink
//                 to="/personal-information"
//                 style={({ isActive }) =>
//                   isActive
//                     ? {
//                         color: "#0973BA",
//                         borderColor: "#0973BA",
//                         borderRight: "solid",
//                         borderRightWidth: "4px",
//                         lineHeight: "25px",
//                         width: "full",
//                         display: "grid",
//                       }
//                     : {
//                         color: "black",
//                       }
//                 }
//               >
//                 <li>Profile</li>
//               </NavLink>

//               <NavLink
//                 to="/location"
//                 style={({ isActive }) =>
//                   isActive
//                     ? {
//                         color: "#0973BA",
//                         borderColor: "#0973BA",
//                         borderRight: "solid",
//                         borderRightWidth: "4px",
//                         lineHeight: "25px",
//                         width: "full",
//                         display: "grid",
//                       }
//                     : {
//                         color: "black",
//                       }
//                 }
//               >
//                 <li>Location</li>
//               </NavLink>
//               {/* <NavLink
//               to="/"
//                 className={({ isActive }) =>
//                   `relative flex items-center ${
//                     isActive
//                       ? "text-[#0973BA] "
//                       : "text-black border-b-2 md:border-r-2 "
//                   } text-lg font-semibold ${isActive ? "" : ""} ${
//                     isOpen ? "border-b-2 md:border-r-2 pb-3" : ""
//                   }`
//                 }
//                 activeClassName="text-[#0973BA] border-[#0973BA] "
//               >
//                 <li>Billing Details</li>
//               </NavLink> */}
//               <NavLink
//                 to="/change-password"
//                 style={({ isActive }) =>
//                   isActive
//                     ? {
//                         color: "#0973BA",
//                         borderColor: "#0973BA",
//                         borderRight: "solid",
//                         borderRightWidth: "4px",
//                         lineHeight: "25px",
//                         width: "full",
//                         display: "grid",
//                       }
//                     : {
//                         color: "black",
//                       }
//                 }
//               >
//                 <li>Change Password</li>
//               </NavLink>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SideBarMenu;
