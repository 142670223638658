import React, { useState } from "react";
import SideBarMenu from "./SideBarMenu";
import BookingNavBar from "views/partials/navbar/BookingNavbar";
import SettingFooter from "./SettingFooter";
import {
  GoogleMap,
  Autocomplete,
  useJsApiLoader,
  Marker
} from "@react-google-maps/api";
import { BsSearch } from "react-icons/bs";
import { TbCurrentLocation } from "react-icons/tb";
import { MdLocationOn } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import { useShowLocation, useUpdateLocation } from "hooks/useUser";

// export const googleMapsConfig = {
//   libraries: ["places"],
// };

const containerStyle = {
  width: "100%",
  height: "216px",
};

const center = {
  lat: 31.4697,
  lng: 74.2728,
};

const Location = () => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  const [selectedAddress, setSelectedAddress] = useState("");
  const { mutate, isLoading } = useUpdateLocation();
  const { data: showLocation } = useShowLocation();

  const handleLocation = () => {
    let latitude, longitude, address;

    if (selectedPlace) {
      latitude = selectedPlace.geometry.location.lat();
      longitude = selectedPlace.geometry.location.lng();
      address = selectedPlace.formatted_address;
    } else if (currentLocation) {
      latitude = currentLocation.lat;
      longitude = currentLocation.lng;
      address = currentLocationAddress;
    } else {
      // Handle the case when neither a selected place nor current location is available
      return;
    }

    const payload = {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      latitude,
      longitude,
      address,
    };

    mutate(payload);
  };
  const [libraries] = useState(["places"]);
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  // const { isLoaded, loadError } = useJsApiLoader({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,

  //   ...googleMapsConfig,
  // });

  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentLocationAddress, setCurrentLocationAddress] = useState("");

  const onLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);

    const geocoder = new window.google.maps.Geocoder();
    if (currentLocation) {
      geocoder.geocode(
        { location: { lat: currentLocation.lat, lng: currentLocation.lng } },
        (results, status) => {
          if (status === "OK") {
            const address = results[0].formatted_address;
            setCurrentLocationAddress(address);
          } else {
            console.error("Geocoder failed due to: " + status);
          }
        }
      );
    }
  };

  const onUnmount = () => {
    setMap(null);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      // Check if place is valid and has geometry location
      if (place && place.geometry && place.geometry.location) {
        setSelectedPlace(place);
        if (map !== null) {
          map.setCenter(place.geometry.location);
        }
        const address = place.formatted_address;
        setSelectedAddress(address); // Update selected address state variable
      } else {
        setSelectedPlace(place.geometry.location);
        setSelectedAddress(place.geometry.location);
      }
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });

          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode(
            { location: { lat: latitude, lng: longitude } },
            (results, status) => {
              if (status === "OK") {
                const address = results[0].formatted_address;
                setCurrentLocationAddress(address);
                setSelectedAddress(address);
              } else {
                console.error("Geocoder failed due to: " + status);
              }
            }
          );
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={libraries}> */}
      <ToastContainer />
      <div className="bg-[#F0F7FC]">
        <BookingNavBar color="#F0F7FC" />

        <div className="w-full font-Trueno my-20">
          <div className="md:w-[85%] mx-auto flex-col md:flex md:flex-row md:gap-3 md:px-0 px-2">
            <div>
              <SideBarMenu />
            </div>
            <div className="border rounded-[20px] shadow-2xl bg-white w-full md:w-[988px] lg:w-full mt-4 md:mt-0">
              <h1 className="font-normal text-[18px] leading-[21.6px] border-b border-[#DFDFDF] px-4 md:px-[38px] pt-8 pb-4">
                Update Your Location
              </h1>
              <div className="flex flex-col gap-9 justify-between px-[18px] md:px-[38px] mt-4 mb-52 w-full ">
                {/* --------------------- Form Area --------------------- */}
                <form>
                  <div className="w-full">
                    <div className="flex items-center gap-1 ">
                      <div className="grid lg:flex items-center gap-2 rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#AEE0FF] max-h-full min-h-[50px] w-full pl-2 lg:py-0 py-2">
                        <div className="flex items-center gap-1md:px-0 px-2">
                          <MdLocationOn
                            color="#0973BA"
                            className="h-[27px] w-[27px]"
                          />
                          <p className="w-[140px] h-[20px] font-semibold text-[16px] leading-[19.5px] text-black">
                            Current Address:{" "}
                          </p>
                        </div>
                        {isLoading ? (
                          <div className="p-3 space-y-4 w-full ">
                            <div className="animate-pulse mx-auto w-full bg-sky-400 h-5 "></div>
                          </div>
                        ) : (
                          <>{showLocation?.address}</>
                        )}
                      </div>
                    </div>
                    <div className="my-3">
                      <label htmlFor="Select Location">Select Location</label>
                    </div>
                    <div className="relative">
                      <div>
                        {isLoaded && (
                          <Autocomplete
                            onLoad={(autocomplete) =>
                              setAutocomplete(autocomplete)
                            }
                            onPlaceChanged={onPlaceChanged}
                          >
                            <div className="relative">
                              <BsSearch className="w-[15.83px] h-[15.83px] absolute inset-y-0 left-5 top-4" />
                              <input
                                type="text"
                                onKeyDown={handleKeyDown}
                                placeholder="Search for a location"
                                className="w-full rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#DFDFDF] h-[46px] py-4 text-black mb-4 pl-12"
                                value={selectedAddress}
                                onChange={(e) =>
                                  setSelectedAddress(e.target.value)
                                }
                              />
                            </div>
                          </Autocomplete>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={handleLocation}
                        className="font-semibold text-sm text-white py-2 w-full  md:w-[117px] border bg-[#0973BA] rounded-lg lg:absolute top-1 right-1"
                      >
                        {isLoading ? (
                          <div
                            className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </div>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>

                    <div className="flex gap-2 justify-start items-center my-4">
                      <button
                        onClick={getCurrentLocation}
                        type="button"
                        className="flex items-center gap-1 font-normal text-[14px] leading-[16.8px] font-Trueno text-[#0973BA] hover:text-blue-800 cursor-pointer"
                      >
                        <span>Use Current Location</span>
                        <TbCurrentLocation
                          className="w-[21.5px] h-[21.5px]"
                          color="#0973BA"
                        />
                      </button>
                    </div>
                    <div>
                      {loadError ? (
                        <p>Error loading Google Maps</p>
                      ) : isLoaded ? (
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={currentLocation || center}
                          zoom={currentLocation ? 14 : 10}
                          onLoad={onLoad}
                          onUnmount={onUnmount}
                          options={{
                            fullscreenControl: false,
                            zoomControl: false,
                            mapTypeControl: false,
                            streetViewControl: false,
                          }}
                        >
                          {selectedPlace && (
                            <Marker
                              position={{
                                lat: selectedPlace.geometry.location.lat(),
                                lng: selectedPlace.geometry.location.lng(),
                              }}
                            />
                          )}
                        </GoogleMap>
                      ) : (
                        <p className="flex mx-auto justify-center"></p>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <SettingFooter />
      </div>
      {/* </LoadScript> */}
    </>
  );
};

export default Location;
