import { usePopular_sub_categories } from "hooks/useUser";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showErrorToast } from "store/features/toastSlice";
import { facebook_conversions } from "views/conversionAPI/FacebookConversions";

const PopularServiceButtons = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: popular_categories, mutate, isLoading } = usePopular_sub_categories();

  useEffect(() => {
    const payload = {
      city_id: localStorage.getItem("selectedOption") || 1,
    };
    mutate(payload);
  }, []);

  const handleClick = (item) => {
    const sub_category_slug = item?.slug;

    if (item?.is_bidded === "yes") {
      localStorage.setItem("selectedBooking", 0);
    } else if (item?.is_bidded === "no") {
      localStorage.setItem("selectedBooking", 2);
    } else if (item?.is_bidded === "only") {
      localStorage.setItem("selectedBooking", 1);
    }

    if (localStorage.getItem("selectedOption") === null) {
      dispatch(showErrorToast("Select the City First"));
    } else {
      navigate(`/${sub_category_slug}`, {
        state: {
          sub_category_slug: item?.slug,
        },
      });
    }
  };

  return (
    <>
      {popular_categories?.success === false ? (
        <p className=" font-bold text-sm text-white mt-3">{popular_categories?.message}</p>
      ) : (
        <div className="grid grid-cols-2 lg:flex gap-1 ">
          {isLoading
            ? Array(1)
                .fill()
                .map((_, index) => (
                  <div key={index} className="w-fit">
                    <button
                      className="author border border-white max-h-[33px] w-fit overflow-hidden rounded-full
                  text-white font-bold md:text-[12px] leading-[19px] text-center font-Trueno mt-6 p-2 px-5"
                    >
                      Loading...
                    </button>
                  </div>
                ))
            : popular_categories?.data?.slice(0, 4).map((item, index) => (
                <button
                  key={index}
                  onClick={() => {
                    handleClick(item);
                    facebook_conversions("Book Now");

                    const cardDetails = {
                      category_id: item.category_id,
                      sub_category_id: item?.id,
                      title: item.name,
                      image: item.picture,
                      price: item.price,
                      category_description: item.description,
                      type: item.price_type,
                      sub_category_is_bidded: item.is_bidded,
                    };
                    localStorage.setItem("cardDetails", JSON.stringify(cardDetails));
                  }}
                  className="author border border-white max-h-[33px] min-w-[110px] lg:w-full overflow-hidden rounded-full text-white font-bold	md:text-[12px] leading-[19px] text-center font-Trueno mt-6 p-2 "
                >
                  {item?.name?.length > 17 ? item?.name.substring(0, 15) + "...." : item?.name}
                </button>
              ))}
          <Link to="/popular-services">
            <button className="author border border-white max-h-[35px] min-w-[35px] overflow-hidden  rounded-full text-white font-bold	md:text-[12px] leading-[19px] text-center font-Trueno mt-6 p-2 ">
              +
            </button>
          </Link>
        </div>
      )}
    </>
  );
};

export default PopularServiceButtons;
