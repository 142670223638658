import React from "react";

const WorksOffer = (props) => {
  return (
    <>
      <div className="w-full flex flex-col gap1 lg:gap-2 justify-between items-center font-Trueno py-[25px] px-[10px]  bg-[#ECF7FE] rounded-3xl">
        <div className="mb-3">
          <h3 className="font-semibold text-[24px] py-[14px] leading-[29.26px] ">{props.heading}</h3>
        </div>

        <ul className="font-medium text-[12px] md:text-[14px] leading-[16px]">
          <li className="flex items-center gap-2 lg:gap-1 mb-[23.33px]">
            <img
              className="w-[18px] md:w-[25px] h-[18px] md:h-[25px] "
              src={`${process.env.PUBLIC_URL}/images/CheckBoxIcon.png`}
              alt=""
            />
            {props.first_line}
          </li>
          <li className="flex items-center gap-2 lg:gap-1 mb-[23.33px]">
            <img
              className="w-[18px] md:w-[25px] h-[18px] md:h-[25px] "
              src={`${process.env.PUBLIC_URL}/images/CheckBoxIcon.png`}
              alt=""
            />
            {props.second_line}
          </li>
          <li className="flex items-center gap-2 lg:gap-1 mb-[23.33px]">
            <img
              className="w-[18px] md:w-[25px] h-[18px] md:h-[25px] "
              src={`${process.env.PUBLIC_URL}/images/CheckBoxIcon.png`}
              alt=""
            />
            {props.third_line}
          </li>
        </ul>
      </div>
    </>
  );
};

export default WorksOffer;
