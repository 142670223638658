import React, { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import { useLogout } from "hooks/useAuth";

const NavCustom = (props) => {
  const { mutate, isLoading } = useLogout();

  const handleLogout = () => {
    mutate();
  };

  const [open, setOpen] = useState(false);
  // const location = useLocation();
  const navigate = useNavigate()

  return (
    <div className={`w-full text-white  font-Trueno first-letter:first-line`}>
      <div className="md:flex gap-6 w-[90%] justify-between py-4 mx-auto  ">
        <div className="flex gap-6 ">

          <img
            className="object-cover md:shrink-0 h-[41.15px] cursor-pointer"
            src={`${process.env.PUBLIC_URL}/images/KarsaazOriginalLogo.png`}
            alt=""
            onClick={() => navigate("/")}
          />

          <img
            src={`${process.env.PUBLIC_URL}/images/HomeLogoIcon.svg`}
            alt=""
            className="object-cover md:shrink-0 hidden lg:block cursor-pointer h-[41.15px] font-medium hover:scale-105 duration-200"
            onClick={() => navigate("/")}
          />
        </div>

        <div
          onClick={() => setOpen(!open)}
          className="text-black absolute right-8 top-6 cursor-pointer block tablet:hidden"
        >
          {open ? (
            <XMarkIcon className="w-[24px] h-[24px]" color="black" />
          ) : (
            <Bars3Icon className="w-[24px] h-[24px]" color="black" />
          )}
        </div>

        {/* <ul
          className={`md:flex gap-6 md:items-center md:pb-0 pb-12 absolute md:static  md:z-auto z-10 left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500  text-white ease-in ${
            open ? "top-20 " : "top-[-530px]"
          }`}
        > */}
        <ul
          style={{ backgroundColor: props.color }}
          // style={props.styling}
          className={`grid grid-cols-1 gap-6 tablet:grid tablet:grid-flow-col auto-cols-max place-content-start tablet:place-items-center  tablet:pb-0 pb-12 absolute tablet:static  tablet:z-auto z-10 left-0 w-full tablet:w-auto tablet:pl-0 pl-9 tablet:my-0 transition-all duration-500 text-black ease-in font-semibold text-[14px] ${open ? "top-24" : "top-[-530px]"
            }

          `}
        >
          {/* {NavBarData.map((item, index) => (
            <li
              key={index}
              className="block md:hidden lg:hidden xl:block cursor-pointer capitalize font-semibold text-[14px] leading-4 hover:scale-105 duration-200 md:my-0 my-7 "
            >
              <Link to={item.path}>{item.name}</Link>
            </li>
          ))} */}

          {localStorage.getItem("token") ? (
            <>
              <li className="cursor-pointer capitalize hover:scale-105 duration-200">
                <Link to="/karsaaz-app-rewards"> Rewards </Link>
              </li>
              <li className="cursor-pointer capitalize hover:scale-105 duration-200">
                <Link to="/transaction-history"> Transaction History</Link>
              </li>
              <li className="cursor-pointer capitalize hover:scale-105 duration-200">
                <Link to="/history"> History</Link>
              </li>
              <li className="cursor-pointer capitalize hover:scale-105 duration-200">
                <Link to="/my-jobs">My Jobs </Link>
              </li>
              <li className="cursor-pointer capitalize hover:scale-105 duration-200">
                <Link to="/favorite-provider"> Favorite Provider</Link>
              </li>
              <li className="cursor-pointer capitalize hover:scale-105 duration-200">
                <Link to="/personal-information"> Settings </Link>
              </li>
            </>
          ) : null}

          {!localStorage.getItem("token") ? (
            <>
              <Link
                to="/login"
                className="cursor-pointer capitalize font-semibold text-[14px] hover:scale-105 duration-200"
              >
                Login
              </Link>
              <li className="md:my-0 my-2 cursor-pointer capitalize font-semibold text-[14px] hover:scale-105 duration-200 py-4">
                <Link to="/register"> Create Account </Link>
              </li>

              <button className="bg-[#09BA70] rounded-xl text-[#FFFFFF] font-semibold	text-[14px] leading-[17px] text-center px-[12px] py-[11px]  my-4 md:my-0 max-w-[175px]">
                <Link to="/join-as-service-provider"> Join as an Expert</Link>
              </button>
            </>
          ) : (
            <button
              onClick={handleLogout}
              className="flex gap-3 justify-between items-center bg-[#FF4E4E] bg-opacity-[0.15]  rounded-2xl text-[#FF4E4E] font-semibold	text-[14px] leading-[17px] text-center px-[18px] py-3 w-fit"
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/LogoutArrow.png`}
                alt="arrow"
                className="w-[16px] h-[16px]"
              />
              {isLoading ? (
                <div
                  className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              ) : (
                "Logout"
              )}
            </button>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NavCustom;
