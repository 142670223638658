import { Weekly_offers } from "helpers/constants";
import { useRef } from "react";
import { AiFillTag, AiFillStar } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { showErrorToast } from "store/features/toastSlice";
import { facebook_conversions } from "views/conversionAPI/FacebookConversions";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

import ShimmerSkeleton from "views/loader/ShimmerSkeleton";
const WeeklyOffers = ({ isLoading, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sliderRef = useRef(null);

  const handleClick = (item) => {
    const sub_category_slug = item?.slug;
    const cardDetails = {
      category_id: item.category_id,
      sub_category_id: item?.id,
      title: item.name,
      image: item.picture,
      price: item.price,
      category_description: item.description,
      type: item.price_type,
      sub_category_is_bidded: item.is_bidded,
    };
    localStorage.setItem("cardDetails", JSON.stringify(cardDetails));

    if (item?.is_bidded === "yes") {
      localStorage.setItem("selectedBooking", 0);
    } else if (item?.is_bidded === "no") {
      localStorage.setItem("selectedBooking", 2);
    } else if (item?.is_bidded === "only") {
      localStorage.setItem("selectedBooking", 1);
    }

    if (localStorage.getItem("selectedOption") === null) {
      dispatch(showErrorToast("Select the City First"));
    } else {
      navigate(`/${sub_category_slug}`, {
        state: {
          sub_category_slug: item?.slug,
        },
      });
    }
  };

  const handleNextButton = () => {
    sliderRef.current.slickNext();
  };

  const handleBackButton = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <>
      <div className="relative font-Trueno">
        <div className={`hidden md:block`}>
          <button
            className={` md:absolute md:top-0 md:bottom-0 md:left-[-48px] ${data?.data?.length < 5 ? "hidden" : "block"
              }`}
            onClick={handleBackButton}
          >
            <FaArrowCircleLeft
              className="w-[28px] md:w-[34px] h-[28px] md:h-[34px]"
              color="white"
            />
          </button>
          <button
            className={`md:absolute top-0 bottom-0 right-[-48px] outline-none ${data?.data?.length < 5 ? "hidden" : "block"
              }`}
            onClick={handleNextButton}
          >
            <FaArrowCircleRight
              className="w-[28px] md:w-[34px] h-[28px] md:h-[34px]"
              color="white"
            />
          </button>
        </div>
        <Slider
          ref={sliderRef}
          {...Weekly_offers}
          slidesToShow={data?.data?.length > 4 ? 4 : data?.data?.length}
        >
          {isLoading
            ? Array(3)
              .fill()
              .map((_, index) => (
                <div key={index}>
                  <ShimmerSkeleton />
                </div>
              ))
            : data?.data?.map((item, index) => (
              <div
                key={index}
                style={{
                  width: 297,
                  display: "flex",
                  gap: "1px",
                  alignContent: "center",
                }}
              >
                <div className="w-fit h-[397px] border rounded-2xl border-[#DFDFDF] shadow-lg	bg-white p-[7px] flex flex-col gap-1 justify-between">
                  <div className="relative">
                    <div className="h-[212.59px] w-[260px]">
                      <img
                        src={item?.picture}
                        alt="pictures"
                        className="rounded-xl h-full w-full object-fit"
                      />
                    </div>
                    <div className="flex items-center gap-1 absolute z-10 top-2 left-2 bg-[#09BA70] opacity-90 text-white rounded-lg px-2 py-1">
                      <AiFillStar className="" />

                      <p className=" font-bold text-sm">Weekly Offers</p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1 px-1">
                    <h2 className="font-semibold text-base text-black text-left">
                      {item?.name}
                      {/* {item?.name?.length > 35 ? item?.name.substring(0, 35) + "...." : item?.name} */}
                    </h2>

                    <div className="flex gap-1">
                      {item?.discount_price > 0 ? (
                        <div className="flex items-center gap-1">
                          <span className="inline-block text-[#0973BA] text-sm font-bold line-through">
                            Rs.{item?.discount_price}
                          </span>
                          <span className="inline-block text-[#0973BA] text-sm font-bold ">
                            Rs.{item?.price}{" "}
                          </span>
                        </div>
                      ) : (
                        <div className="flex items-center gap-1">
                          <span className="inline-block text-[#0973BA] text-sm font-bold ">
                            Rs.{item?.price}{" "}
                          </span>
                        </div>
                      )}

                      <span className="flex gap-1  text-[#09BA70] text-sm font-bold object-cover items-center">
                        <AiFillTag
                          height="12px"
                          width="12px"
                          alt="PriceTag"
                        />
                        {item?.price_type}
                      </span>
                    </div>
                    <span className="text-[#CC7D05] text-sm font-semibold h-[30px] text-left">
                      {item.description?.length > 38
                        ? item.description.substring(0, 32) + "...."
                        : item.description}
                    </span>
                  </div>
                  {item.is_enabled === 0 ? (
                    <button
                      type="button"
                      disabled
                      className="relative w-full rounded-xl opacity-80 inline-block outline-none py-4 text-center text-base md:text-sm font-semibold text-white bg-[#0973BA]"
                    >
                      Coming Soon
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        handleClick(item);
                        facebook_conversions("Book Now");
                      }}
                      className="relative w-full rounded-xl opacity-80 inline-block outline-none py-4 text-center text-base md:text-sm font-semibold text-white bg-[#0973BA]"
                    >
                      Book Now
                    </button>
                  )}
                </div>
              </div>
            ))}
        </Slider>

        {data?.success === false && (
          <p className=" font-bold text-sm text-white">{data?.message}</p>
        )}
      </div>
    </>
  );
};

export default WeeklyOffers;
