import { RxCross2 } from "react-icons/rx";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import { useForgotPassword } from "hooks/useUser";
import OtpModal from "views/auth/OtpModal";
import { useState } from "react";
import { useAbandonedLogIn } from "hooks/useAuth";
import { showErrorToast } from "store/features/toastSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ConfirmMobileNumberModal = ({
  setConfirmMobileNumModal,
  handleSubmitGuest,
  isLoading,
  sync_social_account_isLoading,
  onClose,
  user,
  setCurrentStep,
  setMobile_number,
  mobile_number,
  handle_sync_payload,
  handle_request_submit,
}) => {
  const { mutate: forgot_pass_mutate } = useForgotPassword();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  const random_password = Math.random().toString(36).slice(2).substr(0, 6);

  const {
    mutate: abandoned_request_mutate,
    data: abandoned_request_data,
    isLoading: abandoned_request_isLoading,
  } = useAbandonedLogIn();

  const handleNext = () => {
    setStep(step + 1);
  };

  const initialValues = {
    phone: sessionStorage.getItem("mobile_number"),
  };
  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      phone: yup
        .string()
        .matches(/^[0-9]+$/, "Invalid phone number")
        .max(11)
        .min(11)
        .required("Enter Phone Number"),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const { phone } = values;

      const newObj = {
        mobile: phone,
      };
      setMobile_number(newObj?.mobile);
      sessionStorage.setItem("mobile_number", newObj?.mobile);
      if (user === "guest") {
        handleSubmitGuest();
      } else {
        const newObj = {
          mobile: phone,
          password: random_password,
          login_by: "manual",
          device_type: "web",
        };
        abandoned_request_mutate(newObj);
      }
    },
  });

  const handle_otp_payload = () => {
    const payload = {
      mobile: sessionStorage.getItem("mobile_number"),
      device_type: "web",
      login_by: user,
    };
    forgot_pass_mutate(payload);
    handleNext();
  };

  useEffect(() => {
    if (abandoned_request_data?.error_code === 160) {
      dispatch(showErrorToast("New User Must Register Account First"));
      navigate("/register");
      localStorage.removeItem("mobile_number");
    } else if (
      abandoned_request_data?.error_code === 1051 &&
      user === "google"
    ) {
      handle_otp_payload();
    } else if (
      abandoned_request_data?.error_code === 1051 &&
      user === "facebook"
    ) {
      handle_otp_payload();
    }
  }, [abandoned_request_data]);
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    Formik;

  return (
    <>
      <div className="w-full font-Trueno bg-[#AEE0FF]">
        <div className="fixed inset-0 overflow-y-auto scrollbar-hide  bg-black bg-opacity-25 backdrop-blur-sm px-3 md:px-9 py-16">
          <div className="md:w-[50%] h-auto mx-auto bg-white px-3 md:px-6 flex justify-center border rounded-[15px] relative">
            <button
              className=" font-bold uppercase  py-2 text-sm outline-none focus:outline-none mb-1 absolute right-5 top-5"
              type="button"
            >
              <RxCross2
                onClick={onClose}
                className="w-[18px] md:w-[22px] h-[18px] md:h-[22px]"
              />
            </button>
            {step === 1 && (
              <div className="flex flex-col gap-5 py-20 w-full mx-auto text-center">
                {user === "google" || user === "facebook" ? (
                  <div className="flex flex-col gap-5 w-full mx-auto text-center">
                    <h2 className="font-extrabold text-xl md:text-[24px] leading-[29.26px] text-black">
                      Sync
                      <span className="text-[#0973BA]"> Social Account</span>
                    </h2>
                    <p className="text-sm text-black font-normal leading-6">
                      Please enter your mobile number if you already have an
                      account
                    </p>
                    <p className="text-sm text-black font-normal leading-6">
                      If you don't have account please signup your account to
                      continue
                    </p>
                  </div>
                ) : (
                  <h2 className="font-extrabold text-xl md:text-[24px] leading-[29.26px]">
                    Confirm Your Mobile Number to
                    <span className="text-[#0973BA]"> Continue</span>
                  </h2>
                )}
                <div className="flex flex-col gap-3 my-11 text-left">
                  <label
                    htmlFor="phone Number"
                    className="font-semibold text-xs md:text-sm"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="Phone"
                    autoComplete="off"
                    name="phone"
                    id="Phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="03xxx"
                    className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-4 md:pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs md:placeholder:text-sm"
                  />
                  {errors.phone && touched.phone ? (
                    <p className="font-semibold text-sm text-red-600">
                      {errors.phone}
                    </p>
                  ) : null}
                </div>

                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="font-semibold text-[14px] leading-[12px] text-white h-[46px] px-5 w-fit mx-auto border bg-[#0973BA] rounded-lg"
                >
                  {isLoading ||
                  sync_social_account_isLoading ||
                  abandoned_request_isLoading ? (
                    <p className="font-semibold text-xs md:text-sm text-white text-center">
                      Please Wait...
                    </p>
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            )}
            {step === 2 && (
              <div className="py-16">
                <OtpModal
                  user={user}
                  mobile={mobile_number}
                  setCurrentStep={setCurrentStep}
                  handle_sync_payload={handle_sync_payload}
                  handle_request_submit={handle_request_submit}
                />

                {/* <SocialOTPModal mobile={mobile_number} user={user} handle_sync_payload={handle_sync_payload} /> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmMobileNumberModal;
