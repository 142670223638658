import RightSideBar from "common/RightSideBar";
import { useForgotPassword, useVerify_forgototp } from "hooks/useUser";
import React, { useState } from "react";
import { useEffect } from "react";
import OTPInput from "react-otp-input";
import BookingNavbar from "views/partials/navbar/BookingNavbar";
import UpdatePassword from "./UpdatePassword";

const ForgotPasswordOTP = ({ mobile }) => {
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1);

  const verifyOtpMutation = useVerify_forgototp();
  const forgotPasswordMutation = useForgotPassword();
  const { isLoading, data } = verifyOtpMutation;

  const id = data?.data?.id;

  const handleChange = (otp) => setOtp(otp);

  const [remainingTime, setRemainingTime] = useState(180);

  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [remainingTime]);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  const handleNext = () => {
    setStep(step + 1);
  };

  useEffect(() => {
    if (data?.success === true) {
      handleNext();
      // const id = data.data.id;
      // const mobile = data.data.mobile;
      // navigate(`/update-password`, {
      //   state: { mobile: mobile, id: id },
      // });
    }
  }, [data]);

  return (
    <>
      {step === 1 && (
        <div className="flex flex-col w-full  font-Trueno bg-[#F0F7FC]">
          <BookingNavbar color="#F0F7FC" />

          <RightSideBar />
          <div className="w-[98%] md:w-[80%] mx-auto py-20 px-[3px] lg:px-[100px]">
            <div className="bg-white flex flex-col gap-6 items-center py-20 px-[12px] md:px-32 border rounded-[20px] shadow-2xl">
              <h1 className="font-bold text-[20px] md:text-[36px] leading-[24px] md:leading-[43.2px] text-center">
                Your one time Passcode for Karsaaz App is Send on your Mobile
                Number{" "}
              </h1>
              <p className="font-normal text-[14px] leading-[16.8px] mt-[31px]">
                Enter your 6 digit Passcode
              </p>
              <OTPInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                containerStyle={
                  "flex gap-2 lg:gap-10 flex-wrap sm:grid sm:grid-cols-3 justify-center"
                }
                shouldAutoFocus={true}
                separator={<span></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  textAlign: "center",
                  borderRadius: "0.5rem",
                  borderWidth: "1px",
                  background: "#FBFBFB",
                  width: "40px",
                  height: "40px",
                  outline: "none",
                }}
              />

              <p className="font-normal text-[14px] leading-[16.8px] mt-[31px]">
                Have not got it?
              </p>
              <label className="font-semibold text-[17.07px] leading-[17.07px] text-[#0973BA]">
                {remainingTime > 0 ? (
                  // `Resend code in: ${minutes}:${seconds}`
                  <p>
                    Resend code in: {minutes < 10 ? `${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <button
                    type="button"
                    disabled={remainingTime > 0}
                    onClick={() => {
                      const payload = {
                        mobile: mobile,
                      };
                      setRemainingTime(180);
                      forgotPasswordMutation.mutate(payload);
                    }}
                    className="font-semibold text-[17.07px] leading-[17.07px] text-[#0973BA]"
                  >
                    Resend Otp Code
                  </button>
                )}
              </label>
              <button
                type="submit"
                onClick={() => {
                  verifyOtpMutation.mutate({ forgototpcode: otp, mobile });
                }}
                className="font-semibold text-sm text-white h-[46px] w-full border bg-[#0973BA] rounded-lg relative"
              >
                {isLoading ? (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div role="status" className="absolute inset-y-3.8">
                      <div role="status" className="">
                        <svg
                          aria-hidden="true"
                          className="w-6 h-6 mr-2 text-slate-200 animate-spin  fill-[#0973BA]"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  "Verify"
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div>
          <UpdatePassword id={id} />
        </div>
      )}
    </>
  );
};

export default ForgotPasswordOTP;
