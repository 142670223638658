import RightSideBar from "common/RightSideBar";
import React from "react";
import BookingNavbar from "views/partials/navbar/BookingNavbar";

import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useUpdatePassword } from "hooks/useUser";

const UpdatePassword = ({ id }) => {
  const { mutate, isLoading } = useUpdatePassword();

  const initialValues = {
    id: id,
    password: "",
    password_confirmation: "",
    user_type: "user",
  };

  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      password: yup.string().max(8).required("Enter Your Password"),
      password_confirmation: yup
        .string()
        .required()
        .oneOf([yup.ref("password"), null], "Password must match"),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (values) mutate(values);
    },
  });
  const { values, handleSubmit, handleChange, handleBlur, errors, touched } = Formik;

  return (
    <>
      <div className="w-full font-Trueno bg-[#F0F7FC]">
        <BookingNavbar color="#F0F7FC" />

        <RightSideBar />
        <div className="w-[98%] md:w-[80%] mx-auto py-20 px-[5px] lg:px-[100px]">
          <div className="bg-white flex flex-col gap-6 items-center py-20 px-[22px] md:px-32 border rounded-[20px] shadow-2xl">
            <h1 className="font-bold text-[26px] md:text-[36px] leading-[26px] md:leading-[43.2px] text-center">
              Update Your New Password
            </h1>

            {/* --------------------- Form Area --------------------- */}

            <div className=" w-full flex flex-col gap-9">
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-5 w-full">
                  {/* ------------------ Password Field ------------------ */}

                  <div className="flex flex-col gap-3 relative">
                    <label htmlFor="Current Password" className="font-normal text-[12px] leading-5 text-[#979797]">
                      Please Enter A New Password Below
                    </label>
                  </div>
                  <div className="flex flex-col gap-3 relative">
                    <label htmlFor="New Password" className="font-normal text-[14px] leading-[16.8px]">
                      New Password
                    </label>
                    <input
                      type="password"
                      autoComplete="off"
                      name="password"
                      id="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                    />
                    {errors.password && touched.password ? (
                      <p className="font-semibold text-sm text-red-600">{errors.password}</p>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-3 relative">
                    <label htmlFor="Confirm Password" className="font-normal text-[14px] leading-[16.8px]">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      autoComplete="off"
                      name="password_confirmation"
                      id="password_confirmation"
                      value={values.password_confirmation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                    />
                    {errors.password_confirmation && touched.password_confirmation ? (
                      <p className="font-semibold text-sm text-red-600">{errors.password_confirmation}</p>
                    ) : null}
                  </div>

                  {/* ------------------ Button ------------------ */}

                  <button
                    type="submit"
                    className="font-semibold text-sm text-white h-[46px] w-full border bg-[#0973BA] rounded-lg relative"
                  >
                    {isLoading ? (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div role="status" className="absolute inset-y-3.8">
                          <div role="status" className="">
                            <svg
                              aria-hidden="true"
                              className="w-6 h-6 mr-2 text-slate-200 animate-spin  fill-[#0973BA]"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      "Change Password"
                    )}
                  </button>

                  <p className="font-normal text-[14px] leading-5 text-center">
                    Already Have an Account?
                    <Link to="/LogIn" className="font-semibold text-sm text-[#0973BA]">
                      {" "}
                      LogIn{" "}
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
