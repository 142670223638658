import { useState } from "react";
import { useEffect } from "react";
import { BsHeartFill } from "react-icons/bs";
import { MdVerifiedUser } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import SettingFooter from "../settingsPage/SettingFooter";
import BookingNavbar from "views/partials/navbar/BookingNavbar";
import ProfessionalDetails from "../dashboard/ProfessionalDetails";
import { useFav_providers_list, useProvider_detail, useRemove_fav_provider } from "hooks/useUser";

const FavoriteProvider = () => {
  const [providerDetails, setProviderDetails] = useState();
  const [loadingId, setLoadingId] = useState(null);
  const { data, isLoading, refetch } = useFav_providers_list();
  const [showProviderDetails, setShowProviderDetails] = useState(false);
  const { mutate, data: remove_fav_provider, isLoading: remove_fav_provider_isLoading } = useRemove_fav_provider();
  const { mutate: mutate_provider_details, data: provider_detail, isLoading: isLoading_provider_detail } = useProvider_detail();

  const handleMutation = (item) => {
    setLoadingId(item?.id);
    const payload = {
      id: item?.id,
      user_id: localStorage.getItem("id"),
    };
    mutate_provider_details(payload);
  };

  useEffect(() => {
    if (provider_detail?.success === true) {
      setShowProviderDetails(true);
      showProvider();
    }
  }, [provider_detail]);

  const showProvider = () => {
    setProviderDetails({
      avg_rating: provider_detail?.data?.avg_rating,
      business_name: provider_detail?.data?.business_name,
      police_verified: provider_detail?.data?.police_verified,
      provider_name: provider_detail?.data?.provider_name,
      provider_picture: provider_detail?.data?.provider_picture,
      ratings: provider_detail?.data?.ratings,
      services: provider_detail?.data?.services,
      working_days: provider_detail?.data?.working_days,
      working_from: provider_detail?.data?.working_from,
      working_to: provider_detail?.data?.working_to,
      experience: provider_detail?.data?.experience,
      education: provider_detail?.data?.education,
      description: provider_detail?.data?.description,
      work_from: provider_detail?.data?.work_from,
      isindividual: provider_detail?.data?.isindividual,
      qualification: provider_detail?.data?.qualification,
      provider_id: provider_detail?.data?.provider_id,
      user_id: provider_detail?.data?.user_id,
      total_projects: provider_detail?.data?.total_projects,
      latitude: provider_detail?.data?.latitude,
      longitude: provider_detail?.data?.longitude,
      address: provider_detail?.data?.address,
      provider_mobile: provider_detail?.data?.mobile,
      rating_details: provider_detail?.data?.rating_details,
      is_favorite: "yes",
    });
  };

  const handle_remove_fav_provider = (item) => {
    setLoadingId(item?.id);
    const payload = {
      user_id: localStorage.getItem("id"),
      provider_id: item?.id,
    };

    mutate(payload);
  };

  useEffect(() => {
    if (remove_fav_provider?.success === true) {
      refetch();
    }
  }, [remove_fav_provider]);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const starElements = [];

    for (let i = 0; i < fullStars; i++) {
      starElements.push(<AiFillStar key={`full-star-${i}`} color="#F9B523" height="16.28px" width="17.12px" />);
    }

    if (hasHalfStar) {
      starElements.push(<AiFillStar key="half-star" color="#F9B523" height="16.28px" width="17.12px" />);
    }

    const remainingStars = 5 - Math.ceil(rating); // Calculate the remaining empty stars
    for (let i = 0; i < remainingStars; i++) {
      starElements.push(<AiOutlineStar key={`empty-star-${i}`} color="#828282" height="16.28px" width="17.12px" />);
    }

    return starElements;
  };

  return (
    <>
      <div className="w-full max-h-full bg-[#EBF4F9]">
        <BookingNavbar color="#EBF4F9" />
        <div className="w-[95%] md:w-[90%] min-h-[738px] h-auto mx-auto rounded-2xl shadow-2xl bg-white px-[10px] md:px-0 py-3 my-8">
          <div className="border-b border-[#DFDFDF] px-[26px]">
            <h1 className="font-semibold text-[22px] leading-[26.82px] py-[34px]">Favorite Providers</h1>
          </div>
          <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2">
            {isLoading
              ? Array(1)
                  .fill()
                  .map((_, index) => (
                    <div key={index} className="absolute inset-0 flex items-center justify-center">
                      <div role="status" className="absolute inset-y-3.8">
                        <div role="status" className="">
                          <svg
                            aria-hidden="true"
                            className="w-6 h-6 mr-2 text-slate-200 animate-spin  fill-[#0973BA]"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  ))
              : data?.data?.map((item, index) => (
                  <div key={index} className="w-full max-w-[339px] bg-white border rounded-2xl my-6 md:mx-[26px] self-center">
                    <div className="flex flex-col gap-3 p-3">
                      {/* <img className="rounded-lg w-[116px] h-[116px]" src={item?.picture} alt="" /> */}

                      {item?.picture === "" || item?.picture === "https://stage.karsaaz.app/placeholder.png" ? (
                        <img
                          src={`${process.env.PUBLIC_URL}/images/EditProfileIcon.png`}
                          alt=""
                          className="rounded-full h-[121px] w-[121px] object-cover"
                        />
                      ) : (
                        <img
                          src={item?.picture}
                          // src={`${process.env.PUBLIC_URL}/images/FavoriteProviderIcon.png`}
                          className="w-[116px] h-[116px] rounded-[15px] object-cover"
                          alt=""
                        />
                      )}

                      <div className="grid md:flex gap-2 justify-between md:items-center">
                        <h1 className="font-semibold text-[18px] leading-[21.94px]"> {item?.name}</h1>
                        {item?.is_approved === 1 && item?.provider_verified === "yes" ? (
                          <p className="flex items-center gap-2 font-medium text-[12px] leading-[28px] text-white bg-[#09BA70] px-3 py-[2px] w-fit rounded-xl">
                            <MdVerifiedUser color="white" />
                            Verified
                          </p>
                        ) : (
                          <p className="flex items-center gap-2 font-medium text-[12px] leading-[28px] text-[#FF4E4E] bg-red-300 bg-opacity-[0.15] px-3 py-[2px] w-fit rounded-xl">
                            <RxCrossCircled color="#FF4E4E" />
                            Not Verified
                          </p>
                        )}
                      </div>
                      <div>
                        <div className="flex items-center gap-1">
                          {item?.avg_rating && renderStars(parseFloat(item?.avg_rating))}
                          <span className="font-normal text-[14px] leading-[16.8px]">
                            {item?.avg_rating && parseFloat(item?.avg_rating).toFixed(1)}
                          </span>
                          <p className="font-normal text-[14px] leading-[19.2px] text-[#979797]">{item?.ratings?.length}</p>
                        </div>

                        {item?.avg_rating && (
                          <span className="font-normal text-[14px] leading-[16.8px]">
                            ({parseFloat(item?.avg_rating).toFixed(1)} customer review)
                          </span>
                        )}
                      </div>
                      <hr className="w-full border-[#ADADAD]" />
                      <div className="flex flex-col gap-5">
                        <div className="grid md:flex gap-2 justify-between md:items-center">
                          <label className="font-normal text-[12px] leading-[14.4px]">Location</label>
                          <h1 className="font-semibold text-[12px] leading-[14.63px]">{item?.address} </h1>
                        </div>{" "}
                        <div className="grid sm:flex  gap-2 justify-between md:items-center">
                          <label className="font-normal text-[12px] leading-[14.4px]">Completed Jobs</label>
                          <h1 className="font-semibold text-[12px] leading-[14.63px]">{item?.total_projects}</h1>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between items-center divide-x border-t ">
                      <button
                        onClick={() => {
                          handleMutation(item);
                          localStorage.removeItem("cardDetails");
                        }}
                        className="font-semibold text-[14px] leading-[17.07px] text-[#0973BA] py-[4px] px-3"
                      >
                        {isLoading_provider_detail && item?.id === loadingId ? "Please Wait..." : "View Profile"}
                      </button>
                      {showProviderDetails && (
                        <ProfessionalDetails
                          details={providerDetails}
                          onClose={() => setShowProviderDetails(false)}
                          setShowProviderDetails={setShowProviderDetails}
                        />
                      )}
                      <button
                        onClick={() => {
                          handle_remove_fav_provider(item);
                        }}
                        className="px-5 py-[18px]"
                      >
                        {remove_fav_provider_isLoading && item?.id === loadingId ? (
                          <div
                            className="text-[#FF4E4E] inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </div>
                        ) : (
                          <BsHeartFill className="w-[20px] h-[18.48px]" color="#FF4E4E" />
                        )}
                      </button>
                    </div>
                  </div>
                ))}
          </div>
          {data?.data?.length === 0 ? (
            <div className="w-full mx-auto my-8 text-center border rounded-lg py-3">
              <p className="font-semibold text-[14px] leading-[17.07px] text center">No Data Found</p>
            </div>
          ) : null}
        </div>

        <SettingFooter />
      </div>
    </>
  );
};

export default FavoriteProvider;
