import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const MetaTags = () => {
  const location = useLocation();

  const noIndexRoutes = [
    '/home',
    '/more/services',
    '/static-page/karsaaz-app-rewards',
    '/static-page/contact-us',
    '/static-page/karsaaz-terms-and-conditions',
    '/static-page/privacy-policy',
    '/static-page/faq',
    '/user/login',
    '/popular-services',
    '/register',
    '/female-tailoring-service-at-home/1000',
    '/waxing-at-home/1000',
    '/login',
    '/internal/admin/login',
    '/index.php/static-page/faq',
    '/index.php/static-page/about-us',
    '/index.php/static-page/privacy-policy',
    '/index.php/login',
    '/index.php/search',
    '/static-page/karsaaz-terms-and-conditions-',
    '/static-page/contact',
    '/static-page/privacy',
    '/index.php/static-page/about',
    '/index.php/static-page/privacy',
    '/index.php/static-page/karsaaz-app-rewards',
  ];

  const shouldNoIndex = noIndexRoutes.includes(location.pathname);

  return (
    <Helmet>
      {shouldNoIndex ? (
        <meta key="noindex" name="robots" content="noindex, nofollow" />
      ) : (
        <meta
          key="index"
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      )}
    </Helmet>
  );
};

export default MetaTags;
