// import { FaPhoneAlt } from "react-icons/fa";
// import { RiWhatsappFill } from "react-icons/ri";
// import { BsMessenger } from "react-icons/bs";
// import { useState, useEffect } from "react";
// import axios from "axios";

// const baseURL = "https://karsaaz.app/api/common/env_verification_values";

// const RightSideBar = () => {
//   const [data, setData] = useState([]);

//   const getApi = () => {
//     axios
//       .get(baseURL)
//       .then((res) => {
//         setData(res.data);
//       })
//       .catch((err) => {});
//   };

//   useEffect(() => {
//     getApi();
//   }, []);

//   return (
//     <>
//       <div className="h-fit bg-[#09BA70] rounded-l-[15px] fixed  top-[10%] lg:top-[30%] lg:inset-y-0 right-0 z-10 p-1 px-1 md:px-3 py-7">
//         <div className="flex flex-col gap-4 md:gap-5 items-center place-content-center">
//           <button className="w-15" onClick={getApi}>
//             <a href={`tel:${data?.data?.karsaaz_mobile}`}>
//               <FaPhoneAlt className="w-[22px] md:w-[29.63px] h-[22px] md:h-[29.63px] " color="white" />
//             </a>
//           </button>

//           <svg width="40" height="1" viewBox="0 0 42 1" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <line opacity="0.5" x1="0.5" y1="0.5" x2="41.5" y2="0.5" stroke="white" strokeLinecap="round" />
//           </svg>
//           <a href={`https://wa.me/+92${data?.data?.karsaaz_whatsapp}`} target="_blank" rel="noreferrer">
//             <RiWhatsappFill className="w-[28px] md:w-[38px] h-[28px] md:h-[38px] " color="white" />
//           </a>

//           <svg width="40" height="1" viewBox="0 0 42 1" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <line opacity="0.5" x1="0.5" y1="0.5" x2="41.5" y2="0.5" stroke="white" strokeLinecap="round" />
//           </svg>

//           <a href="https://www.facebook.com/karsaazApp/" target="_blank" rel="noreferrer">
//             <BsMessenger className="w-[22px] md:w-[29.63px] h-[22px] md:h-[29.63px]" color="white" />
//           </a>
//         </div>
//       </div>
//     </>
//   );
// };

// export default RightSideBar;

import { useState, useEffect } from "react";
import axios from "axios";
import { facebook_conversions } from "views/conversionAPI/FacebookConversions";

const baseURL = "https://karsaaz.app/api/common/env_verification_values";

const RightSideBar = () => {
  const [data, setData] = useState([]);

  const getApi = () => {
    axios
      .get(baseURL)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getApi();
  }, []);

  return (
    <>
      <div className="sidebar z-10">
        <div className="social facebook">
          <div className="sidebar-anchor">
            <a
              onClick={() => {
                facebook_conversions("Phone");
              }}
              href={`tel:${data?.data?.karsaaz_mobile}`}
            >
              <div className="sidebar-paragraph">
                <div className="flex items-center gap-1 text-white">
                  <div className="p-2  w-[50px] h-[50px]">
                    <img className="" src={`${process.env.PUBLIC_URL}/images/PhoneIcon.png`} alt="" />
                  </div>
                  {data?.data?.karsaaz_mobile}
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="social whatsapp">
          <div className="sidebar-anchor">
            <a
              onClick={() => {
                facebook_conversions("Whatsapp");
              }}
              href={`https://wa.me/92${data?.data?.karsaaz_whatsapp.slice(1)}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="sidebar-paragraph">
                <div className="flex items-center gap-1 text-white">
                  <div className="p-2 w-[48px] h-[48px]">
                    <img className="" src={`${process.env.PUBLIC_URL}/images/whatsapp_icon.png`} alt="" />
                  </div>
                  {data?.data?.karsaaz_whatsapp}
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* <div className="social messenger">
          <div className="sidebar-anchor">
            <a href="https://www.facebook.com/karsaazApp/" target="_blank" rel="noreferrer">
              <div className="sidebar-paragraph">
                <div className="flex items-center gap-1 text-white w-full">
                  <div className="p-2 w-[48px] h-[48px]">
                    <img className="" src={`${process.env.PUBLIC_URL}/images/MessangerIcon.png`} alt="" />
                  </div>
                  Chat
                </div>
              </div>
            </a>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default RightSideBar;
