import React from "react";
import NavCustom from "views/partials/navbar/NavCustom";
import RightSideBar from "common/RightSideBar";

const Banner = (props) => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url('${props.backgroundImage}')`,
        }}
        className={`bg-[#0973BA] relative  bg-no-repeat   bg-[length:_500px]   ${props.BackgroundImageStyling}`}
      >
        {/* <div style={props.style} className="bg-[#0973BA] relative bg-no-repeat"> */}
        <div style={{ height: props.banner_height }} className="flex flex-col w-full">
          <NavCustom styling={{ color: "white", backgroundColor: "#0973BA" }} />
          <RightSideBar />

          <div className="font-Trueno flex flex-col-reverse md:flex-row justify-between text-center md:text-left items-center w-[90%] mx-auto md:pb-0">
            <div className="md:w-[650px]">
              <h1 className=" font-extrabold text-[24px] md:text-[48px] leading[58.51px] text-white">{props.Heading}</h1>
              <p className="font-normal text-base md:text-lg text-white">{props.Descriptions}</p>
            </div>
            {/* <------------- Hero----Section----Image-------- > */}
            <div className=" max-w-[363.76px]">
              {/* <img src={HandyManIcon} alt="" className=" h-[363.97px] " /> */}
              <img src={props.Icon} alt="" className={`h-auto ${props.BackgroundSecondImageStyling}`} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
