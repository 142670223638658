import axios from "axios";

export const facebook_conversions = (event_name) => {
  const baseUrl = "https://graph.facebook.com/v18.0/879536679349416/events";

  const accessToken = process.env.REACT_APP_FACEBOOK_CONVERSION_API_KEY;

  const currentTime = Math.floor(Date.now() / 1000);

  const eventData = {
    data: [
      {
        event_name: event_name, // EVERY EVENT NAME
        event_time: currentTime, // sent this in time format
        event_id: "2",
        event_source_url: process.env.REACT_APP_API_URL,
        action_source: "website",
        user_data: {
          client_ip_address: "192.168.0.1",
          client_user_agent: "Karsaaz",
        },
      },
    ],
    // test_event_code: "TEST27757",
  };

  axios
    .post(baseUrl, eventData, {
      params: {
        access_token: accessToken,
      },
    })

    .catch((err) => {
      console.error("API request failed:", err);
    });
};
