import React from "react";
import SideBarMenu from "./SideBarMenu";
import BookingNavBar from "views/partials/navbar/BookingNavbar";
import SettingFooter from "./SettingFooter";
import { useChangePassword } from "hooks/useUser";
import { useFormik } from "formik";
import * as yup from "yup";

const ChangePassword = () => {
  const { mutate, isLoading } = useChangePassword();

  const initialValues = {
    old_password: "",
    password: "",
    password_confirmation: "",
  };

  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      old_password: yup.string().min(2).max(25),
      password: yup.string().min(6).required("Enter Your Password"),
      password_confirmation: yup
        .string()
        .required()
        .oneOf([yup.ref("password"), null], "Password must match"),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (values) mutate(values);
    },
  });
  const { values, handleSubmit, handleChange, handleBlur, errors, touched } =
    Formik;

  return (
    <>
      <div className="bg-[#F0F7FC]">
        <BookingNavBar color="#F0F7FC" />
        <div className="w-full font-Trueno my-20">
          <div className="md:w-[85%] mx-auto flex-col md:flex md:flex-row md:gap-3 md:justify-between md:px-0 px-2">
            <div>
              <SideBarMenu />
            </div>
            <div className="border rounded-[20px] shadow-2xl bg-white w-full md:w-[988px] lg:w-full mt-4 md:mt-0">
              <h1 className="font-normal text-[18px] leading-[21.6px] border-b border-[#DFDFDF] px-4 md:px-[38px] pt-8 pb-4">
                Change Password
              </h1>
              <div className="flex flex-col-reverse lg:flex lg:flex-row gap-6 justify-between  px-[18px] md:px-[38px]  w-full ">
                {/* --------------------- Form Area --------------------- */}

                <div className="my-14 md:max-w-[417px] w-full flex flex-col gap-9">
                  <form onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-5 w-full">
                      {/* ------------------ Password Field ------------------ */}

                      <div className="flex flex-col gap-3 relative">
                        <label
                          htmlFor="Current Password"
                          className="font-normal text-[14px] leading-[16.8px]"
                        >
                          Current Password
                        </label>

                        <input
                          type="password"
                          autoComplete="off"
                          name="old_password"
                          id="old_password"
                          value={values.old_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                        />
                      </div>
                      <div className="flex flex-col gap-3 relative">
                        <label
                          htmlFor="Current Password"
                          className="font-normal text-[14px] leading-5"
                        >
                          Please Enter A New Password Below
                        </label>
                        <label
                          htmlFor="Current Password"
                          className="font-normal text-[12px] leading-5 text-[#979797]"
                        >
                          Minimum six character with a number and a letter
                        </label>
                      </div>
                      <div className="flex flex-col gap-3 relative">
                        <label
                          htmlFor="New Password"
                          className="font-normal text-[14px] leading-[16.8px]"
                        >
                          New Password
                        </label>
                        <input
                          type="password"
                          autoComplete="off"
                          name="password"
                          id="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                        />
                        {errors.password && touched.password ? (
                          <p className="font-semibold text-sm text-red-600">
                            {errors.password}
                          </p>
                        ) : null}
                        {/* <input
                          type="password"
                          autoComplete="off"
                          name="password"
                          value={values.password}
                          placeholder=""
                          className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                        /> */}
                      </div>
                      <div className="flex flex-col gap-3 relative">
                        <label
                          htmlFor="Confirm Password"
                          className="font-normal text-[14px] leading-[16.8px]"
                        >
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          autoComplete="off"
                          name="password_confirmation"
                          id="password_confirmation"
                          value={values.password_confirmation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                        />
                        {errors.password_confirmation &&
                        touched.password_confirmation ? (
                          <p className="font-semibold text-sm text-red-600">
                            {errors.password_confirmation}
                          </p>
                        ) : null}
                        {/* <input
                          type="password"
                          autoComplete="off"
                          name="password_confirmation"
                          value={values.password_confirmation}
                          placeholder=""
                          className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-7 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-sm"
                        /> */}
                      </div>

                      {/* ------------------ Button ------------------ */}

                      <button
                        type="submit"
                        className="font-semibold text-sm text-white h-[46px] w-full border bg-[#0973BA] rounded-lg relative"
                      >
                        {isLoading ? (
                          <div
                            className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </div>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </form>
                </div>

                {/* --------------------- Image Area --------------------- */}

                <div className="mt-12 w-fit mx-auto">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ChangePasswordIcon.webp`}
                    alt=""
                    className="object-contain md:w-[245.89px] h-[262.37px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SettingFooter />
      </div>
    </>
  );
};

export default ChangePassword;
