import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Autocomplete, useJsApiLoader, Marker, LoadScript } from "@react-google-maps/api";
import { BsSearch } from "react-icons/bs";
import { TbCurrentLocation } from "react-icons/tb";

const containerStyle = {
  width: "100%",
  height: "216px",
};

const center = {
  lat: 31.4697,
  lng: 74.2728,
};

function MyComponent({
  setFieldValue,
  values,
  errors,
  touched,
  handleBlur,
  setLatitude,
  setLongitude,
  setAddress,
  address,
  autoComplete2,
  setAutoComplete2,
  selectedPlace,
  setSelectedPlace,
  currentLocation,
  setCurrentLocation,
}) {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const [libraries] = useState(["places"]);
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const [map, setMap] = useState(null);

  const autocompleteInputRef = useRef(null);

  useEffect(() => {
    // Reverse geocode when the selected place changes
    if (selectedPlace !== null) {
      getAddress(selectedPlace.geometry.location.lat(), selectedPlace.geometry.location.lng())
        .then((address) => {
          setAddress(address);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedPlace]);

  useEffect(() => {
    // Reverse geocode when the current location changes
    if (currentLocation !== null) {
      getAddress(currentLocation.lat, currentLocation.lng)
        .then((address) => {
          // localStorage.setItem("address", address);
          setAddress(address);

          if (autocompleteInputRef.current) {
            autocompleteInputRef.current.value = address;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [currentLocation]);

  const onLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  };

  const onUnmount = () => {
    setMap(null);
  };

  const onPlaceChanged = () => {
    if (autoComplete2) {
      // Check if autoComplete2 is not null
      const place = autoComplete2.getPlace();

      if (place.geometry && place.geometry.location) {
        // Check if place has a valid location
        setSelectedPlace(place);
        setFieldValue("autocomplete", place);

        setLatitude(place.geometry.location.lat());
        setLongitude(place.geometry.location.lng());

        if (map !== null) {
          map.setCenter(place.geometry.location);
        }
      }
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
          // localStorage.setItem("latitude", latitude);
          // localStorage.setItem("longitude", longitude);
          setLatitude(latitude);
          setLongitude(longitude);
          setFieldValue("autocomplete", { lat: latitude, lng: longitude });

          // Reverse geocode the current location
          getAddress(latitude, longitude)
            .then((address) => {
              // localStorage.setItem("address", address);
              setAddress(address);

              if (autocompleteInputRef.current) {
                autocompleteInputRef.current.value = address;
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const getAddress = (latitude, longitude) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        {
          location: { lat: latitude, lng: longitude },
        },
        (results, status) => {
          if (status === "OK" && results.length > 0) {
            resolve(results[0].formatted_address);
          } else {
            reject("Reverse geocoding failed");
          }
        }
      );
    });
  };

  return (
    <>
      {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={libraries}>  </LoadScript> */}

      <div className="w-full">
        <div>
          <label htmlFor="Select Location">Select Location</label>
        </div>
        {isLoaded && (
          <Autocomplete onLoad={(autocomplete) => setAutoComplete2(autocomplete)} onPlaceChanged={onPlaceChanged}>
            <div className="relative">
              <BsSearch className="w-[15.83px] h-[15.83px] absolute inset-y-0 left-5 top-4" />
              <input
                ref={autocompleteInputRef}
                type="text"
                name="autocomplete"
                id="autocomplete"
                onKeyDown={handleKeyDown}
                placeholder="Search for a location"
                onBlur={handleBlur}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="w-full rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#DFDFDF] h-[46px] py-4 text-black mb-4 pl-12"
              />
            </div>
          </Autocomplete>
        )}
        {errors.autocomplete && touched.autocomplete && <div className="text-red-500 text-sm">{errors.autocomplete}</div>}

        <div className="flex gap-2 justify-start items-center mb-4">
          <button
            onClick={getCurrentLocation}
            type="button"
            className="flex items-center gap-1 font-normal text-[14px] leading-[16.8px] font-Trueno text-[#0973BA]"
          >
            <TbCurrentLocation className="w-[21.5px] h-[21.5px]" color="#0973BA" />
            Use Current Location
          </button>
        </div>

        <div>
          {loadError ? (
            <p>Error loading Google Maps</p>
          ) : isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={currentLocation || center}
              zoom={currentLocation ? 14 : 10}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                fullscreenControl: false,
                zoomControl: false,
                mapTypeControl: false,
                streetViewControl: false,
              }}
            >
              {selectedPlace && (
                <Marker
                  position={{
                    lat: selectedPlace.geometry.location.lat(),
                    lng: selectedPlace.geometry.location.lng(),
                  }}
                />
              )}
            </GoogleMap>
          ) : (
            <div className="rounded-xl bg-slate-700 h-full w-full"></div>
          )}
        </div>
      </div>
    </>
  );
}

export default React.memo(MyComponent);
