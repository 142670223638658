import React from "react";
import { BrowserRouter as Router, Link, Navigate, Route, Routes } from "react-router-dom";

const Policies = () => {
  const item = [
    {
      id: 1,
      name: "Privacy Policy",
      link: "/privacy-policy",
    },
    {
      id: 2,
      name: "Terms and Conditions",
      link: "/karsaaz-terms-and-conditions",
    },
  ];
  return (
    <>
      <div className="flex w-full font-Roboto">
        <div className="w-[270px] text-white ">
          {/* <h1 className="font-bold not-italic text-[18.4592px] leading-[22px]	text-black">Policies</h1> */}
          <div className="">
            <ul className="flex flex-col gap-2 font-normal text-sm leading-[34px] pr-11">
              {item.map((item, index) => (
                <li key={index} className="w-fit cursor-pointer capitalize font-medium hover:scale-105 hover:duration-200">
                  <Link to={item.link}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Policies;
