import React from "react";
import BlogsNavbar from "./BlogsNavbar";
import FooterMain from "views/partials/footer/FooterMain";
import BlogsCarousel from "./BlogsCarousel";
import LatestBlogs from "./LatestBlogs";
import BlogsCategories from "./BlogsCategories";
import TrendingPosts from "./TrendingPosts";
import { useLocation } from "react-router-dom";
import BlogDetailPage from "./BlogDetailPage";
import { useState } from "react";

const Blogs = () => {
  const location = useLocation();
  const { blog_category_id, blogs_detail_data } = location.state || {};
  const [postsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <BlogsNavbar />
      <BlogsCarousel />
      <div className="w-[90%] mx-auto flex flex-col-reverse md:flex md:flex-row gap-7 justify-between">
        <LatestBlogs
          blog_category_id={blog_category_id}
          blogs_detail_data={blogs_detail_data}
          postsPerPage={postsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <BlogsCategories />
      </div>
      <div className="w-[90%] mx-auto block md:hidden mt-4">
        <TrendingPosts />
      </div>
      <FooterMain />
    </>
  );
};

export default Blogs;
