import React, { useEffect } from "react";
import { useGet_country_city, useSee_Seo_page } from "hooks/useUser";
import { useNavigate, useParams } from "react-router-dom";

const AvailableIn = () => {
  const navigate = useNavigate();
  let { seo_city_name } = useParams();
  const { mutate, data: see_Seo_page } = useSee_Seo_page({ seo_city_name });
  const { data: city, isLoading: city_isLoading } = useGet_country_city();


  const handleClick = (item) => {
    const payload = {
      slug: item?.name,
    };
    mutate(payload);
    localStorage.setItem("selectedOption", item?.id);
  };

  useEffect(() => {
    if (see_Seo_page?.success === true) {

      navigate(`/${see_Seo_page?.data?.slug}`, {
        state: {
          slug: see_Seo_page?.data?.slug,
        },
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [see_Seo_page]);

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="w-[316px] text-white">
          <h3 className="font-Inter font-bold not-italic text-[18.4592px] leading-[22px] text-white">Available in</h3>
          <div>
            <ul className="flex flex-col gap-2 font-Roboto font-normal text-sm leading-[34px] mt-5">
              {city_isLoading
                ? Array(4)
                  .fill()
                  .map((_, index) => <div key={index} className="animate-pulse h-[12.22px] w-[122.22px] bg-sky-200 mb-2"></div>)
                : city?.data?.map((item, index) => (
                  <li
                    onClick={() => {
                      handleClick(item);
                    }}
                    className="w-fit cursor-pointer capitalize font-medium hover:scale-105 hover:duration-200"
                    key={index}
                  >
                    {item?.name}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailableIn;
