import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import { store } from "store/store";
import GlobalToast from "utils/GlobalToast";
import "./index.css";
import App from "./App";

const queryClient = new QueryClient();
const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <GlobalToast />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);
