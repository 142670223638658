import { scrollToTop } from "common/ScrollToTop";

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage, setCurrentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  let lastElement = pageNumbers[pageNumbers.length - 1];

  const handleClick = (direction) => {
    let newStep = currentPage;
    direction === "next" ? newStep++ : newStep--;
    newStep > 0 && newStep <= totalPosts && setCurrentPage(newStep);
    scrollToTop();
  };

  return (
    <nav>
      <ul className="pagination flex gap-5 items-center">
        <div>
          <button
            title="previous"
            type="button"
            className="inline-flex items-center justify-center p-2 border border-[#BCBCBC] rounded-full"
            onClick={() => handleClick("back")}
          >
            <svg
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-4"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </button>
        </div>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className="page-item
          
          inline-flex items-center justify-center  text-sm font-semibold  text-black
          "
          >
            <button
              onClick={() => {
                paginate(number);
                scrollToTop();
              }}
              className={`page-link  ${
                currentPage === number ? "w-8 h-8 bg-[#0973BA] text-white border rounded-full shadow-md" : "text-black"
              }`}
            >
              {number}
            </button>
          </li>
        ))}
        <div>
          <button
            title="next"
            type="button"
            className={`inline-flex items-center justify-center p-2 border border-[#BCBCBC] rounded-full ${
              currentPage === lastElement ? "hidden" : "block"
            }`}
            onClick={() => handleClick("next")}
          >
            <svg
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-4"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
        </div>
      </ul>
    </nav>
  );
};

export default Pagination;
