import { usePopular_sub_categories, useWeekly_offer_sub_categories } from "hooks/useUser";
import PopularTrendingButton from "./PopularTrendingButton";
import CityFilter from "./CityFilter";
import WeeklyOffers from "./WeeklyOffers";

const TopTrendingServices = ({ cityFilterBorder, setCityFilterBorder }) => {
  const { data, isLoading, mutate } = useWeekly_offer_sub_categories();
  const {
    data: popular_service_data,
    isLoading: popular_service_isLoading,
    mutate: popular_service_mutate,
  } = usePopular_sub_categories();

  const handleCityChange = (cityValue) => {
    const payload = {
      city_id: cityValue,
    };
    mutate(payload);
    popular_service_mutate(payload);
  };

  const cityFilterComponent = <div>
    <CityFilter onCityChange={handleCityChange} cityFilterBorder={cityFilterBorder} setCityFilterBorder={setCityFilterBorder} />
  </div >
  return (
    <>
      <div
        className=" w-full mx-auto">
        {/* --------------------- Weekly Offers --------------------- */}
        {data?.message === "No weekly offer sub category exists" ? null : (
          <div className="w-full flex flex-col gap-4 md:gap-9 mx-auto text-center  text-black pt-3 md:pt-9">
            <span className="font-bold text-3xl md:text-[36px] leading-[43.2px]">Weekly Offers</span>
            <div
              className="w-full flex justify-center md:justify-start">
              {cityFilterComponent}
            </div>

            <div
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/TrendingBgIcon.webp)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "620px",
              }}
              className="font-Trueno px-2 md:px-14 bg-cover bg-left-bottom md:bg-right-top  bg-[#0973BA] rounded-3xl py-12"
            >
              <WeeklyOffers data={data} isLoading={isLoading} handleCityChange={handleCityChange} />
            </div>
          </div>
        )}

        {/* --------------------- Popular Categories --------------------- */}

        <div className="w-full flex flex-col gap-4 md:gap-9 mx-auto text-center  text-black py-3 md:py-9">
          <span className="font-bold text-3xl md:text-[36px] leading-[43.2px]">Popular Services</span>
          {!data?.data?.length ? (
            <div
              className="w-full flex justify-center md:justify-start">
              {cityFilterComponent}
            </div>
          ) : null}
          <div
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/TrendingBgIcon.webp)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "620px",
            }}
            className="font-Trueno px-2 md:px-14 bg-cover bg-left-bottom md:bg-right-top  bg-[#0973BA] rounded-3xl py-12"
          >
            <PopularTrendingButton
              popular_service_data={popular_service_data}
              popular_service_isLoading={popular_service_isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TopTrendingServices;
