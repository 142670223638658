import AppStoreButton from "theme/buttons/AppStoreButton";
import PlayStoreButton from "theme/buttons/PlayStoreButton";

const TrackYourService = () => {
  return (
    <>
      <div className="w-full mt-7 font-Trueno ">
        <div className=" flex-col md:flex md:flex-row justify-between items-center border rounded-[20px] bg-[#0973BA] relative">
          <div className="flex flex-col gap-3 justify-between md:justify-start items-center md:items-start text-center md:text-left md:w-[324px] mt-9 md:mx-9 my-8 relative  ">
            <h1 className="font-bold md:text-[22px] md:leading-[26px] text-white px-0 md:px-2">
              Download Karsaaz App to track your service delivery and get notifications
            </h1>

            <div className="grid lg:flex gap-2 md:gap-2  ">
              <PlayStoreButton />
              <AppStoreButton />
            </div>
          </div>
          <div className="w-fit mx-auto md:absolute bottom-0 right-2">
            <img
              src={`${process.env.PUBLIC_URL}/images/MobileGetMoreDoneIcon.svg`}
              alt=""
              className="w-full md:w-[251px] h-[281px] object-cover"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackYourService;
