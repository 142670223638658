import React from "react";

const AppStoreButton = () => {
  return (
    <>
      <div className="w-full ">
        <a href="https://tinyurl.com/hjnzs476" target="_blank" rel="noreferrer">
          <button className="w-[182px] h-[50px] flex gap-3 items-center bg-white box-border border rounded-lg text-Black font-normal text-[12px] font-Poppins leading-[18px] text-center pl-[15.06px] pr-8 py-2 hover:scale-105 hover duration-200">
            <img className="w-[30px] h-[30px]" src={`${process.env.PUBLIC_URL}/images/AppleIcon.svg`} alt="" />

            <p className="flex flex-col text-black">
              Download On
              <span className="font-semibold	text-base	">App Store</span>
            </p>
          </button>
        </a>
      </div>
    </>
  );
};

export default AppStoreButton;
