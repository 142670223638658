import React from 'react'
import ShimmerSkeleton from "views/loader/ShimmerSkeleton";
import { Card } from "theme/cards/Card";
import CityFilter from "common/CityFilter";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { usePopular_sub_categories } from 'hooks/useUser';
import { Link } from 'react-router-dom';

const BlogsPopularCategories = () => {

    const {
        data: popular_categories,
        isLoading: popular_categories_isLoading,
        mutate: popular_categories_mutate,
    } = usePopular_sub_categories();

    const handleCityChange = (cityValue) => {
        const payload = {
            city_id: localStorage.getItem("selectedOption") || 1,
        };
        popular_categories_mutate(payload);
    };
    return (
        <>
            <div className="w-[90%] mx-auto my-20 ">
                <h2 className="w-full text-center font-Trueno font-bold text-2xl">Karsaaz Services</h2>

                <div className="w-fit mt-11">
                    <CityFilter onCityChange={handleCityChange} />
                </div>
                <div className="w-full flex justify-end">
                    <Link to="/popular-services">
                        <button className="w-full md:w-[150px] font-semibold text-lg text-[#0973BA] hover:underline mt-8 mb-3">
                            View All
                        </button>
                    </Link>
                </div>
                <Swiper
                    grabCursor={true}
                    slidesPerView={1}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={{ nextEl: "#arrow-right1", prevEl: "#arrow-left1" }}
                    modules={[Autoplay, Navigation]}
                    className="w-full mx-auto"
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        1250: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                    }}
                >
                    <div className="flex gap-3">
                        {popular_categories_isLoading
                            ? Array(3)
                                .fill()
                                .map((_, index) => (
                                    <div key={index}>
                                        <ShimmerSkeleton />
                                    </div>
                                ))
                            : popular_categories?.data?.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <Card
                                        category_id={item?.category_id}
                                        sub_category_id={item?.id}
                                        name={item?.name}
                                        picture={item?.picture}
                                        price={item?.price}
                                        price_type={item?.price_type}
                                        category_description={item?.description}
                                        sub_category_is_bidded={item?.is_bidded}
                                        sub_category_discount_price={item?.discount_price}
                                        backgroundColor="#0973BA"
                                        sub_category_web_seo_url={item?.web_seo_url}
                                        sub_category_is_enabled={item?.is_enabled}
                                        sub_category_slug={item?.slug}
                                    />
                                </SwiperSlide>
                            ))}
                    </div>
                </Swiper>

                <div className="flex justify-between items-center my-6">
                    <button
                        id="arrow-left1"
                        type="button"
                    >
                        <FaArrowCircleLeft color="#0973BA" className="w-[34px] h-[34px]" />

                    </button>
                    <button
                        id="arrow-right1"
                        type="button"
                    >
                        <FaArrowCircleRight color="#0973BA" className="w-[34px] h-[34px]" />

                    </button>
                </div>
            </div >
        </>
    )
}

export default BlogsPopularCategories