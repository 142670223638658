import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePopular_services_by_category } from "hooks/useUser";
import FooterMain from "views/partials/footer/FooterMain";
import CustomerChoosesUs from "common/CustomerChoosesUs";
import CustomerLoveUs from "theme/cards/CustomerLoveUs";
import NavCustom from "./partials/navbar/NavCustom";
import RightSideBar from "common/RightSideBar";
import { useServices } from "hooks/useService";
import SearchBar from "common/SearchBar";
import { useEffect } from "react";


import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";


const Services = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, data, mutate } = useServices();

  const { web_banner_image, web_banner_text, category_id, super_category_name, super_category_description } =
    location.state || {};

  const {
    data: popular_services_by_category_data,
    mutate: popular_services_by_category_mutate,
    isLoading: popular_services_by_category_isLoading,
  } = usePopular_services_by_category();

  useEffect(() => {
    const payload = {
      category_id: category_id,
    };
    mutate(payload);

    const popular_services_by_category = {
      id: category_id,
      popular_type: "categories",
    };
    popular_services_by_category_mutate(popular_services_by_category);
  }, [category_id]);

  const handleClick = (item) => {
    navigate(`/sub-categories/${item?.category_slug}`, {
      state: {
        web_banner_image: item?.web_banner_image,
        web_banner_text: item?.web_banner_text,
        category_name: item?.category_name,
        category_id: item?.category_id,
        category_description: item?.category_description,
      },
    });
  };

  const handle_popular_cat = (item) => {
    navigate(`/sub-categories/${item?.slug}`, {
      state: {
        web_banner_image: item?.web_banner_image,
        web_banner_text: item?.web_banner_text,
        category_name: item?.name,
        category_id: item?.id,
      },
    });
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/FooterBackIcon.png)`,
        }}
        className={`bg-[#0973BA] relative bg-no-repeat bg-[length:_500px] bg-left-bottom`}
      >
        {/* <div style={props.style} className="bg-[#0973BA] relative bg-no-repeat"> */}
        <div className="flex flex-col w-full h-574px">
          <NavCustom styling={{ color: "white", backgroundColor: "#0973BA" }} />

          <RightSideBar />

          {isLoading ? (
            <div>
              <div className="w-[90%] mx-auto flex flex-col-reverse md:grid md:grid-cols-2 p-3 py-12">
                <div className="w-full flex flex-col gap-3 pt-5 md:py-12">
                  <div className="animate-pulse w-full h-7 bg-sky-200"></div>
                  <div className="animate-pulse w-full h-7 bg-sky-200"></div>
                  <div className="animate-pulse w-1/5 h-7 bg-sky-200"></div>

                  <div className="animate-pulse w-1/3 h-3 bg-sky-200 mt-3"></div>
                  <div className="animate-pulse w-full h-12 bg-sky-200 mt-3 rounded-2xl"></div>
                </div>
                <div className="flex items-center justify-center gap-5 w-full">
                  <div className="flex items-center justify-center gap-5 w-full">
                    <div className=" animate-pulse w-[100px] h-[200px] rounded-t-full rounded-b-full bg-slate-200"></div>
                    <div className=" animate-pulse w-[200px] h-[300px]  rounded-t-full rounded-b-full bg-slate-200"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="font-Trueno flex flex-col-reverse md:flex-row justify-between items-center w-[90%] mx-auto ">
              <div className="w-full md:w-[650px] text-center md:text-left">
                <div
                  className="heading-style text-center md:text-left md:mb-[0px] mb-[51.8px]"
                  dangerouslySetInnerHTML={{ __html: web_banner_text }}
                ></div>
                <div
                  className="font-normal text-base md:text-lg text-white my-6"
                  dangerouslySetInnerHTML={{ __html: super_category_description }}
                ></div>
                <div className="my-3">
                  <SearchBar />
                </div>
              </div>
              {/* <------------- Hero----Section----Image-------- > */}
              <div className="w-fit max-w-[363.76px] justify-center md:justify-start">
                <img src={web_banner_image} alt="" className="h-[331.36px] my-16 mb-[0px] md:mb-[81.8px] md:mr-[134px] " />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full font-Trueno mt-14">
        <div className="flex flex-col gap-8 w-[90%] mx-auto">
          {/* ----------------------- Category Popular Services ----------------------- */}

          {popular_services_by_category_data?.success === false ? null : (
            <div className="w-full mx-auto mb-10">
              <h2 className="w-full text-center font-extrabold text-2xl md:text-4xl">Weekly Offers</h2>

              <Swiper
                grabCursor={true}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                }}
                navigation={{ nextEl: "#arrow-right1", prevEl: "#arrow-left1" }}
                modules={[Autoplay, Navigation]}
                className="w-full mx-auto"
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1250: {
                    slidesPerView: 5,
                    spaceBetween: 30,
                  },
                }}
              >
                <div className="flex gap-3">
                  {popular_services_by_category_isLoading
                    ? Array(4)
                      .fill()
                      .map((_, index) => (
                        <div key={index} className="w-full my-7">
                          <div className="flex flex-col gap-4 md:gap-8 mx-auto justify-between w-[231px] bg-white drop-shadow-md rounded-2xl p-3">
                            <div className="animate-pulse rounded-xl w-full h-[210.22px] bg-slate-200"></div>
                            <div className="p-3 space-y-4">
                              <div className="">
                                <div className="animate-pulse rounded-xl w-full h-[22px] bg-slate-200"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : popular_services_by_category_data?.data?.map((item, index) => (
                      <SwiperSlide key={index} className="my-7">
                        <button onClick={() => handle_popular_cat(item)}>
                          <div className="h-[274px] w-[231px] border rounded-2xl border-[#DFDFDF] shadow-lg	 p-[7px] flex flex-col gap-2 justify-between items-center  cursor-pointer">
                            <div className="h-[210px] w-[210.6px]">
                              <img src={item?.icon} alt="pictures" className="rounded-xl h-full w-full object-fit" />
                            </div>

                            <h1 className="font-semibold text-center text-[14px] lg:text-[18px] leading-[22px] text-black">
                              {item?.name?.length > 32 ? item?.name.substring(0, 32) + "...." : item?.name}
                            </h1>
                          </div>
                        </button>
                      </SwiperSlide>
                    ))}
                </div>
              </Swiper>
            </div>
          )}

          {/* ----------------------- Category Services ----------------------- */}

          <div className="flex flex-col gap-3 justify-between items-center text-center">
            <h1 className="font-extrabold text-2xl md:text-4xl">{super_category_name}</h1>
          </div>

          <div className="grid gap-4 md:gap-8 lg:grid-cols-3 xl:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 mx-auto">
            {isLoading
              ? Array(5)
                .fill()
                .map((_, index) => (
                  <div
                    key={index}
                    className="flex flex-col gap-4 md:gap-8 mx-auto justify-between w-[231px] bg-white drop-shadow-md rounded-2xl p-3"
                  >
                    <div className="animate-pulse rounded-xl w-full h-[210.22px] bg-slate-200"></div>
                    <div className="p-3 space-y-4">
                      <div className="">
                        <div className="animate-pulse rounded-xl w-full h-[22px] bg-slate-200"></div>
                      </div>
                    </div>
                  </div>
                ))
              : data?.data?.en?.map((item, index) => (
                <ul key={index} className="">
                  <button onClick={() => handleClick(item)}>
                    {/* <Link to="/HomeMaintenanceSub_Cat"> */}
                    <li
                      key={item?.category_id}
                      className="h-[274px] w-[231px] border rounded-2xl border-[#DFDFDF] shadow-lg	 p-[7px] flex flex-col gap-2 justify-between items-center  cursor-pointer"
                    >
                      <div className="h-[210px] w-[210.6px]">
                        <img src={item?.category_icon} alt="pictures" className="rounded-xl h-full w-full object-fit" />
                      </div>

                      <h1 className="font-semibold text-center text-[14px] lg:text-[18px] leading-[22px] ">
                        {item?.category_name?.length > 32 ? item?.category_name.substring(0, 32) + "...." : item?.category_name}
                      </h1>
                    </li>
                  </button>
                </ul>
              ))}
          </div>
        </div>
      </div>
      <CustomerChoosesUs color="#ECF7FE" TextColor="black" />
      <CustomerLoveUs color="#FAFDFF" QuoteIconColor="#07609C" FaArrowCircleColor="#07609C" TextColor="#0973BA" />
      {/* <GetMoreDoneWithKarsaaz color="#ECF7FE" TextColor="#0973BA" /> */}
      <FooterMain />
    </>
  );
};

export default Services;
