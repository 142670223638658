import * as yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { useEffect, useState } from "react";
import { useRegister } from "hooks/useAuth";
import OtpModal from "./OtpModal";
import { showErrorToast } from "store/features/toastSlice";
import { useDispatch } from "react-redux";

const SocialRegisterModal = ({
  onClose,
  user,
  social_data,
  setCurrentStep,
  setAuth_user,
  setOtp_modal_data,
  otp_modal_data,
  mobile_number,
  setMobile_number,
  handleNext,
  step,
  handle_request_submit,
  showRefferField,
  setShowRefferField,
}) => {
  const { mutate, isLoading, data } = useRegister();

  window.onbeforeunload = null;
  window.onpopstate = null;

  useEffect(() => {
    if ((user === "google" || user === "facebook") && data?.success === true) {
      const payload = {
        id: data?.data?.user_id,
        token: data?.data?.token,
      };
      setAuth_user(payload);
      setOtp_modal_data(payload);

      handleNext();
    } else if (user === "request_user" && data?.success === true) {
      const payload = {
        id: data?.data?.user_id,
        token: data?.data?.token,
      };
      setOtp_modal_data(payload);

      setCurrentStep(5);
    } else {
      if (data?.success === true) {
        const payload = {
          user_id: data?.data?.user_id,
          token: data?.data?.token,
        };
        setOtp_modal_data(payload);
        localStorage.setItem("token", payload?.token);
        localStorage.setItem("id", JSON.stringify(payload?.user_id));

        setCurrentStep(5);
      }
    }
  }, [data, user]);

  // Initializing values for Formik & yup

  const initialValues = {
    phone: "",
    name: social_data?.user?.providerData?.[0]?.displayName,
    gender: "",
    termsAndConditions: "true",
    device_type: "web",
    login_by: "manual",
    email: social_data?.user?.providerData?.[0]?.email,
    non_reffer_code: false,
    referral_code: "",
  };
  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      termsAndConditions: yup
        .bool()
        .oneOf([true], "You need to accept the terms and conditions"),

      gender: yup.string().required("Enter the Gender"),

      phone: yup
        .string()
        .matches(/^[0-9]+$/, "Invalid phone number")
        .max(11)
        .min(11)
        .required("Enter Phone Number"),
      referral_code: yup
        .string()
        .when("non_reffer_code", (non_reffer_code, schema) =>
          non_reffer_code
            ? schema.required("You need to add a reffer code to continue")
            : schema
        ),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const newObj = {
        mobile: values.phone,
        password: "123456",
        name: social_data?.user?.providerData?.[0]?.displayName,
        email: values.email,
        device_type: "web",
        login_by: user === "google" ? "google" : "facebook",
        signup_by: user === "google" ? "google" : "facebook",
        isbuyer: "yes",
        gender: values.gender,
        google_unique_id:
          user === "google" ? social_data?.user?.providerData?.[0]?.uid : "",
        facebook_unique_id:
          user === "facebook" ? social_data?.user?.providerData?.[0]?.uid : "",

        referral_code: showRefferField === true ? values?.referral_code : null,
      };

      mutate(newObj);
      setMobile_number(newObj?.mobile);
    },
  });
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    Formik;

  return (
    <>
      <div className="w-full font-Trueno bg-[#AEE0FF]">
        <div className="fixed inset-0 overflow-y-auto scrollbar-hide  bg-black bg-opacity-25 backdrop-blur-sm px-3 md:px-9 py-12">
          <div className="md:w-[50%] h-auto mx-auto bg-white px-3 md:px-6 flex justify-center border rounded-[15px] relative">
            <button
              className=" font-bold uppercase  py-2 text-sm outline-none focus:outline-none mb-1 absolute right-5 top-5"
              type="button"
            >
              <RxCross2
                onClick={onClose}
                className="w-[18px] md:w-[22px] h-[18px] md:h-[22px]"
              />
            </button>
            <>
              {step === 1 && (
                <div className="flex flex-col w-full  font-Trueno pt-14 md:pt-0">
                  {/* --------------------- Form Area --------------------- */}

                  <div className="my-0 md:my-14 flex flex-col gap-9">
                    <h1 className="font-bold md:font-extrabold text-[22px] md:text-[36px] leading-[26.4px] md:leading-[43px]">
                      Register{" "}
                      <span className="text-[#0973BA]">Social Account</span>
                    </h1>

                    <form onSubmit={handleSubmit}>
                      <div className="flex flex-col gap-5 w-full">
                        {/* ------------------ Phone Number Field ------------------ */}

                        <div className="flex flex-col gap-3">
                          <label
                            htmlFor="mobile Number"
                            className="font-semibold text-xs md:text-sm"
                          >
                            Mobile Number{" "}
                            <label className="text-[#0973BA]">*</label>
                          </label>
                          <input
                            type="Phone"
                            autoComplete="off"
                            name="phone"
                            id="Phone"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Mobile Number"
                            className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs  md:placeholder:text-sm"
                          />
                          {errors.phone && touched.phone ? (
                            <p className="font-semibold text-sm text-red-600">
                              {errors.phone}
                            </p>
                          ) : null}
                        </div>
                        {/* ------------------ EmailAddress Field ------------------ */}

                        <div className="flex flex-col gap-3">
                          <label
                            htmlFor="Full Name"
                            className="font-semibold text-xs md:text-sm"
                          >
                            Email Address
                          </label>
                          <input
                            type="email"
                            autoComplete="off"
                            name="email"
                            id="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Email Address"
                            className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs  md:placeholder:text-sm"
                          />
                        </div>
                        {/* ------------------ Gender Field ------------------ */}

                        <label className="font-semibold text-xs md:text-sm">
                          Gender <label className="text-[#0973BA]">*</label>{" "}
                        </label>
                        <div className="grid grid-cols-2 sm:flex gap-1 md:gap-3 items-center">
                          <div className="flex gap-4">
                            <input
                              type="radio"
                              id="male"
                              name="gender"
                              value="male"
                              checked={values.gender === "male"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              htmlFor="Male"
                              className="font-normal text-xs md:text-sm text-[#0973BA]"
                            >
                              Male
                            </label>
                          </div>
                          <div className="flex gap-4">
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              value="female"
                              checked={values.gender === "female"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              htmlFor="Female"
                              className="font-normal text-xs md:text-sm text-[#0973BA]"
                            >
                              Female
                            </label>
                          </div>
                          <div className="flex gap-4">
                            <input
                              type="radio"
                              id="Non-Specified"
                              name="gender"
                              value="non-specified"
                              checked={values.gender === "non-specified"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              htmlFor="Non-Specified"
                              className="font-normal text-xs md:text-sm text-[#0973BA]"
                            >
                              Non-Specified
                            </label>
                          </div>
                        </div>
                        {errors.gender && touched.gender ? (
                          <p className="font-semibold text-sm text-red-600">
                            {errors.gender}
                          </p>
                        ) : null}

                        {/* ------------------ Referal Code ------------------ */}

                        <div className="flex flex-col gap-3">
                          <div className="flex items-center gap-3">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                setShowRefferField(e.target.checked);
                                Formik.setFieldValue("non_reffer_code", true);
                              }}
                            />
                            <label
                              htmlFor="reffercode"
                              className="font-semibold text-xs md:text-sm"
                            >
                              Do you have a reffer code?
                            </label>
                          </div>
                          {showRefferField === true ? (
                            <input
                              type="text"
                              autoComplete="off"
                              name="referral_code"
                              id="referral_code"
                              value={values.referral_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Reffer Code"
                              className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs  md:placeholder:text-sm"
                            />
                          ) : null}
                        </div>
                        {errors.referral_code && touched.referral_code ? (
                          <p className="font-semibold text-sm text-red-600">
                            {errors.referral_code}
                          </p>
                        ) : null}

                        {/* ------------------ Check Box ------------------ */}

                        <div className="flex items-center gap-3">
                          <input
                            type="checkbox"
                            name="termsAndConditions"
                            id="termsAndConditions"
                            value={values.termsAndConditions}
                            checked={values.termsAndConditions}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label
                            htmlFor="termsAndConditions"
                            className="font-semibold text-xs md:text-sm"
                          >
                            I agree to{" "}
                            <Link to="/karsaaz-terms-and-conditions">
                              <span className="text-[#0973BA]">
                                Terms and Conditions{" "}
                              </span>
                            </Link>
                            and accept
                            <Link to="/privacy-policy">
                              <span className="text-[#0973BA]">
                                {" "}
                                Privacy policy
                              </span>
                            </Link>
                          </label>
                        </div>
                        {errors.termsAndConditions &&
                        touched.termsAndConditions ? (
                          <p className="font-semibold text-sm text-red-600">
                            {errors.termsAndConditions}
                          </p>
                        ) : null}

                        {/* ------------------ Button ------------------ */}

                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleSubmit();
                          }}
                          className="font-semibold text-xs md:text-sm text-white h-[46px] w-fit mx-auto px-5 my-10 border bg-[#0973BA] rounded-lg relative"
                        >
                          {isLoading ? (
                            <p className="font-semibold text-xs md:text-sm text-white text-center">
                              Please Wait ...
                            </p>
                          ) : (
                            "Confirm"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div className="py-16">
                  <OtpModal
                    user={user}
                    otp_modal_data={otp_modal_data}
                    mobile={mobile_number}
                    handle_request_submit={handle_request_submit}
                    setCurrentStep={setCurrentStep}
                  />
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialRegisterModal;
