import { Navigate, Outlet, useNavigate } from "react-router-dom";

const useAuth = () => {
  const token = localStorage.getItem("token");
  const user = { loggedIn: !!token };
  return user && user.loggedIn;
};

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const isAuth = useAuth();

  function handleLoginSuccess() {
    navigate("/home");
  }

  return isAuth ? (
    <Navigate
      to="/home"
      state={{ from: navigate.location }}
      onLoginSuccess={handleLoginSuccess}
    />
  ) : (
    <Outlet />
  );
};

export default ProtectedRoutes;
