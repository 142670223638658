import { useEffect } from "react";
export default function UpdateMetaTags({ data }) {
  useEffect(() => {
    document.title = data?.title ?? "Professional Service at Your Doorstep - Karsaaz";
    document.querySelector('meta[name="description"]').setAttribute("content", data?.description ?? "");
    document.querySelector('meta[name="keywords"]').setAttribute("content", data?.keywords ?? "");
    document.querySelector('link[rel="canonical"]').setAttribute("href", data?.canonical_url ?? window.location.href);
  }, [data]);

  return <></>;
}
