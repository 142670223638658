import React, { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "hooks/useAuth";

const NavCustom = (props) => {
  const [open, setOpen] = useState(false);
  const { mutate, isLoading } = useLogout();

  const location = useLocation();
  const navigate = useNavigate();
  const handleLogout = () => {
    mutate();
  };

  return (
    <div
      className={`w-full text-white  font-Trueno first-letter:first-line mx-auto`}
    >
      <div className="md:flex gap-6 w-[90%] justify-between py-4 mx-auto  ">
        <div className="grid grid-flow-col auto-cols-max gap-6">
          <img
            className="object-cover md:shrink-0 h-[41.15px] cursor-pointer"
            src={`${process.env.PUBLIC_URL}/images/karsaazBlueWhiteLogo.png`}
            alt=""
            onClick={() => navigate("/")}

          />

          <img
            src={`${process.env.PUBLIC_URL}/images/HomeLogoIcon.svg`}
            alt="Home Logo"
            className="object-cover md:shrink-0 hidden lg:block cursor-pointer h-[41.15px] font-medium hover:scale-105 duration-200"
            onClick={() => navigate("/")}
          />
        </div>

        <div
          onClick={() => setOpen(!open)}
          className="text-black absolute right-8 top-6 cursor-pointer block tablet:hidden"
        >
          {open ? (
            <XMarkIcon className="w-[24px] h-[24px]" color="white" />
          ) : (
            <Bars3Icon className="w-[24px] h-[24px]" color="white" />
          )}
        </div>

        <ul
          style={props.styling}
          className={`grid grid-cols-1 tablet:grid tablet:grid-flow-col auto-cols-max gap-6 place-content-start tablet:place-items-center tablet:pb-0 pb-12  absolute tablet:static tablet:z-auto z-10 left-0 w-full tablet:w-auto tablet:pl-0 pl-9 transition-all duration-500  text-white ease-in ${open ? "top-24" : "top-[-530px]"
            }
          ${location?.pathname === "/"
              ? "bg-[#07609C]"
              : location?.pathname === "/PersonalCareMain" ||
                location?.pathname === "/DiscountDealsSub_cat" ||
                location?.pathname === "/HairCareSub_cat" ||
                location?.pathname === "/MakeUpServiceAtHomeSub_cat" ||
                location?.pathname === "/SkinCareServicesAtHomeSub_cat"
                ? "bg-[#F3A267]"
                : location?.pathname === "/settings"
                  ? "bg-[#AEE0FF]"
                  : location?.pathname === "/about-us"
                    ? "bg-[#0973BA]"
                    : location?.pathname === "/login"
                      ? "bg-[#AEE0FF]"
                      : location?.pathname === "/register"
                        ? "bg-[#AEE0FF]"
                        : location?.pathname === "/joinAsServiceProvider"
                          ? "bg-[#AEE0FF]"
                          : location?.pathname === "/rewards"
                            ? "bg-[#0973BA]"
                            : location?.pathname === "/joinAsServiceProvider"
                              ? "bg-[#0973BA]"
                              : location?.pathname === "/privacy-policies"
                                ? "bg-[#0973BA]"
                                : location?.pathname === "/contact-us"
                                  ? "bg-[#0973BA]"
                                  : location?.pathname === "/faq"
                                    ? "bg-[#0973BA]"
                                    : location?.pathname === "/home"
                                      ? "bg-[#07609C]"
                                      : location?.pathname === "/BridalMakeupMain"
                                        ? "bg-[#DE3740]"
                                        : "bg-blend-normal" // default color code
            }
          `}
        >
          {/* <div className="hide lg:block relative inline-block transition-all duration-500  text-white ease-in">
            <div>
              <button
                type="button"
                onClick={toggleDropdown}
                className="flex items-center gap-1 cursor-pointer capitalize font-semibold text-[14px] transition-all ease-in hover:scale-105
                duration-200"
              >
                Services
                <RiArrowDownSLine />
              </button>
            </div>

            {isOpen && (
              <div className="absolute right-0 mt-2 w-[200px] bg-[#0973BA] rounded-md">
                <div className="px-1 py-1">
                  {super_services?.data?.en?.map((item, index) => (
                    <div>
                      <button
                        type="button"
                        key={index}
                        onClick={() => handleClick(item)}
                        className="font-semibold text-[14px] hover:scale-105 duration-200 text-white"
                      >
                        {item?.super_category_name}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div> */}
          {/* {NavBarData.map((item) => (
            <li
              key={item.name}
              className="cursor-pointer capitalize font-semibold text-[14px] leading-4 hover:scale-105 duration-200 md:my-0 my-7 "
            >
              <Link to={item.path}>{item.name}</Link>
            </li>
          ))} */}

          {/* <div className="hidden lg:hidden xl:block 2xl:flex">
            <CitySearchBar />
          </div> */}
          {!localStorage.getItem("token") ? (
            <>
              <li className="cursor-pointer capitalize font-semibold text-[14px] hover:scale-105 duration-200">
                <Link to="/login">Login </Link>
              </li>
              <li className="md:my-0 my-2 cursor-pointer capitalize font-semibold text-[14px] hover:scale-105 duration-200">
                <Link to="/register"> Create Account </Link>
              </li>

              <button className="bg-[#09BA70] rounded-xl text-[#FFFFFF] font-semibold	text-[14px] leading-[17px] text-center px-[12px] py-[11px] my-4 md:my-0 max-w-[175px] capitalize">
                <Link to="/join-as-service-provider"> Join as an Expert</Link>
              </button>
            </>
          ) : (
            <>
              <li className="cursor-pointer capitalize font-semibold text-[14px] hover:scale-105 duration-200">
                <Link to="/karsaaz-app-rewards"> Rewards </Link>
              </li>
              <li className="cursor-pointer capitalize font-semibold text-[14px] hover:scale-105 duration-200">
                <Link to="/transaction-history"> Transaction History</Link>
              </li>
              <li className="cursor-pointer capitalize font-semibold text-[14px] hover:scale-105 duration-200">
                <Link to="/history"> History</Link>
              </li>
              <li className="cursor-pointer capitalize font-semibold text-[14px] hover:scale-105 duration-200">
                <Link to="/my-jobs">My Jobs </Link>
              </li>
              <li className="cursor-pointer capitalize font-semibold text-[14px] hover:scale-105 duration-200">
                <Link to="/favorite-provider"> Favorite Provider</Link>
              </li>
              <li className="cursor-pointer capitalize font-semibold text-[14px] hover:scale-105 duration-200">
                <Link to="/personal-information"> Settings </Link>
              </li>
              <button
                onClick={handleLogout}
                className="flex gap-3 justify-between items-center bg-red-300  rounded-2xl text-[#FF4E4E] font-semibold	text-[14px] leading-[17px] text-center px-[18px] py-3 w-fit"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/LogoutArrow.png`}
                  alt="arrow"
                  className="w-[16px] h-[16px]"
                />
                {isLoading ? (
                  <div
                    className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      Loading...
                    </span>
                  </div>
                ) : (
                  "Logout"
                )}
              </button>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NavCustom;
