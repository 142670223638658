// ---------------------- This is Menu Bar which is using both in Navbar and footer  ----------------------

export const NavBarData = [
  // { name: "Services", path: "/" },
  { name: "FAQ", path: "/faqs" },
  { name: "About Us", path: "/about-us" },
  { name: "Rewards", path: "/karsaaz-app-rewards" },
  { name: "Contact Us", path: "/contact-us" },
  { name: "Blogs", path: "/blogs" },
];
