import { useState, useEffect } from "react";
import { FaEye, FaEyeSlash, FaFacebookF } from "react-icons/fa";
import { useFormik } from "formik";
import * as yup from "yup";
import { useRegister, useResendOtp } from "hooks/useAuth";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showSuccessToast } from "store/features/toastSlice";
import { AiOutlineGoogle } from "react-icons/ai";

import { auth, provider } from "views/firebaseAuthentication/config";
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import SocialRegisterModal from "./SocialRegisterModal";

const RegisterFormModal = ({
  onLoggedIn,
  setCurrentStep,
  setOtp_modal_data,
  otp_modal_data,
  handle_request_submit,

  user,
  setUser,
}) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(true);
  const [social_register_modal, setSocial_register_modal] = useState(false);
  const [social_data, setSocial_data] = useState("");

  const [auth_user, setAuth_user] = useState("");
  const [step, setStep] = useState(1);

  const { mutate, isLoading, data } = useRegister();
  const { mutate: otp_mutate, data: otp_data } = useResendOtp();
  const [mobile_number, setMobile_number] = useState();
  const [showRefferField, setShowRefferField] =useState(false)


  const handleFacebookLogin = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((data) => {
        setSocial_data(data);

        setSocial_register_modal(true);
      })
      .catch((error) => {
        console.error("facebook sign-in error:", error);
      });
  };

  const handleGoogleLogin = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        setSocial_data(data);

        setSocial_register_modal(true);
      })
      .catch((error) => {
        console.error("Google sign-in error:", error);
      });
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  // Initalizing values for Formik & yup

  const initialValues = {
    phone: "",
    name: "",
    password: "",
    confirm_password: "",
    gender: "",
    termsAndConditions: "true",
    device_type: "web",
    login_by: "manual",
    non_reffer_code: false,
    referral_code: ""
  };
  const Formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      name: yup.string().min(2).max(25).required("Please Enter Your Name"),
      password: yup.string().min(6).required("Enter Your Password"),
      confirm_password: yup
        .string()
        .required()
        .oneOf([yup.ref("password"), null], "Password must match"),

      termsAndConditions: yup.bool().oneOf([true], "You need to accept the terms and conditions"),

      gender: yup.string().required("Enter the Gender"),

      phone: yup
        .string()
        .matches(/^[0-9]+$/, "Invalid phone number")
        .max(11)
        .min(11)
        .required("Enter Phone Number"),

        referral_code: yup.string()
        .when("non_reffer_code", (non_reffer_code, schema) => (non_reffer_code ? schema.required("You need to add a reffer code to continue") : schema)),
  
    }),
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const newObj = {
        mobile: values.phone,
        password: values.password,
        name: values.name,
        device_type: "web",
        login_by: "manual",
        signup_by: "manual",
        isbuyer: "yes",
        gender: values.gender,
        referral_code: showRefferField === true ? values?.referral_code : null

      };

      mutate(newObj);

      // Formik.resetForm();
    },
  });
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } = Formik;

  useEffect(() => {
    if (data?.success === true) {
      setCurrentStep(5);
      dispatch(showSuccessToast(data?.message));

      localStorage.setItem("token", data?.data?.token);
      localStorage.setItem("id", JSON.stringify(data?.data?.user_id));

      const payload = {
        token: data?.data?.token,
        id: data?.data?.user_id,
      };

      setOtp_modal_data(payload);
    } else if (data && data?.success) {
      localStorage.setItem("token", data?.data?.token);
      localStorage.setItem("id", JSON.stringify(data?.data?.user_id));

      const payload = {
        token: data?.data?.token,
        id: data?.data?.user_id,
      };

      setOtp_modal_data(payload);
      setCurrentStep(5);
      if (onLoggedIn) {
        onLoggedIn();
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [data]);

  if (otp_data?.success) {
    // setShowRegisterPage(false);
    setCurrentStep(2);
    setTimeout(() => {
      dispatch(showSuccessToast("Please review your Booking Details and proceed further"));
    }, 1000);
  }

  const handleNext = () => {
    setStep(step + 1);
  };

  return (
    <>
      <div className="px-3 md:px-0 w-full font-Trueno">
        <div className="my-[31px] md:my-0 pb-9 border-b border-[#DFDFDF]">
          <h1 className="font-extrabold text-xl md:text-[24px] leading-[29.26px] text-center">
            Create Account to <span className="text-[#0973BA]">Continue</span>
          </h1>
        </div>

        <div className="my-0 md:my-14 flex flex-col gap-9">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-5 w-full">
              {/* ------------------ Name Field ------------------ */}

              <div className="flex flex-col gap-3">
                <label htmlFor="FullName" className="font-semibold text-xs md:text-sm">
                  Full Name <label className="text-[#0973BA]">*</label>
                </label>
                <input
                  type="name"
                  autoComplete="off"
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Name"
                  className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs  md:placeholder:text-sm"
                />
                {errors.name && touched.name ? <p className="font-semibold text-sm text-red-600">{errors.name}</p> : null}
              </div>

              {/* ------------------ Phone Number Field ------------------ */}

              <div className="flex flex-col gap-3">
                <label htmlFor="phone Number" className="font-semibold text-xs md:text-sm">
                  Mobile Number <label className="text-[#0973BA]">*</label>
                </label>
                <input
                  type="Phone"
                  autoComplete="off"
                  name="phone"
                  id="Phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Mobile Number"
                  className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs  md:placeholder:text-sm"
                />
                {errors.phone && touched.phone ? <p className="font-semibold text-sm text-red-600">{errors.phone}</p> : null}
              </div>
              {/* ------------------ EmailAddress Field ------------------ */}

              <div className="flex flex-col gap-3">
                <label htmlFor="Full Name" className="font-semibold text-xs md:text-sm">
                  Email Address
                </label>
                <input
                  type="email"
                  autoComplete="off"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email Address"
                  className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs  md:placeholder:text-sm"
                />
              </div>
              {/* ------------------ Gender Field ------------------ */}

              <label className="font-semibold text-xs md:text-sm">
                Gender <label className="text-[#0973BA]">*</label>{" "}
              </label>
              <div className="grid grid-cols-2 sm:flex gap-1 md:gap-3 items-center">
                <div className="flex gap-4">
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="male"
                    checked={values.gender === "male"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="Male" className="font-normal text-xs md:text-sm text-[#0973BA]">
                    Male
                  </label>
                </div>
                <div className="flex gap-4">
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="female"
                    checked={values.gender === "female"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="Female" className="font-normal text-xs md:text-sm text-[#0973BA]">
                    Female
                  </label>
                </div>
                <div className="flex gap-4">
                  <input
                    type="radio"
                    id="Non-Specified"
                    name="gender"
                    value="non-specified"
                    checked={values.gender === "non-specified"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="Non-Specified" className="font-normal text-xs md:text-sm text-[#0973BA]">
                    Non-Specified
                  </label>
                </div>
              </div>
              {errors.gender && touched.gender ? <p className="font-semibold text-sm text-red-600">{errors.gender}</p> : null}

              {/* ------------------ Password Field ------------------ */}

              <div className="flex flex-col gap-3 relative">
                <label htmlFor="Password" className="font-semibold text-xs md:text-sm">
                  Password <label className="text-[#0973BA]">*</label>
                </label>
                <input
                  type={showPassword ? "password" : "text"}
                  autoComplete="off"
                  name="password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Password"
                  className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs  md:placeholder:text-sm"
                />
                {errors.password && touched.password ? (
                  <p className="font-semibold text-sm text-red-600">{errors.password}</p>
                ) : null}

                <div onClick={togglePassword} className="cursor-pointer absolute inset-y-[48px] right-3 ">
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>

              {/* ------------------ Confirm Password Field ------------------ */}

              <div className="flex flex-col gap-3 relative">
                <label htmlFor="confirm_password" className="font-semibold text-xs md:text-sm">
                  Confirm Password <label className="text-[#0973BA]">*</label>
                </label>
                <input
                  type={showPassword ? "password" : "text"}
                  autoComplete="off"
                  name="confirm_password"
                  id="confirm_password"
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Confirm Your Password"
                  className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs  md:placeholder:text-sm"
                />
                {errors.confirm_password && touched.confirm_password ? (
                  <p className="font-semibold text-sm text-red-600">{errors.confirm_password}</p>
                ) : null}

                <div onClick={togglePassword} className="cursor-pointer absolute inset-y-[48px] right-3 ">
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>

                {/* ------------------ Referal Code ------------------ */}

                <div className="flex flex-col gap-3">

<div className="flex items-center gap-3">
  <input
    type="checkbox"
  onChange={(e)=>{
    setShowRefferField(e.target.checked);
    Formik.setFieldValue("non_reffer_code", true);
  }}
  />
  <label htmlFor="reffercode" className="font-semibold text-xs md:text-sm">
   Do you have a reffer code?
  </label>
  </div>
  {showRefferField === true ? (
  <input
  type="text"
  autoComplete="off"
  name="referral_code"
  id="referral_code"
  value={values.referral_code}
  onChange={handleChange}
  onBlur={handleBlur}
  placeholder="Reffer Code"
    className="rounded-lg outline-none border-[0.5px] border-[#DFDFDF] bg-[#FBFBFB] h-[46px] w-full py-4 pl-5 text-black placeholder:font-normal  placeholder-black-500 placeholder:text-xs  md:placeholder:text-sm"
  />
  ):null}
</div>
{errors.referral_code && touched.referral_code ? (
  <p className="font-semibold text-sm text-red-600">{errors.referral_code}</p>
) : null}

              {/* ------------------ Check Box ------------------ */}

              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  name="termsAndConditions"
                  id="termsAndConditions"
                  value={values.termsAndConditions}
                  checked={values.termsAndConditions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="termsAndConditions" className="font-semibold text-xs md:text-sm">
                  I agree to{" "}
                  <Link to="/karsaaz-terms-and-conditions">
                    <span className="text-[#0973BA]">Terms and Conditions </span>
                  </Link>
                  and accept
                  <Link to="/privacy-policy">
                    <span className="text-[#0973BA]"> Privacy policy</span>
                  </Link>
                </label>
              </div>
              {errors.termsAndConditions && touched.termsAndConditions ? (
                <p className="font-semibold text-sm text-red-600">{errors.termsAndConditions}</p>
              ) : null}

              {/* ------------------ Button ------------------ */}

              <button
                onClick={(e) => {
                  e.preventDefault();
                  Formik.handleSubmit();
                }}
                type="button"
                className="font-semibold text-xs md:text-sm text-white h-[46px] w-full border bg-[#0973BA] rounded-lg relative"
              >
                {isLoading ? (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div role="status" className="absolute inset-y-3.8">
                      <div role="status" className="">
                        <svg
                          aria-hidden="true"
                          className="w-6 h-6 mr-2 text-slate-200 animate-spin  fill-[#0973BA]"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  "Sign Up"
                )}
              </button>
              <div className="flex gap-2 justify-between items-center ">
                <hr className="w-full border-gray-300 border-0.5 hidden md:block" />
                <h1 className="w-full whitespace-nowrap text-center font-semibold text-[12px] leading-[14.63px]">
                  Register using your account on
                </h1>
                <hr className="w-full border-gray-300 border-0.5 hidden md:block" />
              </div>

              <button
                type="button"
                onClick={() => {
                  handleFacebookLogin();
                  setUser("request_user");
                }}
                className="flex justify-center items-center font-semibold text-xs md:text-sm text-white text-center bg-[#1773EA] py-[14px] px-4 w-full rounded-lg"
              >
                <div className="w-full flex justify-center items-center">
                  <FaFacebookF className="flex-none grow-0 w-[24px] h-[24px]" />
                  <p className="grow">Facebook</p>
                </div>
              </button>

              <button
                type="button"
                onClick={() => {
                  handleGoogleLogin();
                  setUser("request_user");
                }}
                className="flex justify-center items-center font-semibold text-xs md:text-sm text-white text-center bg-[#EB4235] py-[14px] px-4 w-full rounded-lg"
              >
                <div className="w-full flex justify-center items-center">
                  <AiOutlineGoogle className="flex-none grow-0 w-[24px] h-[24px]" />
                  <p className="grow">Google</p>
                </div>
              </button>
            </div>
            {social_register_modal && (
              <SocialRegisterModal
                setSocial_register_modal={setSocial_register_modal}
                onClose={() => {
                  setSocial_register_modal(false);
                }}
                social_data={social_data}
                setSocial_data={setSocial_data}
                setCurrentStep={setCurrentStep}
                user={user}
                auth_user={auth_user}
                setAuth_user={setAuth_user}
                setOtp_modal_data={setOtp_modal_data}
                otp_modal_data={otp_modal_data}
                mobile_number={mobile_number}
                setMobile_number={setMobile_number}
                handleNext={handleNext}
                step={step}
                setStep={setStep}
                handle_request_submit={handle_request_submit}
              />
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default RegisterFormModal;
